import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SlashScreen } from '../../components'
import useSecurity from '../../security/useSecurity'

const Reload = () => {
  const history = useHistory()
  const { reload } = useSecurity()

  useEffect(() => {
    reload().then(() => {
      history.push('/')
    })
  }, [reload, history])

  return <SlashScreen />
}

export default Reload
