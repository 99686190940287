import {
  Box,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Back from './BackButton'
import IFrameDemand from './IFrameDemand'
import { Breadcrumb } from '../../components'

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingBottom: 1,

    '& button': {
      maxWidth: '100%',
      fontSize: '1rem',
      textTransform: 'none',
      [theme.breakpoints.up('md')]: {
        minWidth: 175,
      },
    },
    '& .MuiTabs-scroller': {
      paddingBottom: 1,
    },
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #CECECE',
    },
    '& .MuiTabs-indicator': {
      height: 3,
    },
  },
}))

const PathInfo = [
  {
    text: 'Painel',
    href: '/painel',
  },
  {
    text: 'Clientes',
    href: '',
  },
  {
    text: 'Cadastro',
    href: '',
  },
]

function TabPanel(props) {
  const {
    children, value, index, ...rest
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const Register = () => {
  const { DEMAND_URL } = window.ENV
  const [value, setValue] = useState(0)
  const classes = useStyles()
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Breadcrumb paths={PathInfo} />
      <Box mb={10}>
        <Typography component="h1" variant="h4">Cadastro</Typography>
      </Box>

      <Tabs
        value={value}
        onChange={handleChangeTabs}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Acompanhamento de Apólices"
        className={classes.tab}
      >
        <Tab label="Cadastro" {...a11yProps(0)} />
        <Tab label="Consulta de cliente" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <IFrameDemand url={`${DEMAND_URL}/Cadastros`} title="Cadastro" height="1300" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IFrameDemand url={`${DEMAND_URL}/Clientes`} title="Consulta de cliente" height="950" />
      </TabPanel>

      <Back path="/painel" />

    </>
  )
}

export default Register
