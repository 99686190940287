import React, { useMemo, Fragment } from 'react'

import {
  Box,
  Grid,
  Paper,
  Radio,
  Typography,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  lineTitle: {
    height: '2px',
    background: '#f36523',
    border: 'none',
    width: '60px',
    float: 'left',
  },
  radio: {
    '& span': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  containerItem: {
    paddingBottom: '0px !important',
  },
}))

const QuotationCoverages = ({ parentFormik, additionalCoverages }) => {
  const classes = useStyles()
  const { touched, errors } = parentFormik

  const converagesErrors = useMemo(() => errors.coverages || [], [errors.coverages])
  const converagesTouched = useMemo(() => touched.coverages || [], [touched.coverages])

  return (
    <Box component={Paper} p={4} my={2}>
      <Box mb={3}>
        <Typography variant="subtitle1" component="h2">
          <Box fontWeight="bold">Cobertura Adicional</Box>
          <hr className={classes.lineTitle} />
        </Typography>
      </Box>

      {additionalCoverages.map((item, index) => (
        <Fragment key={item.id}>
          <Grid
            container
            spacing={2}
            alignItems="center"
          >
            <Grid item className={classes.containerItem}>{item.description}</Grid>
            <Grid item className={classes.containerItem}>
              <RadioGroup
                row
                id={`coverages-${index}`}
                {...parentFormik.getFieldProps(`coverages.${index}.check`)}
              >
                <FormControlLabel
                  value="true"
                  label="Sim"
                  title="Sim"
                  className={classes.radio}
                  control={<Radio color="primary" size="small" />}
                />
                <FormControlLabel
                  value="false"
                  label="Não"
                  title="Não"
                  className={classes.radio}
                  control={<Radio color="primary" size="small" />}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Box>
            <FormHelperText
              hidden={!converagesTouched[index]?.check || !converagesErrors[index]?.check}
              error={converagesTouched[index]?.check && !!converagesErrors[index]?.check}
            >
              {converagesErrors[index]?.check}
            </FormHelperText>
          </Box>
        </Fragment>
      ))}
    </Box>
  )
}

QuotationCoverages.propTypes = {
  parentFormik: PropTypes.object.isRequired,
  additionalCoverages: PropTypes.array.isRequired,
}

export default QuotationCoverages
