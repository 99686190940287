import React, {
  useMemo,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { isEmpty, truncate } from 'lodash'
import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  tips: {
    ...theme.overrides.MuiButton.outlined,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
    padding: '8px 25px',
    display: 'inline-block',
    borderRadius: 6,
    '& svg': {
      position: 'absolute',
      top: 4,
      right: 8,
      fontSize: '.85rem',
      background: '#E6E6E6',
      borderRadius: '50%',
      padding: 2,
      cursor: 'pointer',
    },
  },
}))

const FilterTips = forwardRef((props, ref) => {
  const {
    filter = {},
    formats = {},
    templates = {},
    onRemove: handleRemove,
  } = props

  const classes = useStyles()

  const attributes = useMemo(() => Object.keys(filter).filter((attribute) => {
    const value = filter[attribute]
    return !isEmpty(`${value}`)
  }), [filter])

  useImperativeHandle(ref, () => ({
    hasFilter: () => !isEmpty(attributes),
  }))

  return (
    <>
      { attributes.map((attribute, index) => (
        <Fragment key={index}>
          <Box
            mr={1}
            my={1 / 2}
            component="span"
            className={classes.tips}
            title="Filtro de Pesquisa"
          >
            <Box component="span">
              {/** Formats */}
              {formats[attribute] && !templates[attribute]
                && truncate(formats[attribute](filter[attribute]), { length: 20 })}

              {/** Templates */}
              {templates[attribute] && !formats[attribute]
                && templates[attribute](filter[attribute])}

              {/** Default */}
              {!formats[attribute] && !templates[attribute]
                && truncate(filter[attribute], { length: 20 })}
            </Box>
            <CloseIcon
              title="Remover Filtro"
              onClick={() => handleRemove(attribute)}
            />
          </Box>
        </Fragment>
      ))}
    </>
  )
})

FilterTips.propTypes = {
  filter: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  formats: PropTypes.object,
  templates: PropTypes.object,
}

FilterTips.defaultProps = {
  formats: {},
  templates: {},
}

export default FilterTips
