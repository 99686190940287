import React, { useMemo, useCallback, useState } from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import NewsItem from './NewsItem'
import PanelFallback from './PanelFallback'
import useBlogClient from '../../clients/BlogClient'
import PanelErrorFallback from './PanelErrorFallback'
import {
  Resolve,
  useLoader,
  CustomCarousel,
} from '../../components'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    minHeight: '342px',
    paddingBottom: theme.spacing(11),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  fallback: {
    minHeight: '406px',
  },
}))

const News = () => {
  const classes = useStyles()
  const blogClient = useBlogClient()
  const [news, setNews] = useState([])
  const { enableLoader, disableLoader } = useLoader()

  const modifyNews = (data) => {
    const RPP = 2
    const results = []
    const pages = Math.ceil(data.length / RPP)

    for (let index = 0; index < pages; index++) {
      results.push(data.slice(index * RPP, index * RPP + RPP))
    }
    setNews(results)
  }

  const handleResolve = useMemo(() => ({
    news: () => new Promise((resolve, reject) => {
      disableLoader()

      blogClient().getNews().then((response) => {
        const { data } = response.data
        enableLoader()
        resolve(data)
      }, () => {
        enableLoader()
        reject()
      })
    }),
  }), [blogClient, enableLoader, disableLoader])

  const handleLoaded = useCallback((data, resolve) => {
    modifyNews(data.news || [])
    resolve()
  }, [])

  return (
    <Card>
      <CardContent>
        <Resolve
          onLoaded={handleLoaded}
          resolve={handleResolve}
          fallback={(<PanelFallback className={classes.fallback} />)}
          errorFallback={(<PanelErrorFallback title="Notícias do Blog!" className={classes.fallback} />)}
        >
          <>
            <Box px={2} py={1}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h6" component="h3">Notícias do Blog!</Typography>
                </Grid>
                <Grid item>
                  <a
                    className={classes.link}
                    href="https://blog.pottencial.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography variant="body2" component="span">VER MAIS</Typography>
                    <Box component="span" ml={1}>
                      <ArrowForwardIosIcon style={{ fontSize: '.8rem' }} />
                    </Box>
                  </a>
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <CustomCarousel
                infinite
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                className={classes.container}
                position="bottom"
              >
                {news.map((page, index) => <NewsItem key={index} page={page} />)}
              </CustomCarousel>
            </Box>
          </>
        </Resolve>
      </CardContent>
    </Card>
  )
}

export default News
