import React, { useEffect } from 'react'

import useSecurity from '../../security/useSecurity'
import useUserClient from '../../clients/UserClient'
import { useContextAction } from '../../store/ducks/context'
import { useSecurityAction } from '../../store/ducks/security'

const RenewCallback = () => {
  const userClient = useUserClient()
  const { userManager } = useSecurity()
  const { setUser } = useSecurityAction()
  const { setContexts } = useContextAction()

  /**
   * CAUTION
   *
   * This useEffect must be executed only once in the React lifecycle,
   * in sections where the page is updated. So the eslint rule has been deactivated,
   * please kindly do not do this in your implementation,
   * unless you have a strong reason for doing so.
   */
  useEffect(() => {
    userManager.signinSilentCallback().then(() => {
      Promise.all([
        userClient().getUserCurrent(),
        userClient().getUserContexts(),
      ]).then((responses) => {
        const { data: user } = responses[0]
        setUser({ ...user })

        const { data: contexts } = responses[1]
        setContexts(contexts)
      })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <></>
}

export default RenewCallback
