import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'

const SelectInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& svg': {
      color: theme.palette.primary.contrastText,
      marginRight: 5,
    },
  },
  input: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 17,
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '5px 15px',
    fontSize: '.8rem',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 17,
      borderColor: '#80bdff',
    },
  },

}))(InputBase)

export default SelectInput
