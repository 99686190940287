export const DEFAULT_QUOTATION = {
  productType: '',
  insuredDays: '',
  broker: {
    documentNumber: '',
  },
  policyHolder: {
    id: '',
    name: '',
    document: '',
  },
  insured: {
    id: '',
    name: '',
    document: '',
  },
  policyValues: {
    contractValue: '',
    insuredAmount: '',
  },
  policyPeriod: {
    initial: '',
    final: '',
  },
  objectInsured: {
    contractNumber: '',
    processNumber: '',
    noticeNumber: '',
  },
  additionalCoverages: [],
}

export const DEFAULT_QUOTATION_INITIAL = {
  policyHolderId: '',
  policyHolderName: '',
  policyHolderDocument: '',
  insuredId: '',
  insuredName: '',
  insuredDocument: '',
  isPublicCoverage: '',
}

export const DEFAULT_QUOTATION_RISK = {
  contractValue: '',
  insuredAmount: '',
  policyPeriodInitial: '',
  policyPeriodFinal: '',
  insuredDays: '',
  contractNumber: '',
  processNumber: '',
  noticeNumber: '',
  additionalCoverages: [],
  coBrokers: [],
}

export const DEFAULT_QUOTATION_BUY = {
  shortage: '',
  installments: '',
  trustedDataConfirmation: false,
}

export const DEFAULT_QUOTATION_ANALYSIS = {
  note: '',
  files: [],
}

export const PRODUCT_TYPE = {
  BID: 1,
  Performance: 0,
}

export const STEP_TYPE = {
  INITIAL: 0,
  RISK: 1,
  CONFIRMATION: 2,
}

export const STEP_DESCRIPTION = [
  'DADOS INICIAIS',
  'DADOS DO RISCO',
  'CONFIRMAÇÃO',
]

export default {
  STEP_TYPE,
  PRODUCT_TYPE,
  STEP_DESCRIPTION,
  DEFAULT_QUOTATION,
  DEFAULT_QUOTATION_RISK,
  DEFAULT_QUOTATION_INITIAL,
}
