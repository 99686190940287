import { useCallback } from 'react'
import axios from 'axios'
import useUtils from '../../hooks/useUtils'

const usePolicyClient = () => {
  const { isBlank } = useUtils()

  const downloadPolicy = useCallback((policyNumber, proposalNumber) => axios.get(`/api/policy/PolicyFile?PolicyNumber=${policyNumber}&ProposalNumber=${proposalNumber}`), [])

  const downloadProposal = useCallback((data) => {
    const params = new URLSearchParams({})

    if (!isBlank(data?.policyId)) {
      params.append('PolicyId', data?.policyId)
    }

    if (!isBlank(data?.proposalNumber)) {
      params.append('ProposalNumber', data?.proposalNumber)
    }

    return axios.get(`/api/policy/ProposalFile?${params}`)
  }, [isBlank])

  const getPolicies = useCallback((filter) => {
    const policyIssuedFilter = {}

    const page = {
      pageSize: 10,
      pageNumber: filter.pageNumber || 0,
    }

    if (!isBlank(filter.product)) {
      policyIssuedFilter.product = filter.product
    }

    if (!isBlank(filter.descriptionModality)) {
      policyIssuedFilter.descriptionModality = filter.descriptionModality
    }

    if (!isBlank(filter.policyNumber)) {
      policyIssuedFilter.policyNumber = filter.policyNumber
    }

    if (!isBlank(filter.brokeDocument)) {
      policyIssuedFilter.brokeDocument = filter.brokeDocument
    }

    if (!isBlank(filter.policyClassification)) {
      policyIssuedFilter.policyClassification = filter.policyClassification
    }

    if (!isBlank(filter.customerName)) {
      policyIssuedFilter.customerName = filter.customerName
    }

    if (!isBlank(`${filter.initialAward || ''}`)) {
      policyIssuedFilter.initialAward = filter.initialAward
    }

    if (!isBlank(`${filter.finalAward || ''}`)) {
      policyIssuedFilter.finalAward = filter.finalAward
    }

    if (!isBlank(`${filter.initialCommission || ''}`)) {
      policyIssuedFilter.initialCommission = filter.initialCommission
    }

    if (!isBlank(`${filter.finalCommission || ''}`)) {
      policyIssuedFilter.finalCommission = filter.finalCommission
    }

    if (!isBlank(filter.dateEmissionInitial)) {
      policyIssuedFilter.dateEmissionInitial = filter.dateEmissionInitial
    }

    if (!isBlank(filter.dateEmissionFinal)) {
      policyIssuedFilter.dateEmissionFinal = filter.dateEmissionFinal
    }

    return axios.post('/api/policy/PolicyIssued', { page, filter: { policyIssuedFilter } })
  }, [isBlank])

  const getPoliciesMonitor = useCallback(({ pageNumber, orderBy, filter }) => {
    const params = new URLSearchParams({
      'Page.PageSize': 10,
      'Page.Direction': 0,
      'Page.OrderBy': orderBy,
      'Page.PageNumber': pageNumber,
    })

    if (!isBlank(filter.clientName)) {
      params.append('ClientName', `%${filter.clientName}%`)
    }

    if (!isBlank(filter.policyNumber)) {
      params.append('PolicyNumber', `${filter.policyNumber}`)
    }

    if (!isBlank(filter.startIS)) {
      params.append('StartIS', `${filter.startIS}`)
    }

    if (!isBlank(filter.endIS)) {
      params.append('EndIS', `${filter.endIS}`)
    }

    if (!isBlank(filter.startPremium)) {
      params.append('StartPremium', `${filter.startPremium}`)
    }

    if (!isBlank(filter.endPremium)) {
      params.append('EndPremium', `${filter.endPremium}`)
    }

    if (!isBlank(filter.period)) {
      params.append('Period', `${filter.period}`)
    }

    return axios.get(`/api/compulsory-renewal?${params}`)
  }, [isBlank])

  const getPoliciesRenewed = useCallback(({ pageNumber, orderBy, filter }) => {
    const params = new URLSearchParams({
      'Page.PageSize': 10,
      'Page.Direction': 0,
      'Page.OrderBy': orderBy,
      'Page.PageNumber': pageNumber,
    })

    if (!isBlank(filter.clientName)) {
      params.append('ClientName', `%${filter.clientName}%`)
    }

    if (!isBlank(filter.policyNumber)) {
      params.append('PolicyNumber', `${filter.policyNumber}`)
    }

    if (!isBlank(filter.insuredName)) {
      params.append('InsuredName', `${filter.insuredName}`)
    }

    if (!isBlank(filter.startDate)) {
      params.append('StartDate', `${filter.startDate}`)
    }

    if (!isBlank(filter.endDate)) {
      params.append('EndDate', `${filter.endDate}`)
    }

    if (!isBlank(filter.startIS)) {
      params.append('StartIS', `${filter.startIS}`)
    }

    if (!isBlank(filter.endIS)) {
      params.append('EndIS', `${filter.endIS}`)
    }

    if (!isBlank(filter.startPremium)) {
      params.append('StartPremium', `${filter.startPremium}`)
    }

    if (!isBlank(filter.endPremium)) {
      params.append('EndPremium', `${filter.endPremium}`)
    }

    if (!isBlank(filter.startCommission)) {
      params.append('StartCommission', `${filter.startCommission}`)
    }

    if (!isBlank(filter.endCommission)) {
      params.append('EndCommission', `${filter.endCommission}`)
    }

    return axios.get(`/api/compulsory-renewed?${params}`)
  }, [isBlank])

  const getDetailsPolicy = useCallback((proposalId) => axios.get(`/api/compulsory-renewal/${proposalId}`), [])

  const renewPolicy = useCallback((proposalId) => axios.post('/api/compulsory-renewal/renew', proposalId), [])

  const stopPolicy = useCallback((values) => {
    const data = new FormData()
    data.append('ProposalId', values?.proposalId)
    data.append('Justification', values?.justification)
    data.append('RequesterEmail', values?.requesterEmail)
    data.append('RequesterName', values?.requesterName)

    const files = values?.files || []

    files.forEach((file) => {
      data.append('Files', file)
    })

    return axios.post('/api/compulsory-renewal/stop', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }, [])

  const getSummary = useCallback(() => axios.get('/api/compulsory-renewal/summary'), [])

  const getSummaryRenewed = useCallback((values) => {
    const params = new URLSearchParams({
      StartDate: values.StartDate,
      EndDate: values.EndDate,
    })

    return axios.get(`/api/compulsory-renewed/summary?${params}`)
  }, [])

  const downloadDraft = useCallback((proposalNumber) => axios.get(`/api/compulsory-renewal/${proposalNumber}/draft`), [])

  const downloadExcel = useCallback(({
    pageNumber, orderBy, filter, query,
  }) => {
    const params = new URLSearchParams({
      'Page.PageSize': 10,
      'Page.Direction': 0,
      'Page.OrderBy': orderBy,
      'Page.PageNumber': pageNumber,
    })

    if (!isBlank(filter.clientName)) {
      params.append('ClientName', `%${filter.clientName}%`)
    }

    if (!isBlank(filter.policyNumber)) {
      params.append('PolicyNumber', `${filter.policyNumber}`)
    }

    if (!isBlank(filter.startIS)) {
      params.append('StartIS', `${filter.startIS}`)
    }

    if (!isBlank(filter.endIS)) {
      params.append('EndIS', `${filter.endIS}`)
    }

    if (!isBlank(filter.startPremium)) {
      params.append('StartPremium', `${filter.startPremium}`)
    }

    if (!isBlank(filter.endPremium)) {
      params.append('EndPremium', `${filter.endPremium}`)
    }

    if (!isBlank(filter.period)) {
      params.append('Period', `${filter.period}`)
    }

    params.append('Filter.Client', `${query.client}`)
    params.append('Filter.IsUpdated', `${query.isUpdated}`)
    params.append('Filter.Modality', `${query.modality}`)
    params.append('Filter.Policy', `${query.policy}`)
    params.append('Filter.VigencyPolicyPeriod', `${query.newVigency}`)
    params.append('Filter.IssuanceDate', `${query.vigency}`)
    params.append('Filter.Commissions', `${query.commissions}`)
    params.append('Filter.Process', `${query.process}`)
    params.append('Filter.Insured', `${query.insured}`)
    params.append('Filter.Is', `${query.is}`)
    params.append('Filter.Premium', `${query.premium}`)

    return axios.get(`/api/compulsory-renewal/export?${params}`)
  }, [isBlank])

  return useCallback(() => ({
    downloadProposal,
    downloadPolicy,
    getPolicies,
    getPoliciesMonitor,
    getPoliciesRenewed,
    getDetailsPolicy,
    renewPolicy,
    stopPolicy,
    getSummary,
    getSummaryRenewed,
    downloadDraft,
    downloadExcel,
  }), [
    downloadProposal,
    downloadPolicy,
    getPolicies,
    getPoliciesMonitor,
    getPoliciesRenewed,
    getDetailsPolicy,
    renewPolicy,
    stopPolicy,
    getSummary,
    getSummaryRenewed,
    downloadDraft,
    downloadExcel,
  ])
}

export default usePolicyClient
