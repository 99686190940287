import React, { useState } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import {
  MenuItem, makeStyles, Popover, Typography, Box, Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: 400,
    maxWidth: '100%',
  },
  ml1: {
    margin: theme.spacing(1),
  },
  uploadText: {
    fontSize: '1rem',
  },
  dropzone: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '0 none',
    '&::after': {
      content: '"Tamanho máximo: 100 MB \\A Extensões permitidas: .PNG, JPEG "',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      paddingBottom: theme.spacing(2),
    },
  },
  itemContainer: {
    maxWidth: '100%',
    flexBasis: '100%',
    '& p': {
      padding: theme.spacing(1),
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      background: '#e9e9e9',
    },
    '& img': {
      display: 'none',
    },
    '& button': {
      right: 40,
    },
  },
}))

const ChangeLogo = ({ handleCloseMenu, refElm, updateLogo }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [files, setFiles] = useState([])

  // POPOVER
  const handleClick = () => {
    setAnchorEl(refElm)
    handleCloseMenu()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeFile = (file) => {
    setFiles(file)
  }

  const saveLogo = (data) => {
    if (data.length > 0) {
      handleClose()
      updateLogo(data)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const classes = useStyles()

  return (
    <>
      <MenuItem
        aria-describedby={id}
        onClick={handleClick}
        tite="Inserir Logo"
      >
        Inserir Logo
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.root}>
          <Typography variant="h5" color="primary">Logo</Typography>

          <Box my={2}>
            <DropzoneArea
              onChange={changeFile}
              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
              dropzoneClass={classes.dropzone}
              showAlerts={false}
              showFileNames
              filesLimit={1}
              dropzoneText="Arraste e solte a imagem!"
              maxFileSize={100000}
              dropzoneParagraphClass={classes.uploadText}
              previewGridClasses={{ item: classes.itemContainer }}
            />
          </Box>

          <Box textAlign="right">
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              title="Cancelar"
            >
              Cancelar
            </Button>
            <Button
              className={classes.ml1}
              variant="contained"
              color="primary"
              onClick={() => saveLogo(files)}
              title="Definir"
            >
              Definir
            </Button>
          </Box>
        </div>
      </Popover>
    </>
  )
}

ChangeLogo.propTypes = {
  handleCloseMenu: PropTypes.func.isRequired,
  refElm: PropTypes.bool.isRequired,
  updateLogo: PropTypes.func.isRequired,
}

export default ChangeLogo
