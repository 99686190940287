import React, { useState, useMemo, useCallback } from 'react'
import {
  Box,
  Grid,
  Card,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core'

import ReactTooltip from 'react-tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import {
  Resolve,
  useLoader,
  ProgressBar,
} from '../../components'
import PanelFallback from './PanelFallback'
import PanelErrorFallback from './PanelErrorFallback'
import usePanelClient from '../../clients/PanelClient'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  period: {
    fontSize: 12,
    color: '#a1a1a1',
  },
  progressSubTitle: {
    fontSize: 14,
    marginLeft: 10,
    color: '#343434',
    fontWeight: 'bold',
  },
  apolices: {
    marginBottom: 14,
  },
  subtitle2: {
    fontWeight: 'bold',
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: 11,
  },
  paddingSubtitle: {
    paddingTop: 8,
    textAlign: 'center',
  },
  textCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9,
    color: '#7B7B7B',
  },
  textNumber: {
    fontSize: 20,
    color: '#343434',
    fontWeight: 'bold',
  },
  fallback: {
    minHeight: '390px',
  },
}))

const apoliceWrapper = (dias, qtdApolices, backgroundColor, { classes }) => (
  <Grid container alignItems="center" justify="space-between">
    <Grid item sm={6} lg={6} xs={6} className={classes.apolices}>
      <ProgressBar
        percentage={100}
        width={90}
        height={90}
        backgroundColor={backgroundColor}
      >
        <div className={classes.textCard}>
          <div>
            Em
          </div>

          <div className={classes.textNumber}>
            <strong>
              {dias}
            </strong>
          </div>
          <div>
            Dias
          </div>
        </div>
      </ProgressBar>
    </Grid>
    <Grid item sm={6} lg={6} xs={6} className={classes.apolices}>
      <Typography
        variant="caption"
        component="p"
        className={classes.progressSubTitle}
      >
        {`Vencem ${qtdApolices} Apólices`}
      </Typography>
    </Grid>
  </Grid>
)

const Policy = () => {
  const classes = useStyles()
  const panelClient = usePanelClient()
  const [policy, setPolicy] = useState({})
  const { enableLoader, disableLoader } = useLoader()

  const handleResolve = useMemo(() => ({
    policy: () => new Promise((resolve, reject) => {
      disableLoader()

      panelClient().getPolicyCard().then((response) => {
        resolve(response.data)
        enableLoader()
      }, () => {
        enableLoader()
        reject()
      })
    }),
  }), [panelClient, enableLoader, disableLoader])

  const handleLoaded = useCallback((data, resolve) => {
    setPolicy(data.policy || {})
    resolve()
  }, [])

  return (
    <Card className={classes.root}>
      <CardContent>
        <Resolve
          resolve={handleResolve}
          onLoaded={handleLoaded}
          fallback={(<PanelFallback className={classes.fallback} />)}
          errorFallback={(<PanelErrorFallback title="Renovação Compulsória" className={classes.fallback} />)}
        >
          <>
            <Box mb={1}>
              <Grid container alignItems="center" justify="center">
                <ReactTooltip place="top" type="dark" />
                <Grid item>
                  <Typography variant="subtitle2" className={classes.subtitle2}>
                    Renovação Compulsória
                    <HelpOutlineIcon
                      fontSize="small"
                      data-html
                      data-tip="Renovação compulsória é a renovação<br /> automática da apólice feita em até 60<br /> dias antes do fim da vigência da apólice."
                    />
                  </Typography>
                </Grid>
                <Grid item className={classes.paddingSubtitle}>
                  <Typography variant="subtitle2" className={classes.subtitle}>{`Você tem ${policy.total || 0} apólices de Depósito Recursal que serão renovadas!`}</Typography>
                </Grid>
              </Grid>
            </Box>
            {apoliceWrapper(policy.shortTerm?.days || 0, policy.shortTerm?.quantity || 0, '#CE5252', { classes })}
            {apoliceWrapper(policy.midTerm?.days || 0, policy.midTerm?.quantity || 0, '#F48B22', { classes })}
            {apoliceWrapper(policy.longTerm?.days || 0, policy.longTerm?.quantity || 0, '#2dce98', { classes })}
          </>
        </Resolve>
      </CardContent>

    </Card>
  )
}

export default Policy
