import React from 'react'
import {
  Grid,
  CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const PanelFallback = ({ className }) => (
  <Grid
    container
    justify="center"
    alignItems="center"
    className={className}
  >
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
)

PanelFallback.propTypes = {
  className: PropTypes.string,
}

PanelFallback.defaultProps = {
  className: '',
}

export default PanelFallback
