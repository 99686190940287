import React from 'react'
import { Callback } from 'react-oidc'
import { useHistory } from 'react-router-dom'

import useUtils from '../../hooks/useUtils'
import { SlashScreen } from '../../components'
import useSecurity from '../../security/useSecurity'
import useUserClient from '../../clients/UserClient'
import userBrokerClient from '../../clients/BrokerClient'
import { useContextAction } from '../../store/ducks/context'
import { useSecurityAction } from '../../store/ducks/security'
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics'

const AccessCalback = () => {
  const { isBlank } = useUtils()
  const history = useHistory()
  const userClient = useUserClient()
  const { userManager } = useSecurity()
  const { event } = useGoogleAnalytics()
  const brokerClient = userBrokerClient()
  const { setContexts } = useContextAction()
  const {
    setUser,
    setDemand,
    setSinister,
    setCredential,
  } = useSecurityAction()

  const sinister = (contextId) => {
    setSinister({ processing: true })

    userClient().getUrlSinister(contextId).then((response) => {
      const { url = '' } = response.data

      setSinister({
        url,
        processing: false,
        success: !isBlank(url),
      })
    }, () => {
      setSinister({
        success: false,
        processing: false,
      })
    })
  }

  const demand = (contextId) => {
    setDemand({ processing: true })

    brokerClient().getTokenDemand(contextId).then((response) => {
      const { token } = response.data

      setDemand({
        token,
        success: true,
        processing: false,
      })
    }, () => {
      setDemand({
        success: false,
        processing: false,
      })
    })
  }

  return (
    <>
      <Callback
        userManager={userManager}
        onError={(err) => console.error('onError', err)}
        onSuccess={(credential) => {
          console.info('onSuccess')
          setCredential(credential)

          Promise.all([
            userClient().getUserCurrent(),
            userClient().getUserContexts(),
          ]).then((responses) => {
            const { data: user } = responses[0]
            setUser({ ...user })

            const { data: contexts } = responses[1]
            setContexts(contexts)

            const { id: contextId, name } = user.context
            event('Acesso', 'Corretora', name || '')

            history.push('/')

            demand(contextId)
            sinister(contextId)
          }, (response) => {
            const { status = 500 } = response

            if (status !== 403) {
              history.push(`/error/${status}`)
            }
          })
        }}
      />
      <SlashScreen />
    </>
  )
}

export default AccessCalback
