import React from 'react'
import PropTypes from 'prop-types'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

const PrevButton = (props) => {
  const { className, style, onClick } = props
  return (
    <button
      type="button"
      className={className}
      style={{ ...style }}
      onClick={onClick}
      title="Anterior"
    >
      Anterior
      <KeyboardArrowLeftIcon />
    </button>
  )
}

PrevButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

PrevButton.defaultProps = {
  className: '',
  style: {},
  onClick: () => { },
}

export default PrevButton
