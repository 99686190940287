import React from 'react'
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import Policy from './Policy'
import Noticias from './News'
import Demand from './Demand'
import Sinister from './Sinister'
import Quotation from './Quotation'
import Commission from './Commission'

const useStyles = makeStyles((theme) => ({
  containerBox: {
    [theme.breakpoints.up('xl')]: {
      width: '90%',
      margin: 'auto',
    },
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
  },
  container: {
    height: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    '& >div': {
      width: '100%',
      '&:last-child': {
        flex: 1,
        marginTop: theme.spacing(4),
      },
    },
  },
}))

const Panel = () => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.title}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm="auto">
            <Typography variant="h5" component="h1">Painel</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.containerBox}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={12} lg={6}>
            <Commission />
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={6}>
            <Quotation />
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={3}>
            <Box mb={2}>
              <Demand />
            </Box>
            <Box mb={0}>
              <Sinister />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={3}>
            <Policy />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Noticias />
          </Grid>

        </Grid>
      </Box>
    </>
  )
}

export default Panel
