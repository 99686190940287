import React, { useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import useUserClient from '../../clients/UserClient'
import { useAlert, AlertProvider } from '../../components'

const useStyles = makeStyles((theme) => ({
  btn: {
    fontWeight: 600,
    padding: theme.spacing(1),
  },
  changeLink: {
    fontSize: '.8rem',
    textDecoration: 'none',
    '& svg': {
      marginLeft: 2,
      fontSize: '.8rem',
    },
  },
}))

const ConfirmEmail = () => {
  const classes = useStyles()
  const { mail } = useParams()
  const { addMsgSuccess, addMsgDanger } = useAlert('check-email')
  const userClient = useUserClient()
  const decryptedMail = useMemo(() => atob(mail), [mail])

  const handleResend = () => {
    userClient().requestPassword(decryptedMail, window.location.origin).then(() => {
      addMsgSuccess('E-mail reenviado com sucesso!')
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  return (
    <>
      <Box my={8} align="center">
        <Typography variant="h4">Verifique seu e-mail</Typography>
      </Box>

      <Box mb={4} mt={1}>
        <AlertProvider id="check-email" />
      </Box>

      <Box mb={8} align="center">
        <Typography align="center">
          <Box component="span">Para concluir seu cadastro, acesse o link enviado para o e-mail</Box>
          <Box component="span" ml={1}>{`${decryptedMail}.`}</Box>
        </Typography>
      </Box>

      <Box mb={2} align="center">
        <Button
          color="primary"
          variant="outlined"
          onClick={handleResend}
          className={classes.btn}
          title="Reenviar e-mail de confirmação"
        >
          Reenviar e-mail de confirmação
        </Button>
      </Box>

      <Box align="center">
        <Button
          to="/signin"
          component={Link}
          className={classes.btn}
          title="Voltar ao Login"
        >
          VOLTAR AO LOGIN
        </Button>
      </Box>
    </>
  )
}

export default ConfirmEmail
