import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import { makeStyles, useTheme } from '@material-ui/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    '& canvas': {
      maxWidth: '100%',
    },
  },
}))

function LineChart({
  className, data: dataProp, labels, ...rest
}) {
  const theme = useTheme()
  const classes = useStyles()

  const data = (canvas) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 400)

    gradient.addColorStop(0, fade(theme.palette.primary.main, 0.4))
    gradient.addColorStop(0.5, 'rgba(255,255,255,0)')
    gradient.addColorStop(1, 'rgba(255,255,255,0)')

    return {
      datasets: [
        {
          data: dataProp,
          backgroundColor: gradient,
          borderColor: theme.palette.primary.main,
          borderWidth: 1.5,
          pointBorderColor: theme.palette.primary.main,
          pointBorderWidth: 1,
          pointRadius: 4,
          pointBackgroundColor: '#FFFFFF',
        },
      ],
      labels,
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 7,
            callback: (value) => (
              ` R$ ${value.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            ),
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      titleFontColor: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: () => { },
        label: (tooltipItem) => (
          ` R$ ${tooltipItem.yLabel.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`
        ),
      },
    },
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Line
        data={data}
        options={options}
      />
    </div>
  )
}

LineChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
}

LineChart.defaultProps = {
  className: '',
}

export default LineChart
