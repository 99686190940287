import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import { Link as RouterLink } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import ListItemText from '@material-ui/core/ListItemText'

import useUserClient from '../../clients/UserClient'
import UserPermissionModal from './UserPermissionModal'
import { MessageBox, useAlert } from '../../components'

const UserAction = ({
  user,
  updateAction,
}) => {
  const buttonRef = useRef()
  // HANDLE ANCHOR
  const [open, setOpen] = useState(false)
  const userClient = useUserClient()

  // MENSAGENS DE FEEDBACK
  const { addMsgDanger, addMsgSuccess } = useAlert()

  // RESETAR SENHA
  const [openModalResetPassword, setOpenModalResetPassword] = useState(false)
  const handleModalResetPassword = () => {
    setOpen(false)
    setOpenModalResetPassword(!openModalResetPassword)
  }
  const confirmResetPassword = () => {
    userClient().requestPassword(user.email).then(() => {
      handleModalResetPassword()
      addMsgSuccess('E-mail de redefinição de senha enviado!')
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  // EXCLUIR USUÁRIO
  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false)

  const handleModalDeleteUser = () => {
    setOpen(false)
    setOpenModalDeleteUser(!openModalDeleteUser)
  }

  const confirmDeleteUser = () => {
    userClient().deleteUser(user.id).then(() => {
      handleModalDeleteUser()
      addMsgSuccess({
        message: 'Excuido com sucesso!',
        action: () => updateAction(),
      })
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  // DESATIVAR USUÁRIO
  const [openModalDesactiveUser, setOpenModalDesactiveUser] = useState(false)

  const handleModalDesactiveUser = () => {
    setOpen(false)
    setOpenModalDesactiveUser(!openModalDesactiveUser)
  }

  const confirmDesactiveUser = () => {
    userClient().toggleActiveUser(user.id, false).then(() => {
      handleModalDesactiveUser()
      addMsgSuccess({
        message: 'Desativado com sucesso!',
        action: () => updateAction(),
      })
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  // ATIVAR USUÁRIO
  const [openModalActiveUser, setOpenModalActiveUser] = useState(false)

  const handleModalActiveUser = () => {
    setOpen(false)
    setOpenModalActiveUser(!openModalActiveUser)
  }

  const confirmActiveUser = () => {
    userClient().toggleActiveUser(user.id, true).then(() => {
      handleModalActiveUser()
      addMsgSuccess({
        message: 'Ativado com sucesso!',
        action: () => updateAction(),
      })
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  // PERMISSÕES
  const [functionality, setFunctionality] = useState([])
  const [openModalPermission, setOpenModalPermission] = useState(false)
  const [permission, setPermission] = useState([])
  const handleModalPermission = () => {
    setOpen(false)
    setOpenModalPermission(!openModalPermission)
  }

  const handleOpenPermission = () => {
    userClient().getFunctionality().then((response) => {
      const data = response.data.map(
        (item) => ({ ...item, checked: false }),
      )
      setFunctionality(data)
      handleModalPermission()
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const confirmModalPermission = () => {
    userClient().setFunctionality(user.id, permission).then(() => {
      handleModalPermission()
      addMsgSuccess({
        message: `As permissões do usuário ${user.name} foram alteradas com sucesso!`,
        action: () => updateAction(),
      })
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  return (
    <>
      {/* RESETAR SENHA */}
      <MessageBox
        opened={openModalResetPassword}
        handleClose={handleModalResetPassword}
        title="Redefinir senha"
        text={`Um email de redefinição será enviado para o email “${user.email}” que está associado a este usuário.`}
        handleSecondary={handleModalResetPassword}
        handlePrimary={confirmResetPassword}
      />

      {/* EXCLUIR USUÁRIO */}
      <MessageBox
        opened={openModalDeleteUser}
        handleClose={handleModalDeleteUser}
        title="Excluir Usuário"
        text={`Deseja realmente excluir o usuário “${user.name}”?`}
        handleSecondary={handleModalDeleteUser}
        handlePrimary={confirmDeleteUser}
      />

      {/* DESATIVAR USUÁRIO */}
      <MessageBox
        opened={openModalDesactiveUser}
        handleClose={handleModalDesactiveUser}
        title="Desativar Usuário"
        text={`Deseja realmente Desativar o usuário “${user.name}”?`}
        handleSecondary={handleModalDesactiveUser}
        handlePrimary={confirmDesactiveUser}
      />

      {/* ATIVAR USUÁRIO */}
      <MessageBox
        opened={openModalActiveUser}
        handleClose={handleModalActiveUser}
        title="Ativar Usuário"
        text={`Deseja realmente Ativar o usuário “${user.name}”?`}
        handleSecondary={handleModalActiveUser}
        handlePrimary={confirmActiveUser}
      />

      {/* PERMISSÕES */}
      <UserPermissionModal
        userInfo={user}
        opened={openModalPermission}
        onPermission={setPermission}
        functionality={functionality}
        onClose={handleModalPermission}
        handleSecondary={handleModalPermission}
        handlePrimary={confirmModalPermission}
      />

      <IconButton color="primary" title="Ações" ref={buttonRef} onClick={() => setOpen(true)}>
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={buttonRef.current}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setOpen(false)}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!user.administrator && (
          <MenuItem title="Permissões">
            <ListItemText
              primary="Permissões"
              onClick={handleOpenPermission}
            />
          </MenuItem>
        )}

        <MenuItem
          title="Editar usuário"
          component={RouterLink}
          to={`/usuario/${user.id}/editar`}
        >
          <ListItemText
            primary="Editar usuário"
          />
        </MenuItem>
        <MenuItem title="Redefinir senha">
          <ListItemText
            primary="Redefinir senha"
            onClick={handleModalResetPassword}
          />
        </MenuItem>
        {
          user.active
            ? (
              <MenuItem title="Desativar usuário">
                <ListItemText
                  primary="Desativar usuário"
                  onClick={handleModalDesactiveUser}
                />
              </MenuItem>
            ) : (
              <MenuItem title="Ativar usuário">
                <ListItemText
                  primary="Ativar usuário"
                  onClick={handleModalActiveUser}
                />
              </MenuItem>
            )
        }

        <MenuItem title="Excluir usuário">
          <ListItemText
            primary="Excluir usuário"
            onClick={handleModalDeleteUser}
          />
        </MenuItem>
      </Menu>
    </>
  )
}

UserAction.propTypes = {
  user: PropTypes.object,
  updateAction: PropTypes.func.isRequired,
}

UserAction.defaultProps = {
  user: null,
}

export default UserAction
