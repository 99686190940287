import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import PrevButton from './PrevButton'
import NextButton from './NextButton'

const bottomRules = {
  bottom: 0,
  top: 'auto',
  '&.slick-prev': {
    left: '50%',
    transform: 'translate(-30px, -50%);',
  },
  '&.slick-next': {
    right: '50%',
    transform: 'translate(30px, -50%)',
  },
}
const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    '& button': {
      top: '50%',
      borderRadius: '50%',
      transform: 'translateY(-50%)',
      background: '#FFF',
      boxShadow: '0px 3px 6px #00000029',
      color: '#808080',
      zIndex: 9,
      width: 25,
      height: 25,
      transition: theme.transitions.create(
        'all',
        { duration: theme.transitions.duration.complex },
      ),
      '&:hover, &:focus': {
        color: '#5D5857',
        background: '#FFF',
      },
      '&:before': {
        display: 'none',
      },
      '&.slick-prev': {
        left: 0,
      },
      '&.slick-next': {
        right: 0,
      },
      '& svg': {
        width: '100%',
        height: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        ...bottomRules,
      },
    },

    '&.bottom': {
      '& button': {
        ...bottomRules,
      },
    },
  },

}))

const CustomCarousel = ({
  position, children, className, ...props
}) => {
  const classes = useStyles()

  return (
    <Slider
      {...props}
      prevArrow={<PrevButton />}
      nextArrow={<NextButton />}
      className={`${classes.carouselContainer} ${position} ${className}`}
    >
      {children}
    </Slider>
  )
}

CustomCarousel.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  position: PropTypes.oneOf([
    'side',
    'bottom',
  ]),
}

CustomCarousel.defaultProps = {
  position: 'side',
  children: {},
  className: '',
}

export default CustomCarousel
