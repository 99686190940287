import React, { useState, useMemo, useCallback } from 'react'
import {
  Box,
  Grid,
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { head } from 'lodash'
import PropTypes from 'prop-types'
import Currency from 'react-currency-formatter'

import InvoiceEmail from './InvoiceEmail'
import useUtils from '../../hooks/useUtils'
import InvoiceBarcode from './InvoiceBarcode'
import useDownload from '../../hooks/useDownload'
import InstallmentActions from './InstallmentActions'
import useInvoiceClient from '../../clients/InvoicesClient'
import { MessageBox, useAlert, Resolve } from '../../components'

import IcoPrint from '../../assets/img/ico-print.svg'
import IcoPDF from '../../assets/img/ico-file-pdf.svg'
import IcoBarcode from '../../assets/img/ico-barcode.svg'
import IcoSendEmail from '../../assets/img/ico-send-email.svg'
import IcoExcel from '../../assets/img/ico-file-excel.svg'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    marginBottom: '20px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  gridParcela: {
    alignItems: 'start',
    paddingLeft: '6px !important',
  },
  gridParcelaItem: {
    alignItems: 'start',
    paddingLeft: '16px !important',
  },
  expand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#BE431D',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '12px',
    marginTop: '10px',
  },
  containerInstallment: {
    border: '1px solid  #DEDEDE',
    padding: '20px 10px',
    borderRadius: '5px',
    width: '100%',
  },

  invoiceLabel: {
    fontWeight: 'bold',
    fontSize: 16,
  },

  invoiceBox: {
    border: '1px solid #DEDEDE',
    padding: '20px 12px',
    borderLeft: '0px',
    borderRight: '0px',
  },

  icones: {
    width: '14px',
    cursor: 'pointer',
  },
}))

const ListInstallment = ({
  invoice, invoiceId, status, amount = 0,
}) => {
  const classes = useStyles()
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()
  const invoiceClient = useInvoiceClient()
  const [installments, setInstallments] = useState([])
  const { downloadPDF, downloadExcel } = useDownload()
  const [openModalEmail, setOpenModalEmail] = useState(false)
  const [openModalBarcode, setOpenModalBarcode] = useState(false)

  const handleLoadedInstallment = useCallback((data, resolve) => {
    const installmentData = data.installment.installments
    if (installmentData !== null) {
      setInstallments(installmentData)
    }
    resolve()
  }, [])

  const handleResolveInstallment = useMemo(() => ({
    installment: () => new Promise((resolve, reject) => {
      invoiceClient().getInstallment(invoice).then((response) => {
        resolve(response.data)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
  }), [invoiceClient, addMsgDanger, invoice])

  const billet = useMemo(() => {
    const installment = head(installments || [])
    const { billets } = installment || {}
    return head(billets || [])
  }, [installments])

  const print = (pdf) => {
    const winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,'
      + 'resizable,screenX=50,screenY=50,width=850,height=1050'

    const htmlPop = `${'<embed width=100% height=100%'
      + ' type="application/pdf"'
      + ' src="data:application/pdf;base64,'}${escape(pdf)}"></embed>`

    const printWindow = window.open('', 'PDF', winparams)
    printWindow.document.write(htmlPop)
    printWindow.print()
  }

  const downloadInvoicePDF = useCallback((invoiceNumber, action) => {
    invoiceClient().getDownloadInvoicePDF(invoiceNumber).then((response) => {
      if (action === 'download') {
        downloadPDF(response.data.invoicePdfByte, `fatura_${invoiceNumber}.pdf`)
      } else {
        print(response.data.invoicePdfByte)
      }
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [invoiceClient, addMsgDanger, downloadPDF])

  const downloadInvoiceExcel = useCallback((invoiceNumber) => {
    invoiceClient().getDownloadInvoiceExcel(invoiceNumber).then((response) => {
      downloadExcel(response.data.invoiceXlsByte, `fatura_${invoiceNumber}.xls`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [invoiceClient, addMsgDanger, downloadExcel])

  const download = (type) => {
    if (type === 'PDF') {
      downloadInvoicePDF(invoice, 'download')
    } else {
      downloadInvoiceExcel(invoice)
    }
  }

  const downloadReceipt = useCallback(() => {
    invoiceClient().getPaidOffReceipt(invoiceId).then((response) => {
      downloadPDF(response.data.file, `comprovante_de_quitação_fatura_${invoice}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [invoiceClient, addMsgDanger, downloadPDF, invoiceId, invoice])

  return (
    <Resolve
      onLoaded={handleLoadedInstallment}
      resolve={handleResolveInstallment}
    >
      <>
        { billet && (
          <>
            <MessageBox
              maxWidth="md"
              opened={openModalBarcode}
              handleClose={() => setOpenModalBarcode(false)}
              title="Código de barras"
            >
              <InvoiceBarcode
                handleCancel={() => setOpenModalBarcode(false)}
                billetId={billet?.billetId}
                billetNumber={billet?.documentNumber}
                ourNumberBillet={billet?.ourNumberBillet}
              />
            </MessageBox>

            <MessageBox
              opened={openModalEmail}
              handleClose={() => setOpenModalEmail(false)}
              title="Enviar por e-mail"
              text="Insira o email que deseja enviar:"
            >
              <InvoiceEmail
                handleCancel={() => setOpenModalEmail(false)}
                ourNumberBillet={billet?.ourNumberBillet}
              />
            </MessageBox>
          </>
        )}

        <Box mt={2} mb={1} className={classes.containerInstallment}>
          <Grid
            container
            justify="space-between"
          >
            <Grid item lg={1} sm={12} xs={12} className={`${classes.grid} ${classes.gridParcela}`}>
              <Typography variant="body2" className={classes.title}>Parcela:</Typography>
            </Grid>
            <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
              <Typography variant="body2" className={classes.title}>Apólice:</Typography>
            </Grid>
            <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
              <Typography variant="body2" className={classes.title}>Proposta:</Typography>
            </Grid>
            <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
              <Typography variant="body2" className={classes.title}>Segurado:</Typography>
            </Grid>
            <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
              <Typography variant="body2" className={classes.title}>Data da Apólice:</Typography>
            </Grid>
            <Grid item lg={1} sm={12} xs={12} className={classes.grid}>
              <Typography variant="body2" className={classes.title}>Valor:</Typography>
            </Grid>
            <Grid item lg={1} sm={12} xs={12} />
          </Grid>
          {
            installments.map((item, index) => (
              <Box key={index} mt={2} mb={2}>
                <Grid
                  container
                  justify="space-between"
                >
                  <Grid item lg={1} sm={12} xs={12} className={`${classes.grid} ${classes.gridParcelaItem}`}>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {item.number}
                      /
                      {item.installmentsCount}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2">
                      {item.policyNumber}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2">{item.proposalNumber}</Typography>
                  </Grid>
                  <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2" style={{ textAlign: 'center' }}>{item.insurerName}</Typography>
                  </Grid>
                  <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2">{formatDate(item.policyDate)}</Typography>
                  </Grid>
                  <Grid item lg={1} sm={12} xs={12} className={classes.grid}>
                    <Typography variant="body2">
                      <Currency quantity={item?.installmentAmount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                    </Typography>
                  </Grid>
                  <Grid item lg={1} sm={12} xs={12} className={classes.grid}>
                    <InstallmentActions installment={item} />
                  </Grid>
                </Grid>
              </Box>
            ))
          }
          <Box mt={4} mb={2} className={classes.invoiceBox}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="body2" className={classes.invoiceLabel}>Valor da Fatura:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.invoiceLabel} style={{ color: '#5D20AE' }}>
                  <Currency quantity={amount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} mb={1}>
            <Grid container justify="flex-end">
              {status !== 'Paid' && installments.length > 0 && (
                <Grid item>
                  <Box
                    ml={1}
                    mr={1}
                    title="Código de Barras"
                    onClick={() => setOpenModalBarcode(true)}
                  >
                    <img
                      src={IcoBarcode}
                      alt="Código de Barras"
                      style={{ width: '22px' }}
                      className={classes.icones}
                    />
                  </Box>
                </Grid>
              )}

              <Grid item>
                <Box ml={1} mr={1} title="Fatura em PDF" onClick={() => download('PDF')}>
                  <img src={IcoPDF} alt="Fatura em PDF" className={classes.icones} />
                </Box>
              </Grid>

              <Grid item>
                <Box ml={1} mr={1} title="Fatura em Excel" onClick={() => download('EXCEL')}>
                  <img src={IcoExcel} alt="Fatura em Excel" className={classes.icones} />
                </Box>
              </Grid>

              <Grid item>
                <Box ml={1} mr={1} title="Imprimir Fatura" onClick={() => downloadInvoicePDF(invoice, 'print')}>
                  <img src={IcoPrint} alt="Imprimir Fatura" className={classes.icones} style={{ width: '18px' }} />
                </Box>
              </Grid>

              <Grid item>
                <Box ml={1} mr={1} title="Enviar Fatura" onClick={() => setOpenModalEmail(true)}>
                  <img src={IcoSendEmail} alt="Enviar Fatura" className={classes.icones} style={{ width: '18px' }} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        { status === 'Paid' && (
          <Box mt={2} mb={2}>
            <Grid container justify="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={downloadReceipt}
                title="Comprovante de quitação"
              >
                Comprovante de quitação
              </Button>
            </Grid>
          </Box>
        )}
      </>
    </Resolve>
  )
}

ListInstallment.propTypes = {
  invoice: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
}

export default ListInstallment
