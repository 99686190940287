import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  Card,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core'
import ReactTooltip from 'react-tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { isNull } from 'lodash'
import PolicyWrapper from './PolicyWrapper'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}))

const PolicyChartRenewal = ({ searchPolicies, queryPolicies, summary }) => {
  const classes = useStyles()

  const handleChange = (value) => {
    if (!isNull(value)) {
      const search = { ...queryPolicies, period: value }
      searchPolicies(search)
    }
  }

  const fiveDays = useMemo(() => summary?.expiryPolicy?.[0] || {}, [summary])
  const thirtyDays = useMemo(() => summary?.expiryPolicy?.[1] || {}, [summary])
  const sixtyDays = useMemo(() => summary?.expiryPolicy?.[2] || {}, [summary])

  return (
    <Box my={3}>
      <Grid container justify="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              <Box mb={3}>
                <Grid container alignItems="flex-start" justify="flex-start">
                  <ReactTooltip place="top" type="dark" />
                  <Grid item>
                    <Typography variant="subtitle2">
                      Apólices a atualizar por período
                      <HelpOutlineIcon
                        fontSize="small"
                        data-html
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3} mt={6} display="flex" justifyContent="center">
                <PolicyWrapper search={handleChange} count={5} param={queryPolicies.period || 0} item={fiveDays} backgroundColor="#CE5252" />
                <PolicyWrapper search={handleChange} count={30} param={queryPolicies.period || 0} item={thirtyDays} backgroundColor="#F48B22" />
                <PolicyWrapper search={handleChange} count={60} param={queryPolicies.period || 0} item={sixtyDays} backgroundColor="#2DCE98" />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

PolicyChartRenewal.propTypes = {
  searchPolicies: PropTypes.func.isRequired,
  queryPolicies: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
}

export default PolicyChartRenewal
