import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import {
  Box,
  Card,
  Grid,
  makeStyles,
  Typography,
  CardContent,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'

import { MONTHS } from '../../constants'
import {
  Resolve,
  LineChart,
  useLoader,
} from '../../components'
import PanelFallback from './PanelFallback'
import PanelErrorFallback from './PanelErrorFallback'
import usePanelClient from '../../clients/PanelClient'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  value: {
    color: '#157E2D',
  },
  subtitle2: {
    fontWeight: 'bold',
  },
  fallback: {
    minHeight: '280px',
  },
}))

const Commission = () => {
  const classes = useStyles()
  const panelClient = usePanelClient()
  const [commission, setCommission] = useState({})
  const { enableLoader, disableLoader } = useLoader()

  const handleResolve = useMemo(() => ({
    commission: () => new Promise((resolve, reject) => {
      disableLoader()

      panelClient().getCommissionCard().then((response) => {
        resolve(response.data)
        enableLoader()
      }, () => {
        enableLoader()
        reject()
      })
    }),
  }), [panelClient, enableLoader, disableLoader])

  const handleLoaded = useCallback((data, resolve) => {
    const { monthValue, data: dataset } = data.commission
    const { labels, values } = dataset || {}

    setCommission({
      monthValue: monthValue || 0,
      labels: (labels || []).map((month) => MONTHS[month]),
      values: values || [],
    })

    resolve()
  }, [])

  return (
    <Card className={classes.root}>
      <CardContent>
        <Resolve
          resolve={handleResolve}
          onLoaded={handleLoaded}
          fallback={(<PanelFallback className={classes.fallback} />)}
          errorFallback={(<PanelErrorFallback title="Comissão" className={classes.fallback} />)}
        >
          <>
            <Box mb={2}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="subtitle2" className={classes.subtitle2}>Comissão</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <Box component="span" mr={1}>Mês atual:</Box>
                    <Currency quantity={commission?.monthValue || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <LineChart
              data={commission?.values || []}
              labels={commission?.labels || []}
            />
          </>
        </Resolve>
      </CardContent>
    </Card>
  )
}

export default Commission
