import React from 'react'
import { Box, Typography } from '@material-ui/core'

import Back from './BackButton'
import IFrameDemand from './IFrameDemand'
import { Breadcrumb } from '../../components'

const PathInfo = [
  {
    text: 'Painel',
    href: '/painel',
  },
  {
    text: 'Clientes',
    href: '',
  },
  {
    text: 'CCG',
    href: '',
  },
]

const CCGDemand = () => {
  const { DEMAND_URL } = window.ENV

  return (
    <>
      <Breadcrumb paths={PathInfo} />
      <Box mb={10}>
        <Typography component="h1" variant="h4">Contrato Contra Garantia</Typography>
      </Box>

      <IFrameDemand url={`${DEMAND_URL}/CCG`} title="Contrato Contra Garantia" height="800" />
      <Back path="/painel" />
    </>
  )
}

export default CCGDemand
