import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  isEmpty,
  isEqual,
  isArray,
  countBy,
} from 'lodash'

import emitter from './EventSecurity'
import useUtils from '../hooks/useUtils'
import userManager from './UserManager'
import { useSecurityAction } from '../store/ducks/security'

const useSecurity = () => {
  const { isBlank } = useUtils()
  const {
    cleanUser,
    cleanDemand,
    cleanSinister,
    cleanCredential,
  } = useSecurityAction()

  const {
    user, demand, sinister, credential,
  } = useSelector(({ security }) => ({
    user: security.user,
    demand: security.demand,
    sinister: security.sinister,
    credential: security.credential,
  }))

  const getName = useCallback(() => user?.name || '', [user])

  const getUsername = useCallback(() => {
    const { profile } = credential
    return profile?.preferred_username || ''
  }, [credential])

  const getPersonId = useCallback(() => {
    const { profile = {} } = credential || {}
    return profile.person_id || ''
  }, [credential])

  const getProfile = useCallback(() => user?.profile || '', [user])

  const getCargo = useCallback(() => user?.position || '', [user])

  const getContext = useCallback(() => user?.context || {}, [user])

  const getAccessToken = useCallback(() => credential?.access_token, [credential])

  const getIdToken = useCallback(() => credential?.id_token, [credential])

  const getURLAvatar = useCallback(() => {
    const { avatar } = getContext()
    let { path } = avatar || {}

    if (!isBlank(path)) {
      path = `${path}?v=${Date.now()}`
    }
    return path || ''
  }, [getContext, isBlank])

  const getSinister = useCallback(() => sinister || {}, [sinister])

  const getDemand = useCallback(() => demand || {}, [demand])

  const getUser = useCallback(() => user, [user])

  const isLoggedIn = useCallback(() => {
    let loggedIn = false

    if (credential) {
      const currentAt = Math.round(Date.now() / 1000)
      loggedIn = credential.expires_at > currentAt
    }
    return loggedIn
  }, [credential])

  const reload = useCallback(() => new Promise((resolve) => emitter.emit('security.reload', resolve)), [])

  const signin = useCallback(() => userManager.signinRedirect(), [])

  const signout = useCallback((data) => {
    cleanUser()
    cleanDemand()
    cleanSinister()
    cleanCredential()
    userManager.signoutRedirect({ state: data || '' })
  }, [cleanUser, cleanDemand, cleanSinister, cleanCredential])

  const hasFunctionality = useCallback((data) => {
    let valid = false
    const logged = isLoggedIn()
    const { functionalities: currents = [] } = user

    if (logged && !isEmpty(currents)) {
      const functionalities = isArray(data) ? data : [data]

      functionalities.every((functionality) => {
        valid = !!countBy(currents, (current) => isEqual(functionality, current)).true

        if (valid) {
          return false
        }
        return true
      })
    }

    return valid
  }, [user, isLoggedIn])

  const onlyFunctionality = useCallback((functionality) => {
    let valid = false
    const logged = isLoggedIn()
    const { functionalities: currents = [] } = user || {}

    if (logged && !isEmpty(currents) && currents.length === 1 && !isArray(functionality)) {
      valid = !!countBy(currents, (current) => isEqual(functionality, current)).true
    }
    return valid
  }, [user, isLoggedIn])

  return {
    getUser,
    getName,
    getCargo,
    getProfile,
    getContext,
    getIdToken,
    getUsername,
    getPersonId,
    getURLAvatar,
    getAccessToken,
    getSinister,
    getDemand,
    isLoggedIn,
    credential,
    userManager,
    reload,
    signin,
    signout,
    hasFunctionality,
    onlyFunctionality,
  }
}

export default useSecurity
