import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  IconButton,
  makeStyles,
  FormHelperText,
} from '@material-ui/core'
import * as Yup from 'yup'
import { find } from 'lodash'
import { Autocomplete } from '@material-ui/lab'
import React, { useState, useCallback } from 'react'

import { useFormik } from 'formik'
import useYup from '../../hooks/useYup'
import { useBroker } from './BrokerViewContext'
import useBankClient from '../../clients/BankClient'
import { ModalInfo, useAlert } from '../../components'
import icoEdit from '../../assets/img/ico-edit-default.svg'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 850,
    },
  },
  modalForm: {
    paddingTop: 20,
  },
  backgroundIcon: {
    backgroundColor: '#f25a0f5c',
  },
  buttonsPad: {
    padding: 20,
  },
}))

const DEFAULT_BANK = {
  branch: '',
  accountNumber: '',
  accountDigit: '',
  financialInstitutionId: '',
}

const BankModal = () => {
  const { broker, setBroker } = useBroker()
  const { addMsgDanger, addMsgSuccess } = useAlert()

  const classes = useStyles()
  const bankClient = useBankClient()

  const [open, setOpen] = useState(false)
  const [banks, setBanks] = useState([])

  const { onlyNumber } = useYup()

  const formik = useFormik({
    initialValues: { ...DEFAULT_BANK },
    validationSchema: Yup.object({
      branch: onlyNumber.required().test({
        test: (value) => value?.length <= 4,
      }),
      accountNumber: onlyNumber.required().test({
        test: (value) => value?.length <= 13,
      }),
      accountDigit: onlyNumber.required().test({
        test: (value) => value?.length <= 2,
      }),
      financialInstitutionId: Yup.string().required(),
    }),
    onSubmit: (formData) => {
      const { brokerBankReference: currentBank } = broker
      const { id } = currentBank || {}

      const brokerBankReference = {
        ...formData,
        id,
        brokerId: broker.id,
      }

      bankClient().editBank(brokerBankReference).then((response) => {
        setBroker(response.data)
        setOpen(false)
        addMsgSuccess('Dados alterados com sucesso!')
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  const { brokerBankReference } = broker
  const { setValues } = formik

  const initbank = () => {
    const {
      branch,
      accountNumber,
      accountDigit,
      financialInstitutionId,
    } = brokerBankReference || {}

    const data = {
      branch: branch || '',
      accountDigit: accountDigit || '',
      accountNumber: accountNumber || '',
      financialInstitutionId: financialInstitutionId || '',
    }

    setValues(data)
  }

  const handleOpenModalEdit = () => {
    initbank()
    Promise.all([
      bankClient().getBanks(),
    ]).then((response) => {
      setOpen(true)
      setBanks(response[0].data)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const handleSubmit = () => {
    formik.submitForm()
  }

  const { financialInstitutionId } = formik.values

  const getBankById = useCallback(() => find(banks, ['id', financialInstitutionId]) || null, [banks, financialInstitutionId])

  const handleChangeFinancialInstitution = (event, value) => {
    formik.setValues({ ...formik.values, financialInstitutionId: value?.id })
  }

  return (
    <>
      <ModalInfo
        open={open}
        className={classes.modal}
        onClose={() => setOpen(false)}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <form>
          <Box mb={5}>
            <Typography component="span" color="primary" className={classes.title}>ALTERAR DADOS BANCÁRIOS</Typography>
          </Box>
          <Grid container spacing={4} className={classes.modalForm}>
            <Grid item lg={6} sm={6} xs={12}>
              <Autocomplete
                id="financialInstitutionId"
                options={banks}
                getOptionLabel={(option) => `${option.code} - ${option.name}`}
                clearOnBlur
                handleHomeEndKeys
                value={getBankById()}
                onChange={handleChangeFinancialInstitution}
                noOptionsText="Nenhuma opção correspondente"
                ListboxProps={{ style: { fontSize: 12 } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="financialInstitutionId"
                    label="Banco que deseja receber a comissão*:"
                    title="Selecione o banco que deseja receber sua comissão"
                    color="secondary"
                    error={formik.touched.financialInstitutionId
                        && !!formik.errors.financialInstitutionId}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <FormHelperText
                hidden={!formik.touched.financialInstitutionId
                  || !formik.errors.financialInstitutionId}
                error={formik.touched.financialInstitutionId
                  && !!formik.errors.financialInstitutionId}
              >
                {formik.errors.financialInstitutionId}
              </FormHelperText>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <TextField
                id="branch"
                color="secondary"
                label="Agência*:"
                title="Agência"
                {...formik.getFieldProps('branch')}
                fullWidth
                error={formik.touched.branch && !!formik.errors.branch}
                helperText={formik.touched.branch && formik.errors.branch}
              />
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <TextField
                id="accountNumber"
                color="secondary"
                label="Conta*:"
                title="Número da conta"
                {...formik.getFieldProps('accountNumber')}
                fullWidth
                error={formik.touched.accountNumber && !!formik.errors.accountNumber}
                helperText={formik.touched.accountNumber && formik.errors.accountNumber}
              />
            </Grid>
            <Grid item lg={6} sm={3} xs={12}>
              <TextField
                id="accountDigit"
                color="secondary"
                label="Dígito verificador*:"
                title="Dígito verificador"
                {...formik.getFieldProps('accountDigit')}
                fullWidth
                error={formik.touched.accountDigit && !!formik.errors.accountDigit}
                helperText={formik.touched.accountDigit && formik.errors.accountDigit}
              />
            </Grid>
            <Grid container justify="center" spacing={2} className={classes.buttonsPad}>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  title="Cancelar"
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  title="Salvar"
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ModalInfo>
      <IconButton
        size="small"
        color="primary"
        component="span"
        title="Editar dados"
        aria-label="Editar dados bancários"
        onClick={handleOpenModalEdit}
        className={open && classes.backgroundIcon}
      >
        <img src={icoEdit} alt="Editar dados" />
      </IconButton>
    </>
  )
}

export default BankModal
