import { useCallback } from 'react'
import axios from 'axios'

const useHistoryClient = () => {
  const getHistoryCurrent = useCallback(() => axios.get('/api/attendance/ListBlipTickets'), [])

  const getHistoryFilterId = useCallback((id) => axios.get(`/api/attendance/ListTicketsMessages?id=${id}`), [])

  return useCallback(() => ({
    getHistoryCurrent,
    getHistoryFilterId,
  }), [getHistoryCurrent, getHistoryFilterId])
}

export default useHistoryClient
