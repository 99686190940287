import { useCallback } from 'react'
import axios from 'axios'

const useBlogClient = () => {
  const getNews = useCallback(() => axios.get('/api/blog'), [])

  return useCallback(() => ({
    getNews,
  }), [getNews])
}

export default useBlogClient
