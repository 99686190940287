import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { CustomSwitch } from '../../../../components'

const useStyles = makeStyles(() => ({
  labelSwitch: {
    lineHeight: '1.20rem',
    marginLeft: 10,
  },
}))

const ExportSwitch = ({ formik, handleChange, field }) => {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Box display="flex">
          <CustomSwitch
            {...formik.getFieldProps(field.name)}
            checked={formik.values[field.name]}
            onChange={(event, value) => handleChange(field.name, value)}
            id={field.name}
            label={field.text}
          />
          <Typography className={classes.labelSwitch} variant="subtitle1">
            {field.text}
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

ExportSwitch.propTypes = {
  formik: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
}

export default ExportSwitch
