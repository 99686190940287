import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import {
  Box,
  Card,
  Grid,
  makeStyles,
  Typography,
  CardContent,
} from '@material-ui/core'
import { MONTHS } from '../../constants'
import {
  Resolve,
  BarChart,
  useLoader,
} from '../../components'
import PanelFallback from './PanelFallback'
import PanelErrorFallback from './PanelErrorFallback'
import usePanelClient from '../../clients/PanelClient'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  value: {
    color: '#157E2D',
  },
  subtitle2: {
    fontWeight: 'bold',
  },
  detalhes: {
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  fallback: {
    minHeight: '280px',
  },
}))

const Quotation = () => {
  const classes = useStyles()
  const panelClient = usePanelClient()
  const [quotation, setQuotation] = useState({})
  const { enableLoader, disableLoader } = useLoader()

  const handleResolve = useMemo(() => ({
    quotation: () => new Promise((resolve, reject) => {
      disableLoader()

      panelClient().getQuotationCard().then((response) => {
        resolve(response.data)
        enableLoader()
      }, () => {
        enableLoader()
        reject()
      })
    }),
  }), [panelClient, enableLoader, disableLoader])

  const handleLoaded = useCallback((data, resolve) => {
    const {
      mes,
      qtdCotacao,
      qtdEmissao,
    } = data.quotation || {}

    setQuotation(
      {
        values: [
          {
            label: 'Cotações',
            data: qtdCotacao || [],
          },
          {
            label: 'Apólice',
            data: qtdEmissao || [],
          },
        ],
        labels: (mes || []).map((month) => MONTHS[month]),
      },
    )
    resolve()
  }, [])

  return (
    <Card className={classes.root}>
      <CardContent>
        <Resolve
          resolve={handleResolve}
          onLoaded={handleLoaded}
          fallback={(<PanelFallback className={classes.fallback} />)}
          errorFallback={(<PanelErrorFallback title="Cotações" className={classes.fallback} />)}
        >
          <>
            <Box mb={2}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="subtitle2" className={classes.subtitle2}>Cotações</Typography>
                </Grid>
              </Grid>
            </Box>

            <BarChart
              data={quotation.values || []}
              labels={quotation.labels || []}
            />
          </>
        </Resolve>
      </CardContent>
    </Card>
  )
}

export default Quotation
