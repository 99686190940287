import React from 'react'
import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: '14px',
    width: '100%',
    height: '100%',
  },
}))

const SettingsButton = (props) => {
  const classes = useStyles()
  const {
    ico, title, description, to,
  } = props

  return (
    <>
      <Grid item xs={12} sm={3}>
        <Box mb={2} textAlign="center">

          <Paper>
            <Button
              to={to}
              className={classes.button}
              component={Link}
            >
              <Box display="flex" justifyContent="center" alignItems="center" alignContent="center">
                <Box className={classes.ico} mb={1}>
                  <img src={ico} alt="" width="80" />
                </Box>
              </Box>
            </Button>
          </Paper>

          <Box mt={2}>
            <Typography variant="subtitle2" component="span">{title}</Typography>
          </Box>
          <Typography component="span" className={classes.title}>
            {description}
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

SettingsButton.propTypes = {
  to: PropTypes.string.isRequired,
  ico: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default SettingsButton
