import React, { useState, useMemo, useCallback } from 'react'
import { Box, Grid } from '@material-ui/core'
import Currency from 'react-currency-formatter'

import BilletTable from './BilletTable'
import BilletFilter from './BilletFilter'
import useUtils from '../../hooks/useUtils'
import { BILLETS_STATUS_ENUM } from '../../constants'
import InstallmentDueCard from './InstallmentDueCard'
import InstallmentOpenCard from './InstallmentOpenCard'
import InstallmentOverdueCard from './InstallmentOverdueCard'
import { useAlert, FilterTips, Resolve } from '../../components'
import useBilletClient from '../../clients/BilletClient/useBilletClient'

const DEFAULT_FILTER = {
  policyNumber: '',
  proposalNumber: '',
  startAmount: '',
  endAmount: '',
  startDueDate: '',
  endDueDate: '',
  status: '',
  period: '',
}

const Billets = () => {
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()
  const billetClient = useBilletClient()

  const [summary, setSummary] = useState({})
  const [filters, setFilters] = useState({ ...DEFAULT_FILTER })

  const toExpireBillet = useMemo(() => {
    const { toExpireBilletSummary = {} } = summary
    return toExpireBilletSummary
  }, [summary])

  const tipsFormats = useMemo(() => ({
    status: (data) => BILLETS_STATUS_ENUM[data],
    startDueDate: (data) => formatDate(data),
    endDueDate: (data) => formatDate(data),
    period: (data) => `${data} dias`,
  }), [formatDate])

  const tipsTemplates = useMemo(() => ({
    startAmount: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
    endAmount: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
  }), [])

  const handleResolve = useMemo(() => ({
    summary: () => new Promise((resolve, reject) => {
      billetClient().getBilletSummary().then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [billetClient, addMsgDanger])

  const handleLoaded = useCallback((data, resolve) => {
    setSummary(data.summary)
    resolve()
  }, [])

  const handleChangeDays = (event) => {
    const { value } = event.target

    billetClient().getToExpireBilletSummary(value).then((response) => {
      setSummary((data) => ({ ...data, toExpireBilletSummary: response.data }))
      setFilters({ status: 'AVencer', period: value })
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const handleRemoveFilter = (param) => {
    if (param === 'startDueDate' || param === 'endDueDate') {
      setFilters({ ...filters, startDueDate: '', endDueDate: '' })
    } else if (param === 'startAmount' || param === 'endAmount') {
      setFilters({ ...filters, startAmount: '', endAmount: '' })
    } else if (param === 'status') {
      setFilters({ ...filters, status: '', period: '' })
    } else {
      setFilters({ ...filters, [param]: '' })
    }
  }

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <Box mt={3} mb={5}>
        <Grid container justify="space-evenly" spacing={2}>
          <Grid item>
            <InstallmentOpenCard
              installment={summary.openBilletSummary || {}}
              onClick={() => setFilters({ ...DEFAULT_FILTER, status: 'EmAndamento' })}
              title="TOTAL DE BOLETOS EM ABERTO"
            />
          </Grid>
          <Grid item>
            <InstallmentDueCard
              id="billetDue"
              installment={toExpireBillet}
              onChange={handleChangeDays}
              onClick={() => setFilters({ ...DEFAULT_FILTER, status: 'AVencer', period: toExpireBillet.days })}
              title="A VENCER NO PERÍODO DE:"
            />
          </Grid>
          <Grid item>
            <InstallmentOverdueCard
              installment={summary.expiredBilletSummary || {}}
              onClick={() => setFilters({ ...DEFAULT_FILTER, status: 'Vencido' })}
              title="TOTAL DE BOLETOS VENCIDOS"
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={8} mb={4}>
        <Grid container justify="flex-end">
          <BilletFilter addFilter={setFilters} filters={filters} />
        </Grid>
      </Box>

      <Box>
        <FilterTips
          filter={filters}
          formats={tipsFormats}
          templates={tipsTemplates}
          onRemove={handleRemoveFilter}
        />
      </Box>

      <BilletTable filters={filters} />
    </Resolve>
  )
}

export default Billets
