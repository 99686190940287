import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import EventEmitter from 'events'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core'
import { useSpring, animated } from 'react-spring'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import animation from './animation.json'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: '#00000030',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
  loading: {
    width: 50,
    maxWidth: '100%',
    animation: '$spin 2.5s linear infinite',
    [theme.breakpoints.down('xs')]: {
      width: 40,
    },
  },
  svgContainer: {
    width: 50,
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    padding: '20px 25px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
    },
  },
  cls1: {
    fill: theme.palette.primary.main,
  },
  '@keyframes spin': {
    '100%': {
      transform: 'rotate(-360deg)',
    },
  },
}))

const emitter = new EventEmitter()

const LOADER_ENABLE = 'loader.enable'
const LOADER_DISABLE = 'loader.disable'

const Loader = ({ show }) => {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(0)
  const props = useSpring({ opacity: 1, from: { opacity: 0 } })

  useEffect(() => {
    const enable = () => setDisabled((count) => --count)
    emitter.on(LOADER_ENABLE, enable)

    const disable = () => setDisabled((count) => ++count)
    emitter.on(LOADER_DISABLE, disable)

    return () => {
      emitter.removeListener(LOADER_ENABLE, enable)
      emitter.removeListener(LOADER_DISABLE, disable)
    }
  }, [])

  return (
    <>
      {disabled === 0 && show && (
        <animated.div style={props} className={classes.root}>
          <Card>
            <CardContent>
              <Box p={1}>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  direction="column"
                  alignItems="center"
                >
                  <Grid item>
                    <Box className={classes.svgContainer}>
                      <Lottie options={{ animationData: animation }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography align="center" variant="h6">
                      Carregando...
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </animated.div>
      )}
    </>
  )
}

export const useLoader = () => {
  const enableLoader = useCallback(() => emitter.emit(LOADER_ENABLE), [])
  const disableLoader = useCallback(() => emitter.emit(LOADER_DISABLE), [])

  return {
    enableLoader,
    disableLoader,
  }
}

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Loader
