import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  iFrame: {
    minHeight: '600px',
    border: 'none',
  },
}))

const AwardPaidDemand = () => {
  const classes = useStyles()
  const { DEMAND_URL } = window.ENV

  return (
    <>
      <iframe
        className={classes.iFrame}
        src={`${DEMAND_URL}/PremioQuitado`}
        height="1000"
        width="100%"
        title="Prêmios Quitados"
      />
    </>
  )
}

export default AwardPaidDemand
