import React from 'react'
import {
  Box,
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import { ModalInfo } from '../../../../components'
import bgSuccess from '../../../../assets/img/bg-success-quote.png'

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 850,
      padding: 0,
    },
  },
  background: {
    backgroundImage: `url(${bgSuccess})`,
    backgroundPositionX: 'center',
    height: '485px',
  },
  midText: {
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  values: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
  btn: {
    width: 150,
  },
}))

const SuccessModal = ({ currentPolicy, open, setOpen }) => {
  const classes = useStyles()

  return (
    <ModalInfo
      open={open}
      close={false}
      className={classes.modal}
    >
      <Box textAlign="center">
        <Grid container justify="space-between">
          <Grid item xs={8}>
            <Box p={5} display="grid" height="100%">
              <Box textAlign="left" mb={1}>
                <Typography variant="body2" component="span">
                  Apólice renovada!
                </Typography>
              </Box>
              <Box textAlign="left">
                <Typography
                  className={classes.values}
                  variant="subtitle2"
                  component="span"
                >
                  Apólice renovada com sucesso!
                </Typography>
              </Box>
              <Box className={classes.midText}>
                <Typography variant="subtitle1" align="left">
                  A apólice de número
                  {' '}
                  {currentPolicy.policyNumber}
                  {' '}
                  foi renovada com sucesso
                  e está disponível para consulta no menu “Renovadas”.
                </Typography>
              </Box>
              <Box textAlign="right">
                <Button
                  color="primary"
                  variant="contained"
                  title="Voltar"
                  fullWidth
                  className={classes.btn}
                  onClick={() => setOpen(false)}
                >
                  Voltar
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} className={classes.background} />
        </Grid>
      </Box>
    </ModalInfo>
  )
}

SuccessModal.propTypes = {
  currentPolicy: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default SuccessModal
