import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { SlashScreen } from '../../components'
import useSecurity from '../../security/useSecurity'
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics'

const BrokerConcluded = () => {
  const history = useHistory()
  const { state } = useLocation()
  const { reload } = useSecurity()
  const { event } = useGoogleAnalytics()

  useEffect(() => {
    const { path = '/', broker, documentNumber } = state || {}

    reload().then(() => {
      history.replace(path)
    })

    event('Cadastro', 'Complemento de Cadastro', `${broker} - ${documentNumber}`)
  }, [history, state, reload, event])

  return <SlashScreen />
}

export default BrokerConcluded
