import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Currency from 'react-currency-formatter'
import CircularProgress from '@material-ui/core/CircularProgress'

import useUtils from '../../hooks/useUtils'
import useDownload from '../../hooks/useDownload'
import useSecurity from '../../security/useSecurity'
import { useAlert, useLoader } from '../../components'
import useBilletClient from '../../clients/BilletClient'
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics'

const useStyles = makeStyles(() => ({
  containerAction: {
    alignSelf: 'center',
  },
  title: {
    fontWeight: 600,
  },

  text: {
    marginLeft: 10,
  },

  bold: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  newText: {
    marginLeft: 10,
    fontSize: '1rem',
  },
  box: {
    backgroundColor: '#FCFCFC',
    borderRadius: '11px',
    border: '1px solid #e3e3e3',
    padding: '20px',
  },
  button: {
    minWidth: '215px',
    minHeight: '40px',
  },
}))

const DaysSlider = withStyles({
  root: {
    color: '#F26522',
    height: 8,
    marginTop: '40px',
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#F26522',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    color: '#F26522',
    '& span': {
      backgroundColor: 'transparent',
      width: '42px',
      '& span': {
        color: '#F26522',
      },
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider)

const SecondBillet = ({
  installment, billet, handleClose, successMessage, clientName,
}) => {
  const classes = useStyles()
  const { getContext } = useSecurity()
  const { downloadPDF } = useDownload()
  const billetClient = useBilletClient()
  const { event } = useGoogleAnalytics()
  const { addMsgSuccess, addMsgDanger } = useAlert()
  const { disableLoader, enableLoader } = useLoader()
  const { formatDate, formatDateApi, date } = useUtils()

  const [newValue, setNewValue] = useState(0)
  const [newdDueDate, setNewDueDate] = useState({})
  const [processingValue, setProcessingValue] = useState(false)

  const updateValue = useCallback((due) => {
    disableLoader()
    setProcessingValue(true)

    billetClient().getNewValueBillet(installment.installmentId, due).then((response) => {
      enableLoader()
      setProcessingValue(false)
      setNewValue(response.data.value)
    }, (error) => {
      enableLoader()
      setProcessingValue(false)
      addMsgDanger(error.data)
    })
  }, [addMsgDanger, billetClient, enableLoader, disableLoader, installment])

  const baseDue = useMemo(() => {
    const now = date()
    const current = date(billet.dueDate)
    return current.isAfter(now) ? current : now
  }, [billet.dueDate, date])

  useEffect(() => {
    const due = date(baseDue.toString()).add(1, 'days')
    setNewDueDate(due)

    const apiDue = formatDateApi(due)
    updateValue(apiDue)
  }, [baseDue, date, updateValue, formatDateApi])

  const updateData = (e, value) => {
    const due = date(baseDue.toString()).add(value, 'days')
    setNewDueDate(due)

    const apiDue = formatDateApi(due)
    updateValue(apiDue)
  }

  const updateBillet = () => {
    const due = formatDateApi(newdDueDate)

    billetClient().getBilletUpdate(billet.billetId, due).then((response) => {
      addMsgSuccess(successMessage)
      downloadPDF(response.data.file, `boleto_${date().valueOf()}.pdf`)

      const { name } = getContext()
      event('Pagamentos', 'Gerar segunda via', `${name} - ${clientName}`)

      handleClose()
    }, (error) => {
      addMsgDanger(error.data)
      handleClose()
    })
  }

  return (
    <Box style={{ padding: 10 }}>
      <Grid container justify="space-between">
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Typography variant="body2" className={classes.title}>Cliente:</Typography>
            <Typography variant="body2" className={classes.text}>{clientName}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
          >
            <Typography variant="body2" className={classes.title}>Parcela:</Typography>
            <Typography variant="body2" className={classes.text}>{installment.number}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box pt={2}>
        <Grid container justify="space-between">
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography variant="body2" className={classes.title}>Valor:</Typography>
              <Typography variant="body2" className={classes.text}>
                <Currency quantity={installment?.installmentAmount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Typography variant="body2" className={classes.title}>Vencimento atual:</Typography>
              <Typography variant="body2" className={classes.text}>{formatDate(billet?.dueDate)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box pt={1} mt={3} mb={3} className={classes.box}>
        <Grid container justify="space-between">
          <Grid item sm={12} xs={12} lg={4} md={4}>
            <Typography variant="body2" className={classes.bold}>Prorrogar em:</Typography>
            <DaysSlider
              onChangeCommitted={updateData}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => (value === 1 ? `${value} dia` : `${value} dias`)}
              aria-label="slider"
              defaultValue={1}
              min={1}
              max={30}
            />
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="body2">1 Dia</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">30 Dias</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} lg={8} md={8}>
            <Grid container direction="column" alignItems="flex-end" style={{ marginTop: 40 }}>
              <Box pt={2}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Typography variant="body2" className={classes.bold}>Novo valor:</Typography>
                  {processingValue && (
                    <Box ml={2}>
                      <CircularProgress size={20} />
                    </Box>
                  )}

                  {!processingValue && (
                    <Typography variant="body2" className={classes.newText}>
                      <Currency quantity={newValue || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                    </Typography>
                  )}
                </Grid>
              </Box>
              <Box pt={2}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Typography variant="body2" className={classes.bold}>Novo vencimento:</Typography>
                  <Typography variant="body2" className={classes.newText}>
                    {formatDate(newdDueDate)}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid pb={2} container justify="center" spacing={2}>
        <Grid item lg="auto" sm={6} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={handleClose}
            title="Cancelar"
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item lg="auto" sm={6} xs={12}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={updateBillet}
            title="Confirmar"
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

SecondBillet.propTypes = {
  billet: PropTypes.object.isRequired,
  installment: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  successMessage: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
}

export default SecondBillet
