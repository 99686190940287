import React from 'react'
import PropTypes from 'prop-types'

import {
  Typography,
  Box,
  makeStyles,
  Grid,
  Paper,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'

import { Paginator } from '../../../../components'
import PolicyRenewedAction from './PolicyRenewedAction'
import useUtils from '../../../../hooks/useUtils'

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 4,
  },
  containerAction: {
    alignSelf: 'center',
  },
  title: {
    fontWeight: 600,
    paddingRight: 5,
  },
}))

const PoliciesRenewed = ({
  policies, total, updatePage,
}) => {
  const classes = useStyles()
  const { formatDate } = useUtils()

  return (
    <>
      {policies
        && policies.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Paper className={classes.paper}>
              <Box mt={1} mb={1} p={1.5}>
                <Grid container justify="space-between">
                  <Grid item lg={4} sm={12}>
                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        Cliente:
                      </Typography>
                      <Typography variant="body2">{item?.name}</Typography>
                    </Box>

                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        Modalidade:
                      </Typography>
                      <Typography variant="body2">{item?.modality}</Typography>
                    </Box>

                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        Apólice:
                      </Typography>
                      <Typography variant="body2">
                        {item.policyNumber}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} sm={12}>
                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        IS Atualizada:
                      </Typography>
                      <Typography variant="body2">
                        {item?.updatedIs && (
                        <Currency quantity={item?.updatedIs || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          )}
                      </Typography>
                    </Box>

                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        Prêmio Total:
                      </Typography>
                      <Typography variant="body2">
                        {item?.newPremium && (
                        <Currency quantity={item?.newPremium || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          )}
                      </Typography>
                    </Box>

                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        Comissão:
                      </Typography>
                      <Typography variant="body2">
                        {item?.commission && (
                        <Currency quantity={item?.commission || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          )}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} sm={12}>
                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        Segurado:
                      </Typography>
                      <Typography variant="body2">
                        {item.insuredName}
                      </Typography>
                    </Box>

                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        Vigência:
                      </Typography>
                      <Typography variant="body2">
                        {formatDate(item?.startPolicyPeriod)}
                        {' '}
                        até
                        {' '}
                        {formatDate(item?.endPolicyPeriod)}
                      </Typography>
                    </Box>

                    <Box display="flex" m={1}>
                      <Typography variant="body2" className={classes.title}>
                        Emissão:
                      </Typography>
                      <Typography variant="body2">
                        {formatDate(item?.issuanceDate)}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item className={`${classes.containerAction} xsOrder`} lg="auto" sm={1} xs="auto">
                    <PolicyRenewedAction policy={item} />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        ))}

      <Paginator totalResults={total} changePaginator={updatePage} />
    </>
  )
}

PoliciesRenewed.propTypes = {
  policies: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired,
}

export default PoliciesRenewed
