import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import ListItemText from '@material-ui/core/ListItemText'

import { useAlert } from '../../../../components'
import useDownload from '../../../../hooks/useDownload'
import usePolicyClient from '../../../../clients/PolicyClient/usePolicyClient'

const PolicyRenewedAction = ({ policy }) => {
  const buttonRef = useRef()
  const { addMsgDanger } = useAlert()
  const { downloadPDF } = useDownload()
  const policyClient = usePolicyClient()

  const [open, setOpen] = useState(false)

  const handleDownloadPolicy = () => {
    const { policyNumber, proposalNumber } = policy
    setOpen(false)

    policyClient().downloadPolicy(policyNumber, proposalNumber).then((response) => {
      const { apoliceDsd } = response.data
      downloadPDF(apoliceDsd, `apolice_${policyNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <>
      <IconButton color="primary" title="Ações" ref={buttonRef} onClick={() => setOpen(true)}>
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={buttonRef.current}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setOpen(false)}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem title="Visualizar">
          <ListItemText
            primary="Visualizar apólice"
            onClick={handleDownloadPolicy}
          />
        </MenuItem>
      </Menu>
    </>
  )
}

PolicyRenewedAction.propTypes = {
  policy: PropTypes.object.isRequired,
}

export default PolicyRenewedAction
