import React, { useState } from 'react'
import clsx from 'clsx'

import {
  Box,
  Tab,
  Tabs,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { Breadcrumb } from '../../../components'
import CompulsoryRenewal from './renewal/CompulsoryRenewal'
import CompulsoryRenewed from './renewed/CompulsoryRenewed'
import PolicyJudicial from './PolicyJudicial'

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  unActive: {
    backgroundColor: theme.palette.secondary.main,
    color: '#3c3c3c',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff !important',
    },
  },
  tab: {
    paddingBottom: 1,
    '& button': {
      maxWidth: '100%',
      fontSize: '1rem',
      textTransform: 'none',
      [theme.breakpoints.up('md')]: {
        minWidth: 175,
      },
    },
    '& .MuiTabs-scroller': {
      paddingBottom: 1,
    },
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #CECECE',
    },
    '& .MuiTabs-indicator': {
      height: 3,
    },
  },
}))

const PATH_INFO_PREFIX = [
  {
    text: 'Painel',
    href: '/',
  },
  {
    text: 'Apólices',
    href: '/',
  },
  {
    text: 'Acompanhar Apólices',
    href: '/apolices/acompanhar',
  },
]

const PolicyMonitoring = () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [renewalOpen, setRenewalOpen] = useState(true)

  const getTabPathInfo = (tab) => {
    let text
    if (tab === 0) { text = 'Prorrogação judicial' }
    if (tab === 1) { text = 'Judicial recursal' }

    const pathInfoData = Array.from(PATH_INFO_PREFIX)
    pathInfoData.push({ text, href: '/' })
    return pathInfoData
  }

  const [pathInfo, setPathInfo] = useState(getTabPathInfo(0))

  const handleChangeTabs = (event, newValue) => {
    setPathInfo(getTabPathInfo(newValue))
    setValue(newValue)
  }

  const handleChangeCompulsory = (open) => {
    setRenewalOpen(open)
  }

  return (
    <Box position="relative" component="main">
      <Breadcrumb paths={pathInfo} />
      <Box mb={4}>
        <Typography variant="h4">Acompanhar Apólices</Typography>
      </Box>
      <div className={classes.root}>
        <div>
          <Tabs
            value={value}
            onChange={handleChangeTabs}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Acompanhar Apólices"
            className={classes.tab}
          >
            <Tab label={(<Typography component="span">Prorrogação judicial</Typography>)} {...a11yProps(0)} />
            <Tab label={(<Typography component="span">Recursal / Atualização Ex. Fiscal</Typography>)} {...a11yProps(1)} />
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <>
            <Box display="inline-flex">
              <Box mr={2}>
                <Button
                  className={clsx({
                    [classes.unActive]: !renewalOpen,
                  })}
                  color="primary"
                  variant="contained"
                  title="A renovar"
                  onClick={() => handleChangeCompulsory(true)}
                >
                  A renovar
                </Button>
              </Box>

              <Box>
                <Button
                  className={clsx({
                    [classes.unActive]: renewalOpen,
                  })}
                  color="primary"
                  variant="contained"
                  title="Renovadas"
                  onClick={() => handleChangeCompulsory(false)}
                >
                  Renovadas
                </Button>
              </Box>
            </Box>

            {renewalOpen && (
              <CompulsoryRenewal />
            )}

            {!renewalOpen && (
              <CompulsoryRenewed />
            )}

          </>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PolicyJudicial />
        </TabPanel>
      </div>
    </Box>
  )
}

export default PolicyMonitoring
