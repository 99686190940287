import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { CustomSwitch } from '../../components'

const SwitchWrapper = ({
  itemIndex, dataItem, handleParent, onChange, handleOpened, ...props
}) => {
  const {
    name, checked, id, isGroup,
  } = dataItem

  // ESTRUTRA DO CAMPO
  const field = (nameField, checkedField, idField, onChangeField, grouped) => (
    <Box mb={1}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <CustomSwitch
            id={idField}
            value={nameField}
            checked={checkedField}
            onChange={onChangeField}
            title={nameField}
            {...props}
          />
        </Grid>
        <Grid item>
          <Typography variant="body2" htmlFor={idField}>{nameField}</Typography>
        </Grid>
        {
          grouped && (
            dataItem.opened
              ? <ExpandLess color="primary" onClick={() => handleOpened(idField)} />
              : <ExpandMore color="primary" onClick={() => handleOpened(idField)} />
          )
        }
      </Grid>
    </Box>
  )

  // SE FOR UM GRUPO
  if (isGroup) {
    return (
      <Grid item xs={12} md={6}>
        {field(name, checked, id, handleParent, true)}
        <Collapse in={dataItem.opened}>
          <Box pl={3}>
            <Grid container>
              {
                dataItem.children.map((item, index) => (
                  <Grid key={index} item xs={12}>
                    {field(item.name, item.checked, item.id, onChange, false)}
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </Collapse>
      </Grid>
    )
  }

  // SE FOR UM ITEM
  return (
    <Grid item xs={12} md={6}>
      {field(name, checked, id, onChange, false)}
    </Grid>
  )
}

SwitchWrapper.propTypes = {
  dataItem: PropTypes.object.isRequired,
  handleParent: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleOpened: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
}
SwitchWrapper.defaultProps = {}

const UserPermission = ({ permission, setPermission }) => {
  const [opened, setOpened] = useState([])
  const openedStatus = useCallback((id) => {
    const index = opened.findIndex((item) => item.id === id)

    if (index === -1) {
      opened.push({
        id,
        status: true,
      })
      setOpened(opened.filter((item) => item))
      return true
    }

    return opened[index].status
  }, [opened])

  // AGRUPA AS PERMISSÕES AOS SEUS GRUPOS
  const getPermissionGroup = useCallback((data, itemPermission, groupIndex) => {
    const index = data.findIndex((item) => item.name === itemPermission.group)
    if (index === -1) {
      const groupedItems = permission.filter((item) => item.group === itemPermission.group)
      const checkedItems = groupedItems.filter((item) => item.checked)

      return {
        id: groupIndex.toString(),
        name: itemPermission.group,
        checked: checkedItems.length > 0,
        children: groupedItems,
        opened: openedStatus(groupIndex.toString()),
        isGroup: true,
      }
    }
    return false
  }, [permission, openedStatus])

  // TRATAMENTO DOS DADOS
  const [dataInfo, setDataInfo] = useState([])
  const handleData = useCallback(() => {
    const data = []
    permission.map((itemPermission, index) => {
      if (itemPermission.group) {
        const group = getPermissionGroup(data, itemPermission, index)
        data.push(group)
      } else {
        data.push({ ...itemPermission, isGroup: false })
      }
      return true
    })

    setDataInfo(data.filter((item) => item))
  }, [permission, getPermissionGroup])

  useEffect(() => {
    handleData()
  }, [permission, handleData])

  // AO MARCAR ALGUMA PERMISSÃO
  const handleChange = (e) => {
    const newData = permission.map(
      (item) => (item.id === e.target.id ? { ...item, checked: e.target.checked } : { ...item }),
    )
    setPermission(newData)
  }

  // AO MARCAR ALGUM GRUPO
  const handleParent = (e) => {
    const elements = permission.filter((item) => item.group === e.target.value)
    const checkedData = elements.map((item) => ({ ...item, checked: e.target.checked }))

    checkedData.map((itemData) => {
      const index = permission.findIndex((item) => itemData.id === item.id)
      permission[index] = itemData
      return true
    })

    const newData = permission.map((item) => ({ ...item }))
    setPermission(newData)
  }

  // EXPANDIR GRUPO
  const handleOpened = (id) => {
    const index = opened.findIndex((item) => item.id === id)
    opened[index].status = !opened[index].status

    const newData = opened.map((item) => ({ ...item }))
    setOpened(newData)
  }

  return (
    <Box mb={2}>
      <Grid container>
        {
          dataInfo.map((dataItem, index) => (
            <SwitchWrapper
              key={index}
              itemIndex={index}
              handleParent={handleParent}
              onChange={handleChange}
              dataItem={dataItem}
              handleOpened={handleOpened}
              color="primary"
            />
          ))
        }
      </Grid>
    </Box>
  )
}

UserPermission.propTypes = {
  permission: PropTypes.array,
  setPermission: PropTypes.func.isRequired,
}
UserPermission.defaultProps = {
  permission: [],
}

export default UserPermission
