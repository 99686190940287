import { useCallback } from 'react'
import axios from 'axios'

const useCustomerClient = () => {
  const getCustomers = useCallback((query) => axios.get(`/api/BrokerCustomer/Customer?CustomerName=${query}`), [])

  return useCallback(() => ({
    getCustomers,
  }), [
    getCustomers,
  ])
}

export default useCustomerClient
