import { useCallback } from 'react'
import axios from 'axios'

const useBankClient = () => {
  const getBanks = useCallback(() => axios.get('/api/resources/banks'), [])

  const getAccountTypes = useCallback(() => axios.get('/api/resources/accountsType'), [])

  const editBank = useCallback((bank) => axios.put(`/api/broker/bankReference/${bank.brokerId}`, bank), [])

  return useCallback(() => ({
    getBanks,
    getAccountTypes,
    editBank,
  }), [
    getBanks,
    getAccountTypes,
    editBank,
  ])
}

export default useBankClient
