import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useUtils from '../../hooks/useUtils'
import useSecurity from '../../security/useSecurity'

const SignoutCallback = () => {
  const history = useHistory()
  const { isBlank } = useUtils()
  const { userManager } = useSecurity()

  useEffect(() => {
    userManager.signoutRedirectCallback().then((data) => {
      const { state } = data

      if (isBlank(state)) {
        userManager.signinRedirect()
      } else {
        history.push(state)
      }
    })
  }, [history, userManager, isBlank])

  return <></>
}

export default SignoutCallback
