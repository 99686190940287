import { useCallback } from 'react'
import axios from 'axios'

const useRatingClient = () => {
  const sendFeedBack = useCallback((values) => axios.post('/api/broker/feedback', values), [])

  return useCallback(() => ({
    sendFeedBack,
  }), [
    sendFeedBack,
  ])
}

export default useRatingClient
