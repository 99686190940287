import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  Button,
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  ModalInfo, useAlert, InputIPv4Sub, InputIPv6Sub,
} from '../../../components'
import useIpsClient from '../../../clients/IpsClient'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 800,
    },
  },
  modalForm: {
    padding: 20,
  },
  backgroundIcon: {
    backgroundColor: '#f25a0f5c',
  },
  formControl: {
    width: '100%',
  },
}))

const DEFAULT_IP = {
  ip: '',
}

const IPNew = ({ onInsertIp }) => {
  const { addMsgDanger, addMsgSuccess } = useAlert()
  const ipsClient = useIpsClient()

  const classes = useStyles()
  const [type, setType] = useState(0)
  const [open, setOpen] = useState(false)

  const formik = useFormik({
    initialValues: { ...DEFAULT_IP },
    validationSchema: Yup.object({
      ip: Yup.string().required(),
    }),
    onSubmit: (data) => {
      const values = {
        ips: [
          {
            type,
            ip: data.ip,
          },
        ],
      }

      ipsClient().createIp(values).then(() => {
        onInsertIp()
        setOpen(false)
        addMsgSuccess('IP inserido com sucesso!')
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  const handleChange = (event) => {
    setType(event.target.value)
  }

  const handleOpenModalEdit = () => {
    setOpen(true)
  }

  const handleSubmit = () => {
    formik.submitForm()
  }

  return (
    <>
      <ModalInfo
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
      >
        <>
          <Grid container spacing={4} className={classes.modalForm}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography component="h1" variant="h5">Restrição de Acesso</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" textAlign="center">
                <Grid item xs={10}>
                  <Typography variant="body1" component="span">
                    Estamos tentando melhorar o que fazemos e sua opinião é
                    muito importante para que possamos oferecer a melhor experiência.
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <form>
            <Grid container spacing={4} className={classes.modalForm}>
              <Grid item xs={5}>
                <FormControl className={classes.formControl}>
                  <InputLabel>
                    Selecione o tipo de endereço IP*:
                  </InputLabel>
                  <Select
                    id="type"
                    value={type}
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>Ipv4</MenuItem>
                    <MenuItem value={1}>Ipv6</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={7}>
                {type === 0 && (
                  <InputIPv4Sub
                    id="ip"
                    color="secondary"
                    label="Especifique a faixa de endereço IP em notação CIDR*:"
                    title="Especifique a faixa de endereço IP em notação CIDR"
                    {...formik.getFieldProps('ip')}
                    fullWidth
                    error={formik.touched.ip && !!formik.errors.ip}
                    helperText={formik.touched.ip && formik.errors.ip}
                  />
                )}

                {type === 1 && (
                  <InputIPv6Sub
                    id="ip"
                    color="secondary"
                    label="Especifique a faixa de endereço IP em notação CIDR*:"
                    title="Especifique a faixa de endereço IP em notação CIDR"
                    {...formik.getFieldProps('ip')}
                    fullWidth
                    error={formik.touched.ip && !!formik.errors.ip}
                    helperText={formik.touched.ip && formik.errors.ip}
                  />
                )}

              </Grid>

              <Grid item xs={12}>
                <Box mt={5} mb={5}>
                  Importante: Ao restringir o acesso à sua rede corportiva,
                  acessos originados de outros endereços IP,
                  não configurados, serão bloqueados.
                </Box>
              </Grid>

              <Grid container justify="center" spacing={2} className={classes.buttonsPad}>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    color="primary"
                    title="Cancelar"
                    variant="outlined"
                    onClick={() => setOpen(false)}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    title="Salvar"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </>
      </ModalInfo>
      <Button
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={handleOpenModalEdit}
        title="Adicionar"
      >
        Adicionar
      </Button>
    </>
  )
}

IPNew.propTypes = {
  onInsertIp: PropTypes.func.isRequired,
}

export default IPNew
