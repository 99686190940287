import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import ListItemText from '@material-ui/core/ListItemText'

import { useAlert } from '../../../components'
import useDownload from '../../../hooks/useDownload'
import usePolicyClient from '../../../clients/PolicyClient'

const PolicyAction = ({
  policy,
}) => {
  const buttonRef = useRef()
  const { addMsgDanger } = useAlert()
  const { downloadPDF } = useDownload()
  const policyClient = usePolicyClient()
  const [open, setOpen] = useState(false)

  const handleDownloadPolicy = () => {
    const { documentNumber, proposalNumber } = policy
    setOpen(false)

    policyClient().downloadPolicy(documentNumber, proposalNumber).then((response) => {
      const { apoliceDsd } = response.data
      downloadPDF(apoliceDsd, `apolice_${documentNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const handleDownloadProposal = () => {
    const { proposalNumber } = policy
    setOpen(false)

    policyClient().downloadProposal({ proposalNumber }).then((response) => {
      const { proposalFile } = response.data
      downloadPDF(proposalFile, `proposta_${proposalNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <>
      <IconButton color="primary" title="Ações" ref={buttonRef} onClick={() => setOpen(true)}>
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={buttonRef.current}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setOpen(false)}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {!policy.administrator && (
          <MenuItem title="Visualizar Apólice">
            <ListItemText primary="Visualizar Apólice" onClick={handleDownloadPolicy} />
          </MenuItem>
        )}

        <MenuItem title="Visualizar Proposta">
          <ListItemText primary="Visualizar Proposta" onClick={handleDownloadProposal} />
        </MenuItem>
      </Menu>
    </>
  )
}

PolicyAction.propTypes = {
  policy: PropTypes.object,
}

PolicyAction.defaultProps = {
  policy: null,
}

export default PolicyAction
