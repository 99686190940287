import React, { useState, useEffect } from 'react'
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  Paper,
} from '@material-ui/core'
import { first } from 'lodash'

import AddressModal from './AddressModal'
import { useAlert } from '../../components'
import { useBroker } from './BrokerViewContext'
import useLocationClient from '../../clients/LocationClient'
import useUtils from '../../hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const AddressInfo = () => {
  const { addMsgDanger } = useAlert()
  const { broker } = useBroker()
  const locationClient = useLocationClient()

  const [states, setStates] = useState([])

  const classes = useStyles()
  const { formatCEP } = useUtils()

  useEffect(() => {
    locationClient().getStates().then((response) => {
      setStates(response.data)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }, [locationClient, addMsgDanger])

  const getStateName = () => {
    const stateFound = first(states.filter((state) => state.code === broker.state))
    return stateFound ? stateFound.code : ''
  }

  return (
    <div className={classes.center}>
      <Paper className={classes.root}>
        <Box px={4} pt={3} pb={5}>
          <Box mb={5} spacing={2}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography component="span" color="primary" className={classes.title}>
                  ENDEREÇO
                </Typography>
              </Grid>
              <Grid item>
                <Typography component="span">
                  <AddressModal />
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={7} xs={12}>
              <Box display="inline" mr={1}>
                <Typography variant="subtitle2" component="span">CEP:</Typography>
              </Box>
              <Typography variant="body2" component="span">{formatCEP(broker.zipCode)}</Typography>
            </Grid>
            <Grid item lg={5} xs={12}>
              <Box display="inline" mr={1}>
                <Typography variant="subtitle2" component="span">Endereço:</Typography>
              </Box>
              <Typography variant="body2" component="span">{`${broker.address} ${broker.streetNumber} ${broker.addressComplement}`}</Typography>
            </Grid>
            <Grid item lg={7} xs={12}>
              <Box display="inline" mr={1}>
                <Typography variant="subtitle2" component="span">Bairro:</Typography>
              </Box>
              <Typography variant="body2" component="span">{broker.neighborhood}</Typography>
            </Grid>
            <Grid item lg={5} xs={12}>
              <Box display="inline" mr={1}>
                <Typography variant="subtitle2" component="span">Cidade/UF:</Typography>
              </Box>
              <Typography variant="body2" component="span">
                {broker.city}
                /
                {getStateName()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  )
}

export default AddressInfo
