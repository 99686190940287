import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography, Box, makeStyles, Button, Grid, Drawer,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: '-100%',
    width: 400,
    maxWidth: '100%',
    opacity: 0,
    transition: `all .25s ${theme.transitions.easing.easeInOut}`,
    zIndex: 1299,
    '&.active': {
      opacity: 1,
      right: -30,
    },
    '& h3': {
      fontWeight: 400,
    },
  },
  close: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    cursor: 'pointer',
  },
}))

const FilterContainer = ({
  open,
  children,
  onClose: handleClose,
  onClean: handleClean,
  onSearch: handleSearch,
}) => {
  const classes = useStyles()

  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={handleClose}
    >
      <Box p={3} width={400}>
        <Typography variant="h5" component="h3" color="primary">Filtro</Typography>
        <CloseIcon className={classes.close} onClick={handleClose} />

        <Box mt={4} mb={3}>
          {children}
        </Box>

        <Box mb={1}>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={handleClean}
                title="Limpar"
              >
                Limpar
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handleSearch}
                title="Buscar"
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  )
}

FilterContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  onClean: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default FilterContainer
