import React, { useMemo, useState, useCallback } from 'react'
import { Box, Grid } from '@material-ui/core'
import Currency from 'react-currency-formatter'

import InvoicesList from './InvoicesList'
import useUtils from '../../hooks/useUtils'
import InvoicesFilter from './InvoicesFilter'
import InstallmentDueCard from './InstallmentDueCard'
import { INVOICE_STATUS_ENUM } from '../../constants'
import InstallmentOpenCard from './InstallmentOpenCard'
import InstallmentOverdueCard from './InstallmentOverdueCard'
import { useAlert, FilterTips, Resolve } from '../../components'
import useInvoicesClient from '../../clients/InvoicesClient/useInvoicesClient'

const DEFAULT_FILTER = {
  policyNumber: '',
  proposalNumber: '',
  number: '',
  startAmount: '',
  endAmount: '',
  startDueDate: '',
  endDueDate: '',
  status: '',
  clientName: '',
  period: '',
}

const Invoices = () => {
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()
  const invoiceClient = useInvoicesClient()
  const [summary, setSummary] = useState({})
  const [filters, setFilters] = useState({ ...DEFAULT_FILTER })

  const toExpire = useMemo(() => {
    const { toExpireInvoice = {} } = summary
    return toExpireInvoice
  }, [summary])

  const tipsFormats = useMemo(() => ({
    status: (data) => INVOICE_STATUS_ENUM[data],
    startDueDate: (data) => formatDate(data),
    endDueDate: (data) => formatDate(data),
    period: (data) => `${data} dias`,
  }), [formatDate])

  const tipsTemplates = useMemo(() => ({
    startAmount: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
    endAmount: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
  }), [])

  const handleResolve = useMemo(() => ({
    summary: () => new Promise((resolve, reject) => {
      invoiceClient().getSummary().then((response) => {
        resolve(response.data)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
  }), [invoiceClient, addMsgDanger])

  const handleLoaded = useCallback((data, resolve) => {
    setSummary(data.summary)
    resolve()
  }, [])

  const handleChangeDays = useCallback((e) => {
    const { value } = e.target

    invoiceClient().getSumarryToExpire(value).then((response) => {
      setSummary((data) => ({ ...data, toExpireInvoice: response.data }))
      setFilters({ status: 'ToExpire', period: value })
    }, (error) => {
      addMsgDanger(error)
    })
  }, [invoiceClient, addMsgDanger])

  const handleRemoveFilter = (param) => {
    if (param === 'startDueDate' || param === 'endDueDate') {
      setFilters({ ...filters, startDueDate: '', endDueDate: '' })
    } else if (param === 'startAmount' || param === 'endAmount') {
      setFilters({ ...filters, startAmount: '', endAmount: '' })
    } else if (param === 'status') {
      setFilters({ ...filters, status: '', period: '' })
    } else {
      setFilters({ ...filters, [param]: '' })
    }
  }

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <Box
        mt={3}
        mb={4}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container justify="space-evenly" spacing={2}>
          <Grid item>
            <InstallmentOpenCard
              installment={{
                amount: summary?.openInvoice?.amount,
                quantity: summary?.openInvoice?.quantity,
              }}
              onClick={() => setFilters({ ...DEFAULT_FILTER, status: 'InProgress' })}
              title="TOTAL DE FATURAS EM ABERTO"
            />
          </Grid>

          <Grid item>
            <InstallmentDueCard
              id="billetDue"
              installment={{ amount: toExpire?.amount, days: toExpire?.days }}
              onChange={handleChangeDays}
              onClick={() => setFilters({ ...DEFAULT_FILTER, status: 'ToExpire', period: toExpire?.days })}
              title="A VENCER NO PERÍODO DE:"
            />
          </Grid>

          <Grid item>
            <InstallmentOverdueCard
              installment={{
                amount: summary?.expiredInvoice?.amount,
                quantity: summary?.expiredInvoice?.quantity,
              }}
              onClick={() => setFilters({ ...DEFAULT_FILTER, status: 'Expired' })}
              title="TOTAL DE FATURAS VENCIDAS"
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={8} mb={4}>
        <Grid container justify="flex-end">
          <InvoicesFilter addFilter={setFilters} filters={filters} />
        </Grid>
      </Box>

      <Box>
        <FilterTips
          filter={filters}
          formats={tipsFormats}
          templates={tipsTemplates}
          onRemove={handleRemoveFilter}
        />
      </Box>

      <Box>
        <InvoicesList filters={filters} />
      </Box>
    </Resolve>
  )
}

export default Invoices
