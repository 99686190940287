import React, { useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Grid,
  Popover,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core'

import { useAlert } from '../../components'
import useSecurity from '../../security/useSecurity'
import useBrokerClient from '../../clients/BrokerClient'

import icoClose from '../../assets/img/ico-close.svg'
import icoHistory from '../../assets/img/ico-history-default.svg'

const useStyles = makeStyles(() => ({
  popoverHist: {
    lineHeight: 2,
  },
  backgroundIcon: {
    backgroundColor: '#f25a0f5c',
  },
}))

const PopOverHistory = () => {
  const brokerClient = useBrokerClient()
  const { getContext } = useSecurity()
  const { addMsgDanger } = useAlert()

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [history, setHistory] = useState({})
  const [open, setOpen] = useState(Boolean(anchorEl))

  const init = () => {
    const context = getContext()
    const defautMsg = 'Sem alterações'
    Promise.all([
      brokerClient().getHistoryChange(context.personId),
    ]).then((response) => {
      if (response[0].status === 204) {
        setHistory({
          createdDate: defautMsg,
          modifiedDate: defautMsg,
          modifiedBy: defautMsg,
        })
      } else {
        setHistory(response[0].data)
      }

      setOpen(true)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const handleOpen = (event) => {
    init()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        elevation={1}
      >
        <Box p={2} pt={1} pb={3}>
          <Grid className={classes.popoverHist}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                size="small"
                color="primary"
                aria-label="Fechar histórico de alterações"
                component="span"
                onClick={handleClose}
              >
                <img src={icoClose} alt="" />
              </IconButton>
            </Box>
            <Grid item xs={12}>
              <Box display="inline" mr={1} fontWeight="fontWeightBold">Cadastro realizado:</Box>
              <Typography variant="body2" component="span">{history.createdDate}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="inline" mr={1} fontWeight="fontWeightBold">Última alteração:</Box>
              <Typography variant="body2" component="span">{history.modifiedDate}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box display="inline" mr={1} fontWeight="fontWeightBold">Responsável:</Box>
              <Typography variant="body2" component="span">{history.modifiedBy}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Popover>
      <IconButton
        size="small"
        color="primary"
        component="span"
        title="Histórico de alterações"
        aria-label="Ver histórico de alterações"
        onClick={handleOpen}
        className={clsx({ [classes.backgroundIcon]: open })}
      >
        <img src={icoHistory} alt="Histórico de alterações" />
      </IconButton>
    </>
  )
}

export default PopOverHistory
