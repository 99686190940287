import React, { useState } from 'react'
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import { DropzoneArea } from 'material-ui-dropzone'
import BackupIcon from '@material-ui/icons/Backup'
import RejectedModal from './RejectedModal'
import QuotationInfo from './QuotationInfo'
import { useAlert } from '../../../components'
import icoInfo from '../../../assets/img/ico-info.svg'
import { useQuotationContext } from './QuotationContext'
import { DEFAULT_QUOTATION_ANALYSIS } from './Constantes'
import useQuotationClient from '../../../clients/QuotationClient'

const useStyles = makeStyles((theme) => ({
  analyzeGrid: {
    minWidth: 215,
  },
  dropzone: {
    minHeight: 'unset',
    borderColor: '#f265229c',
    background: '#FAFAFA',
    '& .MuiDropzoneArea-textContainer p': {
      fontSize: 15,
      color: 'gray',
    },
  },
  selectedMember: {
    ...theme.overrides.MuiButton.outlined,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
    padding: theme.spacing(1, 5),
    display: 'inline-block',
    borderRadius: '5px',
  },
  iconUpload: {
    fontSize: 45,
  },
  cancel: {
    padding: '6px 0px',
  },
  solicitationNumber: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    padding: '5px 20px',
    borderRadius: '5px',
  },
}))

const QuotationDenied = () => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()
  const {
    quotation,
    onCancel,
    onCorrect,
    brokerDocument,
  } = useQuotationContext()

  const [open, setOpen] = useState(false)
  const [ticket, setTicket] = useState()

  const formik = useFormik({
    initialValues: { ...DEFAULT_QUOTATION_ANALYSIS },
    validationSchema: Yup.object({
      note: Yup.string().max(500),
      files: Yup.array(),
    }),
    onSubmit: (data) => {
      const {
        policyHolder,
        solicitationNumber,
      } = quotation

      const analyze = {
        ...data,
        brokerDocument,
        solicitationNumber,
        policyHolderDocument: policyHolder?.document,
      }

      quotationClient().analyze(analyze).then((response) => {
        const { ticketNumber } = response.data
        setOpen(true)
        setTicket(ticketNumber)
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  function handleChange(files) {
    formik.setFieldValue('files', files)
  }

  return (
    <>
      <Box mb={2} textAlign="center">
        <Typography
          component="span"
          variant="subtitle2"
          className={classes.solicitationNumber}
        >
          {`Cotação: ${quotation.solicitationNumber}`}
        </Typography>
      </Box>

      <Box component={Paper} p={4} my={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <img src={icoInfo} alt="Informação" width={28} />
          </Grid>
          <Grid item xs={10}>
            Vamos precisar avaliar mais de perto sua cotação!
            Confira os dados abaixo e caso necessário informe
            os campos que ainda não foram preenchidos para
            realizarmos a análise interna.
          </Grid>
        </Grid>
      </Box>

      <QuotationInfo />

      <Box component={Paper} p={4} my={2}>
        <Box mb={3}>
          <Typography fontWeight="bold" variant="subtitle1" component="h2">
            Descreva abaixo as informações que considera importante
            para analisarmos melhor essa solicitação:
          </Typography>
        </Box>

        <Box>
          <TextField
            id="note"
            {...formik.getFieldProps('note')}
            error={formik.touched.note && !!formik.errors.note}
            helperText={formik.touched.note && formik.errors.note}
            className={classes.minHeight}
            label="Digite aqui..."
            variant="outlined"
            multiline
            fullWidth
            rows={6}
          />
        </Box>
      </Box>

      <Box component={Paper} p={4} my={2}>
        <Box mb={3}>
          <Typography fontWeight="bold" variant="subtitle1" component="h2">
            Envie em anexo os documentos que considera importantes para a análise:
          </Typography>
        </Box>

        <Box>
          <DropzoneArea
            id="files"
            {...formik.getFieldProps('files')}
            dropzoneClass={classes.dropzone}
            Icon={() => (
              <>
                <Box mb={2}>
                  <BackupIcon color="primary" className={classes.iconUpload} />
                </Box>

                <Box mb={2}>
                  <Typography variant="body2" component="span">
                    Arraste o arquivo ou
                  </Typography>
                </Box>

                <Box mb={2}>
                  <Button
                    color="primary"
                    title="Selecione do seu computador"
                    variant="outlined"
                  >
                    Selecione do seu computador
                  </Button>
                </Box>

                <Box mb={2}>
                  <Typography variant="body2" component="span">
                    Extensões permitidas: .pdf, .xls.
                  </Typography>
                </Box>
              </>
            )}
            onChange={handleChange}
            dropzoneText=""
            acceptedFiles={['application/pdf', '.xls']}
            showPreviewsInDropzone={false}
            showAlerts={false}
          />
        </Box>

        {formik.values.files.length > 0 && (
          <>
            <Box pt={2} pb={2}>
              {formik.values.files.map((item, index) => (
                <span key={index}>
                  <Box component="span" mr={1} my={1 / 2} className={classes.selectedMember}>
                    {item.name}
                  </Box>
                </span>
              ))}
            </Box>
          </>
        )}
      </Box>

      <Grid container spacing={1} alignItems="center" justify="flex-end">
        <Grid item lg={2}>
          <Button
            fullWidth
            variant="text"
            color="primary"
            title="Cancelar"
            onClick={onCancel}
            className={classes.cancel}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item lg={2}>
          <Button
            color="primary"
            title="Corrigir"
            variant="outlined"
            onClick={onCorrect}
            fullWidth
          >
            Corrigir
          </Button>
        </Grid>
        <Grid item lg={3} className={classes.analyzeGrid}>
          <Button
            color="primary"
            title="Enviar para Análise"
            variant="contained"
            onClick={() => formik.submitForm()}
            fullWidth
          >
            ENVIAR PARA ANÁLISE
          </Button>
        </Grid>
      </Grid>

      <RejectedModal
        open={open}
        ticketNumber={`#${ticket}`}
        solicitationNumber={`${quotation.solicitationNumber}`}
      />
    </>
  )
}

export default QuotationDenied
