import React from 'react'
import {
  Box,
  Grid,
  Button,
  TextField,
  makeStyles,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useAlert } from '../../components'
import useInvoiceClient from '../../clients/InvoicesClient/useInvoicesClient'

const useStyles = makeStyles(() => ({
  btn: {
    width: '100%',
    heigth: 30,
  },
}))

const InvoiceEmail = ({ handleCancel, ourNumberBillet }) => {
  const classes = useStyles()
  const invoiceClient = useInvoiceClient()
  const { addMsgDanger, addMsgSuccess } = useAlert()

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),
    onSubmit: (data) => {
      invoiceClient().getSendBillet(
        data.email,
        ourNumberBillet,
      )
        .then((response) => {
          addMsgSuccess(response.data)
          handleCancel()
        }, (error) => {
          addMsgDanger(error.data)
        })
    },
  })

  return (
    <Box mb={4}>
      <TextField
        id="email"
        color="secondary"
        label="E-mail*:"
        title="E-mail"
        fullWidth
        {...formik.getFieldProps('email')}
        error={formik.touched.email && !!formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
      />

      <Box mt={4}>
        <Grid pb={2} container justify="center" spacing={2}>
          <Grid item lg={4} sm={6} xs={12}>
            <Button
              color="primary"
              variant="outlined"
              className={classes.btn}
              onClick={handleCancel}
              title="Cancelar"
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={() => { formik.submitForm() }}
              title="Confirmar"
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

InvoiceEmail.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  ourNumberBillet: PropTypes.string.isRequired,
}

export default InvoiceEmail
