import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  Box,
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import { ModalInfo } from '../../../components'

const useStyles = makeStyles(() => ({
  text: {
    background: '#FFF',
    overflow: 'auto',
    maxHeight: 480,
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 850,
      background: '#efefef',
    },
  },
  btn: {
    width: '100%',
  },
}))

const TermModal = forwardRef(({ onAccept, showBack }, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    onOpen: () => setOpen(true),
  }))

  return (
    <ModalInfo
      open={open}
      close={showBack}
      className={classes.modal}
      onClose={() => setOpen(false)}
    >
      <Box textAlign="center">
        <Typography variant="h6">TERMOS E CONDIÇÕES PARA EMISSÃO DE APÓLICE DE SEGURO</Typography>
        <Box px={3} mb={2} mt={5}>
          <Typography variant="body1">Prezado corretor, é muito importante a leitura atenta destes Termos no ato da contratação do seguro, pois ao contratar você estará aceitando as nossas regras.</Typography>
        </Box>

        <Box className={classes.text} mb={5} p={3} textAlign="left">
          <Box mb={3}>
            <Typography variant="body2" align="justify">
              Este Termo e Condições Gerais (“Termo”) aplicam-se ao serviço de emissão de Proposta
              Comercial de Seguro oferecido pela POTTENCIAL SEGURADORA S.A., sociedade
              devidamente inscrita no CNPJ/MF sob o nº 11.699.534/0001-74, para o cliente,
              cujos dados passam a ser parte integrante deste Termo, que aceita todas as
              cláusula e condições da proposta, bem como todas as demais políticas disponíveis
              no site da seguradora, neste ato denominada “USUÁRIO”
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              CONSIDERANDO QUE:
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              I. A POTTENCIAL SEGURADORA é prestadora dos serviços descritos na Proposta
              Comercial e na plataforma digital de emissão de apólices de seguro;
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              II. O USUÁRIO tem interesse na contratação dos serviços oferecidos pela POTTENCIAL
              SEGURADORA;
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              III. Ao aceitar eletronicamente o presente Termo, através do clique no botão
              “Aceito os termos e condições da proposta”, o USUÁRIO estará automaticamente
              aderindo e concordando em se submeter integralmente a seus termos e condições,
              bem como aos Termos e Condições da apólice e de qualquer de suas alterações
              futuras, além de aceitar as disposições das políticas do site, de todas as
              condições da Proposta Comercial de Seguro e da legislação aplicável.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              1) DO CONTEÚDO E DA PROPRIEDADE INTELECTUAL
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              1.1. O USUÁRIO aceita e declara compreender que a Pottencial Seguradora envidará seus
              maiores esforços para assegurar que as informações, materiais, dados e ferramentas
              virtuais oferecidos neste site estejam atualizados, precisos e completos quanto
              possível, mas que, em nenhuma circunstância, poderá ser responsabilizada por fatos
              que não lhe sejam exclusivamente atribuíveis quanto à integridade, atualidade,
              exatidão, sigilo e utilização de tais informações, materiais, dados e ferramentas
              virtuais.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              1.2. O USUÁRIO aceita e declara compreender que, em razão do ambiente virtual, a
              Pottencial Seguradora não poderá garantir que o acesso ao site seja livre de erros ou
              problemas decorrentes de casos fortuitos, internos ou externos, casos de força
              maior ouainda de outros casos não inteiramente sujeitos ao controle direto dos
              administradores do 2 site, e portanto, o USUÁRIO se obriga a isentar a Pottencial
              Seguradora de quaisquer reclamações referentes a descumprimento de prazo,
              interrupções, interceptações, invasões, disseminação de vírus ou outros atos
              ilícitos, típicos e atípicos do ambiente virtual, dos quais a Pottencial
              Seguradora não tenha tido intenção deliberada de participar ou praticar.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              1.3. O USUÁRIO aceita e declara compreender que qualquer texto, marca, áudio, imagem,
              ou “CONTEÚDO” veiculados no presente site são protegidos por direitos de propriedade
              intelectual pertencentes a Pottencial Seguradora e/ou parceiros que autorizaram a
              divulgação, razão pela qual não podem ser copiados, reproduzidos ou simplesmente
              republicados em nenhuma hipótese, salvo se expressamente autorizado pelo respectivo
              detentor dos referidos direitos, sob pena de apuração das responsabilidades cabíveis,
              bem como cobrança por danos morais e materiais.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              2) CONDIÇÕES PARA EMISSÃO
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              2.1: São condições para emissão da apólice:

              <ul>
                <li>
                  <Box my={1}>
                    O PEDIDO é vinculante e obrigatório por parte do Proponente/Tomador, não
                    podendo, após sua CONFIRMAÇÃO, ser retratado, alterado ou revogado;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    Ao CONFIRMAR O PEDIDO, a Proposta de Seguro será enviada e a APÓLICE
                    EMITIDA AUTOMATICAMENTE, gerando o dever de pagamento do respectivo
                    Prêmio pelo Tomado/Usuário;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    O Tomador/Usuário é inteiramente responsável pela exatidão, veracidade e
                    legalidade das informações e declarações que prestar e fornecer para geração do
                    PEDIDO;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    Para as apólices judiciais na modalidade Depósito Recursal, o Tomador deverá
                    solicitar expressamente a sua renovação com antecedência mínima de 90 dias para
                    o fim de sua vigência, sob pena de prorrogação automática da apólice pela
                    SEGURADORA, e emissão do respectivo boleto de cobrança do prêmio;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    Para as apólices judiciais (Judicial Cível, Execução Fiscal e Execução
                    Trabalhista), o Tomador deverá igualmente solicitar de forma expressa
                    a sua renovação com antecedência mínima de 90 dias para o fim de sua
                    vigência, sob pena de prorrogação automática da apólice pela SEGURADORA,
                    e emissão do respectivo boleto de cobrança do prêmio;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    As solicitações de cancelamento de apólices emitidas serão processadas
                    regularmente pelas áreas de “Cancelamento” e “Subscrição” da Cia., e somente
                    serão deferidas caso preenchidos os requisitos destas áreas para o cancelamento
                    de apólices;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    Não haverá devolução de prêmio nas modalidades de Seguro Garantia Depósito
                    Recursal e Garantia Licitante;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    O Tomador declara estar CIENTE e de ACORDO com as Regras e Condições
                    propostas, bem como com as regras e condições de cada apólice que
                    solicitar e com os termos e condições da modalidade de seguro contratada,
                    nos termos da Circular SUSEP 477/2013 e de outros normativos aplicáveis,
                    nada podendo reclamar neste sentido.
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    A Seguradora informa que não trabalha com a Circular SUSEP 577/2018;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    O corretor de seguros declara que disponibilizou formalmente, antes da aquisição
                    deste seguro, as informações mínimas estabelecidas na Resolução CNSP nº 382,
                    notadamente descritas no Art. 4 §1º, incisos I a IV, incluindo o valor da
                    comissão desta operação;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    Este Termo permanecerá vigente até que sejam cumpridas todas as obrigações do
                    Tomador garantidas pela Seguradora através da(s) apólice(s) e/ou endosso(s). O
                    USUÁRIO declara estar ciente de todas as informações contidas na proposta para
                    realizar a emissão.
                  </Box>
                </li>
              </ul>
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              3) CANCELAMENTO
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              3.1. As solicitações de cancelamento de apólices emitidas serão processadas
              regularmente pelas áreas de “Cancelamento” e “Subscrição” da Cia., e somente
              serão deferidas caso preenchidos os requisitos destas áreas para o cancelamento
              de apólices.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              3.2. Não haverá devolução de prêmio nas modalidades de Seguro Garantia Depósito
              Recursal e Garantia Licitante. Não será aplicado o disposto nesta cláusula nos
              casos em que o pedido de cancelamento seja realizado por erro na emissão da
              garantia e não aceitação da garantia pelo juízo, de forma documentalmente comprovada,
              apenas para a garantia de depósito recursal.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              3.3. Solicitado o cancelamento pelo Tomador ou ocorrendo a extinção da
              apólice (s) e/ou do endosso(s), independentemente do motivo, será devido
              pelo Tomador todos os custos, prêmios, impostos e taxas em aberto na apólice
              cancelada e/ou extinta, ressalvado apenas o disposto no item 3.3.1 abaixo.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              3.3.1. Na hipótese de recusa formal e escrita da(s) apólice(s) e/ou endosso(s)
              por parte do(s) Segurado(s), poderá o Tomador solicitar à Seguradora o cancelamento
              do seguro. Se a solicitação de cancelamento for feita, excepcionalmente, no prazo de
              30 (trinta) dias contados da emissão da(s) apólice(s) e/ou endosso(s), a Seguradora
              restituirá integralmente 4 o valor do prêmio pago ao Tomador, deduzindo-se apenas as
              taxas, custos e impostos incidentes. Após o prazo acima, a Seguradora reterá do prêmio
              recebido, além das taxas, custos e impostos incidentes, a parte proporcional ao tempo
              decorrido com base na Tabela de Prazo Curto da SUSEP.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              3.3.2. Conforme Art. 9º da Instrução Normativa 907/2009, as instituições financeiras
              encarregadas da cobrança de prêmio de seguro são responsáveis pela cobrança e repasse
              do IOF. Assim, em caso de restituição de prêmio de seguro, independentemente
              do motivo, não haverá a restituição do IOF.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              3.4. Fica desde já ajustado que relativamente às apólices emitidas na modalidade
              Licitante, nos casos em que o Tomador lograr-se vencedor do certame licitatório,
              com a consequente assinatura do contrato administrativo, a Seguradora não estará
              obrigada a emitir as apólices de performance às quais o instrumento contratual
              e/ou o edital determinar(em).
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              3.4.1. Ainda referente à modalidade Licitante, o Tomador não terá direito à
              devolução do prêmio, taxas e impostos incidentes após a emissão da apólice,
              em quaisquer casos, seja por desclassificação, seja porque não se sagrou
              vitorioso no certame, seja porque desistiu de participar do certame, seja
              porque a apólice foi recusada por qualquer motivo.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              4) FALSIDADE IDEOLÓGICA
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              4.1. O USUÁRIO declara, neste ato, para todos os fins e efeitos de direito,
              que o(s) signatário(s) é(são) seu(s) legítimo(s) representante(s), conforme
              documentos societários e, quando for o caso, procuração, estando ciente de
              que a falsidade na prestação desta informação acarretará na apuração das
              perdas e danos e sujeitará todas as pessoas que para ela concorrem, às
              penalidades previstas na legislação criminal relativas à falsidade
              ideológica (art. 299 do Código Penal).
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              5) ASPECTOS LEGAIS DE IDONEIDADE
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              5.1. O USUÁRIO se obriga, sob as penas previstas na legislação aplicável,
              a observar e cumprir rigorosamente todas as leis cabíveis, incluindo,
              mas não se limitando à legislação brasileira anticorrupção, a legislação
              brasileira contra a lavagem de dinheiro, assim como as normas e exigências
              constantes das políticas internas da Pottencial Seguradora.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              5.2. O USUÁRIO declara e garante que não está envolvido ou irá se envolver,
              direta ou indiretamente, por meio de seus representantes, administradores,
              diretores, conselheiros, sócios ou acionistas, assessores, consultores,
              partes relacionadas, em qualquer atividade ou prática que constitua uma
              infração aos termos das leis anticorrupção.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              5.3. O USUÁRIO declara e garante que não se encontra, assim como seus representantes,
              administradores, diretores, conselheiros, sócios ou acionistas, assessores,
              consultores, direta ou indiretamente (i) sob investigação em virtude de
              denúncias de suborno e/ou corrupção; (ii) no curso de um processo judicial
              e/ou administrativo ou foi condenada ou indiciada sob a acusação de corrupção
              ou suborno; (iii) suspeita de práticas de terrorismo e/ou lavagem de dinheiro
              por qualquer entidade governamental; e (iv) sujeito à restrições ou sanções
              econômicas e de negócios por qualquer entidade governamental.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              5.4. O USUÁRIO declara que, direta ou indiretamente, não irá receber,
              transferir, manter, usar ou esconder recursos que decorram de qualquer
              atividade ilícita, bem como não irá contratar como empregado ou de alguma
              forma manter relacionamento profissional com pessoas físicas ou jurídicas
              envolvidas em atividades criminosas, em especial pessoas investigadas pelos
              delitos previstos nas leis anticorrupção, de lavagem de dinheiro, tráfico
              de drogas e terrorismo
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              5.5. O USUÁRIO declara e garante que (i) os atuais representantes não são funcionários
              públicos ou empregados do governo; e que (ii) informará por escrito, no prazo
              de 3 (três) dias úteis, qualquer nomeação de seus representantes como funcionários
              públicos ou empregados do governo.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              5.6 O USUÁRIO se obriga a cumprir e fazer respeitar o código de ética da Pottencial
              Seguradora (“Código de Ética”).
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              6) TRATAMENTO DE DADOS PESSOAIS
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.1. O Titular, de maneira livre, informada e inequívoca consente e concorda
              que a empresa POTTENCIAL SEGURADORA S.A., CNPJ nº 11.699.534/0001-74, com
              sede na Av. Raja Gabaglia, nº 1143, 20º andar, Luxemburgo, Belo Horizonte/MG,
              CEP:30.380-403, telefone (31) 2121-7777, e-mail: juridico@pottencial.com.br,
              doravante denominada Controlador, tome decisões referentes ao tratamento de
              seus dados pessoais, bem como realize o tratamento de seus dados pessoais,
              envolvendo operações como as que se referem a coleta, produção, recepção,
              classificação, utilização, acesso, reprodução, transmissão, distribuição,
              processamento, arquivamento, armazenamento, eliminação, avaliação ou controle
              da informação, modificação, comunicação, transferência, difusão ou extração.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.2. O Controlador fica autorizado a tomar decisões referentes ao tratamento e a
              realizar o tratamento dos seguintes dados pessoais do Titular:

              <ul>
                <li>
                  <Box my={1}>Nome completo/Razão Social.</Box>
                </li>
                <li>
                  <Box my={1}>Profissão</Box>
                </li>
                <li>
                  <Box my={1}>Número do Cadastro de Pessoas Físicas (CPF)/CNJP.</Box>
                </li>
                <li>
                  <Box my={1}>Endereço completo.</Box>
                </li>
                <li>
                  <Box my={1}>Endereço de e-mail.</Box>
                </li>
              </ul>
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.3. O tratamento dos dados pessoais listados nesta cláusula tem as
              seguintes finalidades:

              <ul>
                <li>
                  <Box my={1}>
                    Possibilitar que o Controlador identifique e entre em contato com o
                    Titular para fins de relacionamento comercial;
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    O legítimo interesse da Pottencial e a intenção das partes na execução de
                    contrato e transações correlatas.
                  </Box>
                </li>
                <li>
                  <Box my={1}>
                    Interesse do Titular em firmar contrato de prestação de serviços ou correlatos
                    com a Pottencial.
                  </Box>
                </li>
              </ul>
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.4. O Controlador fica autorizado a compartilhar os dados pessoais do Titular
              com outros agentes de tratamento de dados, caso seja necessário para as finalidades
              listadas neste Termo, observados os princípios e as garantias estabelecidas
              pela Lei nº 13.709/2018
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.5. O Controlador responsabiliza-se pela manutenção de medidas de segurança, técnicas
              e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de
              situações acidentais ou ilícitas de destruição, perda, alteração, comunicação
              ou qualquer forma de tratamento inadequado ou ilícito.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.6. Em conformidade ao art. 48 da Lei nº 13.709/2018, o Controlador comunicará
              ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de
              incidente de segurança que possa acarretar risco ou dano relevante ao Titular.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.7. O Controlador poderá manter e tratar os dados pessoais do Titular durante todo o
              período em que os mesmos forem pertinentes ao alcance das finalidades listadas nesta
              cláusula. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo,
              poderão ser mantidos por período indefinido.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.8. O Titular poderá solicitar via e-mail ou correspondência ao Controlador,
              a qualquer momento, que sejam eliminados os dados pessoais não anonimizados do
              Titular. O Titular fica ciente de que poderá ser inviável ao Controlador continuar
              o fornecimento de produtos ou serviços ao Titular a partir da eliminação dos
              dados pessoais.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.9. O Titular tem direito a obter do Controlador, em relação aos dados por ele
              tratados, a qualquer momento e mediante requisição: I - confirmação da existência
              de tratamento; II - acesso aos dados; III - correção de dados incompletos, inexatos
              ou desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709/2018;
              V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante
              requisição expressa, de acordo com a regulamentação da autoridade nacional,
              observados os segredos comercial e industrial; VI - eliminação dos dados pessoais
              tratados com o consentimento do titular, exceto nas hipóteses previstas no
              art. 16 da Lei nº 13.709/2018; VII - informação das entidades públicas e privadas
              com as quais o controlador realizou uso compartilhado de dados; VIII - informação
              sobre a possibilidade de não fornecer consentimento e sobre as consequências
              da negativa; IX - revogação do consentimento, nos termos do § 5º do art. 8º da
              Lei nº 13.709/2018.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              6.10. Este consentimento poderá ser revogado pelo Titular, a qualquer momento,
              mediante solicitação via e-mail ou correspondência ao Controlador.
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="subtitle2" align="left">
              7) DECLARAÇÃO DE TRANSPARÊNCIA
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify">
              O corretor de seguros/intermediário declara que disponibilizou formalmente, antes da
              aquisição deste seguro, as informações mínimas estabelecidas na Resolução CNSP nº 382,
              notadamente descritas no Art. 4 §1º, incisos I a IV, incluindo o valor da comissão
              desta operação.
            </Typography>
          </Box>
        </Box>

        <Grid container justify="center" spacing={2}>
          {showBack && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Button
                color="primary"
                variant="outlined"
                className={classes.btn}
                onClick={() => setOpen(false)}
                title="Voltar"
              >
                Voltar
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              title="Li e Aceito"
              onClick={() => {
                onAccept()
                setOpen(false)
              }}
            >
              Li e Aceito
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalInfo>
  )
})

TermModal.propTypes = {
  showBack: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
}

TermModal.defaultProps = {
  showBack: true,
}

export default TermModal
