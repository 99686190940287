import React, { useState, useEffect } from 'react'
import NotificationsIcon from '@material-ui/icons/Notifications'
import CloseIcon from '@material-ui/icons/Close'
import {
  Badge,
  Button,
  makeStyles,
  Typography,
  Box,
  Popover,
  List,
  ListItem,
  Grid,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  badge: {
    background: '#484545',
    color: '#FFF',
    borderRadius: '50%',
    fontSize: 9,
    position: 'absolute',
    top: 4,
    right: 4,
    width: 15,
    height: 15,
    textAlign: 'center',
    display: 'block',
  },
  btn: {
    color: theme.palette.primary.contrastText,
    fontSize: '1.05rem',
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    width: 10,
    height: 10,
    cursor: 'pointer',
  },
  date: {
    color: '#79828B',
    fontSize: 10,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    whiteSpace: 'normal',
    width: '100%',
    borderTop: '1px solid #EAEEF2',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(
      1,
    )}px 0`,
  },
  all: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    width: '100%',
    fontWeight: 300,
    padding: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    fontSize: '.8rem',
  },
  titleBox: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1.2rem',
    marginLeft: theme.spacing(1),
  },
  itemContainer: {
    maxWidth: 320,
    width: '100%',
  },
  container: {
    padding: `0 ${theme.spacing(2)}px`,
    maxWidth: 500,
  },
  title: {
    fontWeight: 500,
  },
  desc: {
    fontSize: '.8rem',
  },
  containerAction: {
    textAlign: 'right',
  },
}))

const Notifications = () => {
  const [items, setItems] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  // POPOVER
  const handleClick = (event) => {
    setAnchorEl(event.target)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    async function getNotifications() {
      // const data = await fetch('http://demo0939101.mockable.io/notification');
      // const json = await data.json();
      const json = [
        {
          id: 0,
          date: '20 de Março',
          text: 'Pendencia Financeira!',
          desc:
            'O boleto referente a apólice 827467365 venceu a 5 dias…',
          action: 'Realizar_pagamento',
          actionText: 'Realizar Pagamento',
        },
        {
          id: 1,
          date: '20 de Março',
          text: 'Pendencia Financeira!',
          desc:
            'O boleto referente a apólice 827467365 venceu a 5 dias…',
          action: 'Realizar_pagamento',
          actionText: 'Realizar Pagamento',
        },
      ]
      setItems(json)
    }
    getNotifications()
  }, [])

  const notifyAction = () => {

  }

  const deleteItem = (event) => {
    const itemsMirror = items
    const itemIndex = itemsMirror.findIndex(
      (element) => element.id === parseInt(event.target.dataset.id, 10),
    )
    itemsMirror.splice(itemIndex, 1)
    setItems(itemsMirror.map((item) => item))
  }

  const classes = useStyles()
  return (
    <>
      <Button
        className={classes.btn}
        onClick={handleClick}
        aria-controls="simple-menu"
        title={items.length !== 0 ? items.length : ''}
      >
        <span>
          <NotificationsIcon />
          {items.length !== 0 ? (
            <Badge className={classes.badge}>{items.length}</Badge>
          ) : (
            ''
          )}
        </span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box className={classes.container}>
          <Box className={classes.titleBox}>
            <NotificationsIcon color="action" />
            <Typography className={classes.titleText} variant="h6">
              Notificações
            </Typography>
          </Box>
          {items.length === 0 ? (
            <Box textAlign="center" pb={2}>
              <Typography>Sem novos alertas</Typography>
            </Box>
          ) : (
            <List className={classes.listContainer} component="nav">
              {items.map((item) => (
                <ListItem
                  key={item.id}
                  className={classes.item}
                >
                  <Box>
                    <CloseIcon
                      className={classes.close}
                      onClick={deleteItem}
                      data-id={item.id}
                    />
                    <Typography className={classes.date}>
                      {item.date}
                    </Typography>
                    <Typography
                      className={classes.title}
                      paragraph
                    >
                      {item.text}
                    </Typography>
                  </Box>
                  <Grid container>
                    <Grid item xs>
                      <Typography
                        className={classes.desc}
                      >
                        {item.desc}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      className={classes.containerAction}
                    >
                      {item.action !== '' ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          title={item.actionText}
                          onClick={() => notifyAction(
                            item.action,
                          )}
                        >
                          {item.actionText}
                        </Button>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Popover>
    </>
  )
}

export default Notifications
