import { useCallback } from 'react'
import axios from 'axios'

const useIpsClient = () => {
  const apiIpsUrl = '/api/ips/allowed-ips'

  const getIps = useCallback(() => axios.get(apiIpsUrl), [])

  const createIp = useCallback((values) => axios.post(apiIpsUrl, values), [])

  const deleteIp = useCallback((values) => axios.delete(apiIpsUrl, values), [])

  return useCallback(() => ({
    getIps,
    createIp,
    deleteIp,
  }), [getIps, createIp, deleteIp])
}

export default useIpsClient
