import React, { useState, useMemo, useCallback } from 'react'
import {
  Box,
  Grid,
  Card,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core'
import ReactTooltip from 'react-tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import PolicyWrapper from './PolicyWrapper'
import { Resolve } from '../../../../components'
import useUtils from '../../../../hooks/useUtils'
import usePolicyClient from '../../../../clients/PolicyClient'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}))

const PolicyChartRenewed = () => {
  const classes = useStyles()
  const { date } = useUtils()
  const policyClient = usePolicyClient()

  const [sevenDays, setSevenDays] = useState({})
  const [thirtyDays, setThirtyDays] = useState({})
  const [sixtyDays, setSixtyDays] = useState({})

  const handleResolve = useMemo(() => ({
    sevenDays: () => new Promise((resolve) => {
      const query = { StartDate: date().subtract(7, 'd').format(), EndDate: date().format() }

      policyClient().getSummaryRenewed(query).then((response) => {
        resolve(response.data)
      }, () => {
        resolve({})
      })
    }),
    thirtyDays: () => new Promise((resolve) => {
      const query = { StartDate: date().subtract(38, 'd').format(), EndDate: date().subtract(8, 'd').format() }

      policyClient().getSummaryRenewed(query).then((response) => {
        resolve(response.data)
      }, () => {
        resolve({})
      })
    }),
    sixtyDays: () => new Promise((resolve) => {
      const query = { StartDate: date().subtract(99, 'd').format(), EndDate: date().subtract(39, 'd').format() }

      policyClient().getSummaryRenewed(query).then((response) => {
        resolve(response.data)
      }, () => {
        resolve({})
      })
    }),
  }), [policyClient, date])

  const handleLoaded = useCallback((data, resolve) => {
    setSevenDays(data.sevenDays || 0)
    setThirtyDays(data.thirtyDays || 0)
    setSixtyDays(data.sixtyDays || 0)
    resolve()
  }, [])

  return (
    <Resolve
      resolve={handleResolve}
      onLoaded={handleLoaded}
    >
      <Box my={3}>
        <Grid container justify="flex-start" spacing={2}>
          <Grid item xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <Box mb={3}>
                  <Grid container alignItems="flex-start" justify="flex-start">
                    <ReactTooltip place="top" type="dark" />
                    <Grid item>
                      <Typography variant="subtitle2">
                        Apólices renovadas por período
                        <HelpOutlineIcon
                          fontSize="small"
                          data-html
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={3} mt={6} display="flex" justifyContent="center">
                  <PolicyWrapper count={7} item={sevenDays} backgroundColor="#f36523" />
                  <PolicyWrapper count={30} item={thirtyDays} backgroundColor="#ec8d61" />
                  <PolicyWrapper count={60} item={sixtyDays} backgroundColor="#f7c8b2" />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Resolve>
  )
}

export default PolicyChartRenewed
