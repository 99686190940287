import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import {
  Link,
  useParams,
  useHistory,
} from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import Box from '@material-ui/core/Box'
import ReactTooltip from 'react-tooltip'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import {
  Resolve,
  useAlert,
  CPFInput,
  PhoneInput,
  RadioButton,
  CustomSwitch,
} from '../../components'
import useYup from '../../hooks/useYup'
import useUtils from '../../hooks/useUtils'
import UserSystemAccess from './UserSystemAccess'
import useUserClient from '../../clients/UserClient'

const DEFAULT_PROFILE = {
  name: '',
  mainDocument: '',
  email: '',
  office: '',
  phoneNumber: '',
  administrator: '',
  active: '',
  functionalities: [],
}

const UserProfile = () => {
  const params = useParams()
  const history = useHistory()
  const { isBlank } = useUtils()
  const userClient = useUserClient()
  const { addMsgDanger, addMsgSuccess } = useAlert()
  const { cpf: cpfRule, phoneOrCellphone: phoneRule } = useYup()

  const [permission, setPermission] = useState({})
  const [functionality, setFunctionality] = useState([])
  const [userInfo, setUserInfo] = useState({ ...DEFAULT_PROFILE })

  const formik = useFormik({
    initialValues: { ...DEFAULT_PROFILE },
    validationSchema: Yup.object({
      id: Yup.string(),
      name: Yup.string().max(200).required(),
      email: Yup.string().email().required(),
      mainDocument: Yup.string().when('id', {
        is: (id) => isBlank(id),
        then: cpfRule.required(),
      }),
      office: Yup.string().max(60).required(),
      phoneNumber: phoneRule.required(),
      administrator: Yup.string().required(),
      active: Yup.boolean().required(),
    }),
    onSubmit: (data) => {
      const { name, administrator } = data

      const user = {
        ...permission,
        ...data,
        id: params.id,
        administrator: JSON.parse(administrator),
      }

      if (params.id) {
        userClient().update(params.id, user).then(() => {
          history.replace('/usuario/listar')
          addMsgSuccess(`O usuário ${name} foi alterado com sucesso!`)
        }, (response) => {
          addMsgDanger(response.data)
        })
      } else {
        userClient().create(user).then(() => {
          history.replace('/usuario/listar')
          addMsgSuccess(`O usuário ${name} foi incluído com sucesso!`)
        }, (response) => {
          addMsgDanger(response.data)
        })
      }
    },
  })

  const { setValues, setFieldValue } = formik

  const inputProps = {
    disabled: !!params.id,
  }

  const handleResolve = useMemo(() => ({
    user: () => new Promise((resolve, reject) => {
      if (params.id) {
        userClient().getUserbyId(params.id).then((response) => {
          resolve(response.data)
        }, (error) => {
          reject()
          addMsgDanger(error.data)
        })
      } else {
        resolve({ ...DEFAULT_PROFILE })
      }
    }),
    functionality: () => new Promise((resolve, reject) => {
      userClient().getFunctionality().then((response) => {
        const data = response.data.map((item) => ({ ...item, checked: false }))
        resolve(data)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
  }), [userClient, addMsgDanger, params.id])

  const handleLoaded = useCallback((data, resolve) => {
    const {
      user,
      functionality: functionalities,
    } = data

    setUserInfo(user)
    setFunctionality(functionalities)
    setPermission({ functionalities: user?.functionalities || [] })

    const {
      id,
      name,
      mainDocument,
      email,
      office,
      phoneNumber,
      administrator,
      active,
    } = user

    setValues({
      id,
      name: name || '',
      email: email || '',
      office: office || '',
      active: active || false,
      mainDocument: mainDocument || '',
      phoneNumber: phoneNumber || '',
      administrator: '',
    })

    if (!isBlank(administrator)) {
      setFieldValue('administrator', `${administrator}`)
    }

    resolve()
  }, [isBlank, setValues, setFieldValue])

  const handleAdm = (event) => {
    const { value } = event.target
    setFieldValue('administrator', `${value}`)

    if (value === 'true') {
      setPermission({ functionalities: [] })
      setUserInfo({ ...userInfo, functionalities: [] })
    }
  }

  const handleSubmit = () => {
    formik.submitForm()
  }

  return (
    <>
      <Resolve
        onLoaded={handleLoaded}
        resolve={handleResolve}
      >
        <>
          <Grid item sm={12}>

            {/* DADOS DO USUARIO */}
            <Box component={Paper} pt={3} px={4} pb={5} mb={3}>
              {/* TITULO */}
              <Box mb={4}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Typography
                      variant="body1"
                      component="h2"
                      color="primary"
                    >
                      DADOS GERAIS
                    </Typography>
                  </Grid>
                  {params.id && (
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid item>
                          <CustomSwitch
                            {...formik.getFieldProps('active')}
                            checked={formik.values.active}
                            id="userActive"
                            title={formik.values.active ? 'Inativar Usuário' : 'Ativar Usuário'}
                          />
                        </Grid>
                        <Grid item>
                          <Box component="label" htmlFor="userActive" ml={1}>
                            <Typography component="span">
                              {formik.values.active ? ' Usuário Ativo' : 'Usuário Inativo'}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                    </Grid>
                  )}
                </Grid>
              </Box>

              {/* INFORMAÇÕES */}
              <Box>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="name"
                      title="Nome"
                      color="secondary"
                      {...formik.getFieldProps('name')}
                      label="Nome*:"
                      error={formik.touched.name && !!formik.errors.name}
                      helperText={formik.touched.name && formik.errors.name}
                      InputProps={inputProps}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="email"
                      title="E-mail"
                      color="secondary"
                      label="E-mail*:"
                      fullWidth
                      InputProps={inputProps}
                      {...formik.getFieldProps('email')}
                      error={formik.touched.email && !!formik.errors.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    {!params.id && (
                      <CPFInput
                        title="CPF"
                        id="mainDocument"
                        color="secondary"
                        label="CPF*:"
                        fullWidth
                        {...formik.getFieldProps('mainDocument')}
                        error={formik.touched.mainDocument && !!formik.errors.mainDocument}
                        helperText={formik.touched.mainDocument && formik.errors.mainDocument}
                      />
                    )}

                    {!!params.id && (
                      <TextField
                        fullWidth
                        title="CPF"
                        label="CPF*:"
                        id="mainDocument"
                        color="secondary"
                        InputProps={{ disabled: true }}
                        {...formik.getFieldProps('mainDocument')}
                      />
                    )}
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="office"
                      title="Cargo"
                      color="secondary"
                      label="Cargo*:"
                      fullWidth
                      {...formik.getFieldProps('office')}
                      error={formik.touched.office && !!formik.errors.office}
                      helperText={formik.touched.office && formik.errors.office}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <PhoneInput
                      title="Telefone"
                      id="phoneNumber"
                      color="secondary"
                      label="Telefone*:"
                      fullWidth
                      {...formik.getFieldProps('phoneNumber')}
                      error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
                      helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel>
                      Perfil administrador*:
                      <Box
                        ml={1}
                        component="span"
                        data-html
                        data-tip="O usuário Administrador de uma Corretora<br /> possui acesso a todas as funcionalidades<br /> do sistema."
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </Box>
                    </InputLabel>

                    <RadioGroup
                      row
                      aria-label="administrator"
                      name="administrator"
                      {...formik.getFieldProps('administrator')}
                      value={formik.values.administrator}
                      touched={formik.touched.administrator?.toString()}
                      onChange={handleAdm}
                    >
                      <FormControlLabel
                        value="true"
                        control={<RadioButton />}
                        label="Sim"
                        title="Sim"
                      />
                      <FormControlLabel
                        value="false"
                        control={<RadioButton />}
                        label="Não"
                        title="Não"
                      />
                    </RadioGroup>
                    <FormHelperText
                      hidden={!formik.touched.administrator || !formik.errors.administrator}
                      error={formik.touched.administrator && !!formik.errors.administrator}
                    >
                      {formik.errors.administrator}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {formik.values.administrator === 'false' && (
              <Box component={Paper} pt={3} px={4} mb={3}>
                <UserSystemAccess
                  userInfo={userInfo}
                  onPermission={setPermission}
                  functionality={functionality}
                />
              </Box>
            )}

            <Grid container spacing={2} alignItems="center" justify="flex-end">
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  to="/usuario/listar"
                  component={Link}
                  title="Cancelar"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  title="Concluir"
                >
                  Concluir
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <ReactTooltip place="top" type="dark" />
        </>
      </Resolve>
    </>
  )
}

export default UserProfile
