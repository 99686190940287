import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import {
  Box,
  Grid,
  Paper,
  Button,
  Accordion,
  makeStyles,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import {
  Resolve,
  useAlert,
  Paginator,
  FilterTips,
  Breadcrumb,
} from '../../../components'
import PolicyAction from './PolicyAction'
import PolicyFilter from './PolicyFilter'
import useUtils from '../../../hooks/useUtils'
import { PRODUCTS_POTTENCIAL_ENUM } from '../../../constants'
import usePolicyClient from '../../../clients/PolicyClient/usePolicyClient'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
  },
  detailButton: {
    width: 75,
    height: 20,
    padding: 0,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 9,
    [theme.breakpoints.down('md')]: {
      width: 128,
      height: 30,
      fontSize: 12,
    },
  },
  accordion: {
    boxShadow: 'none',
    marginBottom: '0px !important',
  },
  expandIcon: {
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  title: {
    fontWeight: 600,
  },
  actions: {
    textAlign: 'right',
  },
  summary: {
    userSelect: 'inherit',
    cursor: 'text !important',
    '& .MuiAccordionSummary-content': {
      marginBottom: 0,
    },
  },
}))

const PathInfo = [
  {
    text: 'Painel',
    href: '/',
  },
  {
    text: 'Apólices',
    href: '/',
  },
]

const DEFAULT_FILTER = {
  product: '',
  policyNumber: '',
  brokeDocument: '',
  customerName: '',
  initialAward: '',
  finalAward: '',
  initialCommission: '',
  finalCommission: '',
  dateEmissionInitial: '',
  dateEmissionFinal: '',
  descriptionModality: '',
}

const PRODUCT_RENT = 'Pottencial Aluguel'

const PolicyIssued = () => {
  const classes = useStyles()
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()
  const policyClient = usePolicyClient()

  const [results, setResults] = useState(0)
  const [policies, setPolicies] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [filters, setFilters] = useState({ ...DEFAULT_FILTER })

  const tipsFormats = useMemo(() => ({
    dateEmissionInitial: (data) => formatDate(data),
    dateEmissionFinal: (data) => formatDate(data),
    product: (data) => PRODUCTS_POTTENCIAL_ENUM[data],
  }), [formatDate])

  const tipsTemplates = useMemo(() => ({
    initialCommission: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
    finalCommission: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
    initialAward: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
    finalAward: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
  }), [])

  const handleResolve = useMemo(() => ({
    policy: () => new Promise((resolve, reject) => {
      policyClient().getPolicies({}).then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [policyClient, addMsgDanger])

  const handleLoaded = useCallback((data, resolve) => {
    const { policy } = data

    setPolicies(policy.data || [])
    setResults(policy.total)
    resolve()
  }, [])

  const handleSearch = useCallback((filter) => {
    setExpanded('')

    policyClient().getPolicies(filter).then((response) => {
      const { data, total } = response.data

      setPolicies(data || [])
      setResults(total)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }, [policyClient, addMsgDanger])

  const addFilter = (data) => {
    setFilters(data)
    handleSearch(data)
  }

  const updatePage = (value) => {
    const data = { ...filters, pageNumber: value }
    handleSearch(data)
  }

  const handleRemoveFilter = (attribute) => {
    let data = {}

    if (attribute === 'initialAward' || attribute === 'finalAward') {
      data = { ...filters, initialAward: '', finalAward: '' }
    } else if (attribute === 'initialCommission' || attribute === 'finalCommission') {
      data = { ...filters, initialCommission: '', finalCommission: '' }
    } else if (attribute === 'dateEmissionInitial' || attribute === 'dateEmissionFinal') {
      data = { ...filters, dateEmissionInitial: '', dateEmissionFinal: '' }
    } else {
      data = { ...filters, [attribute]: '' }
    }

    setFilters(data)
    handleSearch(data)
  }

  const handleAccordion = (panel) => {
    let value = ''

    if (panel !== expanded) {
      value = panel
    }
    setExpanded(value)
  }

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <main>
        <Breadcrumb paths={PathInfo} />

        <Box mb={4}>
          <Typography variant="h4">Apólices Emitidas</Typography>
        </Box>

        <Grid container alignItems="center" justify="flex-end">
          <Grid item>
            <PolicyFilter addFilter={addFilter} filters={filters} />
          </Grid>
        </Grid>

        <Box>
          <FilterTips
            filter={filters}
            formats={tipsFormats}
            templates={tipsTemplates}
            onRemove={handleRemoveFilter}
          />
        </Box>

        <Box mt={3}>
          {results === 0 && (
            <Paper>
              <Box px={2} py={3}>Não foi possível encontrar registros para esta pesquisa.</Box>
            </Paper>
          )}

          {policies?.map((policy, index) => (
            <Box
              pb={1}
              my={1}
              key={index}
              borderRadius={4}
              component={Paper}
            >
              <Accordion
                className={classes.accordion}
                expanded={expanded === `${index}`}
              >
                <AccordionSummary className={classes.summary}>
                  <Grid container spacing={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <Box mb={1}>
                        <Box component="span" mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Produto:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{policy?.policyClassification}</Typography>
                      </Box>
                      <Box>
                        <Box component="span" mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Apólice:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{policy?.documentNumber}</Typography>
                      </Box>
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      {policy?.productModality !== PRODUCT_RENT && (
                        <>
                          <Box mb={1}>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Cliente:</Typography>
                            </Box>
                            <Typography variant="body2" component="span">{policy?.customer}</Typography>
                          </Box>
                          <Box>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Importância Segurada:</Typography>
                            </Box>
                            <Currency quantity={policy?.importanceInsured || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          </Box>
                        </>
                      )}

                      {policy?.productModality === PRODUCT_RENT && (
                        <>
                          <Box mb={1}>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Estipulante:</Typography>
                            </Box>
                            <Typography variant="body2" component="span">{policy?.stipulating}</Typography>
                          </Box>
                          <Box>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Garantido:</Typography>
                            </Box>
                            <Typography variant="body2" component="span">{policy?.guaranteed}</Typography>
                          </Box>
                        </>
                      )}
                    </Grid>

                    <Grid item lg={3} md={6} xs={12}>
                      <Box mb={1}>
                        <Box component="span" mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Emissão:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{formatDate(policy.issuanceDate)}</Typography>
                      </Box>
                      <Box>
                        <Box component="span" mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Vigência:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{formatDate(policy.initialTerm)}</Typography>

                        <Box component="span" ml={1} mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Até</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{formatDate(policy.finalTerm)}</Typography>
                      </Box>
                    </Grid>

                    <Grid item lg={1} md={6} xs={12} className={classes.actions}>
                      <Box component="span">
                        <PolicyAction policy={policy} />
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item lg={4} md={6} xs={12}>
                      <Box mb={1}>
                        <Box component="span" mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Modalidade:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{policy?.productModality}</Typography>
                      </Box>
                      <Box>
                        <Box component="span" mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Proposta:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{policy?.proposalNumber}</Typography>
                      </Box>
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                      <Box mb={1}>
                        <Box component="span" mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Segurado:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{policy.insured}</Typography>
                      </Box>
                      <Box>
                        <Box component="span" mr={1}>
                          <Typography variant="body2" component="span" className={classes.title}>Solicitante:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{policy?.requester}</Typography>
                      </Box>
                    </Grid>

                    <Grid item lg={2} md={6} xs={12}>
                      {policy?.productModality !== PRODUCT_RENT && (
                        <>
                          <Box mb={1}>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Comissão:</Typography>
                            </Box>
                            <Currency quantity={policy?.commission || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          </Box>

                          <Box>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Prêmio:</Typography>
                            </Box>
                            <Currency quantity={policy?.monthlyAward || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          </Box>
                        </>
                      )}

                      {policy?.productModality === PRODUCT_RENT && (
                        <>
                          <Box>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Prêmio Total:</Typography>
                            </Box>
                            <Currency quantity={policy?.totalAward || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          </Box>

                          <Box>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Prêmio Mensal:</Typography>
                            </Box>
                            <Currency quantity={policy?.monthlyAward || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          </Box>

                          <Box mb={1}>
                            <Box component="span" mr={1}>
                              <Typography variant="body2" component="span" className={classes.title}>Comissão:</Typography>
                            </Box>
                            <Currency quantity={policy?.commission || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          </Box>
                        </>
                      )}
                    </Grid>

                    <Grid item lg={2} md={6} xs={12} />
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Grid container>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Button
                      color="primary"
                      variant="text"
                      className={classes.detailButton}
                      onClick={() => handleAccordion(`${index}`)}
                      title="Ver Mais"
                    >
                      {expanded !== `${index}` && <ExpandMoreIcon className={classes.expandIcon} />}
                      {expanded === `${index}` && <ExpandLessIcon className={classes.expandIcon} />}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>

        <Paginator totalResults={results} changePaginator={updatePage} />
      </main>

    </Resolve>
  )
}

export default PolicyIssued
