import React, { useState, useEffect } from 'react'
import {
  Typography, Button, Box, makeStyles, Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles(() => ({

  btn: {
    width: '100%',
    height: '100%',
  },
  clear: {
    fontSize: '1.25rem',
    color: '#808080',
    cursor: 'pointer',
  },
}))
const RecentSearch = ({ submitForm }) => {
  const [items, setItems] = useState([])

  const clear = () => {
    setItems([])
  }

  useEffect(() => {
    async function getRecent() {
      // const data = await fetch('path_to_api');
      // const json = await data.json();
      const json = ['Incluir Garantia Performance', 'Sinistros', 'Vincular Apólices', 'Lorem Ipsum']
      setItems(json)
    }
    getRecent()
  }, [])

  const classes = useStyles()
  return (
    items.length > 0
      ? (
        <Box mb={3}>
          <Typography variant="h6">
            Pesquisas recentes
            <CancelIcon className={classes.clear} onClick={clear} />
          </Typography>
          <Box mt={2} className={classes.container}>
            <Grid container spacing={2}>
              {
                items.map(
                  (item, index) => (
                    <Grid key={index} item xl={3} lg={4} sm={6} xs={12}>
                      <Button
                        color="secondary"
                        variant="contained"
                        className={classes.btn}
                        onClick={() => submitForm(item)}
                        title={item}
                      >
                        {item}
                      </Button>
                    </Grid>
                  ),
                )
              }
            </Grid>
          </Box>
        </Box>
      ) : ''

  )
}

RecentSearch.propTypes = {
  submitForm: PropTypes.func,
}

RecentSearch.defaultProps = {
  submitForm: () => { },
}

export default RecentSearch
