import { WebStorageStateStore } from 'oidc-client'
import { makeUserManager } from 'react-oidc'

const configManager = {
  client_id: window.ENV.CLIENT_ID,
  redirect_uri: window.ENV.REDIRECT_URI,
  response_type: 'token id_token',
  scope: 'openid profile pottencial-canais-corretor-bff pottencial-canais-garantia-bff',
  authority: window.ENV.AUTHORITY_URL,
  silent_redirect_uri: window.ENV.SILENT_RENEW_REDIRECT_URI,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  post_logout_redirect_uri: window.ENV.POST_LOGOUT_REDIRECT_URI,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

export default makeUserManager(configManager)
