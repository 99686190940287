import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'

import YUP_MESSAGE from '../../../helpers/yup'
import useUtils from '../../../hooks/useUtils'
import { useAlert } from '../../../components'
import QuotationBrokers from './QuotationBrokers'
import { DEFAULT_QUOTATION_RISK } from './Constantes'
import QuotationCoverages from './QuotationCoverages'
import { useQuotationContext } from './QuotationContext'
import QuotationObligationInf from './QuotationObligationInf'
import useQuotationClient from '../../../clients/QuotationClient'
import QuotationValuesAndDeadlines from './QuotationValuesAndDeadlines'

const QuotationRisk = forwardRef((props, ref) => {
  const {
    onNext,
    onPrevious,
    quotation,
    isBid,
    isPerfomer,
    setQuotation,
  } = useQuotationContext()

  const { addMsgDanger } = useAlert()
  const { isBlank, date } = useUtils()
  const quotationClient = useQuotationClient()

  const [show, setShow] = useState(false)
  const [additionalCoverages, setAdditionalCoverages] = useState([])

  const getQuotationForm = (data) => {
    const {
      contractValue,
      insuredAmount,
      policyPeriodInitial,
      policyPeriodFinal,
      insuredDays,
      contractNumber,
      processNumber,
      noticeNumber,
      coverages,
      coBrokers,
    } = data

    return {
      ...quotation,
      insuredDays,
      policyValues: {
        contractValue: isBlank(contractValue) ? '' : Number(contractValue),
        insuredAmount: isBlank(insuredAmount) ? '' : Number(insuredAmount),
      },
      policyPeriod: {
        initial: policyPeriodInitial,
        final: policyPeriodFinal,
      },
      objectInsured: {
        contractNumber,
        processNumber,
        noticeNumber,
      },
      additionalCoverages: coverages.map((coverage) => {
        const { check } = coverage
        return {
          ...coverage,
          check: isBlank(check) ? '' : JSON.parse(check),
        }
      }),
      coBrokers: coBrokers.map((broker) => ({
        ...broker,
        id: `${broker.id}`,
        percentageOfParticipation: isBlank(broker.percentageOfParticipation) ? '' : Number(broker.percentageOfParticipation),
      })),
    }
  }

  const formik = useFormik({
    initialValues: { ...DEFAULT_QUOTATION_RISK },
    validationSchema: Yup.object().shape({
      productType: Yup.string(),
      contractValue: Yup.number().max(99999999.99, YUP_MESSAGE.invalid).required(),
      insuredAmount: Yup.number().max(99999999.99, YUP_MESSAGE.invalid).required(),
      policyPeriodInitial: Yup.date().when('productType', () => {
        let schema = Yup.date().required()

        if (isBid) {
          schema = Yup.date().min(date().set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          }).toDate(), 'A data não pode ser retroativa').required()
        }
        return schema
      }),
      policyPeriodFinal: Yup.date().when('policyPeriodInitial', (policyPeriodInitial) => {
        let schema

        if (!isBlank(policyPeriodInitial)) {
          const time = policyPeriodInitial.getTime()
          const min = date(time).add(1, 'd').set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          })
          schema = Yup.date().min(min, 'O final da vigência deve ser maior que a data de início').required()
        }
        return schema
      }).required(),
      insuredDays: Yup.number().test({
        test: (value) => value !== 0 && `${value || ''}`.length <= 4,
      }).required(),
      contractNumber: Yup.string().max(50),
      processNumber: Yup.string().max(50),
      noticeNumber: Yup.string().max(50).when('productType', {
        is: () => isBid,
        then: Yup.string().max(50).required(),
      }),
      perfomerObligationInf: Yup.string().max(50).when('productType', {
        is: () => isPerfomer,
        then: Yup.string().required('Ao menos um dos campos deve ser informado.'),
      }),
      coverages: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          check: Yup.string().required(),
        }),
      ),
      coBrokers: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(),
          brokerName: Yup.string().required(),
          documentNumber: Yup.string().required(),
          percentageOfParticipation: Yup.number().test({
            test: (value) => `${value || ''}`.length <= 2,
          }).required(),
        }),
      ),
    }),
    validateOnBlur: true,
    onSubmit: (data) => {
      const values = getQuotationForm(data)

      quotationClient().quote(values).then((response) => {
        const {
          proposalId,
          solicitationId,
          proposalNumber,
          proposalStatus,
          solicitationNumber,
          approvalUser,
          brokerProposalId,
          internetProposalId,
          automaticSubscription,
          originSystem,
        } = response.data

        setQuotation({
          ...values,
          proposalId,
          solicitationId,
          proposalNumber,
          proposalStatus,
          solicitationNumber,
          approvalUser,
          brokerProposalId,
          internetProposalId,
          automaticSubscription,
          originSystem,
        })

        onNext()
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  const handleChangePerfomerObligationInf = (contractNumber, processNumber, noticeNumber) => {
    if (isBlank(contractNumber) && isBlank(processNumber) && isBlank(noticeNumber)) {
      formik.setFieldValue('perfomerObligationInf', '')
    } else {
      formik.setFieldValue('perfomerObligationInf', 'OK')
    }
  }

  const initForm = (data) => {
    const {
      productType,
      policyValues,
      policyPeriod,
      objectInsured,
      insuredDays = '',
      coBrokers = [],
      additionalCoverages: coveragesQuotation = [],
    } = quotation || {}

    const {
      contractValue = '',
      insuredAmount = '',
    } = policyValues || {}

    const {
      initial: policyPeriodInitial = '',
      final: policyPeriodFinal = '',
    } = policyPeriod || {}

    const {
      contractNumber = '',
      processNumber = '',
      noticeNumber = '',
    } = objectInsured || {}

    let coverages = []

    if (isPerfomer) {
      const { coveragesCurrent } = data

      coverages = coveragesCurrent.map((coverageCurrent) => {
        const { id } = coverageCurrent
        const current = coveragesQuotation.find((coverageQuotation) => coverageQuotation.id === id)
        const value = typeof current?.check === 'boolean' ? `${current?.check}` : ''
        return { id, check: value }
      })
    }

    formik.setValues({
      contractValue,
      insuredAmount,
      policyPeriodInitial,
      policyPeriodFinal,
      insuredDays,
      contractNumber,
      processNumber,
      noticeNumber,
      coverages,
      productType,
      perfomerObligationInf: '',
      coBrokers: [...coBrokers],
    })

    handleChangePerfomerObligationInf(contractNumber, processNumber, noticeNumber)
  }

  const findAdditionalCoverages = () => new Promise((resolve, reject) => {
    if (isPerfomer) {
      const { productType, insured } = quotation
      const { isPublicCoverage } = insured

      quotationClient().getAdditionalCoverages(productType, isPublicCoverage).then((response) => {
        const { data } = response
        setAdditionalCoverages(data)
        resolve(data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    } else {
      resolve([])
    }
  })

  useImperativeHandle(ref, () => ({
    onOpen: () => new Promise((resolve) => {
      Promise.all([
        findAdditionalCoverages(),
      ]).then((data) => {
        initForm({
          coveragesCurrent: data[0],
        })

        resolve()
        setShow(true)
      })
    }),
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: 'Verifique os campos em destaque antes de prosseguir.' }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
    onPrevious: () => {
      const values = getQuotationForm(formik.values)
      setQuotation(values)
      onPrevious()
    },
    onClose: () => setShow(false),
  }))

  return (
    <>
      {show && (
        <>
          <QuotationValuesAndDeadlines
            parentFormik={formik}
          />

          <QuotationObligationInf
            parentFormik={formik}
            onChangePerfomerObligationInf={handleChangePerfomerObligationInf}
          />

          {isPerfomer && (
            <QuotationCoverages
              parentFormik={formik}
              additionalCoverages={additionalCoverages}
            />
          )}

          <QuotationBrokers parentFormik={formik} />
        </>
      )}
    </>
  )
})

export default QuotationRisk
