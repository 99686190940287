import PropTypes from 'prop-types'
import React from 'react'
import {
  Avatar, Box, Button, makeStyles,
} from '@material-ui/core'
import { first } from 'lodash'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'

const useStyles = makeStyles(() => ({
  avatar: {
    width: '100px',
    height: '100px',
  },
  avatarButton: {
    width: '100px',
    height: '100px',
    borderRadius: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  overlay: {
    transition: 'opacity .2s',
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
  uploadIcon: {
    backgroundColor: 'rgba(32, 33, 36, 0.6)',
    height: '40px',
    paddingTop: '4px',
    textAlign: 'center',
  },
}))

const UploadInput = (props) => {
  const {
    id, nameProfile, urlProfile, onChange,
  } = props

  const classes = useStyles()

  const handleChange = (event) => {
    const imageFile = first(event.target.files)

    onChange({
      target: { id, value: imageFile },
    })
  }

  return (
    <Button
      className={classes.avatarButton}
      component="label"
    >
      <Avatar
        alt={nameProfile}
        src={urlProfile}
        className={classes.avatar}
      />
      <Box className={classes.overlay}>
        <Box style={{ height: '80px' }} />
        <Box className={classes.uploadIcon}>
          <PhotoCameraIcon style={{ color: '#fff', fontSize: 30 }} />
        </Box>
      </Box>
      <input
        type="file"
        style={{ display: 'none' }}
        accept="image/jpeg, image/png"
        onChange={handleChange}
      />
    </Button>
  )
}

UploadInput.propTypes = {
  id: PropTypes.string.isRequired,
  nameProfile: PropTypes.string,
  urlProfile: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

UploadInput.defaultProps = {
  nameProfile: '',
  onChange: () => { },
}

export default UploadInput
