import React from 'react'
import {
  Box,
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import { ModalInfo } from '../../../components'
import { useQuotationContext } from './QuotationContext'
import bgSuccess from '../../../assets/img/bg-success-quote.png'

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 850,
      padding: 0,
    },
  },
  background: {
    backgroundImage: `url(${bgSuccess})`,
    backgroundPositionX: 'center',
    height: '485px',
  },
  midText: {
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  values: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
  btn: {
    width: 150,
  },
}))

const SuccessModal = ({ open }) => {
  const classes = useStyles()
  const { description } = useQuotationContext()

  return (
    <ModalInfo
      open={open}
      close={false}
      className={classes.modal}
    >
      <Box textAlign="center">
        <Grid container justify="space-between">
          <Grid item xs={8}>
            <Box p={5}>
              <Box textAlign="left" mb={1}>
                <Typography variant="body2" component="span">
                  {description}
                </Typography>
              </Box>
              <Box textAlign="left">
                <Typography
                  className={classes.values}
                  variant="subtitle2"
                  component="span"
                >
                  Apólice emitida com sucesso!
                </Typography>
              </Box>
              <Box className={classes.midText}>
                <Typography variant="subtitle1" align="left">
                  Em breve a sua apólice e boleto estarão disponíveis para download.
                  No menu à esquerda, acesse
                  <Button
                    color="primary"
                    variant="text"
                    to="/apolices"
                    component={Link}
                    title="Apólices"
                  >
                    Apólices
                  </Button>
                  e
                  <Button
                    color="primary"
                    variant="text"
                    to="/financeiro"
                    component={Link}
                    title="Financeiro"
                  >
                    Financeiro
                  </Button>
                  para consultar.
                </Typography>
              </Box>
              <Box textAlign="right">
                <Button
                  color="primary"
                  variant="contained"
                  to="/cotacoes"
                  component={Link}
                  title="Voltar"
                  fullWidth
                  className={classes.btn}
                >
                  Voltar
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} className={classes.background} />
        </Grid>
      </Box>
    </ModalInfo>
  )
}

SuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default SuccessModal
