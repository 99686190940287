import {
  Box,
  Grid,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { ModalInfo } from '../../components'
import UserPermission from './UserPermission'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 450,
    },
  },
}))

const UserPermissionModal = (props) => {
  const {
    opened,
    onClose,
    userInfo,
    onPermission,
    functionality,
    handlePrimary,
    handleSecondary,
  } = props

  const classes = useStyles()
  const [permission, setPermission] = useState([])

  useEffect(() => {
    const currentPermission = []
    const currentFunctionality = functionality || []
    const { functionalities: userFunctionality = [] } = userInfo

    currentFunctionality.forEach((item) => {
      const checked = userFunctionality.findIndex((itemUser) => item.id === itemUser.id) !== -1
      currentPermission.push({
        ...item,
        checked,
      })
    })

    setPermission(currentPermission)
  }, [functionality, userInfo])

  useEffect(() => {
    const functionalityChecked = permission.filter((item) => item.checked)
    const functionalityId = {
      permissions: functionalityChecked.map((item) => item.id),
    }
    onPermission(functionalityId)
  }, [permission, onPermission])

  return (
    <ModalInfo
      open={opened}
      onClose={onClose}
      className={classes.modal}
    >
      <>
        <Box mb={5} align="center">
          <Typography variant="h6">Permissões do usuário</Typography>
        </Box>

        <UserPermission permission={permission} setPermission={setPermission} />

        <Box mt={3}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                color="primary"
                title="Cancelar"
                variant="outlined"
                onClick={handleSecondary}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                title="Salvar"
                color="primary"
                variant="contained"
                onClick={handlePrimary}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    </ModalInfo>
  )
}

UserPermissionModal.propTypes = {
  userInfo: PropTypes.object,
  functionality: PropTypes.array,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPermission: PropTypes.func.isRequired,
  handlePrimary: PropTypes.func.isRequired,
  handleSecondary: PropTypes.func.isRequired,
}

UserPermissionModal.defaultProps = {
  userInfo: {},
  functionality: [],
}

export default UserPermissionModal
