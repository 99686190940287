import React, { useRef, useState, useMemo } from 'react'
import {
  Box,
  Grid,
  Paper,
  Select,
  Button,
  MenuItem,
  Checkbox,
  Typography,
  InputLabel,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import Currency from 'react-currency-formatter'
import { makeStyles } from '@material-ui/core/styles'

import TermModal from './TermModal'
import SuccessModal from './SuccessModal'
import QuotationInfo from './QuotationInfo'
import { useAlert } from '../../../components'
import YUP_MESSAGE from '../../../helpers/yup'
import { DEFAULT_QUOTATION_BUY } from './Constantes'
import useDownload from '../../../hooks/useDownload'
import { useQuotationContext } from './QuotationContext'
import useQuotationClient from '../../../clients/QuotationClient'

import icoDownload from '../../../assets/img/ico-download.svg'
import icoGarantiaBid from '../../../assets/img/ico-product-bid.svg'
import icoGarantiaPerformance from '../../../assets/img/ico-product-performance.svg'

const useStyles = makeStyles((theme) => ({
  lineTitle: {
    height: '2px',
    background: '#f36523',
    border: 'none',
    width: '60px',
    float: 'left',
  },
  values: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  icon: {
    width: '70px',
  },
  cancel: {
    padding: '6px 0px',
  },
  download: {
    height: 64,
    display: 'flex',
    borderRadius: 4,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FAFAFA',
    border: '1px dashed #707070',
  },
  subtitle: {
    fontSize: 10,
  },
  separator: {
    border: '1px solid #bdbdbd',
    borderBottom: 0,
    borderTop: 0,
  },
  solicitationNumber: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    padding: '5px 20px',
    borderRadius: '5px',
  },
}))

const QuotationSuccess = () => {
  const termRef = useRef()
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const { downloadPDF } = useDownload()
  const quotationClient = useQuotationClient()
  const {
    isBid,
    quotation,
    onCancel,
    onCorrect,
    isPerfomer,
  } = useQuotationContext()

  const [open, setOpen] = useState(false)

  const hasCoBrokers = useMemo(() => !isEmpty(quotation?.coBrokers), [quotation])
  const automaticSubscription = useMemo(() => quotation?.automaticSubscription || {}, [quotation])
  const paymentMethod = useMemo(() => automaticSubscription?.paymentsMethods.find((item) => item.paymentMethodType === 'Boleto') || {}, [automaticSubscription])
  const installments = useMemo(() => paymentMethod?.installments || [], [paymentMethod])
  const shortages = useMemo(() => paymentMethod?.shortages || [], [paymentMethod])

  const formik = useFormik({
    initialValues: { ...DEFAULT_QUOTATION_BUY },
    validationSchema: Yup.object({
      installments: Yup.number().required(),
      shortage: Yup.number().required(),
      trustedDataConfirmation: Yup.boolean().test({
        message: YUP_MESSAGE.required,
        test: (value) => value,
      }),
    }),
    onSubmit: (data) => {
      const {
        proposalNumber,
        proposalId,
        solicitationId,
      } = quotation

      const values = {
        proposalNumber,
        proposalId,
        solicitationId,
        paymentMethod: {
          id: paymentMethod.id,
          installments: data.installments,
          shortage: data.shortage,
        },
      }

      quotationClient().policy(values).then(() => {
        setOpen(true)
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  const hadleDraft = () => {
    const { proposalNumber } = quotation

    quotationClient().downloadDraft(proposalNumber).then((response) => {
      const { file } = response.data
      downloadPDF(file, `minuta_${proposalNumber}.pdf`)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const handleProposal = () => {
    const { proposalNumber } = quotation

    quotationClient().downloadProposal(proposalNumber).then((response) => {
      const { file } = response.data
      downloadPDF(file, `proposta_${proposalNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <>
      <Box mb={2} textAlign="center">
        <Typography
          component="span"
          variant="subtitle2"
          className={classes.solicitationNumber}
        >
          {`Cotação: ${quotation.solicitationNumber}`}
        </Typography>
      </Box>

      <QuotationInfo />

      <Box component={Paper} p={4} my={2}>
        <Box mb={3}>
          <Typography variant="subtitle1" component="h2">
            <Box fontWeight="bold">Valores e Pagamento</Box>
            <hr className={classes.lineTitle} />
          </Typography>
        </Box>

        <Grid container spacing={1} justify="space-between" alignItems="flex-end">
          <Grid item lg={2} sm={6} xs={12}>
            <Box textAlign="center" mb={1}>
              <Typography
                variant="body2"
                component="span"
                className={classes.subtitle}
              >
                COMISSÃO TOTAL
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography
                className={classes.values}
                variant="subtitle2"
                component="span"
              >
                <Currency
                  quantity={automaticSubscription?.totalComission || 0}
                  currency="R$ "
                  locale="pt_BR"
                  decimal=","
                  group="."
                />
              </Typography>
            </Box>
            <Box textAlign="center">
              <Typography
                variant="body2"
                component="span"
                className={classes.subtitle}
              >
                {`${automaticSubscription?.totalComissionPercent || 0}%`}
              </Typography>
            </Box>
          </Grid>

          {hasCoBrokers && (
            <>
              <Grid item lg={3} sm={6} xs={12}>
                <Box textAlign="center" mb={1}>
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.subtitle}
                  >
                    PARTICIPAÇÃO DO CORRETOR
                  </Typography>
                </Box>
                <Box className={classes.separator}>
                  <Box textAlign="center">
                    <Typography
                      className={classes.values}
                      variant="subtitle2"
                      component="span"
                    >
                      <Currency
                        quantity={automaticSubscription?.brokerParticipation || 0}
                        currency="R$ "
                        locale="pt_BR"
                        decimal=","
                        group="."
                      />
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.subtitle}
                    >
                      {`${automaticSubscription?.brokerParticipationPercent || 0}%`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} sm={6} xs={12}>
                <Box
                  mb={1}
                  minWidth={185}
                  textAlign="center"
                >
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.subtitle}
                  >
                    PARTICIPAÇÃO DOS CO-CORRETORES
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Typography
                    className={classes.values}
                    variant="subtitle2"
                    component="span"
                  >
                    <Currency
                      quantity={automaticSubscription?.coBrokerParticipation || 0}
                      currency="R$ "
                      locale="pt_BR"
                      decimal=","
                      group="."
                    />
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.subtitle}
                  >
                    {`${automaticSubscription?.coBrokerParticipationPercent || 0}%`}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}

          <Grid item lg={4} sm={6} xs={12}>
            <Box
              m={1}
              p={1}
              display="flex"
              justifyContent={{ xs: 'center', lg: 'flex-end' }}
            >
              <Box pr={1}>
                {isPerfomer && (
                  <img
                    className={classes.icon}
                    src={icoGarantiaPerformance}
                    alt="Garantia Performance"
                  />
                )}

                {isBid && (
                  <img
                    className={classes.icon}
                    src={icoGarantiaBid}
                    alt="Garantia BID"
                  />
                )}

              </Box>
              <Box>
                <Box textAlign="center" mb={1}>
                  <Typography
                    variant="body2"
                    component="span"
                    className={classes.subtitle}
                  >
                    VALOR DO PRÊMIO
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Typography
                    className={classes.values}
                    variant="subtitle2"
                    component="span"
                  >
                    <Currency
                      quantity={automaticSubscription?.valuePolicy || 0}
                      currency="R$ "
                      locale="pt_BR"
                      decimal=","
                      group="."
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box mt={4} mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel
                error={formik.touched.installments && !!formik.errors.installments}
              >
                {`Forma de pagamento: ${paymentMethod.paymentMethodType}`}
              </InputLabel>
              <Select
                id="installments"
                title="Parcelas"
                {...formik.getFieldProps('installments')}
                error={formik.touched.installments && !!formik.errors.installments}
                fullWidth
              >
                {installments.map((item) => (
                  <MenuItem key={item.quantity} value={item.quantity}>
                    {`${item.quantity} x de `}
                    {item.amount && (
                      <Currency
                        quantity={item.amount || 0}
                        currency="R$ "
                        locale="pt_BR"
                        decimal=","
                        group="."
                      />
                    )}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={!formik.touched.installments || !formik.errors.installments}
                error={formik.touched.installments && !!formik.errors.installments}
              >
                {formik.errors.installments}
              </FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <InputLabel
                error={formik.touched.shortage && !!formik.errors.shortage}
              >
                Possui carência no primeiro pagamento?
              </InputLabel>
              <Select
                id="shortage"
                title="dias"
                {...formik.getFieldProps('shortage')}
                error={formik.touched.shortage && !!formik.errors.shortage}
                fullWidth
              >
                {shortages.map((item) => (
                  <MenuItem key={item} value={item}>
                    {`${item} dias `}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                hidden={!formik.touched.shortage || !formik.errors.shortage}
                error={formik.touched.shortage && !!formik.errors.shortage}
              >
                {formik.errors.shortage}
              </FormHelperText>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mb={2}>
        <Grid container justify="space-between">
          <Grid item xs={12}>
            <TermModal
              ref={termRef}
              onAccept={() => formik.setFieldValue('trustedDataConfirmation', true)}
            />
            <Box ml={1}>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    id="trustedDataConfirmation"
                    name="trustedDataConfirmation"
                    className={classes.checkboxTerm}
                    checked={formik.values.trustedDataConfirmation}
                    onChange={(event) => {
                      formik.setFieldValue(
                        'trustedDataConfirmation',
                        event.target.checked,
                      )
                    }}
                  />
                )}
                label={(
                  <>
                    <Box component="span">
                      Ao concluir seu cadastro, você aceita nosso
                    </Box>
                    <Box component="span">
                      <Button
                        color="primary"
                        className={classes.buttonTerm}
                        onClick={() => termRef.current.onOpen()}
                        title="Termos e condições"
                      >
                        Termos e condições
                      </Button>
                    </Box>
                    <Box component="span">.</Box>
                  </>
                )}
              />
              <FormHelperText
                hidden={!formik.touched.trustedDataConfirmation
                  || !formik.errors.trustedDataConfirmation}
                error={formik.touched.trustedDataConfirmation
                  && !!formik.errors.trustedDataConfirmation}
              >
                {formik.errors.trustedDataConfirmation}
              </FormHelperText>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} lg={3}>
          <Box
            onClick={hadleDraft}
            title="Visualizar Minuta"
            className={classes.download}
            style={{ minWidth: 180 }}
          >
            <Box component="span">
              <img src={icoDownload} alt="Ícone - Download" width={28} />
            </Box>
            <Box component="span" mx={1}>
              <Typography variant="subtitle2">Visualizar Minuta</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box
            onClick={handleProposal}
            title="Visualizar Proposta"
            className={classes.download}
            style={{ minWidth: 185 }}
          >
            <Box component="span">
              <img src={icoDownload} alt="Ícone - Download" width={28} />
            </Box>
            <Box component="span" mx={1}>
              <Typography variant="subtitle2">Visualizar Proposta</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={1} alignItems="center" justify="flex-end">
            <Grid item lg={3}>
              <Button
                variant="text"
                color="primary"
                title="Nova Solicitação"
                onClick={onCancel}
                className={classes.cancel}
                fullWidth
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item lg={3}>
              <Button
                color="primary"
                title="Corrigir"
                variant="outlined"
                fullWidth
                onClick={onCorrect}
              >
                Corrigir
              </Button>
            </Grid>
            <Grid item lg={4}>
              <Button
                color="primary"
                title="Contratar"
                variant="contained"
                onClick={() => formik.submitForm()}
                fullWidth
              >
                Contratar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SuccessModal open={open} setOpen={setOpen} />
    </>
  )
}

export default QuotationSuccess
