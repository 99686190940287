import React, { useState, useRef, useEffect } from 'react'
import { isArray } from 'lodash'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import Broker from './Broker'
import Sponsor from './Sponsor'
import RegisterContext from './RegisterContext'
import { AlertProvider, useAlert } from '../../components'
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics'

const useStyles = makeStyles(() => ({
  btn: {
    width: '100%',
    fontWeight: 600,
    padding: '13px 30px',
  },
}))

const StepType = {
  BROKER: 0,
  SPONSOR: 1,
}

const DEFAULT_BROKER = {
  brokerDocument: '',
  brokerName: '',
  sponsorDocument: '',
  sponsorName: '',
  sponsorEmail: '',
}

const Register = () => {
  const stepRef = useRef()
  const { Provider } = RegisterContext
  const { event } = useGoogleAnalytics()

  const classes = useStyles()
  const history = useHistory()
  const [startTime, setStartTime] = useState()
  const [actionNext, setActionNext] = useState(false)
  const { addMsgDanger, cleanMsg } = useAlert('register')
  const [actionPrevious, setActionPrevious] = useState(false)
  const [broker, setBroker] = useState({ ...DEFAULT_BROKER })
  const [activeStep, setActiveStep] = useState(StepType.BROKER)

  useEffect(() => {
    const start = performance.now()
    setStartTime(start)
  }, [])

  const addMsgError = (error) => {
    let data = { ...error }

    if (isArray(error)) {
      data = error.map((item) => {
        const { code } = item
        let { message } = item

        switch (code) {
          case '02.27':
            message = `E-mail vinculado a outro CPF. Favor entrar contato conosco através dos nossos <a href="${window.ENV.POTTENCIAL_ATENDIMENTO}" title="Canais de Atendimento">Canais de Atendimento</a>.`
            break
          case '02.32':
            message = `Esse e-mail já possui usuário. Favor realizar o seu <a href="${window.location.origin}/signin" title="Login" >Login</a> para continuar.`
            break
          default:
        }

        return { code, message }
      })
    }

    addMsgDanger(data)
  }

  const handleNext = () => {
    stepRef.current.onSubmit().then((data) => {
      if (data) {
        addMsgError(data)
      } else {
        setActionNext(true)
        setActionPrevious(false)
      }
    })
  }

  const handlePrevious = () => {
    stepRef.current.onSubmit().then((data) => {
      if (data) {
        addMsgError(data)
      } else {
        setActionNext(false)
        setActionPrevious(true)
      }
    })
  }

  const handleRegister = () => {
    stepRef.current.onSubmit().then((data) => addMsgError(data))
  }

  const handleCancelar = () => history.replace('/signin')

  const onNext = () => {
    cleanMsg()
    setActiveStep(activeStep + 1)
  }

  const onPrevious = () => {
    cleanMsg()
    setActiveStep(activeStep - 1)
  }

  const onRegister = (data) => {
    const {
      brokerName,
      brokerDocument,
      existentUserAssociatedToBroker,
    } = data

    if (existentUserAssociatedToBroker) {
      history.replace('/register/concluded')
    } else {
      const { sponsorEmail } = broker
      const mail = btoa(sponsorEmail)
      history.replace(`/check-email/${mail}`)
    }

    event('Cadastro', 'Pré Cadastro', `${brokerName} - ${brokerDocument}`)

    const time = performance.now() - startTime
    event('Cadastro', 'Pré Cadastro - Tempo', time)
  }

  return (
    <Provider value={{
      broker,
      actionNext,
      actionPrevious,
      onNext,
      onPrevious,
      onRegister,
      setBroker,
      addMsgError,
    }}
    >
      {/* STEPS */}
      <Box mb={2} mt={8} align="center">
        <Typography variant="h4">Seja bem-vindo(a)!</Typography>
      </Box>

      <Box mb={8} mt={1}>
        <Typography align="center">Novo Cadastro</Typography>
      </Box>

      <Box mb={4} mt={1}>
        <AlertProvider id="register" />
      </Box>

      {/** CONTEUDO */}
      {activeStep === StepType.BROKER && <Broker ref={stepRef} />}
      {activeStep === StepType.SPONSOR && <Sponsor ref={stepRef} />}

      {/* CONTROLES */}
      <Box mt={2}>
        {activeStep === StepType.BROKER && (
          <Button
            color="primary"
            title="Próximo"
            variant="contained"
            className={classes.btn}
            onClick={handleNext}
          >
            Próximo
          </Button>
        )}

        {activeStep === StepType.SPONSOR && (
          <Button
            color="primary"
            title="Cadastrar"
            variant="contained"
            className={classes.btn}
            onClick={handleRegister}
          >
            Cadastrar
          </Button>
        )}
      </Box>
      <Box mt={2}>
        {activeStep === StepType.BROKER && (
          <Button
            title="Cancelar"
            className={classes.btn}
            onClick={handleCancelar}
          >
            CANCELAR
          </Button>
        )}

        {activeStep === StepType.SPONSOR && (
          <Button
            title="Voltar"
            className={classes.btn}
            onClick={handlePrevious}
          >
            VOLTAR
          </Button>
        )}
      </Box>
    </Provider>
  )
}

export default Register
