import React from 'react'
import {
  Grid,
  CircularProgress,
} from '@material-ui/core'

const PreviewFallback = () => (
  <Grid
    container
    justify="center"
    alignItems="center"
    style={{ height: '100%' }}
  >
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
)

export default PreviewFallback
