import { useCallback } from 'react'
import axios from 'axios'

const usePanelClient = () => {
  const getPolicyCard = useCallback(() => axios.get('/api/policy/PolicyCard'), [])
  const getQuotationCard = useCallback(() => axios.get('/api/cards/quotation'), [])
  const getCommissionCard = useCallback(() => axios.get('/api/cards/commission'), [])

  return useCallback(() => ({
    getPolicyCard,
    getQuotationCard,
    getCommissionCard,
  }), [getPolicyCard, getQuotationCard, getCommissionCard])
}

export default usePanelClient
