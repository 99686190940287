import React from 'react'
import {
  TextField,
} from '@material-ui/core'
import { parseInt } from 'lodash'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'

function InputIPv4Sub(props) {
  function checkIpValue(value) {
    const subips = value.split('.')
    const subBars = value.split('/')

    if (subips.length > 4) {
      return false
    }

    if (subips.length === 4 && subBars.length === 2) {
      return true
    }

    const invalidSubips = subips.filter((ip) => {
      if (ip.length > 3) {
        return true
      }
      ip = parseInt(ip)
      return ip < 0 || ip > 255
    })

    if (invalidSubips.length !== 0) {
      return false
    }

    let emptyIpCount = 0
    subips.forEach((ip) => {
      if (ip === '') {
        emptyIpCount++
      }
    })
    if (emptyIpCount > 1) {
      return false
    }
    return true
  }

  return (
    <InputMask
      formatChars={{
        9: '[0-9./]',
      }}
      mask="999999999999999999"
      maskChar={null}
      alwaysShowMask={false}
      beforeMaskedValueChange={(newState, oldState) => {
        let { value } = newState
        const oldValue = oldState.value
        let { selection } = newState
        let cursorPosition = selection ? selection.start : null
        const result = checkIpValue(value)
        const countBars = value.split('/').length - 1
        const subips = value.split('.')
        const lastSubIp = subips[3]
        const lastSubIpInt = parseInt(lastSubIp)

        if (value.includes('/') && (subips.length !== 4 || countBars !== 1 || value.split('/')[1].length > 2 || lastSubIp === '/')) {
          value = oldValue
          return {
            value,
            selection,
          }
        }

        if (
          !value.includes('/')
          && ((lastSubIp && lastSubIp.length > 3) || (lastSubIpInt < 0 || lastSubIpInt > 255))
        ) {
          value = value.trim()
          const newValueWithBar = `${value.substring(0, value.length - 1)
          }/${value.substring(value.length - 1)}`

          if (
            subips.length !== 4
            || countBars !== 1
            || newValueWithBar.split('/')[1].length > 2
            || lastSubIp === '/'
          ) {
            cursorPosition++
            selection = { start: cursorPosition, end: cursorPosition }
            value = newValueWithBar
          } else {
            value = oldValue
          }

          return {
            value,
            selection,
          }
        }

        if (!result) {
          value = value.trim()
          const newValue = `${value.substring(0, value.length - 1)
          }.${value.substring(value.length - 1)}`

          if (checkIpValue(newValue)) {
            cursorPosition++
            selection = { start: cursorPosition, end: cursorPosition }
            value = newValue
          } else {
            value = oldValue
          }
        }

        return {
          value,
          selection,
        }
      }}
      {...props}
    >
      {(inputProps) => <TextField {...inputProps} />}
    </InputMask>
  )
}

InputIPv4Sub.propTypes = {
  value: PropTypes.string,
}

InputIPv4Sub.defaultProps = {
  value: '',
}

export default InputIPv4Sub
