import React, { useState } from 'react'
import {
  makeStyles, Box, Card, CardContent, ClickAwayListener,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import RecentSearch from './RecentSearch'
import FastSearch from './FastSearch'

const useStyles = makeStyles((theme) => ({
  containerBtn: {
    background: theme.palette.primary.main,
    borderRadius: 50,
    width: 44,
    height: 44,
    color: theme.palette.primary.contrastText,
    transition: `all .3s ${theme.transitions.easing.easeInOut}`,
    marginLeft: 'auto',
    boxShadow: '0px 3px 6px #00000029',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    '&.active': {
      width: '100%',
      background: '#EFEFEF',
      color: theme.palette.text.primary,
    },
  },

  containerIco: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 44,
    height: 44,
  },

  searchField: {
    width: '100%',
    height: '100%',
    border: '0 none',
    background: 'transparent',
  },

  backIco: {
    height: '100%',
    cursor: 'pointer',
    padding: 10,
    width: 40,
  },

  popover: {
    zIndex: 90,
    opacity: 0,
    position: 'absolute',
    right: 0,
    top: `calc(100% + ${theme.spacing(1)}px)`,
    left: 0,
    display: 'none',
    '&.active': {
      display: 'block',
      animation: `$showElm .3s ${theme.transitions.easing.easeInOut} .32s forwards`,
    },
  },
  '@keyframes showElm': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

function SearchButton() {
  const [active, setActive] = useState(false)
  const [term, setTerm] = useState('')

  const classes = useStyles()

  const submitForm = () => {}

  const handleActive = () => {
    if (active && term !== '') {
      submitForm(term)
    } else {
      setActive(true)
    }
  }

  const removeActive = () => {
    setActive(false)
  }

  return (
    <ClickAwayListener
      onClickAway={removeActive}
    >
      <form className={`${classes.containerBtn} ${active ? 'active' : ''}`}>
        {active
          ? (
            <>
              <ArrowBackIcon onClick={removeActive} className={classes.backIco} />
              <input
                id="mainSearch"
                placeholder="Buscar"
                className={classes.searchField}
                type="text"
                value={term}
                onChange={(ev) => setTerm(ev.target.value)}
                title="Buscar"
              />
            </>
          )
          : ''}
        <Box className={classes.containerIco} onClick={handleActive}>
          <SearchIcon />
        </Box>
        <Box className={`${classes.popover} ${active ? 'active' : ''}`}>
          <Card className={classes.card}>
            <CardContent className={classes.cardBody}>
              <RecentSearch submitForm={submitForm} />
              <FastSearch submitForm={submitForm} />
            </CardContent>
          </Card>
        </Box>
      </form>
    </ClickAwayListener>
  )
}

export default SearchButton
