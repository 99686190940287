import React, {
  useRef,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import UserFilter from './UserFilter'
import { FilterTips } from '../../components'

const useStyles = makeStyles((theme) => ({

  selectedFilter: {
    ...theme.overrides.MuiButton.outlined,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
    padding: '8px 25px',
    display: 'inline-block',
    borderRadius: 6,
    '& svg': {
      position: 'absolute',
      top: 4,
      right: 8,
      fontSize: '.85rem',
      background: '#E6E6E6',
      borderRadius: '50%',
      padding: 2,
      cursor: 'pointer',
    },
  },
  orderItem: {
    position: 'relative',
    padding: theme.spacing(1, 6, 1, 3),
    fontSize: '.9rem',
    '& svg': {
      display: 'none',
      position: 'absolute',
      left: theme.spacing(1),
      fontSize: '.9rem',
    },
  },
  orderActive: {
    color: theme.palette.primary.main,
    '& svg': {
      display: 'block',
    },
  },
}))

const UserHeader = ({
  updateFilter, updateOrder, defaultFilter, results,
}) => {
  const tipsRef = useRef()
  const classes = useStyles()
  const [order, setOrder] = useState('name')
  const [filters, setFilters] = useState(defaultFilter)
  const [anchorElOrder, setAnchorElOrder] = useState(null)

  const tipsFormats = useMemo(() => ({
    active: (data) => (data ? 'Usuário ativo' : 'Usuário inativo'),
    administrator: (data) => (data ? 'Administrador' : 'Usuário Comum'),
  }), [])

  const addFilter = (data) => {
    setFilters(data)
    updateFilter(data)
  }

  const handleRemoveFilter = (param) => {
    const data = { ...filters, [param]: '' }

    setFilters(data)
    updateFilter(data)
  }

  const handleClickOrder = (event) => {
    setAnchorElOrder(event.currentTarget)
  }

  const handleCloseOrder = () => {
    setAnchorElOrder(null)
  }

  const handleSelectOrder = (value) => {
    setOrder(value)
    updateOrder(value)
    handleCloseOrder()
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item xs>
          <Button
            color="primary"
            variant="contained"
            to="/usuario/criar"
            component={Link}
            title="Novo usuário"
          >
            Novo usuário
          </Button>
        </Grid>

        <Grid item>
          <UserFilter addFilter={addFilter} filters={filters} />
        </Grid>
      </Grid>

      <Box mt={3}>
        <Grid container spacing={2} alignItems="center" justify="space-between">
          <Grid item xs>
            <FilterTips
              ref={tipsRef}
              filter={filters}
              formats={tipsFormats}
              onRemove={handleRemoveFilter}
            />
          </Grid>

          {results !== 0 && tipsRef.current?.hasFilter() && (
            <Grid item className={classes.gridHeader}>
              <Button title="Ordenar" onClick={handleClickOrder}>
                <Typography color="primary">Ordenar por</Typography>
                <KeyboardArrowDownIcon color="primary" />
              </Button>
              <Menu
                keepMounted
                id="order-menu"
                anchorEl={anchorElOrder}
                open={Boolean(anchorElOrder)}
                onClose={handleCloseOrder}
              >
                <MenuItem title="Nome" onClick={() => handleSelectOrder('name')} className={`${classes.orderItem} ${order === 'name' ? classes.orderActive : ''}`}>
                  <CheckIcon color="primary" />
                  Nome
                </MenuItem>
                <MenuItem title="Cargo" onClick={() => handleSelectOrder('office')} className={`${classes.orderItem} ${order === 'office' ? classes.orderActive : ''}`}>
                  <CheckIcon color="primary" />
                  Cargo
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}

UserHeader.propTypes = {
  results: PropTypes.number.isRequired,
  updateOrder: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  defaultFilter: PropTypes.object.isRequired,
}

UserHeader.defaultProps = {}

export default UserHeader
