import { useCallback } from 'react'
import produce from 'immer'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const INITIAL_STATE = {
  contexts: [],
}

export const Types = {
  CONTEXTS: 'context/CONTEXTS',
}

const reducer = (state = INITIAL_STATE, action) => {
  if (action.type === Types.CONTEXTS) {
    const { contexts } = action.payload

    return produce(state, (draft) => {
      draft.contexts = contexts
    })
  }

  return state
}

export const useContextAction = () => {
  const dispatch = useDispatch()

  const setContexts = useCallback((contexts) => dispatch({
    type: Types.CONTEXTS,
    payload: { contexts },
  }), [dispatch])

  return {
    setContexts,
  }
}

export default persistReducer({
  key: 'context',
  storage,
}, reducer)
