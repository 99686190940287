import React from 'react'

import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  FormHelperText,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import useUtils from '../../../hooks/useUtils'
import { useQuotationContext } from './QuotationContext'

const useStyles = makeStyles(() => ({
  lineTitle: {
    height: '2px',
    background: '#f36523',
    border: 'none',
    width: '60px',
    float: 'left',
  },
  borderDetails: {
    border: '1px solid lightgray',
    borderRadius: '6px',
  },
}))

const QuotationObligationInf = ({
  parentFormik,
  onChangePerfomerObligationInf: changePerfomerObligationInf,
}) => {
  const classes = useStyles()
  const { isBlank } = useUtils()
  const { isPerfomer } = useQuotationContext()

  return (
    <Box component={Paper} p={4} my={2}>
      <Box mb={3}>
        <Typography variant="subtitle1" component="h2">
          <Box fontWeight="bold">Informação da obrigação</Box>
          <hr className={classes.lineTitle} />
        </Typography>
      </Box>

      <Grid container spacing={2} justify="space-between">
        {isPerfomer && (
          <>
            <Grid item xs={12} lg={4}>
              <TextField
                id="contractNumber"
                color="secondary"
                title="Informe o número do Contrato"
                label="Informe o número do Contrato:"
                {...parentFormik.getFieldProps('contractNumber')}
                error={parentFormik.touched.contractNumber && (
                  !!parentFormik.errors.contractNumber
                  || !!parentFormik.errors.perfomerObligationInf)}
                helperText={parentFormik.touched.contractNumber
                  && parentFormik.errors.contractNumber}
                fullWidth
                onChange={(event) => {
                  const { value: contractNumber } = event.target
                  parentFormik.setFieldValue('contractNumber', contractNumber)

                  const { processNumber, noticeNumber } = parentFormik.values
                  changePerfomerObligationInf(
                    contractNumber, processNumber, noticeNumber,
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                id="processNumber"
                color="secondary"
                title="Informe o número do Processo"
                label="Informe o número do Processo:"
                {...parentFormik.getFieldProps('processNumber')}
                error={parentFormik.touched.processNumber && (
                  !!parentFormik.errors.processNumber
                  || !!parentFormik.errors.perfomerObligationInf)}
                helperText={parentFormik.touched.processNumber && parentFormik.errors.processNumber}
                fullWidth
                onChange={(event) => {
                  const { value: processNumber } = event.target
                  parentFormik.setFieldValue('processNumber', processNumber)

                  const { contractNumber, noticeNumber } = parentFormik.values
                  changePerfomerObligationInf(
                    contractNumber, processNumber, noticeNumber,
                  )
                }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} lg={4}>
          <TextField
            id="noticeNumber"
            color="secondary"
            title="Informe o número do edital"
            label="Informe o número do edital:"
            {...parentFormik.getFieldProps('noticeNumber')}
            error={parentFormik.touched.noticeNumber && (
              !!parentFormik.errors.noticeNumber || !!parentFormik.errors.perfomerObligationInf)}
            helperText={parentFormik.touched.noticeNumber && parentFormik.errors.noticeNumber}
            fullWidth
            onChange={(event) => {
              const { value: noticeNumber } = event.target
              parentFormik.setFieldValue('noticeNumber', noticeNumber)

              const { contractNumber, processNumber } = parentFormik.values
              changePerfomerObligationInf(
                contractNumber, processNumber, noticeNumber,
              )
            }}
          />
        </Grid>
      </Grid>

      <Box>
        <FormHelperText
          hidden={!parentFormik.touched.noticeNumber
            && !parentFormik.touched.processNumber
            && !parentFormik.touched.contractNumber}
          error={(parentFormik.touched.noticeNumber
            || parentFormik.touched.processNumber
            || parentFormik.touched.contractNumber)
            && !!parentFormik.errors.perfomerObligationInf}
        >
          {parentFormik.errors.perfomerObligationInf}
        </FormHelperText>
      </Box>

      <Box mt={2} p={2} className={classes.borderDetails}>
        <Box mb={2} fontWeight="600">
          Veja como ficará o objeto na sua apólice:
        </Box>

        <Box>
          <Box component="span">
            Este seguro garante a indenização, até o valor da garantia
            fixado na apólice, pelos prejuízos causados pelos Tomador
            ao Segurado, em razão de inadimplemento das obrigações
            previstas no
          </Box>

          {(!isBlank(parentFormik.values.contractNumber)
            || !isBlank(parentFormik.values.processNumber)
            || !isBlank(parentFormik.values.noticeNumber)) && (
              <Box component="span" ml={1}>
                <Typography variant="subtitle2" component="span">
                  {[
                    !isBlank(parentFormik.values.contractNumber) && `CONTRATO nº ${parentFormik.values.contractNumber}`,
                    !isBlank(parentFormik.values.processNumber) && `PROCESSO nº ${parentFormik.values.processNumber}`,
                    !isBlank(parentFormik.values.noticeNumber) && `EDITAL nº ${parentFormik.values.noticeNumber}`,
                  ].filter(Boolean).join(', ')}
                </Typography>
              </Box>
          )}
          .
        </Box>
      </Box>
    </Box>
  )
}

QuotationObligationInf.propTypes = {
  parentFormik: PropTypes.object.isRequired,
  onChangePerfomerObligationInf: PropTypes.func.isRequired,
}

export default QuotationObligationInf
