import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { DEFAULT_THEME } from '../constants'
import { useSecurityAction } from '../store/ducks/security'

const useSettings = () => {
  const { setUser } = useSecurityAction()
  const { user } = useSelector(({ security }) => ({ user: security.user }))

  const setColor = useCallback((color) => {
    setUser({ ...user, color })
  }, [user, setUser])

  const getColor = useCallback(() => {
    const current = user?.color || DEFAULT_THEME

    return {
      ...current,
      primary: current.primary || DEFAULT_THEME.primary,
    }
  }, [user])

  return {
    setColor,
    getColor,
  }
}

export default useSettings
