import React from 'react'
import {
  Box,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SettingsButton from './SettingsButton'
import { Breadcrumb } from '../../components'
import icoCustomize from '../../assets/img/ico-customize.svg'
import icoSecurity from '../../assets/img/ico-security.svg'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  cards: {
    '& span': {
      fontSize: '13px',
    },
    '& .MuiPaper-root': {
      minHeight: '130px',
      minWidth: '120px',
      display: 'inline-grid',
    },
  },
  buttonBack: {
    '& .MuiButton-root': {
      minWidth: '200px',
    },
  },
}))

const Path = [
  {
    text: 'Espaço Corretor',
    href: '/',
  },
  {
    text: 'Configurações',
  },
]

const Settings = () => {
  const classes = useStyles()

  return (
    <>
      <Breadcrumb paths={Path} />

      <Box mb={10}>
        <Typography component="h1" variant="h5">Configurações</Typography>
      </Box>

      <Box align="center" mb={6}>
        <Typography
          variant="body2"
          component="span"
          className={classes.title}
        >
          As configurações permitem você personalizar a experiência,
          incluindo as definições de cores e segurança.
        </Typography>
      </Box>

      <Box
        p={1}
        m={1}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        className={classes.cards}
      >
        <SettingsButton
          to="/customizar"
          ico={icoCustomize}
          title="Personalização"
          description="Personalize a cor do portal"
        />

        <SettingsButton
          to="/ips"
          ico={icoSecurity}
          title="Segurança"
          description="Configure os endereçoes IP's de acesso."
        />
      </Box>
    </>
  )
}

export default Settings
