import { useCallback } from 'react'
import axios from 'axios'

import useUtils from '../../hooks/useUtils'

const useQuotationClient = () => {
  const { getOnlyNumber } = useUtils()

  const getAdditionalCoverages = useCallback((productType, isPublicCoverage) => axios.get(`${window.ENV.BFF_GUARANTEE_URL}/api/coverage?ProductType=${productType}&IsPublicCoverage=${isPublicCoverage}`), [])

  const getPolicyHolders = useCallback((term, brokerDocument) => {
    const params = new URLSearchParams({ term, brokerDocument })
    return axios.get(`${window.ENV.BFF_GUARANTEE_URL}/api/policy-holder?${params}`)
  }, [])

  const getAssociatedBrokers = useCallback((term, brokerDocument) => {
    const params = new URLSearchParams({ term, brokerDocument })
    return axios.get(`${window.ENV.BFF_GUARANTEE_URL}/api/associated-broker?${params}`)
  }, [])

  const getBrokers = useCallback((policyHolderId, brokerDocument) => axios.get(`${window.ENV.BFF_GUARANTEE_URL}/api/associated-broker?policyHolderId=${policyHolderId}&brokerDocument=${brokerDocument}`), [])

  const getInsured = useCallback((cnpj) => axios.get(`${window.ENV.BFF_GUARANTEE_URL}/api/insured/${cnpj}`), [])

  const quote = useCallback((quotation) => axios.post(`${window.ENV.BFF_GUARANTEE_URL}/api/quote`, quotation), [])

  const analyze = useCallback((documents) => {
    const data = new FormData()
    data.append('Note', documents?.note)
    data.append('BrokerDocument', documents?.brokerDocument)
    data.append('SolicitationNumber', documents?.solicitationNumber)

    const policyHolderDocument = getOnlyNumber(documents?.policyHolderDocument)
    data.append('PolicyHolderDocument', policyHolderDocument)

    const files = documents?.files || []

    files.forEach((file) => {
      data.append('Files', file)
    })

    return axios.post(`${window.ENV.BFF_GUARANTEE_URL}/api/proposal/analysis`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }, [getOnlyNumber])

  const policy = useCallback((quotation) => axios.post(`${window.ENV.BFF_GUARANTEE_URL}/api/proposal`, quotation), [])

  const downloadProposal = useCallback((proposalNumber) => axios.get(`${window.ENV.BFF_GUARANTEE_URL}/api/proposal/download/${proposalNumber}/pdf`), [])

  const downloadDraft = useCallback((proposalNumber) => axios.get(`${window.ENV.BFF_GUARANTEE_URL}/api/quote/${proposalNumber}/draft`), [])

  return useCallback(() => ({
    getAdditionalCoverages,
    getAssociatedBrokers,
    getPolicyHolders,
    getBrokers,
    getInsured,
    downloadProposal,
    downloadDraft,
    quote,
    policy,
    analyze,
  }), [
    getAdditionalCoverages,
    getAssociatedBrokers,
    getPolicyHolders,
    getBrokers,
    getInsured,
    downloadProposal,
    downloadDraft,
    analyze,
    quote,
    policy,
  ])
}

export default useQuotationClient
