import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'

import IcoSinistro from '../../assets/img/ico-sinistro.svg'

const useStyles = makeStyles(() => ({
  root: {
    padding: 12, // theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent !important',
  },
}))

const EmptyResult = ({ size, message }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
      >
        <Grid item key={1} lg={12} md={12} xs={12}>
          <Paper className={classes.root}>
            <img
              alt="imagem vazia"
              src={IcoSinistro}
              style={{
                height: size,
              }}
            />
            <br />
            <Typography
              variant="h5"
              color="textSecondary"
              component="p"
              style={{
                textAlign: 'center',
              }}
            >
              {message}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

EmptyResult.propTypes = {
  size: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
}

export default EmptyResult
