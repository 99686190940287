import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { Breadcrumbs, makeStyles, Box } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    marginBottom: theme.spacing(3),
    '& .MuiBreadcrumbs-separator': {
      marginLeft: 4,
      marginRight: 4,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}))

const Breadcrumb = ({ paths }) => {
  const classes = useStyles()

  return (
    <Breadcrumbs className={classes.root} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {
        paths.map((path, index) => (
          <span key={index}>
            {index !== paths.length - 1 && (
              <Link className={classes.link} to={path.href}>{path.text}</Link>
            )}
            {index === paths.length - 1 && (
            <Typography variant="body2" color="textPrimary">
              <Box component="span" fontWeight={600}>{path.text}</Box>
            </Typography>
            )}
          </span>
        ))
      }

    </Breadcrumbs>
  )
}

Breadcrumb.propTypes = {
  paths: PropTypes.array.isRequired,
}

export default Breadcrumb
