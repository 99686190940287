import React, { useContext } from 'react'

const QuotationContext = React.createContext()

export const useQuotationContext = () => {
  const {
    quotation,
    setQuotation,
    actionNext,
    actionPrevious,
    onNext,
    onCancel,
    onCorrect,
    onPrevious,
    isBid,
    isPerfomer,
    brokerDocument,
    description,
  } = useContext(QuotationContext)

  return {
    quotation,
    setQuotation,
    actionNext,
    actionPrevious,
    onNext,
    onCancel,
    onCorrect,
    onPrevious,
    isBid,
    isPerfomer,
    brokerDocument,
    description,
  }
}

export default QuotationContext
