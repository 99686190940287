import React, { useState, useMemo, useCallback } from 'react'
import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import {
  Resolve,
  useAlert,
  Breadcrumb,
} from '../../components'
import CardDemand from './CardDemand'
import IFrameDemand from './IFrameDemand'
import useSecurity from '../../security/useSecurity'
import useCustomerClient from '../../clients/CustomerClient'

import icoInfo from '../../assets/img/ico-info.svg'
import icoBid from '../../assets/img/ico-product-bid.svg'
import icoAlugel from '../../assets/img/ico-product-aluguel.svg'
import icoGarantia from '../../assets/img/ico-product-garantia.svg'
import icoRecursal from '../../assets/img/ico-product-recursal.svg'
import icoEngenharia from '../../assets/img/ico-product-engenharia.svg'
import icoGarantiaJudicial from '../../assets/img/ico-product-judicial.svg'
import icoGarantiaPerformance from '../../assets/img/ico-product-performance.svg'

const { DEMAND_URL } = window.ENV

const PathInfo = [
  {
    text: 'Painel',
    href: '/painel',
  },
  {
    text: 'Cotações',
    href: '/cotacoes',
  },
  {
    text: 'Nova Cotação',
    href: '/cotacoes',
  },
]

const DemandsTypes = [
  {
    text: 'Garantia do Licitante (BID)',
    ico: icoBid,
    sizeIco: '63px',
    to: '/cotacoes/novo/bid',
    height: '700',
  },
  {
    text: 'Garantia Executante (Performance)',
    ico: icoGarantiaPerformance,
    sizeIco: '63px',
    to: '/cotacoes/novo/performance',
    height: '700',
  },
  {
    text: 'Garantia Judicial',
    ico: icoGarantiaJudicial,
    sizeIco: '60px',
    url: '/Judicial/Proposta',
    height: '1300',
  },
  {
    text: 'Garantia Recursal',
    ico: icoRecursal,
    sizeIco: '60px',
    url: '/DepositoRecursal/Proposta',
    height: '1300',
  },
  {
    text: 'Garantia (Outros)',
    ico: icoGarantia,
    sizeIco: '57px',
    url: '/SeguroGarantia/Proposta',
    height: '1400',
  },
  {
    text: 'Riscos de Engenharia',
    ico: icoEngenharia,
    sizeIco: '70px',
    url: '/RiscoEngenharia/Proposta',
    height: '890',
  },
  {
    text: 'Pottencial Aluguel',
    url: '/FiancaLocaticia/Proposta/Index',
    ico: icoAlugel,
    sizeIco: '57px',
    height: '1800',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  cardsProduct: {
    maxWidth: '60%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    cursor: 'pointer',
    '& span': {
      fontSize: '13px',
    },
    '& .MuiPaper-root': {
      minHeight: '150px',
      borderRadius: '7px',
    },
    '& >div>div:hover': {
      boxShadow: '3px 3px 10px #f7655694',
    },
    '& >div': {
      width: '120px',
      '@media (max-width:1365px)': {
        width: '116px',
        margin: '5px',
      },
    },
  },
  iFrame: {
    border: 'none',
  },
  button: {
    minWidth: '200px',
  },
  buttonInfo: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const ButtonBack = (props) => {
  const { action, classes } = props
  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={() => action(false)}
              title="Voltar"
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const Demands = () => {
  const classes = useStyles()
  const history = useHistory()
  const { addMsgDanger } = useAlert()
  const customerClient = useCustomerClient()
  const [customer, setCustomer] = useState(false)
  const [titleIFrame, setTitleIFrame] = useState('')
  const [selectDemand, setSelectDemand] = useState(false)
  const [iframeUrl, setIFrameUrl] = useState(DEMAND_URL)
  const [heightIframe, setHeightIframe] = useState('600')
  const { hasFunctionality } = useSecurity()

  const handleSelectDemand = (open, title, url, height) => {
    if (selectDemand === open) {
      history.replace('/painel')
    } else {
      setHeightIframe(height)
      setIFrameUrl(url)
      setTitleIFrame(title)
      setSelectDemand(open)
    }
  }

  const handleResolve = useMemo(() => ({
    hasCustomers: () => new Promise((resolve, reject) => {
      customerClient().getCustomers('').then((response) => {
        const { data = [] } = response
        resolve(data.length !== 0)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
  }), [customerClient, addMsgDanger])

  const handleLoaded = useCallback((data, resolve) => {
    const { hasCustomers } = data
    setCustomer(hasCustomers)
    resolve()
  }, [])

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <main>
        <Breadcrumb paths={PathInfo} />

        {!selectDemand && (
          <>
            <Box mb={10}>
              <Typography component="h1" variant="h4">Nova Cotação</Typography>
            </Box>

            {customer && (
              <Box p={4} className={classes.root}>
                <Box pb={6} textAlign="center">
                  <Typography component="span" variant="subtitle1">
                    Selecione o produto desejado para iniciar a cotação:
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Box
                    display="inline-flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    p={1}
                    m={1}
                    css={{ maxWidth: '100%' }}
                    className={classes.cardsProduct}
                  >
                    {DemandsTypes.map((demand, index) => (
                      <Box m={2} key={index}>
                        <CardDemand
                          ico={demand.ico}
                          text={demand.text}
                          sizeIco={demand.sizeIco}
                          action={() => {
                            if (demand.url) {
                              handleSelectDemand(true, demand.text, `${DEMAND_URL}${demand.url}`, demand.height)
                            }

                            if (demand.to) {
                              history.push(demand.to)
                            }
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}

            {!customer && (
              <Paper>
                <Box px={2} py={3}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <img src={icoInfo} alt="Informação" width={28} />
                    </Grid>
                    <Grid item xs={10}>
                      {hasFunctionality('AREA_CLIENTE') && (
                        <>
                          <Box component="span">Para fazer uma nova cotação, você deve possuir clientes cadastrados.</Box>
                          <Button
                            color="primary"
                            component={Link}
                            title="Clique aqui"
                            to="/cadastro-clientes"
                            className={classes.buttonInfo}
                          >
                            Clique aqui
                          </Button>
                          <Box component="span">para cadastrar seu cliente e realizar uma contação!</Box>
                        </>
                      )}

                      {!hasFunctionality('AREA_CLIENTE') && (
                        <>
                          <Box>
                            Para fazer uma nova cotação, você deve possuir clientes cadastrados e
                            verificamos que você não possui permissão para realizar este cadastro.
                            Solicite ao administrador da Corretora o acesso a esta funcionalidade.
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            )}
          </>
        )}

        {selectDemand && (
          <>
            <Box mb={10}>
              <Typography component="h1" variant="h4">{titleIFrame}</Typography>
            </Box>
            <IFrameDemand url={iframeUrl} title={titleIFrame} height={heightIframe} />
            <ButtonBack classes={classes} action={handleSelectDemand} />
          </>
        )}
      </main>
    </Resolve>
  )
}

ButtonBack.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
}

export default Demands
