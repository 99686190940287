import React from 'react'
import {
  Typography,
  Button,
  Box,
  makeStyles,
  Grid,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  btn: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '100%',
    height: '100%',
  },
}))
const FastSearch = ({ submitForm }) => {
  const items = ['Nova Demanda', 'Apólices', 'Financeiro', 'Produtos Pottencial', 'Sinistro', 'Renovação de Apólices']
  const classes = useStyles()
  return (
    items.length > 0
      ? (
        <Box>
          <Box mb={2}>
            <Typography variant="h6">Buscas Rápidas:</Typography>
          </Box>
          <Grid container spacing={2}>
            {
                        items.map(
                          (item, index) => (
                            <Grid key={index} item xl={3} lg={4} sm={6} xs={12}>
                              <Button
                                color="primary"
                                variant="contained"
                                className={classes.btn}
                                onClick={() => submitForm(item)}
                                title={item}
                              >
                                {item}
                              </Button>
                            </Grid>
                          ),
                        )
                    }
          </Grid>
        </Box>
      ) : ''

  )
}

FastSearch.propTypes = {
  submitForm: PropTypes.func,
}

FastSearch.defaultProps = {
  submitForm: () => { },
}

export default FastSearch
