import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles, useTheme } from '@material-ui/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    '& canvas': {
      maxWidth: '100%',
    },
  },
}))

function BarChart({
  className, data: dataProp, labels, ...rest
}) {
  const classes = useStyles()
  const theme = useTheme()

  const data = (canvas) => {
    const ctx = canvas.getContext('2d')
    const gradient = ctx.createLinearGradient(0, 0, 0, 400)

    gradient.addColorStop(0, fade(theme.palette.primary.main, 0.8))
    gradient.addColorStop(0.9, 'rgba(255,255,255,0)')
    gradient.addColorStop(1, 'rgba(255,255,255,0)')

    return {
      labels,
      datasets: [
        {
          label: dataProp[0]?.label,
          backgroundColor: theme.palette.primary.main,
          data: dataProp[0]?.data,
        },
        {
          label: dataProp[1]?.label,
          backgroundColor: gradient,
          data: dataProp[1]?.data,
        },
      ],
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.common.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Bar
        data={data}
        options={options}
      />
    </div>
  )
}

BarChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
}

BarChart.defaultProps = {
  className: '',
}

export default BarChart
