import React from 'react'
import {
  Box,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import icoAlert from '../../assets/img/ico-alert-warning.svg'

const useStyles = makeStyles(() => ({
  description: {
    fontSize: 12,
    color: '#343434',
    textAlign: 'center',
    fontWeight: 'normal',
  },
  alert: {
    textAlign: 'center',
  },
}))

const PanelErrorFallback = ({ className, title }) => {
  const classes = useStyles()

  return (
    <>
      <Box mb={5}>
        <Typography variant="subtitle2">{title}</Typography>
      </Box>

      <Grid
        container
        justify="center"
        className={className}
      >
        <Grid item>
          <Box className={classes.alert}>
            <img src={icoAlert} alt="alert" />
          </Box>
          <Box my={2} color="#595959">
            <Typography
              variant="body2"
              className={classes.description}
            >
              Desculpe, parece que algo deu errado!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

PanelErrorFallback.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
}

PanelErrorFallback.defaultProps = {
  title: '',
  className: '',
}

export default PanelErrorFallback
