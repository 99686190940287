import React, { useMemo } from 'react'
import useSecurity from '../../security/useSecurity'

const IFrameAuth = () => {
  const { getDemand } = useSecurity()
  const demand = useMemo(() => getDemand(), [getDemand])
  return (
    <>
      { demand.success && (
        <>
          <iframe
            hidden
            src={`${window.ENV.DEMAND_URL}/Login/AutenticaPorToken?token=${demand.token}`}
            id="Demand"
            title="Auth"
            frameBorder="none"
          />
        </>
      ) }
    </>
  )
}

export default IFrameAuth
