import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import useIpsClient from '../../../clients/IpsClient'

import { MessageBox, useAlert } from '../../../components'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
  },
  borderDetails: {
    border: '1px solid lightgray',
    borderRadius: '6px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  wrap: {
    wordWrap: 'break-word',
  },
  deleteIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
      transition: 'color 250ms',
    },
    '& .MuiSvgIcon-root:hover': {
      color: '#F26522',
    },
  },
  lineGrid: {
    padding: '15px 10px 15px 30px',
    borderBottom: '1px solid #80808042',
    '&:hover': {
      backgroundColor: '#8080800d',
    },
  },
}))

const IPList = ({ ips, onDeleteIp }) => {
  const classes = useStyles()
  const { addMsgDanger, addMsgSuccess } = useAlert()
  const ipsClient = useIpsClient()

  const [openModalDeleteUser, setOpenModalDeleteUser] = useState(false)
  const [ipId, setIpId] = useState('')
  const [type, setType] = useState('')

  const handleModalDeleteGroup = (ip, typeCurrent) => {
    setIpId(ip)
    setType(typeCurrent)
    setOpenModalDeleteUser(!openModalDeleteUser)
  }

  const confirmDeleteGroup = () => {
    const values = {
      data: {
        removeIps: [
          {
            type: (type === 'IPV4' ? 0 : 1),
            ip: ipId,
          },
        ],
      },
    }

    ipsClient().deleteIp(values).then(() => {
      handleModalDeleteGroup()
      addMsgSuccess('IP excluído com sucesso!')
      onDeleteIp()
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  return (
    <>
      <MessageBox
        opened={openModalDeleteUser}
        handleClose={handleModalDeleteGroup}
        title="Excluir endereço IP"
        text="Deseja realmente excluir o endereço IP?"
        handleSecondary={handleModalDeleteGroup}
        handlePrimary={confirmDeleteGroup}
      />

      {ips.length === 0 && (
        <Box px={2} py={3} className={classes.borderDetails}>
          Sem IP&apos;s cadastrados.
        </Box>
      )}

      {ips.length > 0 && (
        <Box p={2}>
          <Grid
            container
            justify="space-between"
            className={classes.lineGrid}
          >
            <Grid item xs={3} className={classes.grid}>
              <Typography variant="body2" className={classes.title}>TIPO:</Typography>
            </Grid>
            <Grid item xs={3} className={classes.grid}>
              <Typography variant="body2" className={classes.title}>ENDEREÇO IP:</Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>

          {ips.map((subItem, index) => (
            <Grid
              container
              justify="space-between"
              className={classes.lineGrid}
              key={index}
            >
              <Grid item xs={3} className={`${classes.grid} ${classes.wrap}`}>
                <Typography variant="body2">
                  {subItem.type}
                </Typography>
              </Grid>
              <Grid item xs={3} className={`${classes.grid} ${classes.wrap}`}>
                <Typography variant="body2">
                  {subItem.ip}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Box textAlign="right">
                  <Button
                    title="Desassociar usuário"
                    className={classes.deleteIcon}
                    onClick={() => handleModalDeleteGroup(subItem.ip, subItem.type)}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </>
  )
}

IPList.propTypes = {
  ips: PropTypes.array.isRequired,
  onDeleteIp: PropTypes.func.isRequired,
}

export default IPList
