import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { isEqual } from 'lodash'
import { BlockPicker } from 'react-color'
import { Link, useHistory } from 'react-router-dom'

import {
  Resolve,
  useAlert,
  Breadcrumb,
} from '../../components'
import PreviewFallback from './PreviewFallback'
import useSettings from '../../hooks/useSettings'
import useSettingsClient from '../../clients/SettingsClient'

const useStyles = makeStyles(() => ({
  colorPaper: {
    '& div.block-picker': {
      boxShadow: 'none !important',
      '& input': {
        display: 'none',
      },
    },
  },
  colorButton: {
    paddingTop: 5,
    paddingBottom: 6,
  },
}))

const Path = [
  {
    text: 'Espaço Corretor',
    href: '/',
  },
  {
    text: 'Configurações',
    href: '/configuracoes',
  },
  {
    text: 'Personalização de cores',
  },
]

const Customization = () => {
  const classes = useStyles()
  const history = useHistory()
  const settingsClient = useSettingsClient()
  const { setColor, getColor } = useSettings()
  const { addMsgDanger, addMsgSuccess } = useAlert()

  const [colorItems, setColorItems] = useState([])
  const [selected, setSelected] = useState({ ...getColor() })
  const [currentColor, setCurrentColor] = useState(getColor().primary)

  const handleChangeTheme = () => {
    settingsClient().saveColor(selected).then(() => {
      setColor(selected)
      history.push('/configuracoes')
      addMsgSuccess('Configuração realizada com sucesso!')
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const handleChangeComplete = (colorRef) => {
    setCurrentColor(colorRef.hex)

    const data = colorItems.find((item) => isEqual(item.primary.toLowerCase(), colorRef.hex)) || {
      id: 'custom',
      primary: colorRef.hex,
    }
    setSelected(data)
  }

  const handleLoaded = useCallback((data, resolve) => {
    const { colors } = data
    setColorItems(colors)
    resolve()
  }, [])

  const handleResolve = useMemo(() => ({
    colors: () => new Promise((resolve, reject) => {
      settingsClient().getColors().then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [settingsClient, addMsgDanger])

  const Preview = React.lazy(() => import('./CustomPreview'))

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <>
        <Breadcrumb paths={Path} />

        <Box mb={10}>
          <Typography variant="h5" component="h1">Personalização de cores</Typography>
        </Box>

        <Box mb={6}>
          <Typography variant="body2">
            Aqui você poderá personalizar as cores que mais representa a sua corretora.
            Para alterar, basta clicar em uma das cores pré selecionadas:
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} xl={2}>
            <Paper
              variant="outlined"
              className={classes.colorPaper}
            >
              <Box py={2}>
                <Typography align="center" variant="subtitle2">
                  Cores do portal
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center" p={1} pt={0}>
                <BlockPicker
                  triangle="hide"
                  color={currentColor}
                  onChangeComplete={handleChangeComplete}
                  colors={colorItems.map((themeColor) => themeColor.primary)}
                />
              </Box>

              <Box px={1} mt={10} mb={1}>
                <Grid container justify="center" spacing={1} className={classes.buttonsPad}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      color="primary"
                      title="Cancelar"
                      component={Link}
                      variant="outlined"
                      to="/configuracoes"
                      className={classes.colorButton}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      title="Salvar"
                      color="primary"
                      variant="contained"
                      onClick={handleChangeTheme}
                      className={classes.colorButton}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={8} xl={10}>
            <React.Suspense fallback={<PreviewFallback />}>
              <Preview color={currentColor} />
            </React.Suspense>
          </Grid>
        </Grid>
      </>
    </Resolve>
  )
}

export default Customization
