import React, {
  useState, useCallback, useMemo,
} from 'react'

import {
  Box,
  Grid,
  Paper,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'

import { useAlert, FilterTips, Resolve } from '../../../../components'
import useUtils from '../../../../hooks/useUtils'
import Policies from './Policies'
import PolicyFilter from './PolicyFilter'
import PolicyExport from './PolicyExport'
import PolicyChartRenewal from './PolicyChartRenewal'
import usePolicyClient from '../../../../clients/PolicyClient/usePolicyClient'

const DEFAULT_FILTERS = {
  clientName: '',
  policyNumber: '',
  startDate: '',
  endDate: '',
  startIS: '',
  endIS: '',
  startPremium: '',
  endPremium: '',
  period: '',
}

const CompulsoryRenewal = () => {
  const policyClient = usePolicyClient()
  const { addMsgDanger } = useAlert()
  const { formatDate } = useUtils()

  const [policies, setPolicies] = useState([])
  const [total, setTotal] = useState(0)
  const [summary, setSummary] = useState({})
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS })

  const [query, setQuery] = useState({
    pageNumber: 1,
    orderBy: 'clientName',
    filter: DEFAULT_FILTERS,
  })

  const tipsFormats = useMemo(() => ({
    startDueDate: (data) => formatDate(data),
    endDueDate: (data) => formatDate(data),
    period: (data) => `${data} dias`,
  }), [formatDate])

  const tipsTemplates = useMemo(() => ({
    startIS: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
    endIS: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
    startPremium: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
    endPremium: (data) => (
      <Currency quantity={data || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
    ),
  }), [])

  const handleResolve = useMemo(
    () => ({
      policies: () => new Promise((resolve, reject) => {
        policyClient().getPoliciesMonitor(query).then((response) => {
          resolve(response.data)
        }, (response) => {
          reject()
          addMsgDanger(response.data)
        })
      }),
      summary: () => new Promise((resolve, reject) => {
        policyClient().getSummary().then((response) => {
          resolve(response.data)
        }, (response) => {
          addMsgDanger(response.data)
          reject()
        })
      }),
    }),
    [policyClient, query, addMsgDanger],
  )

  const handleLoaded = useCallback((data, resolve) => {
    setPolicies(data.policies.data)
    setTotal(data.policies.total)
    setSummary(data.summary || {})
    resolve()
  },
  [setPolicies])

  const onSearch = useCallback((data) => {
    Promise.all([
      policyClient().getPoliciesMonitor(data),
      policyClient().getSummary(),
    ]).then((response) => {
      setPolicies(response[0].data.data)
      setTotal(response[0].data.total)
      setSummary(response[1].data)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }, [policyClient, setPolicies, addMsgDanger])

  const updateFilter = (value) => {
    const data = { ...query, pageNumber: 1, filter: value }
    setQuery(data)
    setFilters({ ...value })
    onSearch(data)
  }

  const updatePage = (value) => {
    const data = { ...query, pageNumber: value }
    setQuery(data)
    onSearch(data)
  }

  const updateAction = () => {
    onSearch(query)
  }

  const handleRemoveFilter = (filter) => {
    let data = { ...filters, [filter]: '' }
    if (filter === 'startDate' || filter === 'endDate') {
      data = { ...filters, startDate: '', endDate: '' }
    }

    setFilters(data)
    updateFilter(data)
  }

  return (
    <>
      <Resolve onLoaded={handleLoaded} resolve={handleResolve}>
        <>
          <PolicyChartRenewal
            searchPolicies={updateFilter}
            queryPolicies={filters}
            summary={summary}
          />

          <Grid container justify="center">
            <Grid container justify="flex-end">
              <Box my={3} mx={0.5}>
                <PolicyFilter addFilter={updateFilter} filters={filters} />
              </Box>

              <Box my={3} mx={0.5}>
                <PolicyExport filters={query} />
              </Box>
            </Grid>

            <Box>
              <FilterTips
                filter={filters}
                formats={tipsFormats}
                templates={tipsTemplates}
                onRemove={handleRemoveFilter}
              />
            </Box>

            {(!policies || policies.length === 0) && (
              <Grid item xs={12}>
                <Box mt={2}>
                  <Paper>
                    <Box px={2} py={3}>
                      Não foi possível encontrar registros para esta pesquisa.
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            )}

            {policies && policies.length > 0 && (
              <>
                <Policies
                  policies={policies}
                  total={total}
                  updatePage={updatePage}
                  updateAction={updateAction}
                />
              </>
            )}
          </Grid>
        </>
      </Resolve>
    </>
  )
}

export default CompulsoryRenewal
