import React from 'react'
import PropTypes from 'prop-types'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

const NextButton = (props) => {
  const { className, style, onClick } = props
  return (
    <button
      type="button"
      className={className}
      style={{ ...style }}
      onClick={onClick}
      title="Próximo"
    >
      Próximo
      <KeyboardArrowRightIcon />
    </button>
  )
}

NextButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

NextButton.defaultProps = {
  className: '',
  style: {},
  onClick: () => { },
}

export default NextButton
