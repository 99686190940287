import React from 'react'
import IFrameDemand from '../../demands/IFrameDemand'
import Back from '../../demands/BackButton'

const PolicyJudicial = () => {
  const { DEMAND_URL } = window.ENV

  return (
    <>
      <IFrameDemand url={`${DEMAND_URL}/AcompanhamentoApolices`} title="Judicial Execução Fiscal" height="1800" />
      <Back path="/painel" />
    </>
  )
}

export default PolicyJudicial
