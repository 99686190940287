import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from './pages/home/Home'
import Master from './layouts/Master'
import Error from './pages/error/Error'
import Login from './pages/login/Login'
import Panel from './pages/panel/Panel'
import UserNew from './pages/user/UserNew'
import UserList from './pages/user/UserList'
import Demands from './pages/demands/Demands'
import Product from './pages/product/Product'
import Register from './pages/register/Register'
import Settings from './pages/settings/Settings'
import CCGDemand from './pages/demands/CCGDemand'
import PrivateRoute from './security/PrivateRoute'
import Financial from './pages/financial/Financial'
import RegisterDemand from './pages/demands/Register'
import LimitDemand from './pages/demands/LimitDemand'
import BrokerView from './pages/brokerView/BrokerView'
import PolicyIssued from './pages/policy/issued/PolicyIssued'
import PolicyMonitoring from './pages/policy/monitoring/PolicyMonitoring'
import ConfirmEmail from './pages/register/ConfirmEmail'
import Customization from './pages/settings/Customization'
import CustomerDemand from './pages/demands/CustomerDemand'
import QuotationNew from './pages/demands/quotation/QuotationNew'
import OpDeniedDemand from './pages/demands/OpDeniedDemand'
import InProgressDemand from './pages/demands/InProgressDemand'
import ProductionDemand from './pages/demands/ProductionDemand'
import BrokerConcluded from './pages/brokerComplement/BrokerConcluded'
import BrokerComplement from './pages/brokerComplement/BrokerComplement'
import IP from './pages/settings/ip/IP'

import Signin from './pages/access/Signin'
import Reload from './pages/access/Reload'
import Restricted from './pages/error/Restricted'
import Unauthorized from './pages/error/Unauthorized'
import RenewCallback from './pages/access/RenewCallback'
import AccessCallback from './pages/access/AccessCallback'
import SignoutCallback from './pages/access/SignoutCallback'
import ConcludedRegister from './pages/register/ConcludedRegister'
import ServiceHistory from './pages/serviceHistory/ServiceHistory'

const Routes = () => (
  <Switch>
    <Route path="/signin" component={Signin} />
    <Route path="/error/:status" component={Error} />
    <Route path="/restricted" component={Restricted} />
    <Route path="/callback" component={AccessCallback} />
    <Route path="/silent-renew" component={RenewCallback} />
    <Route path="/signout-callback" component={SignoutCallback} />

    <Route path="/unauthorized">
      <Login>
        <Unauthorized />
      </Login>
    </Route>

    <Route path="/register/concluded">
      <Login>
        <ConcludedRegister />
      </Login>
    </Route>

    <Route path="/register">
      <Login>
        <Register />
      </Login>
    </Route>

    <Route path="/check-email/:mail">
      <Login>
        <ConfirmEmail />
      </Login>
    </Route>

    <PrivateRoute
      exact
      layout={Master}
      component={Reload}
      path="/reload"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={Home}
      path="/"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={ServiceHistory}
      path="/atendimento-ao-cliente"
    />

    {/* CONFIGURAÇÃO */}

    <PrivateRoute
      exact
      layout={Master}
      component={Settings}
      path="/configuracoes"
      functionality="CADASTRO"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={IP}
      path="/ips"
      functionality="CADASTRO"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={Customization}
      path="/customizar"
      functionality="CADASTRO"
    />

    {/* PRODUTOS */}

    <PrivateRoute
      exact
      layout={Master}
      component={Product}
      path="/produto"
    />

    {/* PAINEL */}

    <PrivateRoute
      exact
      layout={Master}
      component={Panel}
      path="/painel"
      functionality="PAINEL"
    />

    {/* CADASTRO */}

    <PrivateRoute
      exact
      layout={Master}
      component={BrokerComplement}
      path="/corretor/complemento"
      functionality="CADASTRO"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={BrokerConcluded}
      path="/corretor/concluido"
      functionality="CADASTRO"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={BrokerView}
      path="/corretor/visualizar"
      functionality="CADASTRO"
    />

    {/* DEMANDAS */}

    <PrivateRoute
      exact
      type={1}
      layout={Master}
      component={QuotationNew}
      path="/cotacoes/novo/bid"
      functionality="DEMANDAS"
    />

    <PrivateRoute
      exact
      type={0}
      layout={Master}
      component={QuotationNew}
      path="/cotacoes/novo/performance"
      functionality="DEMANDAS"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={Demands}
      path="/cotacoes"
      functionality="DEMANDAS"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={InProgressDemand}
      path="/em-progresso"
      functionality="DEMANDAS"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={OpDeniedDemand}
      path="/operacao-negada"
      functionality="DEMANDAS"
    />

    {/* CLIENTE */}

    <PrivateRoute
      exact
      layout={Master}
      component={RegisterDemand}
      path="/cadastro-clientes"
      functionality="AREA_CLIENTE"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={LimitDemand}
      functionality="LIMITE_TAXA"
      path="/limite"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={CustomerDemand}
      functionality="AREA_CLIENTE"
      path="/demanda-cliente"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={CCGDemand}
      functionality="CCG"
      path="/ccg"
    />

    {/* PRODUCAO_CORRETORA */}

    <PrivateRoute
      exact
      layout={Master}
      component={ProductionDemand}
      path="/demanda-produto"
      functionality="PRODUCAO_CORRETORA"
    />

    {/* APOLICES */}

    <PrivateRoute
      exact
      layout={Master}
      component={PolicyIssued}
      path="/apolices/emitidas"
      functionality="APOLICES"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={PolicyMonitoring}
      path="/apolices/acompanhar"
      functionality="APOLICES"
    />

    {/* FINANCEIRO */}

    <PrivateRoute
      exact
      layout={Master}
      component={Financial}
      path="/financeiro"
      functionality="FINANCEIRO"
    />

    {/* USUARIOS */}

    <PrivateRoute
      exact
      layout={Master}
      component={UserList}
      path="/usuario/listar"
      functionality="USUARIOS"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={UserNew}
      path="/usuario/:id/editar"
      functionality="USUARIOS"
    />

    <PrivateRoute
      exact
      layout={Master}
      component={UserNew}
      path="/usuario/criar"
      functionality="USUARIOS"
    />

    <Redirect to="/error/404" />
  </Switch>
)

export default Routes
