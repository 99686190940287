import React, { useState, useRef } from 'react'
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemText,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import MoreIcon from '@material-ui/icons/MoreVert'

import { useAlert } from '../../components'
import useDownload from '../../hooks/useDownload'
import usePolicyClient from '../../clients/PolicyClient'

const BilletActions = ({ installment }) => {
  const buttonRef = useRef()
  const { addMsgDanger } = useAlert()
  const { downloadPDF } = useDownload()
  const policyClient = usePolicyClient()
  const [open, setOpen] = useState(false)

  const handleModalPolicySave = () => {
    const { policyNumber, proposalNumber } = installment
    setOpen(false)

    policyClient().downloadPolicy(policyNumber, proposalNumber).then((response) => {
      downloadPDF(response.data.apoliceDsd, `apolice_${policyNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const handleModalProposalSave = () => {
    const { policyId } = installment
    setOpen(false)

    policyClient().downloadProposal({ policyId }).then((response) => {
      downloadPDF(response.data.proposalFile, `proposta_${installment.proposalNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <>
      <IconButton
        title="Ações"
        color="primary"
        ref={buttonRef}
        onClick={() => setOpen(true)}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={buttonRef.current}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setOpen(false)}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >

        <MenuItem title="Ver apólice">
          <ListItemText primary="Ver apólice" onClick={handleModalPolicySave} />
        </MenuItem>
        <MenuItem title="Ver proposta">
          <ListItemText primary="Ver proposta" onClick={handleModalProposalSave} />
        </MenuItem>
      </Menu>
    </>
  )
}

BilletActions.propTypes = {
  installment: PropTypes.object.isRequired,
}

export default BilletActions
