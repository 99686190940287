import React, { useState } from 'react'
import {
  Grid,
  Input,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  InputAdornment,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { isEmpty, debounce } from 'lodash'
import { Autocomplete } from '@material-ui/lab'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import useUtils from '../../../hooks/useUtils'
import { useQuotationContext } from './QuotationContext'
import { useAlert, useLoader } from '../../../components'
import useQuotationClient from '../../../clients/QuotationClient'

const useStyles = makeStyles(() => ({
  removeButton: {
    padding: 0,
  },
}))

const CoBroker = ({
  index,
  errors,
  touched,
  coBroker,
  coBrokers,
  onRemove: remove,
  onChange: change,
}) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const quotationClient = useQuotationClient()
  const { brokerDocument } = useQuotationContext()
  const { enableLoader, disableLoader } = useLoader()
  const { formatCNPJ, isBlank, getOnlyNumber } = useUtils()

  const [brokerRun, setBrokerRun] = useState()
  const [loading, setLoading] = useState(false)
  const [associatedBrokers, setAssociatedBrokers] = useState([])
  const [broker, setBroker] = useState(() => ({
    ...coBroker,
    name: coBroker.brokerName,
  }))

  const handleChangePercentageOfParticipation = (event) => {
    event.persist()
    const { value } = event.target

    change('percentageOfParticipation', value)

    const sum = coBrokers.reduce((accumulator, current, currentIndex) => {
      const { percentageOfParticipation = 0 } = current
      const percentage = currentIndex !== index ? Number(percentageOfParticipation) : 0
      return accumulator + percentage
    }, Number(value))

    if (sum > 99) {
      addMsgDanger({
        message: 'O somatório dos percentuais de coparticipação deve ser menor do que 100%',
        action: () => {
          event.target.focus()
          change('percentageOfParticipation', '')
        },
      })
    }
  }

  const handleChangeCoBroker = (event, value) => {
    const { id = '', name = '', documentNumber = '' } = value || {}
    setBroker({ id, name, documentNumber })

    change('id', id)
    change('brokerName', name)
    change('documentNumber', getOnlyNumber(documentNumber))
  }

  const handleInputChangeCoBroker = (event, value) => {
    setLoading(true)
    setBroker({ name: value })

    if (brokerRun) {
      brokerRun.current.cancel()
    }

    if (isEmpty(value) || value.length < 3) {
      setLoading(false)
      setAssociatedBrokers([])
      return
    }

    const selected = value.split(' / ')

    if (selected.length === 2) {
      setLoading(false)
      return
    }

    const debounced = debounce(() => {
      disableLoader()

      quotationClient().getAssociatedBrokers(value, brokerDocument).then((response) => {
        enableLoader()
        setLoading(false)
        setAssociatedBrokers([...response.data])
      }, (error) => {
        enableLoader()
        setLoading(false)
        setAssociatedBrokers([])
        addMsgDanger(error.data)
      })
    }, 1000)

    debounced()
    setBrokerRun({ current: debounced })
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
    >
      <Grid item xs={7}>
        <Autocomplete
          clearOnBlur
          value={broker}
          handleHomeEndKeys
          loading={loading}
          autoSelect={false}
          options={associatedBrokers}
          getOptionLabel={(item) => {
            const { id, name, documentNumber } = item
            return isBlank(id) ? `${name || ''}` : `${formatCNPJ(documentNumber)} / ${name}`
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          onInputChange={handleInputChangeCoBroker}
          onChange={handleChangeCoBroker}
          loadingText="Carregando opções..."
          noOptionsText="Nenhuma opção correspondente"
          ListboxProps={{ style: { fontSize: 12 } }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              color="secondary"
              title="Co-Corretor"
              label="Co-Corretor:"
              id={`coBroker-${index}`}
              name={`coBrokers.${index}.id`}
              error={touched?.id && !!errors?.id}
              helperText={touched?.id && errors?.id}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel
            color="secondary"
            error={touched?.percentageOfParticipation && !!errors?.percentageOfParticipation}
          >
            Percentual de participação:
          </InputLabel>
          <Input
            maxLength={2}
            color="secondary"
            title="Percentual de participação"
            id={`percentageOfParticipation-${index}`}
            value={coBroker.percentageOfParticipation || ''}
            name={`coBrokers.${index}.percentageOfParticipation`}
            error={touched?.percentageOfParticipation && !!errors?.percentageOfParticipation}
            onChange={handleChangePercentageOfParticipation}
            endAdornment={(
              <InputAdornment position="end">
                <HelpOutlineIcon
                  data-html
                  data-tip="<center>Caso esta emissão seja em co-corretagem, <br/>insira o CNPJ ou Razão Social da corretora, <br/> bem como o percentual da participação <br/>para efeitos de pagamento de comissão.</center>"
                />
              </InputAdornment>
            )}
          />
          <FormHelperText
            hidden={!touched?.percentageOfParticipation || !errors?.percentageOfParticipation}
            error={touched?.percentageOfParticipation && !!errors?.percentageOfParticipation}
          >
            {errors?.percentageOfParticipation}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          color="primary"
          onClick={remove}
          title="Remover Co-Corretor"
          className={classes.removeButton}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>

        <ReactTooltip place="top" type="dark" />
      </Grid>
    </Grid>
  )
}

CoBroker.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  coBroker: PropTypes.object.isRequired,
  coBrokers: PropTypes.array.isRequired,
}

CoBroker.defaultProps = {
  errors: {},
  touched: {},
}

export default CoBroker
