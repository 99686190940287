import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  colors, Select, withStyles, InputBase, MenuItem,
} from '@material-ui/core'
import adjustItems from './adjustItems'

function CustomSelect({
  size, color, items, onChange, empty, ...rest
}) {
  const defaultRules = {
    input: {
      boxShadow: '0px 3px 6px #00000029',
      borderRadius: 17,
      position: 'relative',
      padding: '5px 15px',
      fontSize: '.8rem',
    },
    inputFocus: {
      borderRadius: 17,
      borderColor: '#80bdff',
    },
  }

  const getColor = (theme, type) => {
    if (color === 'primary' && type === 'color') {
      return theme.palette.primary.contrastText
    }

    if (color === 'secondary' && type === 'color') {
      return theme.palette.secondary.contrastText
    }

    if (color === 'primary' && type === 'input') {
      return theme.palette.primary.main
    }

    if (color === 'secondary' && type === 'input') {
      return theme.palette.secondary.main
    }

    return colors.grey[0]
  }

  const StyledInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '& svg': {
        color: getColor(theme, 'color'),
        marginRight: 5,
      },
    },
    input: {
      ...defaultRules.input,
      backgroundColor: getColor(theme, 'input'),
      color: getColor(theme, 'color'),

      '&:focus': {
        ...defaultRules.inputFocus,
        backgroundColor: getColor(theme, 'input'),

      },
    },

  }))(InputBase)

  items = adjustItems(items, empty)
  const initialValue = items.find((item) => item.selected).value

  const { disabled } = items[0]

  const [value, setValue] = useState(initialValue)
  const handleChange = (event) => {
    setValue(event.target.value)
    return onChange ? onChange() : false
  }

  return (

    <Select
      {...rest}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      autoWidth
      input={<StyledInput />}
    >
      {
        items.map((item, index) => <MenuItem key={index} value={item.value}>{item.text}</MenuItem>)
      }

    </Select>
  )
}

CustomSelect.propTypes = {
  empty: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

CustomSelect.defaultProps = {
  size: 'medium',
  color: 'default',
  className: '',
  empty: false,
  onChange: () => {},
}

export default CustomSelect
