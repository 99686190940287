import React, { useState, useMemo, useEffect } from 'react'
import {
  Box,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import { isEmpty, debounce } from 'lodash'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import { RiEqualizerLine } from 'react-icons/ri'
import TextField from '@material-ui/core/TextField'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import useUtils from '../../hooks/useUtils'
import useCustomerClient from '../../clients/CustomerClient'
import DateInput from '../../components/DateInput/DateInput'
import { FilterContainer, useAlert, useLoader } from '../../components'

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1 / 2),
    fontSize: '1.5rem',
    transform: 'rotate(90deg)',
    borderRadius: 4,
  },
  formcontrol: {
    paddingBottom: theme.spacing(3),
  },
  borderBottom: {
    borderBottom: '1px solid #E3E3E3',
  },
  resize: {
    fontSize: 14,
  },
  statusButton: {
    padding: '6px 16px',
  },
  billetAmountText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 3,
  },
  dateRangeText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
}))

const DEFAULT_FILTERS = {
  policyNumber: '',
  proposalNumber: '',
  startAmount: '',
  endAmount: '',
  startDueDate: '',
  endDueDate: '',
  status: '',
  clientName: '',
}

const BilletFilter = ({ addFilter, filters }) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const customerClient = useCustomerClient()
  const [clientRun, setClientRun] = useState()
  const { isBlank, getOnlyNumber } = useUtils()
  const [loading, setLoading] = useState(false)
  const [filterBox, setFilterBox] = useState(false)
  const { enableLoader, disableLoader } = useLoader()
  const [clientOptions, setClientOptions] = useState([])

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTERS },
    validationSchema: Yup.object({
      clientName: Yup.string(),
      policyNumber: Yup.string().max(30),
      proposalNumber: Yup.string().max(30),

      // Valor do boleto
      startAmount: Yup.number(),
      endAmount: Yup.number().when('startAmount', (startAmount) => {
        let schema

        if (startAmount && startAmount !== 0) {
          schema = Yup.number().min(startAmount, 'O valor deve ser maior que o valor anterior').required()
        }
        return schema
      }),

      // Data de vencimento
      startDueDate: Yup.date(),
      endDueDate: Yup.date().when('startDueDate', (startDueDate) => startDueDate && Yup.date().min(startDueDate, 'A data final deve ser maior que a data inicial').required()),
    }),
    onSubmit: (data) => {
      addFilter(data)
      setFilterBox(false)
    },
  })

  const { setValues } = formik

  useEffect(() => {
    setValues({ ...filters })
  }, [filters, setValues])

  const listBoxStyle = useMemo(() => {
    let style = { fontSize: 12 }
    const { clientName } = formik.values

    if (isEmpty(clientName)) {
      style = { ...style, display: 'none' }
    }

    return style
  }, [formik])

  const handleOnChangePolicyNumber = (event) => {
    const { value } = event.target
    const policyNumber = getOnlyNumber(value)
    formik.setFieldValue('policyNumber', policyNumber)
  }

  const handleOnChangeProposalNumber = (event) => {
    const { value } = event.target
    const proposalNumber = getOnlyNumber(value)
    formik.setFieldValue('proposalNumber', proposalNumber)
  }

  const handleClear = () => {
    addFilter({ ...DEFAULT_FILTERS })
    formik.resetForm({ ...DEFAULT_FILTERS })
  }

  const handleInputChangeClient = (event, value) => {
    setLoading(true)
    formik.setFieldValue('clientName', value)

    if (clientRun) {
      clientRun.current.cancel()
    }

    if (isEmpty(value)) {
      setLoading(false)
      setClientOptions([])
      return
    }

    const current = clientOptions.find((item) => item.name === value)

    if (!isEmpty(current)) {
      setLoading(false)
      return
    }

    const debounced = debounce(() => {
      disableLoader()

      customerClient().getCustomers(value).then((response) => {
        enableLoader()
        setLoading(false)
        setClientOptions([...response.data])
      }, (error) => {
        enableLoader()
        setLoading(false)
        setClientOptions([])
        addMsgDanger(error.data)
      })
    }, 1000)

    debounced()
    setClientRun({ current: debounced })
  }

  return (
    <>
      <Button title="Filtrar Boletos" className={classes.filterIcon}>
        <RiEqualizerLine onClick={() => setFilterBox(true)} />
      </Button>

      <FilterContainer
        open={filterBox}
        onClean={handleClear}
        onClose={() => setFilterBox(false)}
        onSearch={() => formik.submitForm()}
      >
        <Box mb={2}>
          <TextField
            id="policyNumber"
            color="secondary"
            label="Número da apólice"
            title="Número da apólice"
            onKeyUp={handleOnChangePolicyNumber}
            {...formik.getFieldProps('policyNumber')}
            error={formik.touched.policyNumber && !!formik.errors.policyNumber}
            helperText={formik.touched.policyNumber && formik.errors.policyNumber}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <TextField
            id="proposalNumber"
            label="Número da proposta"
            color="secondary"
            title="Número da proposta"
            onKeyUp={handleOnChangeProposalNumber}
            {...formik.getFieldProps('proposalNumber')}
            error={formik.touched.proposalNumber && !!formik.errors.proposalNumber}
            helperText={formik.touched.proposalNumber && formik.errors.proposalNumber}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <Autocomplete
            clearOnBlur
            handleHomeEndKeys
            loading={loading}
            options={clientOptions}
            getOptionLabel={(item) => item?.name || ''}
            ListboxProps={{ style: listBoxStyle }}
            value={{ name: formik.values.clientName }}
            getOptionSelected={(option, value) => option.name === value.name}
            onInputChange={handleInputChangeClient}
            loadingText="Carregando opções..."
            noOptionsText="Nenhuma opção correspondente"
            renderInput={(params) => (
              <TextField
                {...params}
                id="clientName"
                name="clientName"
                label="Cliente"
                title="Cliente"
                color="secondary"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>

        <FormControl className={classes.formcontrol} fullWidth>
          <Box my={1}>
            <FormLabel>Valor do boleto:</FormLabel>
          </Box>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item lg={5} xs={12}>
              <CurrencyTextField
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="startAmount"
                title="Menor valor do Boleto"
                color="secondary"
                {...formik.getFieldProps('startAmount')}
                error={formik.touched.startAmount && !!formik.errors.startAmount}
                helperText={formik.touched.startAmount && formik.errors.startAmount}
                fullWidth
                onChange={(event, value) => {
                  if (value === 0) {
                    formik.setFieldValue('endAmount', '')
                  }

                  formik.setFieldValue('startAmount', value)
                }}
              />
            </Grid>
            <Grid item lg={2} xs={12} className={classes.billetAmountText}>
              <Typography component="label" htmlFor="endAmount">Até</Typography>
            </Grid>
            <Grid item lg={5} xs={12}>
              <CurrencyTextField
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="endAmount"
                title="Maior valor do Boleto"
                color="secondary"
                {...formik.getFieldProps('endAmount')}
                error={formik.touched.endAmount && !!formik.errors.endAmount}
                helperText={formik.touched.endAmount && formik.errors.endAmount}
                fullWidth
                onChange={(event, value) => formik.setFieldValue('endAmount', value)}
              />
            </Grid>
          </Grid>
        </FormControl>

        <FormControl className={classes.formcontrol} fullWidth>
          <Box mb={2}>
            <FormLabel>Data de vencimento:</FormLabel>
          </Box>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item lg={5} xs={12}>
              <DateInput
                id="startDueDate"
                label="Data Inicial"
                title="Data Inicial"
                {...formik.getFieldProps('startDueDate')}
                error={formik.touched.startDueDate && !!formik.errors.startDueDate}
                helperText={formik.touched.startDueDate && formik.errors.startDueDate}
                onChange={(event) => {
                  const { value } = event.target

                  if (isBlank(value)) {
                    formik.setFieldValue('endDueDate', '')
                  }

                  formik.setFieldValue('startDueDate', value)
                }}
              />
            </Grid>
            <Grid item lg={2} xs={12} className={classes.dateRangeText}>
              <Typography component="label" htmlFor="endDueDate">Até</Typography>
            </Grid>
            <Grid item lg={5} xs={12}>
              <DateInput
                id="endDueDate"
                label="Data Final"
                title="Data Final"
                {...formik.getFieldProps('endDueDate')}
                error={formik.touched.endDueDate && !!formik.errors.endDueDate}
                helperText={formik.touched.endDueDate && formik.errors.endDueDate}
                disabled={!formik.values.startDueDate}
                onChange={(event) => {
                  const { value } = event.target
                  formik.setFieldValue('endDueDate', value)
                }}
              />
            </Grid>
          </Grid>
        </FormControl>

        <FormControl className={`${classes.formcontrol} ${classes.borderBottom}`} fullWidth>
          <FormLabel>Situação:</FormLabel>
          <RadioGroup
            row
            id="status"
            {...formik.getFieldProps('status')}
          >
            <FormControlLabel
              value="Pago"
              label="Pago"
              title="Pago"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="Vencido"
              label="Vencido"
              title="Vencido"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="AVencer"
              label="A Vencer"
              title="A Vencer"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
      </FilterContainer>
    </>
  )
}

BilletFilter.propTypes = {
  addFilter: PropTypes.func,
  filters: PropTypes.object,
}

BilletFilter.defaultProps = {
  addFilter: () => { },
  filters: {},
}

export default BilletFilter
