import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { Box, makeStyles } from '@material-ui/core'

import {
  useAlert,
  CEPInput,
  CPFInput,
  PhoneInput,
  SUSEPInput,
} from '../../components'
import useYup from '../../hooks/useYup'
import useUtils from '../../hooks/useUtils'
import useSecurity from '../../security/useSecurity'
import useBrokerClient from '../../clients/BrokerClient'
import { useBrokerComplement } from './ComplementContext'
import useLocationClient from '../../clients/LocationClient'

const useStyles = makeStyles(() => ({}))

const DEFAULT_BROKER = {
  documentNumber: '',
  name: '',
  email: '',
  phone: '',
  zipCode: '',
  address: '',
  susepNumber: '',
  streetNumber: '',
  addressComplement: '',
  neighborhood: '',
  state: '',
  city: '',
}

const PersonBroker = forwardRef((props, ref) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const { getContext } = useSecurity()
  const brokerClient = useBrokerClient()
  const locationClient = useLocationClient()
  const { formatSUSEP, getOnlyNumber } = useUtils()

  const [show, setShow] = useState(false)

  const zipCodeInput = React.useRef()
  const streetInput = React.useRef()

  const {
    cep: cepRule,
    cpf: cpfRule,
    susep: susepRule,
    phoneOrCellphone: phoneRule,
  } = useYup()

  const {
    broker,
    setBroker,
    onNext,
  } = useBrokerComplement()

  const formik = useFormik({
    initialValues: { ...DEFAULT_BROKER },
    validationSchema: Yup.object({
      documentNumber: cpfRule.required(),
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      phone: phoneRule.required(),
      susepNumber: susepRule.required(),
      zipCode: cepRule.required(),
      address: Yup.string().max(100).required(),
      streetNumber: Yup.string().max(50).required(),
      addressComplement: Yup.string().max(50),
      neighborhood: Yup.string().max(100).required(),
      state: Yup.string().max(50).required(),
      city: Yup.string().max(100).required(),
    }),
    onSubmit: (data) => {
      const context = getContext()
      const values = {
        ...data,
        id: context.personId,
        idPhone: broker.idPhone,
        idAddress: broker.idAddress,
      }

      brokerClient().editBrokerPF(values).then((response) => {
        setBroker(response.data)
        onNext(response.data)
      }, (error) => {
        addMsgDanger(error.data)
      })
    },
  })

  const { setValues } = formik

  useEffect(() => {
    const {
      documentNumber,
      name,
      email,
      phone,
      zipCode,
      address,
      susepNumber,
      streetNumber,
      addressComplement,
      neighborhood,
      state,
      city,
    } = broker

    setValues({
      documentNumber: documentNumber || '',
      name: name || '',
      email: email || '',
      phone: phone || '',
      susepNumber: formatSUSEP(susepNumber) || '',
      zipCode: zipCode || '',
      address: address || '',
      streetNumber: streetNumber || '',
      addressComplement: addressComplement || '',
      neighborhood: neighborhood || '',
      state: state || '',
      city: city || '',
    })
  }, [broker, setValues, formatSUSEP])

  useImperativeHandle(ref, () => ({
    onOpen: () => new Promise((resolve) => {
      setShow(true)
      resolve()
    }),
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: 'Verifique os campos em destaque antes de prosseguir.' }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
    onClose: () => setShow(false),
  }))

  const handleFindAddressByZipCode = async (event) => {
    const { value } = event.target
    const onlyNumber = getOnlyNumber(value)

    const valid = await Yup.object().shape({ zipCode: cepRule.required() }).isValid({
      zipCode: value,
    })

    if (valid) {
      locationClient().getAddressByZipCode(onlyNumber).then((response) => {
        const {
          cep,
          logradouro,
          bairro,
          uf,
          cidade,
        } = response.data.correios

        formik.setValues({
          ...formik.values,
          zipCode: getOnlyNumber(cep) || '',
          address: logradouro || '',
          streetNumber: formik.getFieldProps('streetNumber').value || '',
          addressComplement: formik.getFieldProps('addressComplement').value || '',
          neighborhood: bairro || '',
          state: uf || '',
          city: cidade || '',
        })

        streetInput.current.focus()
      }, (response) => {
        if (response.status !== 404) {
          addMsgDanger(response.data)
        } else {
          addMsgDanger(response.data)
          formik.setFieldValue('zipCode', '')
          setTimeout(() => zipCodeInput.current.focus())
        }
      })
    }
  }

  return (
    <>
      {show && (
        <>
          {/* DADOS PESSOAIS */}
          <Box mb={2}>
            <Paper className="paper">
              <Grid item xs={12}>
                <Typography component="span" color="primary" className={classes.title}>
                  CORRETORA
                </Typography>
              </Grid>
              <Grid container spacing={5}>
                <Grid item sm={6} xs={12}>
                  <CPFInput
                    id="documentNumber"
                    color="secondary"
                    label="CPF:"
                    title="CPF"
                    {...formik.getFieldProps('documentNumber')}
                    error={formik.touched.documentNumber && !!formik.errors.documentNumber}
                    helperText={formik.touched.documentNumber && formik.errors.documentNumber}
                    fullWidth
                    InputProps={{
                      disabled: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id="name"
                    title="Nome"
                    color="secondary"
                    label="Nome:"
                    {...formik.getFieldProps('name')}
                    error={formik.touched.name && !!formik.errors.name}
                    helperText={formik.touched.name && formik.errors.name}
                    fullWidth
                    InputProps={{
                      disabled: true,
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id="email"
                    title="E-mail"
                    color="secondary"
                    label="E-mail:"
                    {...formik.getFieldProps('email')}
                    error={formik.touched.email && !!formik.errors.email}
                    helperText={formik.touched.email && formik.errors.email}
                    fullWidth
                    InputProps={{
                      disabled: true,
                    }}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <PhoneInput
                    id="phone"
                    title="Telefone"
                    color="secondary"
                    label="Telefone*:"
                    {...formik.getFieldProps('phone')}
                    error={formik.touched.phone && !!formik.errors.phone}
                    helperText={formik.touched.phone && formik.errors.phone}
                    fullWidth
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <SUSEPInput
                    id="susepNumber"
                    color="secondary"
                    title="Registro junto a SUSEP"
                    label="Registro junto a SUSEP*:"
                    {...formik.getFieldProps('susepNumber')}
                    error={formik.touched.susepNumber && !!formik.errors.susepNumber}
                    helperText={formik.touched.susepNumber && formik.errors.susepNumber}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
          {/* ENDEREÇO */}
          <Paper className="paper">
            <Box mb={4}>
              <Typography variant="h6" component="h2" color="primary">Endereço</Typography>
            </Box>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <CEPInput
                  id="zipCode"
                  color="secondary"
                  label="CEP*:"
                  title="CEP"
                  {...formik.getFieldProps('zipCode')}
                  error={formik.touched.zipCode && !!formik.errors.zipCode}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                  fullWidth
                  InputProps={{ onBlur: handleFindAddressByZipCode, inputRef: zipCodeInput }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  id="address"
                  color="secondary"
                  label="Logradouro*:"
                  title="Logradouro"
                  {...formik.getFieldProps('address')}
                  error={formik.touched.address && !!formik.errors.address}
                  helperText={formik.touched.address && formik.errors.address}
                  inputRef={streetInput}
                  fullWidth
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                  id="streetNumber"
                  color="secondary"
                  label="Número*:"
                  title="Número"
                  {...formik.getFieldProps('streetNumber')}
                  error={formik.touched.streetNumber && !!formik.errors.streetNumber}
                  helperText={formik.touched.streetNumber && formik.errors.streetNumber}
                  fullWidth
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                  id="addressComplement"
                  color="secondary"
                  label="Complemento:"
                  title="Complemento"
                  {...formik.getFieldProps('addressComplement')}
                  error={formik.touched.addressComplement && !!formik.errors.addressComplement}
                  helperText={formik.touched.addressComplement && formik.errors.addressComplement}
                  fullWidth
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  id="neighborhood"
                  color="secondary"
                  label="Bairro*:"
                  title="Bairro"
                  {...formik.getFieldProps('neighborhood')}
                  error={formik.touched.neighborhood && !!formik.errors.neighborhood}
                  helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                  fullWidth
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                  id="state"
                  color="secondary"
                  label="Estado*:"
                  title="Estado"
                  {...formik.getFieldProps('state')}
                  error={formik.touched.state && !!formik.errors.state}
                  helperText={formik.touched.state && formik.errors.state}
                  InputProps={{ disabled: true }}
                  fullWidth
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  id="city"
                  color="secondary"
                  label="Cidade*:"
                  title="Cidade"
                  {...formik.getFieldProps('city')}
                  error={formik.touched.city && !!formik.errors.city}
                  helperText={formik.touched.city && formik.errors.city}
                  InputProps={{ disabled: true }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
      {!show && <></>}
    </>
  )
})

export default PersonBroker
