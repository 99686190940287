import React from 'react'

import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  InputLabel,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import useUtils from '../../../hooks/useUtils'
import { DateInput, CurrencyInput } from '../../../components'

const useStyles = makeStyles(() => ({
  lineTitle: {
    height: '2px',
    background: '#f36523',
    border: 'none',
    width: '60px',
    float: 'left',
  },
}))

const QuotationValuesAndDeadlines = ({ parentFormik }) => {
  const classes = useStyles()
  const { isBlank, date } = useUtils()

  const handleProcessInsuredDays = (policyPeriodFinal, policyPeriodInitial) => {
    if (isBlank(policyPeriodInitial) || isBlank(policyPeriodFinal)) {
      parentFormik.setFieldValue('insuredDays', '')
      return
    }

    const inicial = date(policyPeriodInitial)
    const final = date(policyPeriodFinal)
    const day = final.diff(inicial, 'days') + 1

    if (day > 0) {
      parentFormik.setFieldValue('insuredDays', day)
    }
  }

  const handleProcessPolicyPeriodFinal = (insuredDays, policyPeriodInitial) => {
    if (isBlank(policyPeriodInitial) || isBlank(insuredDays) || Number(insuredDays) === 0) {
      parentFormik.setFieldValue('policyPeriodFinal', '')
      return
    }

    const days = Number(insuredDays) - 1
    const inicial = date(policyPeriodInitial).add(days, 'd')
    parentFormik.setFieldValue('policyPeriodFinal', inicial.toJSON())
  }

  const handleChangePolicyPeriodInitial = (event) => {
    const { value: policyPeriodInitial } = event.target
    parentFormik.setFieldValue('policyPeriodInitial', policyPeriodInitial)

    if (isBlank(policyPeriodInitial)) {
      parentFormik.setFieldValue('insuredDays', '')
      parentFormik.setFieldValue('policyPeriodFinal', '')
      return
    }

    const { insuredDays, policyPeriodFinal } = parentFormik.values

    if (!isBlank(policyPeriodFinal)) {
      handleProcessInsuredDays(policyPeriodFinal, policyPeriodInitial)
      return
    }

    if (!isBlank(insuredDays)) {
      handleProcessPolicyPeriodFinal(insuredDays, policyPeriodInitial)
    }
  }

  return (
    <Box component={Paper} p={4} my={2}>
      <Box mb={5}>
        <Typography variant="subtitle1" component="h2">
          <Box fontWeight="bold">Valores e Prazos</Box>
          <hr className={classes.lineTitle} />
        </Typography>
      </Box>

      <Box my={2}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} lg={6}>
            <CurrencyInput
              fullWidth
              color="secondary"
              id="contractValue"
              label="Valor do contrato:"
              title="Valor do contrato"
              {...parentFormik.getFieldProps('contractValue')}
              error={parentFormik.touched.contractValue && !!parentFormik.errors.contractValue}
              helperText={parentFormik.touched.contractValue && parentFormik.errors.contractValue}
              onChange={(event, value) => parentFormik.setFieldValue('contractValue', value || '')}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CurrencyInput
              fullWidth
              id="insuredAmount"
              label="Importância Segurada:"
              title="Importância Segurada"
              color="secondary"
              {...parentFormik.getFieldProps('insuredAmount')}
              error={parentFormik.touched.insuredAmount && !!parentFormik.errors.insuredAmount}
              helperText={parentFormik.touched.insuredAmount && parentFormik.errors.insuredAmount}
              onChange={(event, value) => parentFormik.setFieldValue('insuredAmount', value || '')}
            />
          </Grid>
        </Grid>
      </Box>
      <Box my={2}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} lg={6}>
            <Box mt={0.34985}>
              <InputLabel shrink>Data da Vigência:</InputLabel>
            </Box>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                <DateInput
                  fullWidth
                  id="policyPeriodInitial"
                  title="Data Inicial"
                  {...parentFormik.getFieldProps('policyPeriodInitial')}
                  error={parentFormik.touched.policyPeriodInitial
                    && !!parentFormik.errors.policyPeriodInitial}
                  helperText={parentFormik.touched.policyPeriodInitial
                    && parentFormik.errors.policyPeriodInitial}
                  onChange={handleChangePolicyPeriodInitial}
                />
              </Grid>
              <Grid item xs={2}>
                <Box textAlign="center">Até</Box>
              </Grid>
              <Grid item xs={5}>
                <DateInput
                  fullWidth
                  id="policyPeriodFinal"
                  title="Data Final"
                  {...parentFormik.getFieldProps('policyPeriodFinal')}
                  error={parentFormik.touched.policyPeriodFinal
                    && !!parentFormik.errors.policyPeriodFinal}
                  helperText={parentFormik.touched.policyPeriodFinal
                    && parentFormik.errors.policyPeriodFinal}
                  onChange={(event) => {
                    const { value: policyPeriodFinal } = event.target
                    parentFormik.setFieldValue('policyPeriodFinal', policyPeriodFinal)

                    const { policyPeriodInitial } = parentFormik.values
                    handleProcessInsuredDays(policyPeriodFinal, policyPeriodInitial)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              length={4}
              id="insuredDays"
              color="secondary"
              title="Prazo em dias"
              label="Prazo em dias:"
              {...parentFormik.getFieldProps('insuredDays')}
              error={parentFormik.touched.insuredDays && !!parentFormik.errors.insuredDays}
              helperText={parentFormik.touched.insuredDays && parentFormik.errors.insuredDays}
              inputProps={{ maxLength: 4 }}
              onChange={(event) => {
                const { value: insuredDays } = event.target
                parentFormik.setFieldValue('insuredDays', insuredDays)

                const { policyPeriodInitial } = parentFormik.values
                handleProcessPolicyPeriodFinal(insuredDays, policyPeriodInitial)
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

QuotationValuesAndDeadlines.propTypes = {
  parentFormik: PropTypes.object.isRequired,
}

export default QuotationValuesAndDeadlines
