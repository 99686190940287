import React, {
  useMemo,
  useState,
  useEffect,
} from 'react'
import {
  Box,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import debounce from 'lodash/debounce'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import { RiEqualizerLine } from 'react-icons/ri'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import useUtils from '../../hooks/useUtils'
import useCustomerClient from '../../clients/CustomerClient'
import DateInput from '../../components/DateInput/DateInput'
import { FilterContainer, useAlert, useLoader } from '../../components'

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1 / 2),
    fontSize: '1.5rem',
    transform: 'rotate(90deg)',
    borderRadius: 4,
  },
  formcontrol: {
    paddingBottom: theme.spacing(3),
  },
  borderBottom: {
    borderBottom: '1px solid #E3E3E3',
  },
  resize: {
    fontSize: 14,
  },
  statusButton: {
    padding: '6px 16px',
  },
  invoiceAmountText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 3,
  },
  dateRangeText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
}))

const DEFAULT_FILTER = {
  policyNumber: '',
  proposalNumber: '',
  number: '',
  startAmount: '',
  endAmount: '',
  startDueDate: '',
  endDueDate: '',
  status: '',
  clientName: '',
}

const InvoicesFilter = ({ addFilter, filters }) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const customerClient = useCustomerClient()
  const [clientRun, setClientRun] = useState()
  const { isBlank, getOnlyNumber } = useUtils()
  const [loading, setLoading] = useState(false)
  const [filterBox, setFilterBox] = useState(false)
  const { enableLoader, disableLoader } = useLoader()
  const [clientOptions, setClientOptions] = useState([])

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTER },
    validationSchema: Yup.object({
      status: Yup.string(),
      clientName: Yup.string(),
      number: Yup.number(),
      policyNumber: Yup.number(),
      proposalNumber: Yup.number(),
      startAmount: Yup.number(),
      endAmount: Yup.number().when('startAmount', (startAmount) => {
        let schema

        if (startAmount && startAmount !== 0) {
          schema = Yup.number().min(startAmount, 'O valor deve ser maior que o valor anterior').required()
        }
        return schema
      }),
      startDueDate: Yup.date(),
      endDueDate: Yup.date().when('startDueDate', (startDueDate) => startDueDate && Yup.date().min(startDueDate, 'A data final deve ser maior que a data inicial').required()),
    }),
    onSubmit: (data) => {
      let { startAmount, endAmount } = data

      if (startAmount === 0) {
        startAmount = ''
      }

      if (endAmount === 0) {
        endAmount = ''
      }

      setFilterBox(false)
      addFilter({ ...data, startAmount, endAmount })
    },
  })

  const { setValues } = formik

  useEffect(() => {
    setValues({ ...filters })
  }, [filters, setValues])

  const listBoxStyle = useMemo(() => {
    let style = { fontSize: 12 }
    const { clientName } = formik.values

    if (isEmpty(clientName)) {
      style = { ...style, display: 'none' }
    }

    return style
  }, [formik])

  const handleOnChangeInvoiceNumber = (event) => {
    const { value } = event.target
    const number = getOnlyNumber(value)
    formik.setFieldValue('number', number)
  }

  const handleOnChangeProposalNumber = (event) => {
    const { value } = event.target
    const proposalNumber = getOnlyNumber(value)
    formik.setFieldValue('proposalNumber', proposalNumber)
  }

  const handleClear = () => {
    addFilter({ ...DEFAULT_FILTER })
    formik.resetForm({ ...DEFAULT_FILTER })
  }

  const handleInputChangeClient = (event, value) => {
    setLoading(true)
    formik.setFieldValue('clientName', value)

    if (clientRun) {
      clientRun.current.cancel()
    }

    if (isEmpty(value)) {
      setLoading(false)
      setClientOptions([])
      return
    }

    const current = clientOptions.find((item) => item.name === value)

    if (!isEmpty(current)) {
      setLoading(false)
      return
    }

    const debounced = debounce(() => {
      disableLoader()

      customerClient().getCustomers(value).then((response) => {
        enableLoader()
        setLoading(false)
        setClientOptions([...response.data])
      }, (error) => {
        enableLoader()
        setLoading(false)
        setClientOptions([])
        addMsgDanger(error.data)
      })
    }, 1000)

    debounced()
    setClientRun({ current: debounced })
  }

  return (
    <>
      <Button title="Filtrar Faturas" className={classes.filterIcon}>
        <RiEqualizerLine onClick={() => setFilterBox(true)} />
      </Button>

      <FilterContainer
        open={filterBox}
        onClean={handleClear}
        onClose={() => setFilterBox(false)}
        onSearch={() => formik.submitForm()}
      >
        <Box mb={2}>
          <TextField
            id="number"
            label="Número da fatura"
            title="Número da fatura"
            color="secondary"
            onKeyUp={handleOnChangeInvoiceNumber}
            {...formik.getFieldProps('number')}
            error={formik.touched.number && !!formik.errors.number}
            helperText={formik.touched.number && formik.errors.number}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <TextField
            id="proposalNumber"
            label="Número da proposta"
            title="Número da proposta"
            color="secondary"
            onKeyUp={handleOnChangeProposalNumber}
            {...formik.getFieldProps('proposalNumber')}
            error={formik.touched.proposalNumber && !!formik.errors.proposalNumber}
            helperText={formik.touched.proposalNumber && formik.errors.proposalNumber}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <TextField
            id="policyNumber"
            label="Apólice"
            title="Apólice"
            color="secondary"
            {...formik.getFieldProps('policyNumber')}
            error={formik.touched.policyNumber && !!formik.errors.policyNumber}
            helperText={formik.touched.policyNumber && formik.errors.policyNumber}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <Autocomplete
            clearOnBlur
            handleHomeEndKeys
            loading={loading}
            options={clientOptions}
            getOptionLabel={(item) => item?.name || ''}
            ListboxProps={{ style: listBoxStyle }}
            value={{ name: formik.values.clientName }}
            getOptionSelected={(option, value) => option.name === value.name}
            onInputChange={handleInputChangeClient}
            loadingText="Carregando opções..."
            noOptionsText="Nenhuma opção correspondente"
            renderInput={(params) => (
              <TextField
                {...params}
                id="clientName"
                name="clientName"
                label="Cliente"
                title="Cliente"
                color="secondary"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>

        <FormControl className={classes.formcontrol} fullWidth>
          <Box my={1}>
            <FormLabel>Valor da Fatura:</FormLabel>
          </Box>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item lg={5} xs={12}>
              <CurrencyTextField
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="startAmount"
                title="Menor valor da Fatura"
                color="secondary"
                {...formik.getFieldProps('startAmount')}
                error={formik.touched.startAmount && !!formik.errors.startAmount}
                helperText={formik.touched.startAmount && formik.errors.startAmount}
                fullWidth
                onChange={(event, value) => {
                  if (value === 0) {
                    formik.setFieldValue('endAmount', '')
                  }

                  formik.setFieldValue('startAmount', value)
                }}
              />
            </Grid>
            <Grid item lg={2} xs={12} className={classes.invoiceAmountText}>
              <Typography component="label" htmlFor="endAmount">Até</Typography>
            </Grid>
            <Grid item lg={5} xs={12}>
              <CurrencyTextField
                fullWidth
                id="endAmount"
                title="Maior valor da Fatura"
                color="secondary"
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                disabled={!formik.values.startAmount}
                {...formik.getFieldProps('endAmount')}
                error={formik.touched.endAmount && !!formik.errors.endAmount}
                helperText={formik.touched.endAmount && formik.errors.endAmount}
                onChange={(event, value) => formik.setFieldValue('endAmount', value)}
              />
            </Grid>
          </Grid>
        </FormControl>
        <FormControl className={classes.formcontrol} fullWidth>
          <Box mb={2}>
            <FormLabel>Data de vencimento:</FormLabel>
          </Box>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item lg={5} xs={12}>
              <DateInput
                id="startDueDate"
                label="Data Inicial"
                title="Data Inicial"
                {...formik.getFieldProps('startDueDate')}
                error={formik.touched.startDueDate && !!formik.errors.startDueDate}
                helperText={formik.touched.startDueDate && formik.errors.startDueDate}
                onChange={(event) => {
                  const { value } = event.target

                  if (isBlank(value)) {
                    formik.setFieldValue('endDueDate', '')
                  }

                  formik.setFieldValue('startDueDate', value)
                }}
              />
            </Grid>
            <Grid item lg={2} xs={12} className={classes.dateRangeText}>
              <Typography component="label" htmlFor="endDueDate">Até</Typography>
            </Grid>
            <Grid item lg={5} xs={12}>
              <DateInput
                id="endDueDate"
                label="Data Final"
                title="Data Final"
                disabled={!formik.values.startDueDate}
                {...formik.getFieldProps('endDueDate')}
                error={formik.touched.endDueDate && !!formik.errors.endDueDate}
                helperText={formik.touched.endDueDate && formik.errors.endDueDate}
                onChange={(event) => {
                  const { value } = event.target
                  formik.setFieldValue('endDueDate', value)
                }}
              />
            </Grid>
          </Grid>
        </FormControl>

        <FormControl className={`${classes.formcontrol} ${classes.borderBottom}`} fullWidth>
          <FormLabel>Situação:</FormLabel>
          <RadioGroup
            row
            id="status"
            {...formik.getFieldProps('status')}
          >
            <FormControlLabel
              value="Paid"
              label="Pago"
              title="Pago"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="Expired"
              label="Vencido"
              title="Vencido"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="ToExpire"
              label="A Vencer"
              title="A Vencer"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
      </FilterContainer>
    </>
  )
}

InvoicesFilter.propTypes = {
  addFilter: PropTypes.func,
  filters: PropTypes.object,
}

InvoicesFilter.defaultProps = {
  filters: {},
  addFilter: () => { },
}

export default InvoicesFilter
