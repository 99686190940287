import React, { useEffect } from 'react'
import { BlipChat } from 'blip-chat-widget'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

const Blip = ({
  auth, appKey, account, styleButton,
}) => {
  useEffect(() => {
    const blipClient = new BlipChat()
    const { userIdentity, userPassword } = auth

    if (isEmpty(userIdentity) || isEmpty(userPassword)) {
      // eslint-disable-next-line no-console
      console.warn('The parameters "userIdentity" and "userPassword" are required.')
    } else {
      const { fullName, email } = account
      const { color = '#F26522', icon = '' } = styleButton

      blipClient.withAppKey(appKey)
        .withButton({
          color, icon,
        })
        .withAccount({
          fullName,
          email,
        })
        .withAuth({
          authType: BlipChat.DEV_AUTH,
          userIdentity,
          userPassword,
        })
        .withoutHistory()

      blipClient.build()
    }

    return () => {
      if (!isEmpty(userIdentity) && !isEmpty(userPassword)) {
        blipClient.destroy()
      }
    }
  }, [auth, appKey, account, styleButton])

  return (<> </>)
}

Blip.propTypes = {
  auth: PropTypes.object.isRequired,
  appKey: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired,
  styleButton: PropTypes.object.isRequired,
}

export default Blip
