import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  Box, Typography, Paper,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  text: {
    paddingTop: 20,
    height: '50px',
    fontWeight: 'bold',
    display: 'inline-table',
    '& span': {
      verticalAlign: 'middle',
      display: 'table-cell',
    },
  },
  ico: {
    marginBottom: 0,
  },
}))

const CardDemand = (props) => {
  const classes = useStyles()
  const {
    action, ico, text, sizeIco,
  } = props

  return (
    <>
      <Paper onClick={action}>
        <Box p={2} pb={0} textAlign="center">
          <Box className={classes.ico} mb={1}>
            <img src={ico} alt="" style={{ width: sizeIco }} />
          </Box>
          <Box className={classes.text}>
            <Typography variant="body2" component="span">{text}</Typography>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

CardDemand.propTypes = {
  ico: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  sizeIco: PropTypes.string.isRequired,
}

export default CardDemand
