import React from 'react'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  btn: {
    width: '75%',
  },
}))

const Unauthorized = () => {
  const classes = useStyles()

  return (
    <>
      <Box mb={6} mt={8} align="center">
        <Typography variant="h5">Acesso exclusivo para corretoras e corretores</Typography>
      </Box>

      <Box mb={8} align="center">
        <Typography variant="body2" align="center">Olá! Você não tem acesso ao nosso Portal. Caso seja uma</Typography>
        <Typography variant="body2" align="center">corretora ou corretor e precise de ajuda, entre em contato</Typography>
        <Typography variant="body2" align="center">
          <Box component="span" mr={1}>conosco em um dos nossos</Box>
          <Link
            title="Canais de Atendimento"
            href={window.ENV.POTTENCIAL_ATENDIMENTO}
          >
            canais de atendimento

          </Link>
          <Box component="span">.</Box>
        </Typography>
      </Box>

      <Box align="center">
        <Typography variant="body2" align="center">Já é cliente Pottencial?</Typography>
        <Typography variant="body2" align="center">Acesse nossos Portal do Cliente clicando abaixo:</Typography>

        <Box mt={3}>
          <Button
            fullWidth
            component="a"
            color="primary"
            variant="contained"
            className={classes.btn}
            href={window.ENV.CUSTOMER_URL}
            title="Login Portal do Cliente"
          >
            Login Portal do Cliente
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Unauthorized
