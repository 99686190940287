import React from 'react'
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  Paper,
} from '@material-ui/core'

import { useBroker } from './BrokerViewContext'

import AddressInfo from './AddressInfo'
import PersonBroker from './PersonBroker'
import CompanyBroker from './CompanyBroker'
import useUtils from '../../hooks/useUtils'
import { CPF_SIZE_WITHOUT_MASK } from '../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const BrokerInfo = () => {
  const classes = useStyles()
  const { broker } = useBroker()
  const { formatCPF, formatPhoneOrCellphone } = useUtils()

  const isBrokerPF = (data = {}) => {
    const { documentNumber = '' } = data
    return documentNumber.length === CPF_SIZE_WITHOUT_MASK
  }

  return (
    <div>
      {isBrokerPF(broker) && (<PersonBroker />)}
      {!isBrokerPF(broker) && (<CompanyBroker />)}

      <AddressInfo />

      {!isBrokerPF(broker) && (
        <div className={classes.center}>
          <Paper className={classes.root}>
            <Box px={4} pt={2} pb={5}>
              <Box mt={2} mb={5} spacing={2}>
                <Grid className={classes.icoDefaultContrast}>
                  <Typography component="span" color="primary" className={classes.title}>
                    RESPONSÁVEL PELO CADASTRO
                  </Typography>
                </Grid>
              </Box>
              <Grid container spacing={3}>
                <Grid item lg={7} sm={6} xs={12}>
                  <Box display="inline" mr={1}>
                    <Typography variant="subtitle2" component="span">Nome:</Typography>
                  </Box>
                  <Typography variant="body2" component="span">{broker?.brokerSponsor?.name}</Typography>
                </Grid>
                <Grid item lg={5} sm={6} xs={12}>
                  <Box display="inline" mr={1}>
                    <Typography variant="subtitle2" component="span">E-mail:</Typography>
                  </Box>
                  <Typography variant="body2" component="span">{broker?.brokerSponsor?.email}</Typography>
                </Grid>
                <Grid item lg={7} sm={6} xs={12}>
                  <Box display="inline" mr={1}>
                    <Typography variant="subtitle2" component="span">CPF:</Typography>
                  </Box>
                  <Typography variant="body2" component="span">{formatCPF(broker?.brokerSponsor?.document)}</Typography>
                </Grid>
                <Grid item lg={5} sm={6} xs={12}>
                  <Box display="inline" mr={1}>
                    <Typography variant="subtitle2" component="span">Telefone:</Typography>
                  </Box>
                  <Typography variant="body2" component="span">{formatPhoneOrCellphone(broker?.brokerSponsor?.phone)}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </div>
      )}

    </div>
  )
}

export default BrokerInfo
