import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'

import { useAlert } from '../../../../components'
import useUtils from '../../../../hooks/useUtils'
import useDownload from '../../../../hooks/useDownload'
import usePolicyClient from '../../../../clients/PolicyClient/usePolicyClient'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    paddingRight: 5,
  },
  borderDetails: {
    border: '1px solid lightgray',
    borderRadius: '6px',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

const PolicyDetails = ({ details, stopPolicy, renewPolicy }) => {
  const classes = useStyles()
  const { formatDate } = useUtils()
  const { downloadPDF } = useDownload()
  const { addMsgDanger } = useAlert()
  const policyClient = usePolicyClient()

  const hadleDraft = () => {
    const { proposalNumber } = details || ''
    policyClient().downloadDraft(proposalNumber).then((response) => {
      const { file } = response.data
      downloadPDF(file, `minuta_${proposalNumber}.pdf`)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  return (
    <>
      <Grid container>
        <Grid item sm={5} xs={12} className={classes.grid}>
          <Box display="flex" my={2}>
            <Typography variant="body2" className={classes.title}>
              Percentual de comissão:
            </Typography>
            <Typography variant="body2">
              {details?.brokerCommissions}
              %
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={7} xs={12} className={classes.grid}>
          <Box display="flex" my={2} ml={1}>
            <Typography variant="body2" className={classes.title}>
              Comissão:
            </Typography>
            <Typography variant="body2">
              {details?.commission && (
                <Currency quantity={details?.commission} currency="R$ " locale="pt_BR" decimal="," group="." />
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box p={2} mb={2} className={classes.borderDetails}>
        <Box mb={2} spacing={2}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography component="span" color="primary">
                Dados da apólice a ser endossado:
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid container justify="space-between" spacing={2}>
          <Grid item lg={5} sm={6} xs={12} className={classes.grid}>
            <Box display="flex" mb={1}>
              <Typography variant="body2" className={classes.title}>
                Proposta:
              </Typography>
              <Typography variant="body2">
                {details?.process}
              </Typography>
            </Box>

            <Box display="flex" mb={1}>
              <Typography variant="body2" className={classes.title}>
                Segurado:
              </Typography>
              <Typography variant="body2">
                {details?.insured}
              </Typography>
            </Box>

            <Box display="flex" mb={1}>
              <Typography variant="body2" className={classes.title}>
                Vigência:
              </Typography>
              <Typography variant="body2">
                {`De ${formatDate(details?.startPolicyPeriod)} até ${formatDate(details?.endPolicyPeriod)}`}
              </Typography>
            </Box>
          </Grid>

          <Grid item sm={6} xs={12} className={classes.grid}>
            <Box display="flex" mb={1}>
              <Typography variant="body2" className={classes.title}>
                Importância segurada:
              </Typography>
              <Typography variant="body2">
                {details?.is && (
                  <Currency quantity={details?.is || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                )}
              </Typography>
            </Box>

            <Box display="flex" mb={1}>
              <Typography variant="body2" className={classes.title}>
                Prêmio pago:
              </Typography>
              <Typography variant="body2">
                {details?.premium && (
                  <Currency quantity={details?.premium || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={1} alignItems="center" justify="flex-end">
        <Grid item>
          <Button
            onClick={stopPolicy}
            variant="text"
            color="primary"
            title="Interromper renovação"
          >
            Interromper renovação
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            title="Ver minuta"
            variant="outlined"
            onClick={hadleDraft}
          >
            Ver minuta
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={renewPolicy}
            color="primary"
            title="Confirmar renovação"
            variant="contained"
          >
            Confirmar renovação
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

PolicyDetails.propTypes = {
  details: PropTypes.object.isRequired,
  stopPolicy: PropTypes.func.isRequired,
  renewPolicy: PropTypes.func.isRequired,
}

export default PolicyDetails
