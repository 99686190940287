import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  useAlert,
  ModalInfo,
  CustomSwitch,
} from '../../../../components'
import ExportSwitch from './ExportSwitch'
import usePolicyClient from '../../../../clients/PolicyClient/usePolicyClient'
import useDownload from '../../../../hooks/useDownload'

const DEFAULT_EXPORT = {
  all: false,
  client: false,
  isUpdated: false,
  modality: false,
  newPremium: false,
  policy: false,
  newVigency: false,
  percentCommission: false,
  commissions: false,
  process: false,
  insured: false,
  is: false,
  premium: false,
  vigency: false,
}

const fields = [
  {
    name: 'client',
    text: 'Cliente',
  },
  {
    name: 'isUpdated',
    text: 'IS Atualizada',
  },
  {
    name: 'modality',
    text: 'Modalidade',
  },
  {
    name: 'policy',
    text: 'Apólice',
  },
  {
    name: 'newVigency',
    text: 'Nova vigência',
  },
  {
    name: 'commissions',
    text: 'Comissões',
  },
  {
    name: 'process',
    text: 'Proposta',
  },
  {
    name: 'insured',
    text: 'Segurado',
  },
  {
    name: 'is',
    text: 'Importância Segurada',
  },
  {
    name: 'premium',
    text: 'Prêmios',
  },
]

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 800,
    },
    '& .MuiRating-root': {
      minHeight: 110,
    },
  },
  filterIcon: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1 / 2),
    fontSize: '1.5rem',
    borderRadius: 4,
  },
  labelSwitch: {
    lineHeight: '1.20rem',
    marginLeft: 10,
  },
}))

const PolicyExport = ({ filters }) => {
  const classes = useStyles()
  const policyClient = usePolicyClient()
  const { downloadExcel } = useDownload()
  const { addMsgSuccess, addMsgDanger } = useAlert()

  const [open, setOpen] = useState(false)

  const formik = useFormik({
    initialValues: { ...DEFAULT_EXPORT },
    validationSchema: Yup.object({
      all: Yup.boolean(),
      client: Yup.boolean(),
      isUpdated: Yup.boolean(),
      modality: Yup.boolean(),
      policy: Yup.boolean(),
      newVigency: Yup.boolean(),
      percentCommission: Yup.boolean(),
      commissions: Yup.boolean(),
      process: Yup.boolean(),
      insured: Yup.boolean(),
      is: Yup.boolean(),
      premium: Yup.boolean(),
      vigency: Yup.boolean(),
    }),
    onSubmit: (data) => {
      const filter = { ...filters, query: data }

      policyClient().downloadExcel(filter).then((response) => {
        downloadExcel(response.data.excel, 'AcompanhamentoApolice.xlsx')
        addMsgSuccess('Exportação feita com sucesso.')
        setOpen(false)
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  const { setValues, setFieldValue } = formik

  const handleSelectAll = (value) => {
    setValues({
      all: value,
      client: value,
      isUpdated: value,
      modality: value,
      newPremium: value,
      policy: value,
      newVigency: value,
      percentCommission: value,
      commissions: value,
      process: value,
      insured: value,
      is: value,
      premium: value,
      vigency: value,
    })
  }

  const handleChange = (field, value) => {
    if (!value) {
      setFieldValue('all', value)
    }
    setFieldValue(field, value)
  }

  return (
    <>
      <Button className={classes.filterIcon}>
        <GetAppIcon color="primary" onClick={() => setOpen(true)} />
      </Button>

      <ModalInfo
        open={open}
        className={classes.modal}
        onClose={() => setOpen(false)}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <>
          <Box p={3}>
            <Box textAlign="center" mb={3}>
              <Typography component="h1" variant="h5">Selecione os dados a serem exibidos</Typography>
            </Box>

            <Box mb={4}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Box display="flex">
                    <CustomSwitch
                      {...formik.getFieldProps('all')}
                      checked={formik.values.all}
                      id="all"
                      label="Selecionar todos"
                      onChange={(event, value) => handleSelectAll(value)}
                    />
                    <Typography className={classes.labelSwitch} variant="subtitle1">
                      Selecionar todos
                    </Typography>
                  </Box>
                </Grid>

                {fields.map((item, index) => (
                  <React.Fragment key={index}>
                    <ExportSwitch formik={formik} handleChange={handleChange} field={item} />
                  </React.Fragment>
                ))}

              </Grid>
            </Box>

            <Grid container justify="center" spacing={2} className={classes.buttonsPad}>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  color="primary"
                  title="Cancelar"
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  title="Exportar"
                  color="primary"
                  variant="contained"
                  onClick={() => formik.submitForm()}
                >
                  Exportar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      </ModalInfo>
    </>
  )
}

PolicyExport.propTypes = {
  filters: PropTypes.object.isRequired,
}

export default PolicyExport
