import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import emitter from './EventSecurity'
import useSecurity from './useSecurity'
import { useSecurityAction } from '../store/ducks/security'

const Security = ({ onUserLoad: userLoad }) => {
  const { isLoggedIn, userManager } = useSecurity()
  const {
    setUser,
    setCredential,
    cleanUser,
    cleanDemand,
    cleanSinister,
    cleanCredential,
  } = useSecurityAction()

  const signinSilent = useCallback(() => {
    cleanUser()
    cleanDemand()
    cleanSinister()
    cleanCredential()
    userManager.signinSilent()
  }, [userManager, cleanUser, cleanDemand, cleanSinister, cleanCredential])

  useEffect(() => {
    userManager.events.addUserLoaded((data) => {
      setCredential(data)
    })

    userManager.events.addAccessTokenExpiring(() => {
      signinSilent()
    })

    userManager.events.addSilentRenewError(() => {
      signinSilent()
    })

    return () => {
      userManager.events.removeUserLoaded()
      userManager.events.removeSilentRenewError()
      userManager.events.removeAccessTokenExpiring()
    }
  }, [userManager, setCredential, signinSilent])

  /**
   * CAUTION
   *
   * This useEffect must be executed only once in the React lifecycle,
   * in sections where the page is updated. So the eslint rule has been deactivated,
   * please kindly do not do this in your implementation,
   * unless you have a strong reason for doing so.
   */

  useEffect(() => {
    const loader = (resolve) => userLoad().then((user) => {
      setUser(user)
      resolve()
    })

    emitter.on('security.reload', loader)

    return () => {
      emitter.removeListener('security.reload', loader)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoggedIn()) {
      userLoad().then((user) => setUser(user))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <></>
  )
}

Security.propTypes = {
  onUserLoad: PropTypes.func.isRequired,
}

export default Security
