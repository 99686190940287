import React, { forwardRef, useImperativeHandle } from 'react'
import {
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { merge, isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import InputAdornment from '@material-ui/core/InputAdornment'

import useYup from '../../hooks/useYup'
import { useRegister } from './RegisterContext'
import { CPFOrCNPJInput } from '../../components'
import useBrokerClient from '../../clients/BrokerClient'

const Broker = forwardRef((props, ref) => {
  const { cpfOrCnpj } = useYup()
  const brokerClient = useBrokerClient()

  const {
    broker,
    onNext,
    setBroker,
    addMsgError,
  } = useRegister()

  const formik = useFormik({
    initialValues: { ...broker },
    validationSchema: Yup.object({
      brokerName: Yup.string().max(200).required(),
      brokerDocument: cpfOrCnpj.required(),
    }),
    onSubmit: (data) => {
      brokerClient().checkRegister(data.brokerDocument).then(() => {
        const values = merge(broker, data)
        setBroker(values)
        onNext(values)
      }, (response) => {
        addMsgError(response.data)
      })
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: 'Verifique os campos em destaque antes de prosseguir.' }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  return (
    <>
      <FormControl>
        <CPFOrCNPJInput
          id="brokerDocument"
          color="secondary"
          title="CPF/CNPJ da corretora"
          label="CPF/CNPJ da corretora*:"
          {...formik.getFieldProps('brokerDocument')}
          error={formik.touched.brokerDocument && !!formik.errors.brokerDocument}
          helperText={formik.touched.brokerDocument && formik.errors.brokerDocument}
          fullWidth
          endAdornment={(
            <InputAdornment position="end">
              <HelpOutlineIcon
                data-html
                data-tip="Para cadastrar uma corretora forneça o CNPJ.<br /> Caso você seja um corretor e queira realizar<br /> o seu cadastro, forneça o seu CPF."
              />
            </InputAdornment>
            )}
        />
      </FormControl>
      <FormControl>
        <InputLabel
          color="secondary"
          error={formik.touched.brokerName && !!formik.errors.brokerName}
        >
          Nome/Razão Social*:
        </InputLabel>
        <Input
          id="brokerName"
          color="secondary"
          title="Nome/Razão Social"
          {...formik.getFieldProps('brokerName')}
          error={formik.touched.brokerName && !!formik.errors.brokerName}
          fullWidth
          endAdornment={(
            <InputAdornment position="end">
              <HelpOutlineIcon
                data-html
                data-tip="Para o cadastro da corretora informe a Razão Social.<br /> Caso seja um corretor e esteja realizando o seu<br /> cadastro, forneça o seu nome."
              />
            </InputAdornment>
            )}
        />
        <FormHelperText
          hidden={!formik.touched.brokerName || !formik.errors.brokerName}
          error={formik.touched.brokerName && !!formik.errors.brokerName}
        >
          {formik.errors.brokerName}
        </FormHelperText>
      </FormControl>

      <ReactTooltip place="top" type="dark" />
    </>
  )
})

export default Broker
