import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core'
import { useHistory, Link } from 'react-router-dom'
import SettingsIcon from '@material-ui/icons/Settings'

import UserMenu from './UserMenu'
import TermModal from '../TermModal'
import { useAlert } from '../../components'
import useSecurity from '../../security/useSecurity'
import useBrokerClient from '../../clients/BrokerClient'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      justifyContent: 'center',
      '& :nth-child(1)': {
        order: 2,
      },
      '& :nth-child(2)': {
        order: 3,
      },
      '& :nth-child(3)': {
        order: 1,
      },
    },
  },
  containerTheme: {
    [theme.breakpoints.up('md')]: {
      padding: '0 10px',
      margin: '0 10px',
      border: `solid ${theme.palette.primary.contrastText}`,
      borderWidth: '0 1px 0 0',
    },
  },
  btn: {
    color: theme.palette.primary.contrastText,
    fontSize: '1.05rem',
  },
}))

const HeaderMenu = ({ logout }) => {
  const termRef = useRef()
  const classes = useStyles()
  const history = useHistory()
  const { addMsgDanger } = useAlert()
  const brokerClient = useBrokerClient()

  const {
    onlyFunctionality,
    hasFunctionality,
    getContext,
  } = useSecurity()

  useEffect(() => {
    // TODO Solução para desabilitar o termo até que o endpoint seja ajustado.
    const disabled = true
    const { acceptedTerm } = getContext()

    if (!disabled && !acceptedTerm) {
      termRef.current.onOpen()
    }
  }, [getContext])

  return (
    <>
      <TermModal
        ref={termRef}
        showBack={false}
        onAccept={() => {
          brokerClient().acceptanceTerms().then(() => {
            history.replace('/reload')
          }, (response) => {
            addMsgDanger(response.data)
          })
        }}
      />

      <Grid
        container
        alignItems="center"
        justify="flex-end"
        className={classes.root}
      >
        {hasFunctionality('CADASTRO') && !onlyFunctionality('CADASTRO') && (
          <Grid item className={classes.containerTheme}>
            <Button
              to="/configuracoes"
              component={Link}
              aria-controls="simple-menu"
              className={`${classes.btn} btn-theme`}
            >
              <SettingsIcon fontSize="small" />
            </Button>
          </Grid>
        )}

        <Grid item xs md="auto">
          <UserMenu logout={logout} />
        </Grid>
      </Grid>
    </>
  )
}

HeaderMenu.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default HeaderMenu
