import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/core'

import {
  Loader,
  useLoader,
  AlertProvider,
} from './components'
import Routes from './Routes'
import Security from './security'
import { createTheme } from './theme'
import useSettings from './hooks/useSettings'
import useSecurity from './security/useSecurity'
import useUserClient from './clients/UserClient'
import { useContextAction } from './store/ducks/context'
import { useSecurityAction } from './store/ducks/security'

import './helpers/yup'
import './helpers/chartjs'
import './assets/style/global.scss'
import AxiosSetting from './helpers/axios'
import userBrokerClient from './clients/BrokerClient'

const history = createBrowserHistory()

const App = () => {
  const { signout } = useSecurity()
  const userClient = useUserClient()
  const brokerClient = userBrokerClient()
  const { setDemand } = useSecurityAction()
  const { setContexts } = useContextAction()
  const [loading, setLoading] = useState(false)
  const { enableLoader, disableLoader } = useLoader()

  const { getColor } = useSettings()
  const color = getColor()
  const theme = createTheme(color)

  const handleError = useMemo(() => ({
    401: () => {
      setTimeout(() => signout())
    },
    403: (data) => {
      const { config, data: messages = [] } = data

      // Case user unauthorized access by IP range.
      const codes = (messages || []).map((item) => item.code)

      if (codes.indexOf('02.98')) {
        history.replace('/restricted')
        return
      }

      // Case user unauthorized access userInfo.
      const { url = '' } = config

      if (url === '/api/auth/userInfo') {
        signout('/unauthorized')
        return
      }

      // Case user unauthorized access by roles.
      history.replace('/error/403')
    },
  }), [signout])

  const demand = (contextId) => {
    disableLoader()
    setDemand({ processing: true })

    brokerClient().getTokenDemand(contextId).then((response) => {
      const { token } = response.data
      setDemand({ token, success: true, processing: false })
      enableLoader()
    }, () => {
      setDemand({ success: false, processing: false })
      enableLoader()
    })
  }

  /**
   * CAUTION
   *
   * This useEffect must be executed only once in the React lifecycle,
   * in sections where the page is updated. So the eslint rule has been deactivated,
   * please kindly do not do this in your implementation,
   * unless you have a strong reason for doing so.
   */
  const handleUserLoad = useCallback(() => new Promise((resolve) => {
    disableLoader()

    Promise.all([
      userClient().getUserCurrent(),
      userClient().getUserContexts(),
    ]).then((responses) => {
      const { data: contexts } = responses[1]
      setContexts(contexts)

      const { data: user } = responses[0]
      resolve({ ...user })

      const { id: contextId } = user.context
      demand(contextId)

      enableLoader()
    }, (error) => {
      const { status = 500 } = error

      if (status !== 403) {
        history.replace(`/error/${status}`)
      }

      enableLoader()
    })
  }), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <AxiosSetting
        handleError={handleError}
        onStartRequest={() => setLoading(true)}
        onStopRequest={() => setLoading(false)}
      />
      <Loader show={loading} />
      <Security onUserLoad={handleUserLoad} />
      <ThemeProvider theme={theme}>
        <AlertProvider />
        <Router history={history}>
          <Routes />
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
