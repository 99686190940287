import { useCallback } from 'react'
import axios from 'axios'

const useSettingsClient = () => {
  const getColors = useCallback(() => axios.get('/api/settings/color'), [])

  const saveColor = useCallback((color) => axios.post('/api/settings/organization/color', color), [])

  return useCallback(() => ({
    getColors,
    saveColor,
  }), [
    getColors,
    saveColor,
  ])
}

export default useSettingsClient
