import { useCallback } from 'react'
import { truncate } from 'lodash'
import axios from 'axios'

import useUtils from '../../hooks/useUtils'
import { CONTEXT_EMPTY } from '../../constants'

const useUserClient = () => {
  const { isBlank } = useUtils()

  const getUserContexts = useCallback(() => new Promise((resolve, reject) => {
    axios.get('/api/users/context', { headers: { context_id: CONTEXT_EMPTY } }).then((response) => {
      const { data: contexts = [] } = response
      const data = contexts.map((context) => ({
        ...context,
        shortName: truncate(context.name, {
          length: 20,
        }),
      }))
      resolve({ ...response, data })
    }, (response) => reject(response))
  }), [])

  const getUserCurrent = useCallback(() => new Promise((resolve, reject) => {
    axios.get('/api/auth/userInfo', { headers: { context_id: CONTEXT_EMPTY } }).then((response) => {
      const { data: user = {} } = response
      const { context = {} } = user

      const data = {
        ...user,
        context: {
          ...context,
          shortName: truncate(context.name, {
            length: 20,
          }),
        },
      }

      resolve({ ...response, data })
    }, (response) => reject(response))
  }), [])

  const getUrlSinister = useCallback((contextId) => axios.get('/api/users/sinister', { headers: { context_id: contextId } }), [])

  const updateUserContext = useCallback((id) => axios.put(`/api/users/context/${id}`, {}, { headers: { context_id: CONTEXT_EMPTY } }), [])

  const deleteUser = useCallback((id) => axios.delete(`/api/users/${id}`), [])

  const requestPassword = useCallback((email) => axios.put('/api/users/RequestPassword/', { email, returnUrl: window.location.origin }), [])

  const toggleActiveUser = useCallback((id, action) => axios.put(`/api/users/active/${id}/${action}`, {
    isActive: action,
  }), [])

  const getFunctionality = useCallback(() => axios.get('/api/functionality'), [])

  const setFunctionality = useCallback((id, body) => axios.post(`/api/users/functionalities/${id}`, body), [])

  const getUsers = useCallback(({ pageNumber, orderBy, filter }) => {
    const params = new URLSearchParams({
      'Page.PageSize': 10,
      'Page.Direction': 0,
      'Page.OrderBy': orderBy,
      'Page.PageNumber': pageNumber,
      'Filter.Active': filter.active,
      'Filter.Administrator': filter.administrator,
    })

    if (!isBlank(filter.name)) {
      params.append('Filter.Name', `%${filter.name}%`)
    }

    if (!isBlank(filter.email)) {
      params.append('Filter.Email', `%${filter.email}%`)
    }

    if (!isBlank(filter.office)) {
      params.append('Filter.Office', `%${filter.office}%`)
    }

    return axios.get(`/api/users?${params}`)
  }, [isBlank])

  const getUserbyId = useCallback((id) => axios.get(`api/users/${id}`), [])

  const update = useCallback((id, body) => axios.put(`api/users/${id}`, body), [])

  const create = useCallback((user) => {
    const body = { ...user, returnUrl: window.location.origin }
    return axios.post('api/users/', body)
  }, [])

  const accessPermissionConsentYes = useCallback(() => axios.post('api/broker/pottencial-access-permission-consent', { consent: true }), [])

  return useCallback(() => ({
    getUsers,
    getUserbyId,
    getUserCurrent,
    getUserContexts,
    getUrlSinister,
    getFunctionality,
    setFunctionality,
    deleteUser,
    requestPassword,
    toggleActiveUser,
    updateUserContext,
    update,
    create,
    accessPermissionConsentYes,
  }), [
    getUsers,
    getUserbyId,
    getUserCurrent,
    getUserContexts,
    getUrlSinister,
    getFunctionality,
    setFunctionality,
    deleteUser,
    requestPassword,
    toggleActiveUser,
    updateUserContext,
    update,
    create,
    accessPermissionConsentYes,
  ])
}

export default useUserClient
