import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  Box,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ProgressBar } from '../../../../components'

const useStyles = makeStyles(() => ({
  progressSubTitle: {
    fontSize: 14,
    color: '#343434',
    fontWeight: 'bold',
  },
  policies: {
    maxWidth: 200,
    marginBottom: 14,
    marginLeft: 8,
    marginRight: 8,
    cursor: 'pointer',
    borderRadius: 14,
    padding: 10,
    '&:hover': {
      backgroundColor: 'rgb(109 105 105 / 8%)',
    },
  },
  policyActive: {
    backgroundColor: 'rgb(109 105 105 / 8%)',
  },
  textCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9,
    color: '#7B7B7B',
  },
  textNumber: {
    fontSize: 20,
    color: '#343434',
    fontWeight: 'bold',
  },
}))

const PolicyWrapper = ({
  search, count, param, item, backgroundColor,
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid
        item
        sm={4}
        lg={4}
        xs={12}
        onClick={() => search(count)}
        className={clsx({
          [classes.policies]: true,
          [classes.policyActive]: param === count,
        })}
      >
        <Box display="flex" justifyContent="center">
          <ProgressBar
            percentage={100}
            width={90}
            height={90}
            backgroundColor={backgroundColor}
          >
            <div className={classes.textCard}>
              <div className={classes.textNumber}>
                <strong>
                  {item.amountPolicy || 0}
                </strong>
              </div>
              <div>
                Apólices
              </div>
            </div>
          </ProgressBar>
        </Box>

        <Box textAlign="center">
          Serão renovadas em até
          <Typography
            variant="caption"
            component="p"
            className={classes.progressSubTitle}
          >
            {`${count} Dias`}
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

PolicyWrapper.propTypes = {
  search: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  param: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}

export default PolicyWrapper
