const adjustItems = (items, empty) => {
  const emptyOpt = {
    value: 'empty',
    text: 'Opções indisponíveis',
    selected: true,
    disabled: true,
  }
  const newOpt = {
    value: ' ',
    text: 'Selecione',
    selected: false,
  }

  // se o array de itens existir
  if (items) {
    const selectedObj = Boolean(items.find((item) => item.selected))
    const selecioneItem = items.findIndex((item) => item.value === ' ') === -1

    if (items.length === 0) {
      //  se o array de itens existir mas estiver vazio
      items.unshift(emptyOpt)
    } else if (!selectedObj) {
      // se o array de itens não tiver nenhum selecionado
      items.unshift({ ...newOpt, selected: true })
    } else if (selecioneItem && empty) {
      // se o array de itens tiver algum item selecionado e não tiver o Selecione
      items.unshift(newOpt)
    }
  } else {
    // se o array de itens não existir
    items = [emptyOpt]
  }

  return items
}

export default adjustItems
