import React, { useContext } from 'react'

const BrokerViewContext = React.createContext()

export const useBroker = () => {
  const {
    broker,
    setBroker,
    listBank,
    accountsType,
    setAccountsType,
  } = useContext(BrokerViewContext)

  return {
    broker,
    setBroker,
    listBank,
    accountsType,
    setAccountsType,
  }
}

export default BrokerViewContext
