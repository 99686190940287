import { useCallback } from 'react'
import axios from 'axios'

const useBrokerClient = () => {
  const getBrokerById = useCallback((id) => axios.get(`/api/broker/${id}`), [])

  const register = useCallback((broker) => axios.post('/api/broker', broker), [])

  const getAvatar = useCallback(() => axios.get('/api/broker/avatar'), [])

  const acceptanceTerms = useCallback(() => axios.post('/api/broker/acceptanceterms', { acceptedTerm: true }), [])

  const editBrokerPF = useCallback((broker) => axios.put(`/api/broker/editBrokerPF/${broker.id}`, broker), [])

  const editBrokerPJ = useCallback((broker) => axios.put(`/api/broker/editBrokerPJ/${broker.id}`, broker), [])

  const editSponsor = useCallback((sponsor, idPerson) => axios.put(`/api/broker/sponsor/${idPerson}`, sponsor), [])

  const editCommunicationChannels = useCallback((values) => axios.put(`/api/broker/${values.id}/communication-channels`, values), [])

  const editBrokerAddress = useCallback((brokerId, address) => axios.put(`/api/broker/${brokerId}/address`, address), [])

  const checkRegister = useCallback((document) => axios.get(`/api/broker/check/register/${document}`), [])

  const getHistoryChange = useCallback((id) => axios.get(`/api/broker/change/history/${id}`), [])

  const complete = useCallback((brokerId) => axios.put(`/api/broker/editBroker/complete/${brokerId}`), [])

  const getTokenDemand = useCallback((contextId) => axios.get('/api/warranty/token', { headers: { context_id: contextId } }), [])

  const editBrokerLogo = useCallback((data) => axios.post('/api/broker/avatar', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }), [])

  return useCallback(() => ({
    getAvatar,
    getBrokerById,
    checkRegister,
    editBrokerLogo,
    editBrokerPF,
    editBrokerPJ,
    editCommunicationChannels,
    editBrokerAddress,
    editSponsor,
    register,
    acceptanceTerms,
    getHistoryChange,
    complete,
    getTokenDemand,
  }), [
    getAvatar,
    getBrokerById,
    checkRegister,
    editBrokerLogo,
    editBrokerPF,
    editBrokerPJ,
    editCommunicationChannels,
    editBrokerAddress,
    editSponsor,
    register,
    acceptanceTerms,
    getHistoryChange,
    complete,
    getTokenDemand,
  ])
}

export default useBrokerClient
