import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box, Paper,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  iFrame: {
    minHeight: '600px',
    border: 'none',
  },
}))

const IFrameDemand = (props) => {
  const classes = useStyles()
  const { url, title, height } = props
  return (
    <>
      <Paper>
        <Box p={4}>
          <iframe
            className={classes.iFrame}
            src={url}
            height={height}
            width="100%"
            title={title}
          />
        </Box>
      </Paper>
    </>
  )
}

IFrameDemand.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
}

export default IFrameDemand
