import React,
{
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {
  Resolve,
  useAlert,
  Breadcrumb,
} from '../../components'
import BankInfo from './BankInfo'
import BrokerInfo from './BrokerInfo'
import BrokerViewContext from './BrokerViewContext'
import useSecurity from '../../security/useSecurity'
import useBankClient from '../../clients/BankClient'
import useBrokerClient from '../../clients/BrokerClient'

function TabPanel(props) {
  const {
    children, value, index, ...rest
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

TabPanel.defaultProps = {
  children: '',
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tab: {
    paddingBottom: 1,

    '& button': {
      maxWidth: '100%',
      fontSize: '1rem',
      textTransform: 'none',
      [theme.breakpoints.up('md')]: {
        minWidth: 175,
      },
    },
    '& .MuiTabs-scroller': {
      paddingBottom: 1,
    },
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #CECECE',
    },
    '& .MuiTabs-indicator': {
      height: 3,
    },
  },
}))

const PathInfo = [
  {
    text: 'Espaço Corretor',
    href: '/',
  },
  {
    text: 'Cadastro',
    href: '',
  },
]

const BrokerView = () => {
  const history = useHistory()
  const { Provider } = BrokerViewContext

  const { addMsgDanger } = useAlert()
  const { getContext, onlyFunctionality } = useSecurity()

  const classes = useStyles()
  const brokerClient = useBrokerClient()
  const bankClient = useBankClient()

  const [value, setValue] = useState(0)
  const [broker, setBroker] = useState({})
  const [accountsType, setAccountsType] = useState([])

  useEffect(() => {
    // Ensures that access occurs only when the broker has an complete registration.
    if (onlyFunctionality('CADASTRO')) {
      history.replace('/corretor/complemento')
    }
  }, [onlyFunctionality, history])

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue)
  }

  const handleResolve = useMemo(() => ({
    broker: () => new Promise((resolve, reject) => {
      const context = getContext()

      brokerClient().getBrokerById(context.personId).then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
    accountTypes: () => new Promise((resolve, reject) => {
      bankClient().getAccountTypes().then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [brokerClient, bankClient, addMsgDanger, getContext])

  const handleLoaded = useCallback((data, resolve) => {
    setBroker(data.broker)
    setAccountsType(data.accountTypes)
    resolve()
  }, [])

  return (
    <Provider
      value={{
        broker,
        setBroker,
        accountsType,
        setAccountsType,
      }}
    >
      <Resolve
        onLoaded={handleLoaded}
        resolve={handleResolve}
      >
        {/* CONTAINER PRINCIPAL */}
        <main>
          <Breadcrumb paths={PathInfo} />

          <Box mb={10}>
            <Typography component="h1" variant="h5">Configurar dados corretora</Typography>
          </Box>

          <div className={classes.root}>
            <Tabs
              value={value}
              onChange={handleChangeTabs}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Dados do usuário"
              className={classes.tab}
            >
              <Tab label="Cadastro" {...a11yProps(0)} />
              <Tab label="Dados bancários" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <BrokerInfo />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <BankInfo />
            </TabPanel>
          </div>
        </main>
      </Resolve>
    </Provider>
  )
}

export default BrokerView
