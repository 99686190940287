import React, { useState } from 'react'
import {
  Box,
  Tab,
  Tabs,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import Billets from './Billets'
import Invoices from './Invoices'
import AwardPaid from '../demands/AwardPaidDemand'
import { Breadcrumb } from '../../components'

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    paddingBottom: 1,

    '& button': {
      maxWidth: '100%',
      fontSize: '1rem',
      textTransform: 'none',
      [theme.breakpoints.up('md')]: {
        minWidth: 175,
      },
    },
    '& .MuiTabs-scroller': {
      paddingBottom: 1,
    },
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #CECECE',
    },
    '& .MuiTabs-indicator': {
      height: 3,
    },
  },
}))

const PATH_INFO_PREFIX = [
  {
    text: 'Painel',
    href: '/',
  },
  {
    text: 'Financeiro',
    href: '/financeiro',
  },
]

const Financial = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const getTabPathInfo = (tab) => {
    let text
    if (tab === 0) { text = 'Faturas' }
    if (tab === 1) { text = 'Boletos' }
    if (tab === 2) { text = 'Prêmios Quitados' }

    const pathInfoData = Array.from(PATH_INFO_PREFIX)
    pathInfoData.push({ text, href: '/financeiro' })
    return pathInfoData
  }

  const [pathInfo, setPathInfo] = useState(getTabPathInfo(0))

  const handleChangeTabs = (event, newValue) => {
    setPathInfo(getTabPathInfo(newValue))
    setValue(newValue)
  }

  return (
    <Box position="relative" component="main">
      <Breadcrumb paths={pathInfo} />
      <Box mb={4}>
        <Typography variant="h4">Financeiro</Typography>
      </Box>
      <div className={classes.root}>
        <div>
          <Tabs
            value={value}
            onChange={handleChangeTabs}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Financeiro"
            className={classes.tab}
          >
            <Tab label={(<Typography component="span">Faturas</Typography>)} {...a11yProps(0)} />
            <Tab label={(<Typography component="span">Boletos</Typography>)} {...a11yProps(1)} />
            <Tab label={(<Typography component="span">Prêmios Quitados</Typography>)} {...a11yProps(2)} />
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <Invoices />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Billets />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AwardPaid />
        </TabPanel>
      </div>
    </Box>
  )
}

export default Financial
