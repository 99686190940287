import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Grid,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { useAlert } from '../../components'

import useInvoiceClient from '../../clients/InvoicesClient/useInvoicesClient'
import useDownload from '../../hooks/useDownload'

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '215px',
    minHeight: '40px',
  },
}))

const InvoiceBarcode = ({
  handleCancel, billetId, billetNumber, ourNumberBillet,
}) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const [barcode, setBarcode] = useState()
  const invoiceClient = useInvoiceClient()
  const { downloadPDF } = useDownload()

  const getBarcode = useCallback(() => {
    invoiceClient().getBilletBarcode(billetId).then((response) => {
      setBarcode(response.data)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [invoiceClient, addMsgDanger, billetId])

  useEffect(() => {
    getBarcode()
  }, [getBarcode])

  const copyToClipboard = () => {
    const el = document.getElementById('barcodeRef')
    el.select()
    el.setSelectionRange(0, 99999)
    document.execCommand('copy')
    handleCancel()
  }

  const downdloadBillet = () => {
    invoiceClient().getDownloadBillet(ourNumberBillet).then((response) => {
      downloadPDF(response.data.file, `boleto_${billetNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <>
      { barcode && (
        <Grid>
          <input
            readOnly
            id="barcodeRef"
            value={barcode?.barcode || ''}
            style={{
              position: 'absolute', left: '-999999%',
            }}
          />

          <img src={`data:image/png;base64, ${barcode?.imgBarcode}`} alt="" width="100%" />

          <Typography style={{ textAlign: 'center' }}>
            {barcode?.barcode}
          </Typography>

          <Box pt={4}>
            <Grid container justify="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() => copyToClipboard()}
                className={classes.button}
                title="Copiar código de barras"
              >
                Copiar código de barras
              </Button>
            </Grid>
          </Box>
          <Box pt={2}>
            <Grid container justify="center">
              <Button
                color="primary"
                variant="contained"
                onClick={downdloadBillet}
                className={classes.button}
                title="Baixar Boleto"
              >
                Baixar Boleto
              </Button>
            </Grid>
          </Box>
          <Box pt={2} mb={2}>
            <Grid container justify="center">
              <Button
                color="primary"
                onClick={handleCancel}
                className={classes.button}
                title="Cancelar"
              >
                Cancelar
              </Button>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  )
}

InvoiceBarcode.propTypes = {
  billetId: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  billetNumber: PropTypes.number.isRequired,
  ourNumberBillet: PropTypes.string.isRequired,
}

export default InvoiceBarcode
