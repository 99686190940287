import React from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {
  Box,
  Grid,
  Button,
  TextField,
  makeStyles,
  Typography,
  FormHelperText,
} from '@material-ui/core'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'

import { useAlert, ModalInfo } from '../../components'
import useRatingClient from '../../clients/RatingClient'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 800,
    },
    '& .MuiRating-root': {
      minHeight: 110,
    },
  },
  action: {
    border: '2px solid #222',
  },
  colNps: {
    width: 46,
    height: 46,
    borderRadius: 5,
    color: '#ffffff',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
  },
  npsScore: {
    width: 40,
    height: 40,
    display: 'flex',
    fontSize: 14,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 0.8,
    },
  },
  npsScore0: {
    background: '#B72025',
  },
  npsScore1: {
    background: '#D62027',
  },
  npsScore2: {
    background: '#F05223',
  },
  npsScore3: {
    background: '#F36F21',
  },
  npsScore4: {
    background: '#FAA823',
  },
  npsScore5: {
    background: '#FFCA27',
  },
  npsScore6: {
    background: '#ECDB12',
  },
  npsScore7: {
    background: '#E8E73D',
  },
  npsScore8: {
    background: '#C5D92D',
  },
  npsScore9: {
    background: '#AFD136',
  },
  npsScore10: {
    background: '#64B64D',
  },
}))

const Rating = ({ value, onChange: change }) => {
  const classes = useStyles()

  const handleSelectScore = (newValue) => {
    const data = value !== newValue ? newValue : ''
    change(data)
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box
        onClick={() => handleSelectScore(0)}
        className={clsx({ [classes.action]: value === 0 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore0)}>0</Box>
      </Box>

      <Box
        onClick={() => handleSelectScore(1)}
        className={clsx({ [classes.action]: value === 1 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore1)}>1</Box>
      </Box>

      <Box
        onClick={() => handleSelectScore(2)}
        className={clsx({ [classes.action]: value === 2 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore2)}>2</Box>
      </Box>

      <Box
        m={0.1}
        onClick={() => handleSelectScore(3)}
        className={clsx({ [classes.action]: value === 3 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore3)}>3</Box>
      </Box>

      <Box
        onClick={() => handleSelectScore(4)}
        className={clsx({ [classes.action]: value === 4 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore4)}>4</Box>
      </Box>

      <Box
        onClick={() => handleSelectScore(5)}
        className={clsx({ [classes.action]: value === 5 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore5)}>5</Box>
      </Box>

      <Box
        onClick={() => handleSelectScore(6)}
        className={clsx({ [classes.action]: value === 6 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore6)}>6</Box>
      </Box>

      <Box
        onClick={() => handleSelectScore(7)}
        className={clsx({ [classes.action]: value === 7 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore7)}>7</Box>
      </Box>

      <Box
        component="span"
        onClick={() => handleSelectScore(8)}
        className={clsx({ [classes.action]: value === 8 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore8)}>8</Box>
      </Box>

      <Box
        onClick={() => handleSelectScore(9)}
        className={clsx({ [classes.action]: value === 9 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore9)}>9</Box>
      </Box>

      <Box
        onClick={() => handleSelectScore(10)}
        className={clsx({ [classes.action]: value === 10 }, classes.colNps)}
      >
        <Box component="span" className={clsx(classes.npsScore, classes.npsScore10)}>10</Box>
      </Box>
    </Box>
  )
}

Rating.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
}

const DEFAULT_RATING = {
  ratingNumber: '',
  ratingDesc: '',
}

const FeedBackModal = ({ open, setOpen }) => {
  const classes = useStyles()
  const ratingClient = useRatingClient()
  const { addMsgDanger, addMsgSuccess } = useAlert()

  const formik = useFormik({
    initialValues: { ...DEFAULT_RATING },
    validationSchema: Yup.object({
      ratingNumber: Yup.number().required(),
      ratingDesc: Yup.string(),
    }),
    onSubmit: (data) => {
      const values = {
        ...data,
        ratingNumber: data.ratingNumber - 1,
      }

      ratingClient().sendFeedBack(values).then(() => {
        setOpen(false)
        addMsgSuccess('Agradecemos por dedicar seu tempo e avaliar o Portal. Sua opinião é essencial para que possamos evoluir a cada dia.')
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  return (
    <>
      <ModalInfo
        open={open}
        className={classes.modal}
        onClose={() => setOpen(false)}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <>
          <Box p={3}>
            <Box textAlign="center" mb={3}>
              <Typography component="h1" variant="h5">Envie-nos seu feedback</Typography>
            </Box>

            <Box textAlign="center" mb={3}>
              <Typography variant="body1" component="span">
                Estamos tentando melhorar o que fazemos e sua opinião é
                muito importante para que possamos oferecer a melhor experiência.
              </Typography>
            </Box>

            <Box textAlign="center" mb={3}>
              <Typography variant="body1" component="span">
                <b>Está satisfeito com sua experiência no Portal?</b>
              </Typography>
            </Box>

            <Box component="fieldset" mb={3} textAlign="center" borderColor="transparent">
              <Rating
                value={formik.values.ratingNumber}
                onChange={(value) => formik.setFieldValue('ratingNumber', value)}
              />

              <FormHelperText
                style={{ textAlign: 'center' }}
                hidden={!formik.touched.ratingNumber || !formik.errors.ratingNumber}
                error={formik.touched.ratingNumber && !!formik.errors.ratingNumber}
              >
                {formik.errors.ratingNumber}
              </FormHelperText>
            </Box>

            <Box mb={2}>
              <TextField
                {...formik.getFieldProps('ratingDesc')}
                label="Conte-nos sobre sua experiência no Portal."
                variant="outlined"
                multiline
                fullWidth
                rows={6}
              />
            </Box>

            <Grid container justify="center" spacing={2} className={classes.buttonsPad}>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  color="primary"
                  title="Cancelar"
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  fullWidth
                  title="Salvar"
                  color="primary"
                  variant="contained"
                  onClick={() => formik.submitForm()}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      </ModalInfo>
    </>
  )
}

FeedBackModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default FeedBackModal
