import {
  Box,
  Avatar,
  Select,
  MenuItem,
  makeStyles,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useAlert } from '../components'
import useUserClient from '../clients/UserClient'
import useSecurity from '../security/useSecurity'

const useStyles = makeStyles((theme) => ({
  name: {
    color: theme.palette.primary.contrastText,
  },
  small: {
    width: 110,
    height: 110,
    [theme.breakpoints.down('sm')]: {
      width: 90,
      height: 90,
    },
  },
  normal: {
    width: 150,
    height: 150,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 120,
    },
  },
  thumb: {
    borderRadius: '50%',
    boxShadow: '0px 3px 10px #00000029',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    background: '#FFFFFF',

    '& img': {
      maxWidth: '40%',
      '&:not(.noThumb)': {
        maxWidth: '100%',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '&.hasConstrast': {
      boxShadow: '0 0 0 transparent',
    },
  },
  context: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.contrastText,
    fontWeight: theme.typography.h5.fontWeight,
    '& svg': {
      color: theme.palette.primary.contrastText,
    },
    '&::before': {
      border: 0,
    },
    '&:hover:before': {
      display: 'none',
    },
    '&:focus .MuiSelect-select': {
      background: 'transparent',
    },
  },
  editar: {
    fontSize: '.8rem',
    textDecoration: 'none',
  },
  hasConstrast: {
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
}))

const UserThumb = ({ size, constrast }) => {
  const classes = useStyles()
  const history = useHistory()
  const userClient = useUserClient()
  const { addMsgDanger } = useAlert()
  const [currentContext, setCurrentContext] = useState()
  const {
    hasFunctionality, onlyFunctionality, getURLAvatar,
  } = useSecurity()

  const { contexts = [] } = useSelector(({ context }) => ({
    contexts: context.contexts,
  }))

  useEffect(() => {
    const userContexts = contexts
    const data = userContexts.find((item) => item.main)
    setCurrentContext(data)
  }, [contexts])

  const handleChangeContext = (contextId) => {
    userClient().updateUserContext(contextId).then(() => {
      history.replace('/reload')
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <Box textAlign="center">
      <Box className={`${[classes[size]]} ${classes.thumb} ${constrast ? 'hasConstrast' : ''}`}>
        <Avatar
          src={getURLAvatar()}
          alt={currentContext?.shortName}
          className={classes.avatar}
        />
      </Box>

      <Box mt={2} mb={1}>
        {contexts.length === 1 && (
          <Typography component="h2" variant="h6" className={constrast ? classes.hasConstrast : ''}>
            {currentContext?.shortName}
          </Typography>
        )}

        {contexts.length > 1 && (
          <Select
            id="context"
            className={classes.context}
            value={currentContext?.id || ''}
            title={currentContext?.name || ''}
            onChange={(e) => {
              const { target } = e
              handleChangeContext(target.value)
            }}
          >
            {contexts.map((item) => (
              <MenuItem key={item.id} value={item.id}>{item.shortName}</MenuItem>
            ))}
          </Select>
        )}
      </Box>

      {onlyFunctionality('CADASTRO') && (
        <Typography
          to="/corretor/complemento"
          component={Link}
          className={`${classes.editar} ${constrast ? classes.hasConstrast : ''} `}
        >
          Completar Cadastro
        </Typography>
      )}

      {hasFunctionality('CADASTRO') && !onlyFunctionality('CADASTRO') && (
        <Typography
          to="/corretor/visualizar"
          component={Link}
          className={`${classes.editar} ${constrast ? classes.hasConstrast : ''} `}
        >
          Dados da corretora
        </Typography>
      )}
    </Box>
  )
}

UserThumb.propTypes = {
  constrast: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal']),
}

UserThumb.defaultProps = {
  constrast: false,
  size: 'normal',
}

export default UserThumb
