import { useCallback } from 'react'
import axios from 'axios'
import useUtils from '../../hooks/useUtils'

const useBilletClient = () => {
  const { isBlank } = useUtils()

  const getBillets = useCallback((filter) => {
    const page = {}

    const params = {
      product: isBlank(filter.product) ? 'Todos' : filter.product,
      billet: {
        status: isBlank(filter.status) ? 'Todos' : filter.status,
      },
    }

    if (!isBlank(filter.policyNumber)) {
      params.policyNumber = filter.policyNumber
    }

    if (!isBlank(filter.proposalNumber)) {
      params.proposalNumber = parseInt(filter.proposalNumber, 10)
    }

    if (!isBlank(`${filter.period || ''}`) && filter.status === 'AVencer') {
      params.period = filter.period
    }

    if (!isBlank(filter.clientName)) {
      params.clientName = filter.clientName
    }

    if (!isBlank(`${filter.startAmount || ''}`)) {
      params.billet.startAmount = filter.startAmount
    }

    if (!isBlank(`${filter.endAmount || ''}`)) {
      params.billet.endAmount = filter.endAmount
    }

    if (!isBlank(filter.startDueDate)) {
      params.billet.startDueDate = filter.startDueDate
    }

    if (!isBlank(filter.endDueDate)) {
      params.billet.endDueDate = filter.endDueDate
    }

    if (filter.page) {
      page.pageNumber = filter.page.pageNumber
      page.pageSize = filter.page.pageSize
    }

    return axios.post('/api/billet/billet', { filter: params, page })
  }, [isBlank])

  const getBilletSummary = useCallback(() => axios.get('/api/billet/billet/summary'), [])

  const getToExpireBilletSummary = useCallback((days) => axios.get(`/api/billet/billet/summary/to-expire?days=${days}`), [])

  const getInstallment = useCallback((billetId) => axios.post('/api/installment', billetId), [])

  const getBilletBarcode = useCallback((billetId) => axios.get(`/api/billet/billetbarcode?BilletId=${billetId}`), [])

  const sendBillet = useCallback((to, billet) => axios.post(`/api/billet/sendBillet?SendTo=${to}&OurNumberBillet=${billet}`), [])

  const downloadBillet = useCallback((billetNumber) => axios.get(`/api/billet/download/pdf?OurBilletNumber=${billetNumber}`), [])

  const downloadReceipt = useCallback((billetNumber) => axios.get(`/api/invoice/paidoffreceipt?BilletId=${billetNumber}`), [])

  const getNewValueBillet = useCallback((id, date) => axios.get(`/api/billet/billetSimulation?Id=${id}&NewDueDate=${date}`), [])

  const getBilletUpdate = useCallback((id, date) => axios.get(`/api/billet/billetUpdate?Id=${id}&NewDueDate=${date}`), [])

  return useCallback(() => ({
    getBillets,
    sendBillet,
    downloadBillet,
    getInstallment,
    downloadReceipt,
    getBilletUpdate,
    getBilletSummary,
    getBilletBarcode,
    getNewValueBillet,
    getToExpireBilletSummary,
  }), [
    getBillets,
    sendBillet,
    downloadBillet,
    getInstallment,
    downloadReceipt,
    getBilletUpdate,
    getBilletSummary,
    getBilletBarcode,
    getNewValueBillet,
    getToExpireBilletSummary,
  ])
}

export default useBilletClient
