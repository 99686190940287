import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'

import QuotationDenied from './QuotationDenied'
import QuotationSuccess from './QuotationSuccess'
import { useQuotationContext } from './QuotationContext'

const QuotationConfirmation = forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  const { quotation } = useQuotationContext()

  useImperativeHandle(ref, () => ({
    onOpen: () => new Promise((resolve) => {
      setShow(true)
      resolve()
    }),
    onSubmit: () => new Promise((resolve) => {
      resolve()
    }),
    onPrevious: () => { },
    onClose: () => setShow(false),
  }))

  return (
    <>
      {show && (
        <>
          {quotation?.automaticSubscription?.approved && (
            <QuotationSuccess />
          )}

          {!quotation?.automaticSubscription?.approved && (
            <QuotationDenied />
          )}
        </>
      )}
    </>
  )
})

export default QuotationConfirmation
