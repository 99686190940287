import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import useUtils from '../../hooks/useUtils'

const useStyles = makeStyles((theme) => ({

  itemContainer: {
    transition: theme.transitions.create(
      'all',
      { duration: theme.transitions.duration.complex },
    ),
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
    },
  },

  thumb: {
    width: '100%',
    height: 90,
    objectFit: 'cover',
  },
  date: {
    color: '#404040',
    fontSize: '.7rem',
    opacity: 0.56,
  },
  title: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '.75rem',
    },
  },
}))

const NewsItem = ({ page }) => {
  const classes = useStyles()
  const { formatDateNews } = useUtils()
  return (page.map((item, index) => (
    <Box key={index} className={classes.itemContainer} onClick={() => window.open(item.url, '_blank')} p={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={4} lg={5} xl={4}>
          <img className={classes.thumb} src={item.img} alt={item.title} />
        </Grid>
        <Grid item sm>
          <Typography component="h4" variant="subtitle2" className={classes.title}>{item.title}</Typography>
          <Typography className={classes.date}>{formatDateNews(item.date)}</Typography>
        </Grid>
      </Grid>
    </Box>
  )))
}

NewsItem.propTypes = {
  item: PropTypes.object,
}

NewsItem.defaultProps = {
  item: {},
}

export default NewsItem
