import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ProgressBar } from '../../../../components'

const useStyles = makeStyles(() => ({
  progressSubTitle: {
    fontSize: 14,
    color: '#343434',
    fontWeight: 'bold',
  },
  policies: {
    maxWidth: 200,
    marginBottom: 14,
    marginLeft: 8,
    marginRight: 8,
    padding: 10,
  },
  policyActive: {
    backgroundColor: 'rgb(109 105 105 / 8%)',
  },
  textCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 9,
    color: '#7B7B7B',
  },
  textNumber: {
    fontSize: 20,
    color: '#343434',
    fontWeight: 'bold',
  },
}))

const PolicyWrapper = ({ item, backgroundColor }) => {
  const classes = useStyles()

  return (
    <>
      <Grid
        item
        sm={4}
        lg={4}
        xs={12}
        className={classes.policies}
      >
        <Box display="flex" justifyContent="center">
          <ProgressBar
            percentage={100}
            width={90}
            height={90}
            backgroundColor={backgroundColor}
          >
            <div className={classes.textCard}>
              <div className={classes.textNumber}>
                <strong>
                  {item?.quantityPolicy || 0}
                </strong>
              </div>
              <div>
                Apólices
              </div>
            </div>
          </ProgressBar>
        </Box>

        <Box textAlign="center">
          Renovadas nos
          <Typography
            variant="caption"
            component="p"
            className={classes.progressSubTitle}
          >
            {`Últimos ${item?.days} dias`}
          </Typography>
        </Box>
      </Grid>
    </>
  )
}

PolicyWrapper.propTypes = {
  item: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}

export default PolicyWrapper
