import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  Box,
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import { ModalInfo } from '../components'

const useStyles = makeStyles(() => ({
  text: {
    background: '#FFF',
    overflow: 'auto',
    maxHeight: 480,
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 850,
    },
  },
  btn: {
    width: '100%',
  },
}))

const TermModal = forwardRef(({ onAccept, showBack }, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    onOpen: () => setOpen(true),
  }))

  return (
    <ModalInfo
      open={open}
      close={showBack}
      className={classes.modal}
      onClose={() => setOpen(false)}
    >
      <Box textAlign="center">
        <Typography variant="h5">Termos e condições</Typography>
        <Box px={3} mb={2} mt={5}>
          <Typography variant="body1">Prezado usuário, é muito importante a leitura atenta destes Termos no ato de seu cadastro, pois ao se cadastrar, você estará aceitando as nossas regras.</Typography>
        </Box>

        <Box className={classes.text} mb={5} p={3} textAlign="left">
          <Box mb={3}>
            <Typography variant="body2" align="justify">
              A Corretora, de maneira livre, informada e inequívoca consente e concorda
              que a empresa
              <b> POTTENCIAL SEGURADORA S.A. </b>
              , CNPJ nº 11.699.534/0001-74, com sede na Av. Raja Gabaglia,
              nº 1143, 20º andar, Luxemburgo, Belo Horizonte/MG, CEP:30.380-403, telefone
              (31) 2121-7777, e-mail:
              <Box component="span" ml={1}>
                <a href="mailto:juridico@pottencial.com.br">juridico@pottencial.com.br</a>
              </Box>
              , tome decisões e realize o
              tratamento dos dados pessoais informados para fins cadastrais e para efetuar
              o crédito de pagamento de comissões, através de depósito na conta corrente informada
              no complemento do cadastro. Outrossim, compromete-se a comunicar imediatamente à
              <b> POTTENCIAL SEGURADORA S.A. </b>
              qualquer alteração em seus dados bancários.
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="body2" align="justify">
              As Partes declaram e garantem que cumprirão todas às normas de proteção de dados,
              inclusive a Lei nº 13.709/2018 (Lei Geral de Proteção de Dados) e as políticas de
              privacidade da
              <b> POTTENCIAL SEGURADORA S.A. </b>
              disponíveis para consulta no link
              <Box component="span" ml={1}>
                <a
                  href="https://www.pottencial.com.br/transparenciaeprivacidade"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.pottencial.com.br/transparenciaeprivacidade
                </a>
              </Box>
              .
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography variant="body2" align="justify">
              A Corretora aceita e declara compreender que, em razão do peculiar ambiente da
              internet, a
              <b> POTTENCIAL SEGURADORA S.A. </b>
              não poderá garantir que o acesso ao Portal
              da Corretora seja livre de erros ou problemas decorrentes de casos fortuitos,
              internos ou externos, casos de força maior ou ainda de outros casos não
              inteiramente sujeitos ao controle direto da seguradora, e portanto,
              a corretara se obriga a isentar a
              <b> POTTENCIAL SEGURADORA  S.A. </b>
              de quaisquer reclamações referentes a descumprimento de prazo, interrupções,
              interceptações, invasões, disseminação de vírus ou outros atos ilícitos,
              típicos e atípicos do ambiente virtual, dos quais a seguradora não tenha
              tido intenção deliberada de participar ou praticar.
            </Typography>
          </Box>
        </Box>

        <Grid container justify="center" spacing={2}>
          { showBack && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Button
                color="primary"
                variant="outlined"
                className={classes.btn}
                onClick={() => setOpen(false)}
                title="Voltar"
              >
                Voltar
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              title="Li e Aceito"
              onClick={() => {
                onAccept()
                setOpen(false)
              }}
            >
              Li e Aceito
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalInfo>
  )
})

TermModal.propTypes = {
  showBack: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
}

TermModal.defaultProps = {
  showBack: true,
}

export default TermModal
