import React, { useState, useMemo, useCallback } from 'react'
import { Typography, Box, Paper } from '@material-ui/core'

import {
  Resolve,
  useAlert,
  Breadcrumb,
} from '../../components'
import HistoryCard from './HistoryCard'
import useSecurity from '../../security/useSecurity'
import useHistoryClient from '../../clients/HistoryClient'

const PathInfo = [
  {
    text: 'Meu Seguros',
    href: '/',
  },
  {
    text: 'Histórico de Atendimentos',
    href: '',
  },
]

const ServiceHistory = () => {
  const { getName } = useSecurity()
  const { addMsgDanger } = useAlert()
  const historyClient = useHistoryClient()
  const [results, setResults] = useState(1)
  const [historyList, setHistoryList] = useState([])
  const [openEmptyHistorys, setOpenEmptyHistorys] = useState(false)

  const [query, setQuery] = useState({
    pageNumber: 1,
  })

  const handleResolve = useMemo(() => ({
    history: () => new Promise((resolve, reject) => {
      historyClient().getHistoryCurrent(query).then((response) => {
        resolve(response.data)
      }, (response) => {
        const { status } = response

        if (status === 404) {
          resolve({})
          return
        }

        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [historyClient, addMsgDanger, query])

  const handleLoaded = useCallback((data, resolve) => {
    const { items = [], total = 0 } = data.history
    setHistoryList(items)
    setResults(total)
    resolve()

    const empty = items.length === 0
    setOpenEmptyHistorys(empty)
  }, [])

  const onSearch = useCallback((data) => {
    historyClient().getHistoryCurrent(data).then((response) => {
      setHistoryList(response.data.result.resource.items.sort((a, b) => {
        if (a.statusDate.split('/').reverse().join('/') > b.statusDate.split('/').reverse().join('/')) {
          return -1
        }

        if (a.statusDate.split('/').reverse().join('/') < b.statusDate.split('/').reverse().join('/')) {
          return 1
        }

        return 0
      }))
      setResults(response.data.total)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }, [historyClient, addMsgDanger])

  // ATUALIZAR PAGINAÇÃO
  const updatePage = (value) => {
    const data = { ...query, pageNumber: value }
    setQuery(data)
    onSearch(data)
  }

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <Box component="main">
        <Box mb={8}>
          <Breadcrumb paths={PathInfo} />
          <Typography variant="h5" component="h1">Histórico de Atendimentos</Typography>
        </Box>

        {openEmptyHistorys && (
        <Paper>
          <Box px={2} py={3}>
            <Box mb={2}>
              <Typography variant="subtitle2">
                <Box component="span" mr={1}>Olá</Box>
                <Box component="span">
                  {`${getName()}!`}
                </Box>
              </Typography>
            </Box>
            <Box>
              Não encontramos nenhuma solicitação de atendimento.
              Para iniciar uma conversa clique no ícone do WhatsApp
              ou do Chat no lado direito inferior da tela.
            </Box>
          </Box>
        </Paper>
        )}

        <HistoryCard
          historyList={historyList}
          updatePage={updatePage}
          results={results}
        />
      </Box>
    </Resolve>
  )
}

export default ServiceHistory
