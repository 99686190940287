import { useCallback } from 'react'

const useDownload = () => {
  const base64toBlob = useCallback((base64Data) => {
    const sliceSize = 1024
    const byteCharacters = atob(base64Data)
    const bytesLength = byteCharacters.length
    const slicesCount = Math.ceil(bytesLength / sliceSize)
    const byteArrays = new Array(slicesCount)

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize
      const end = Math.min(begin + sliceSize, bytesLength)

      const bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }
    return new Blob(byteArrays, { type: 'application/pdf' })
  }, [])

  const downloadPDF = useCallback((pdf, name) => {
    const link = document.createElement('a')
    const linkSource = `data:application/pdf;base64,${pdf}`
    link.download = name
    link.href = linkSource
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  const downloadExcel = useCallback((excel, name) => {
    const link = document.createElement('a')
    const linkSource = `data:application/vnd.ms-excel;base64,${excel}`
    link.download = name
    link.href = linkSource
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [])

  return { downloadPDF, base64toBlob, downloadExcel }
}

export default useDownload
