import React, { useState, useRef, useCallback } from 'react'
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemText,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import MoreIcon from '@material-ui/icons/MoreVert'

import { useAlert } from '../../components'
import useDownload from '../../hooks/useDownload'
import usePolicyClient from '../../clients/PolicyClient'

const InstallmentActions = ({ installment }) => {
  const buttonRef = useRef()
  const [open, setOpen] = useState(false)
  const policyClient = usePolicyClient()
  const { addMsgDanger } = useAlert()
  const { downloadPDF } = useDownload()

  const downloadPolicy = useCallback(() => {
    const { policyNumber, proposalNumber } = installment
    setOpen(false)

    policyClient().downloadPolicy(policyNumber, proposalNumber).then((response) => {
      downloadPDF(response.data.apoliceDsd, `apolice_${policyNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [policyClient, addMsgDanger, installment, downloadPDF])

  const downloadProposal = useCallback(() => {
    const { policyId } = installment
    setOpen(false)

    policyClient().downloadProposal({ policyId }).then((response) => {
      downloadPDF(response.data.proposalFile, `proposta_${installment.proposalNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [policyClient, addMsgDanger, installment, downloadPDF])

  return (
    <>
      <IconButton title="Ações" color="primary" ref={buttonRef} onClick={() => setOpen(true)}>
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={buttonRef.current}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setOpen(false)}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem title="Ver apólice">
          <ListItemText
            primary="Ver apólice"
            onClick={downloadPolicy}
          />
        </MenuItem>
        <MenuItem title="Ver proposta">
          <ListItemText
            primary="Ver proposta"
            onClick={downloadProposal}
          />
        </MenuItem>
      </Menu>
    </>
  )
}

InstallmentActions.propTypes = {
  installment: PropTypes.object.isRequired,
}

export default InstallmentActions
