import React, {
  useState, useImperativeHandle, useRef,
} from 'react'
import clsx from 'clsx'
import {
  Box,
  Grid,
  Button,
  TextField,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'

import {
  ModalInfo, useAlert, CEPInput,
} from '../../components'
import useYup from '../../hooks/useYup'
import useUtils from '../../hooks/useUtils'
import { useBroker } from './BrokerViewContext'
import useBrokerClient from '../../clients/BrokerClient'
import icoEdit from '../../assets/img/ico-edit-default.svg'
import useLocationClient from '../../clients/LocationClient'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 800,
    },
  },
  modalForm: {
    paddingTop: 20,
  },
  backgroundIcon: {
    backgroundColor: '#f25a0f5c',
  },
  buttonsPad: {
    padding: 20,
  },
}))

const DEFAULT_ADDRESS = {
  zipCode: '',
  address: '',
  streetNumber: '',
  addressComplement: '',
  neighborhood: '',
  state: '',
  city: '',
}

const AddressModal = () => {
  const ref = useRef()
  const { addMsgDanger, addMsgWarning, addMsgSuccess } = useAlert()
  const brokerClient = useBrokerClient()
  const { broker, setBroker } = useBroker()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const locationClient = useLocationClient()
  const utils = useUtils()

  const zipCodeInput = React.useRef()
  const streetInput = React.useRef()

  const {
    cep: cepRule,
  } = useYup()

  const formik = useFormik({
    initialValues: { ...DEFAULT_ADDRESS },
    validationSchema: Yup.object({
      zipCode: cepRule.required(),
      address: Yup.string().max(100).required(),
      streetNumber: Yup.string().max(50).required(),
      addressComplement: Yup.string().max(50),
      neighborhood: Yup.string().max(100).required(),
      state: Yup.string().max(50).required(),
      city: Yup.string().max(100).required(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (data) => {
      const values = {
        id: broker.idAddress,
        zipCode: data.zipCode,
        address: data.address,
        number: data.streetNumber,
        complement: data.addressComplement,
        district: data.neighborhood,
        state: data.state,
        city: data.city,
      }

      brokerClient().editBrokerAddress(broker.id, values).then((response) => {
        const receivedData = {
          zipCode: response.data.zipCode,
          address: response.data.street,
          streetNumber: response.data.number,
          addressComplement: response.data.complement,
          neighborhood: response.data.district,
          state: response.data.state,
          city: response.data.city,
        }

        setBroker({
          ...broker,
          ...receivedData,
        })

        setOpen(false)
        addMsgSuccess('Endereço atualizado com sucesso!')
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve, reject) => {
      formik.validateForm().then((errors) => {
        formik.submitForm()

        if (isEmpty(errors)) {
          resolve()
        } else {
          const error = new Error('Verifique os campos em destaque antes de prosseguir.')
          reject(error)
        }
      })
    }),
  }))

  const handleSubmit = () => {
    ref.current.onSubmit().then(() => {
      formik.submitForm()
    }, (error) => {
      addMsgWarning(error.message)
    })
  }

  const handleOpenModalEdit = () => {
    formik.setValues({
      zipCode: broker.zipCode || '',
      address: broker.address || '',
      streetNumber: broker.streetNumber || '',
      addressComplement: broker.addressComplement || '',
      neighborhood: broker.neighborhood || '',
      state: broker.state || '',
      city: broker.city || '',
    })

    setOpen(true)
  }

  const handleFindAddressByZipCode = async (event) => {
    const { value } = event.target
    const onlyNumber = utils.getOnlyNumber(value)

    const valid = await Yup.object().shape({ zipCode: cepRule.required() }).isValid({
      zipCode: value,
    })

    if (!valid) {
      formik.setValues({ ...DEFAULT_ADDRESS })
      setTimeout(() => zipCodeInput.current.focus())
      return
    }

    if (valid) {
      locationClient().getAddressByZipCode(onlyNumber).then((response) => {
        const {
          cep,
          logradouro,
          bairro,
          uf,
          cidade,
        } = response.data.correios

        formik.setValues({
          zipCode: utils.getOnlyNumber(cep) || '',
          address: logradouro || '',
          streetNumber: formik.getFieldProps('streetNumber').value || '',
          addressComplement: formik.getFieldProps('addressComplement').value || '',
          neighborhood: bairro || '',
          state: uf || '',
          city: cidade || '',
        })

        streetInput.current.focus()
      }, (response) => {
        if (response.status !== 404) {
          addMsgDanger(response.data)
        } else {
          addMsgDanger(response.data)
          formik.setFieldValue('zipCode', '')
          setTimeout(() => zipCodeInput.current.focus())
        }
      })
    }
  }

  return (
    <>
      <ModalInfo
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
      >
        <>
          <Box mb={5}>
            <Typography component="span" color="primary" className={classes.title}>ALTERAR ENDEREÇO</Typography>
          </Box>
          <form>
            <Grid container spacing={4} className={classes.modalForm}>
              <Grid item sm={6} xs={12}>
                <CEPInput
                  id="zipCode"
                  color="secondary"
                  label="CEP*:"
                  title="CEP"
                  InputProps={{ onBlur: handleFindAddressByZipCode, inputRef: zipCodeInput }}
                  {...formik.getFieldProps('zipCode')}
                  error={formik.touched.zipCode && !!formik.errors.zipCode}
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="address"
                  color="secondary"
                  label="Logradouro*:"
                  title="Logradouro"
                  inputRef={streetInput}
                  {...formik.getFieldProps('address')}
                  error={formik.touched.address && !!formik.errors.address}
                  helperText={formik.touched.address && formik.errors.address}
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                  id="streetNumber"
                  color="secondary"
                  label="Número*:"
                  title="Número"
                  {...formik.getFieldProps('streetNumber')}
                  error={formik.touched.streetNumber && !!formik.errors.streetNumber}
                  helperText={formik.touched.streetNumber && formik.errors.streetNumber}
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                  id="addressComplement"
                  color="secondary"
                  label="Complemento:"
                  title="Complemento"
                  {...formik.getFieldProps('addressComplement')}
                  error={formik.touched.addressComplement && !!formik.errors.addressComplement}
                  helperText={formik.touched.addressComplement && formik.errors.addressComplement}
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                  id="neighborhood"
                  color="secondary"
                  label="Bairro*:"
                  title="Bairro"
                  {...formik.getFieldProps('neighborhood')}
                  error={formik.touched.neighborhood && !!formik.errors.neighborhood}
                  helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <TextField
                  id="state"
                  color="secondary"
                  label="Estado*:"
                  title="Estado"
                  {...formik.getFieldProps('state')}
                  error={formik.touched.state && !!formik.errors.state}
                  helperText={formik.touched.state && formik.errors.state}
                  InputProps={{ disabled: true }}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  id="city"
                  color="secondary"
                  label="Cidade*:"
                  title="Cidade"
                  {...formik.getFieldProps('city')}
                  error={formik.touched.city && !!formik.errors.city}
                  helperText={formik.touched.city && formik.errors.city}
                  InputProps={{ disabled: true }}
                  fullWidth
                />
              </Grid>

              <Grid container justify="center" spacing={2} className={classes.buttonsPad}>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    color="primary"
                    title="Cancelar"
                    variant="outlined"
                    onClick={() => setOpen(false)}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    title="Salvar"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </>
      </ModalInfo>
      <IconButton
        size="small"
        color="primary"
        component="span"
        title="Editar dados"
        aria-label="Editar dados bancários"
        onClick={handleOpenModalEdit}
        className={clsx({ [classes.backgroundIcon]: open })}
      >
        <img src={icoEdit} alt="Editar dados" />
      </IconButton>
    </>
  )
}

export default AddressModal
