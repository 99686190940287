import React, {
  useCallback, useState, useEffect,
} from 'react'
import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
  Accordion,
  makeStyles,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Currency from 'react-currency-formatter'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useUtils from '../../hooks/useUtils'
import ListInstallment from './ListInstallment'
import { Paginator, useAlert } from '../../components'
import useInvoiceClient from '../../clients/InvoicesClient'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  gridFatura: {
    alignItems: 'start',
    paddingLeft: '28px !important',
  },
  expand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#BE431D',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '12px',
    marginTop: '10px',
  },
  statusContainer: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusMessage: {
    borderRadius: 16,
    textAlign: 'center',
    padding: 3,
    width: 92,
    [theme.breakpoints.down('md')]: {
      padding: 6,
      width: 120,
      height: 34,
    },
  },
  accordion: {
    borderRight: '6px solid',
  },
  statusPaidOut: {
    borderColor: '#6E937F',
  },
  statusOverdue: {
    borderColor: '#C6516D',
  },
  statusToWin: {
    borderColor: '#F7A57D',
  },
  containerInstallment: {
    border: '1px solid  #DEDEDE',
    padding: '20px 10px',
    borderRadius: '5px',
    width: '100%',
  },
  detailButton: {
    width: 82,
    height: 25,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 9,
    marginTop: 10,
    marginLeft: -16,
    [theme.breakpoints.down('md')]: {
      width: 128,
      height: 30,
      fontSize: 12,
    },
  },
  expandIcon: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },
  summary: {
    userSelect: 'inherit',
    cursor: 'text !important',
  },
}))

const InvoicesList = ({ filters }) => {
  const classes = useStyles()
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()
  const [total, setTotal] = useState(0)
  const invoiceClient = useInvoiceClient()
  const [invoices, setInvoices] = useState()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    invoiceClient().getInvoice(filters).then((response) => {
      setInvoices(response.data.data)
      setTotal(response.data.total)
    }, (error) => {
      addMsgDanger(error.data)
      setInvoices([])
      setTotal(0)
    })
  }, [invoiceClient, addMsgDanger, filters])

  const nextPage = useCallback((value) => {
    setExpanded('')

    const values = {
      ...filters,
      page: { pageNumber: value, pageSize: 10 },
    }

    invoiceClient().getInvoice(values).then((response) => {
      setInvoices(response.data.data)
      setTotal(response.data.total)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [invoiceClient, addMsgDanger, filters])

  const handleAccordion = (panel) => {
    let value = ''

    if (panel !== expanded) {
      value = panel
    }
    setExpanded(value)
  }

  const getStatus = (status) => {
    if (status === 'Paid') return 'Pago'
    if (status === 'Expired') return 'Vencida'
    return 'A Vencer'
  }

  const getStatusClassName = (status) => {
    if (status === 'Paid') return classes.statusPaidOut
    if (status === 'ToExpire') return classes.statusToWin
    if (status === 'Expired') return classes.statusOverdue
    return classes.statusToWin
  }

  useEffect(() => {
    setExpanded(false)
  }, [invoices])

  return (
    <>
      { invoices && invoices.length === 0 && (
        <Paper>
          <Box px={2} py={3} mt={2}>Não foi possível encontrar registros para esta pesquisa.</Box>
        </Paper>
      )}

      { invoices && invoices.map((item, index) => (
        <Box mt={2} mb={2} key={index}>
          <Accordion
            expanded={expanded === `${index}`}
            className={`${classes.accordion} ${getStatusClassName(item.status)}`}
          >
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className={classes.summary}>
              <Grid
                container
                spacing={3}
                justify="space-between"
                data-tip={getStatus(item.status)}
              >
                <Grid
                  item
                  lg={1}
                  sm={12}
                  xs={12}
                  className={`${classes.grid} ${classes.gridFatura}`}
                >
                  <Typography variant="body2" className={classes.title}>Fatura:</Typography>
                  <Typography variant="body2">
                    {item.invoiceNumber}
                  </Typography>
                </Grid>
                <Grid item lg={5} sm={12} xs={12} className={classes.grid}>
                  <Typography variant="body2" className={classes.title}>Cliente:</Typography>
                  <Typography variant="body2" style={{ textAlign: 'center' }}>
                    {item.clientName}
                  </Typography>
                </Grid>
                <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                  <Typography variant="body2" className={classes.title}>Vencimento:</Typography>
                  <Typography variant="body2">{formatDate(item.dueDate)}</Typography>
                </Grid>
                <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                  <Typography variant="body2" className={classes.title}>Valor:</Typography>
                  <Typography variant="body2">
                    <Currency quantity={item.amount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                  </Typography>
                </Grid>
                <Grid item lg={2} xs={12} className={classes.expand}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.detailButton}
                    onClick={() => handleAccordion(`${index}`)}
                    title="Detalhar"
                  >
                    Detalhar
                    {expanded !== `${index}` && <ExpandMoreIcon className={classes.expandIcon} />}
                    {expanded === `${index}` && <ExpandLessIcon className={classes.expandIcon} />}
                  </Button>
                </Grid>
              </Grid>

              <ReactTooltip
                type="dark"
                place="top"
              />
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: 'column' }}>
              {expanded === `${index}` && (
                <ListInstallment
                  invoice={item.invoiceNumber}
                  invoiceId={item.invoiceId}
                  status={item.status}
                  amount={item.amount}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}

      <Paginator totalResults={total} changePaginator={nextPage} />
    </>
  )
}

InvoicesList.propTypes = {
  filters: PropTypes.object.isRequired,
}

export default InvoicesList
