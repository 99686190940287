import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Main from './Main'
import Footer from './Footer'
import Header from './header/Header'
import {
  Blip,
  WhatsBtn,
} from '../components'
import useSecurity from '../security/useSecurity'
import IFrameAuth from '../pages/demands/IFrameAuth'

const Master = ({ children }) => {
  const {
    signout,
    getName,
    getUsername,
    getPersonId,
  } = useSecurity()
  const [logoClient] = useState(null)
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  const sair = () => signout()

  return (
    <>
      <IFrameAuth />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        logoClient={logoClient}
        logout={sair}
      />
      <Main
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        logout={sair}
      >
        {children}
      </Main>
      <Footer />

      <WhatsBtn />
      <Blip
        appKey={window.ENV.BLIP_KEY}
        auth={{
          userIdentity: getPersonId(),
          userPassword: getPersonId(),
        }}
        account={{
          fullName: getName(),
          email: getUsername(),
        }}
        styleButton={{
          color: '#F26522',
        }}
      />
    </>
  )
}

Master.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Master
