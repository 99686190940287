import React, { useState, useMemo, useCallback } from 'react'
import {
  Box,
  Grid,
  Paper,
  makeStyles,
  Typography,
  Hidden,
} from '@material-ui/core'

import {
  Resolve,
  useAlert,
} from '../../components'

import UploadInput from '../../components/UploadInput/UploadInput'
import useUtils from '../../hooks/useUtils'
import PopOverHistory from './PopOverHistory'
import CommunicationsModal from './CommunicationsModal'
import { useBroker } from './BrokerViewContext'
import useBrokerClient from '../../clients/BrokerClient'
import useSecurity from '../../security/useSecurity'
import { useSecurityAction } from '../../store/ducks/security'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: '100px',
    height: '100px',
  },
  avatarButton: {
    width: '100px',
    height: '100px',
    borderRadius: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  overlay: {
    transition: 'opacity .2s',
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    },
  },
  uploadIcon: {
    backgroundColor: 'rgba(32, 33, 36, 0.6)',
    height: '40px',
    paddingTop: '4px',
    textAlign: 'center',
  },
}))

const PersonBroker = () => {
  const {
    formatCPF,
    formatSUSEP,
    formatPhoneOrCellphone,
  } = useUtils()
  const { addMsgDanger } = useAlert()
  const brokerClient = useBrokerClient()
  const [avatar, setAvatar] = useState('')
  const { broker } = useBroker()
  const { getUser } = useSecurity()
  const { setUser } = useSecurityAction()

  const classes = useStyles()

  const handleResolve = useMemo(() => ({
    avatar: () => new Promise((resolve, reject) => {
      brokerClient().getAvatar().then((response) => {
        resolve(response.data)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
  }), [brokerClient, addMsgDanger])

  const handleLoaded = useCallback((data, resolve) => {
    setAvatar(data.avatar.path)
    resolve()
  }, [])

  const handleUploadPicture = (event) => {
    const { value: file } = event.target
    const { type } = file || {}

    if (!type) {
      return
    }

    if (type !== 'image/jpeg' && type !== 'image/png') {
      addMsgDanger('Extensão de arquivo não permitida. Favor inserir uma imagem com extensão JPG ou PNG.')
      return
    }

    const data = new FormData()
    data.append('file', file)

    brokerClient().editBrokerLogo(data).then((response) => {
      const user = getUser()

      setUser({
        ...user,
        context: {
          ...user.context,
          avatar: { ...response.data },
        },
      })

      setAvatar(`${response.data.path}?v=${Date.now()}`)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <div className={classes.center}>
        <Paper className={classes.root}>
          <Box px={4} pt={3} pb={5}>
            <Box mb={5} spacing={2}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography component="span" color="primary" className={classes.title}>
                    CORRETOR
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="span">
                    <PopOverHistory />
                  </Typography>
                  <Typography component="span">
                    <CommunicationsModal />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={3}>
              <Grid item lg={7} md={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid item md="auto" xs={12}>
                    <UploadInput
                      id="uploadAvatar"
                      nameProfile={broker.name}
                      urlProfile={avatar || ''}
                      onChange={handleUploadPicture}
                    />
                  </Grid>
                  <Grid item md={7} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">{broker.name}</Typography>
                      </Grid>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Box component="span" mr={1}>
                          <Typography variant="subtitle2" component="span">E-mail:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{broker.email}</Typography>
                      </Grid>
                      <Hidden lgUp>
                        <Grid item lg={12} sm={12} xs={12}>
                          <Box component="span" mr={1}>
                            <Typography variant="subtitle2" component="span">CPF:</Typography>
                          </Box>
                          <Typography variant="body2" component="span">{formatCPF(broker.documentNumber)}</Typography>
                        </Grid>
                      </Hidden>
                      <Grid item lg={12} sm={12} xs={12}>
                        <Box component="span" mr={1}>
                          <Typography variant="subtitle2" component="span">SUSEP:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{formatSUSEP(broker.susepNumber)}</Typography>
                      </Grid>
                      <Hidden lgUp>
                        <Grid item lg={12} sm={12} xs={12}>
                          <Box component="span" mr={1}>
                            <Typography variant="subtitle2" component="span">Telefone:</Typography>
                          </Box>
                          <Typography variant="body2" component="span">{formatPhoneOrCellphone(broker.phone)}</Typography>
                        </Grid>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden mdDown>
                <Grid item lg={5} md={6} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box mt={6}>
                        <Box display="inline" mr={1}>
                          <Typography variant="subtitle2" component="span">CPF:</Typography>
                        </Box>
                        <Typography variant="body2" component="span">{formatCPF(broker.documentNumber)}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="inline" mr={1}>
                        <Typography variant="subtitle2" component="span">Telefone:</Typography>
                      </Box>
                      <Typography variant="body2" component="span">{formatPhoneOrCellphone(broker.phone)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
        </Paper>
      </div>
    </Resolve>
  )
}

export default PersonBroker
