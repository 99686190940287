import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Grid,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { useAlert } from '../../components'
import useBilletClient from '../../clients/BilletClient/useBilletClient'

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '215px',
    minHeight: '40px',
  },
}))

const BilletBarcode = ({ handleBack, billetId }) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const billetClient = useBilletClient()
  const [barcode, setBarcode] = useState()

  const getBarcode = useCallback(() => {
    billetClient().getBilletBarcode(billetId).then((response) => {
      setBarcode(response.data)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [addMsgDanger, billetClient, billetId])

  useEffect(() => {
    getBarcode()
  }, [getBarcode])

  const copyToClipboard = () => {
    const el = document.getElementById('barcodeRef')
    el.select()
    el.setSelectionRange(0, 99999)
    document.execCommand('copy')
    handleBack()
  }

  return (
    <Grid>
      <input
        style={{
          position: 'absolute', left: '-999999%',
        }}
        id="barcodeRef"
        value={barcode?.barcode}
      />
      <img src={`data:image/png;base64, ${barcode?.imgBarcode}`} alt="" width="100%" />
      <Typography style={{ textAlign: 'center' }}>
        {barcode?.barcode}
      </Typography>
      <Box pt={4}>
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleBack}
              className={classes.button}
              title="Voltar"
            >
              Voltar
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => copyToClipboard()}
              className={classes.button}
              title="Copiar código de barras"
            >
              Copiar código de barras
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

BilletBarcode.propTypes = {
  handleBack: PropTypes.func.isRequired,
  billetId: PropTypes.string.isRequired,
}

export default BilletBarcode
