import React, { useEffect } from 'react'
import { SlashScreen } from '../../components'
import useSecurity from '../../security/useSecurity'

const Signin = () => {
  const { signin } = useSecurity()

  useEffect(() => {
    signin()
  }, [signin])

  return <SlashScreen />
}

export default Signin
