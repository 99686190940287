import React, { useState, useMemo, useCallback } from 'react'
import {
  Box,
  Grid,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { first, head } from 'lodash'
import PropTypes from 'prop-types'
import Currency from 'react-currency-formatter'

import BilletEmail from './BilletEmail'
import SecondBillet from './SecondBillet'
import BilletActions from './BilletActions'
import BilletBarcode from './BilletBarcode'
import useUtils from '../../hooks/useUtils'
import useDownload from '../../hooks/useDownload'
import IcoPrint from '../../assets/img/ico-print.svg'
import IcoPDF from '../../assets/img/ico-file-pdf.svg'
import IcoBarcode from '../../assets/img/ico-barcode.svg'
import IcoSendEmail from '../../assets/img/ico-send-email.svg'
import { MessageBox, Resolve, useAlert } from '../../components'
import useBilletClient from '../../clients/BilletClient/useBilletClient'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    [theme.breakpoints.up('lg')]: {
      marginBottom: '20px',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px',
    },
  },
  expand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#BE431D',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '12px',
    marginTop: '10px',
  },
  containerInstallment: {
    border: '1px solid  #DEDEDE',
    padding: '20px 10px',
    borderRadius: '5px',
    width: '100%',
  },
  invoiceLabel: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  invoiceBox: {
    border: '1px solid #DEDEDE',
    padding: '20px 12px',
    borderLeft: '0px',
    borderRight: '0px',
  },
  icones: {
    width: '14px',
    cursor: 'pointer',
  },
  textCenter: {
    textAlign: 'center',
  },
}))

const BilletInstallment = ({
  billetId,
  billetStatus,
  clientName,
  reprocessEnabled,
  onUpdate: update,
}) => {
  const classes = useStyles()
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()
  const { downloadPDF } = useDownload()
  const billetClient = useBilletClient()
  const [installment, setInstallment] = useState({})

  const [openModalEmail, setOpenModalEmail] = useState(false)
  const [openModalBarcode, setOpenModalBarcode] = useState(false)
  const [openModalSecondBillet, setOpenModalSecondBillet] = useState(false)
  const [openModalExtendBillet, setOpenModalExtendBillet] = useState(false)

  const billet = useMemo(() => {
    const { billets } = installment || {}
    return head(billets || [])
  }, [installment])

  const print = (pdf) => {
    const winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,'
      + 'resizable,screenX=50,screenY=50,width=850,height=1050'

    const htmlPop = `${'<embed width=100% height=100%'
      + ' type="application/pdf"'
      + ' src="data:application/pdf;base64,'}${escape(pdf)}"></embed>`

    const printWindow = window.open('', 'PDF', winparams)
    printWindow.document.write(htmlPop)
    printWindow.print()
  }

  const handleLoadedInstallment = useCallback((data, resolve) => {
    const installmentData = first(data.installment.installments)
    setInstallment(installmentData)
    resolve()
  }, [])

  const handleResolveInstallment = useMemo(() => ({
    installment: () => new Promise((resolve, reject) => {
      billetClient().getInstallment({ billetId }).then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [billetClient, addMsgDanger, billetId])

  const handleModalBilletSave = () => {
    const { ourNumberBillet } = billet

    billetClient().downloadBillet(ourNumberBillet).then((response) => {
      downloadPDF(response.data.file, `boleto_${ourNumberBillet}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const printBillet = () => {
    const { ourNumberBillet } = billet

    billetClient().downloadBillet(ourNumberBillet).then((response) => {
      print(response.data.file)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const handleModalReceiptSave = () => {
    billetClient().downloadReceipt(billet.billetId).then((response) => {
      downloadPDF(response.data.file, `comprovante_de_quitação_boleto_${billet.documentNumber}.pdf`)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <Resolve
      onLoaded={handleLoadedInstallment}
      resolve={handleResolveInstallment}
    >
      {installment && (
        <>
          {billet && (
            <>
              <MessageBox
                maxWidth="md"
                opened={openModalBarcode}
                title="Código de barras"
                handleClose={() => setOpenModalBarcode(false)}
              >
                <BilletBarcode
                  billetId={billet.billetId}
                  handleBack={() => setOpenModalBarcode(false)}
                />
              </MessageBox>

              <MessageBox
                opened={openModalEmail}
                title="Enviar por e-mail"
                text="Insira o email que deseja enviar:"
                handleClose={() => setOpenModalEmail(false)}
              >
                <BilletEmail
                  billet={billet}
                  handleCancel={() => setOpenModalEmail(false)}
                />
              </MessageBox>

              <MessageBox
                maxWidth="md"
                opened={openModalSecondBillet}
                handleClose={() => setOpenModalSecondBillet(false)}
                title="Gerar segunda via"
              >
                <SecondBillet
                  billet={billet}
                  clientName={clientName}
                  installment={installment}
                  successMessage="Segunda via gerada com sucesso!"
                  handleClose={() => {
                    update()
                    setOpenModalSecondBillet(false)
                  }}
                />
              </MessageBox>

              <MessageBox
                maxWidth="md"
                title="Prorrogar boleto"
                opened={openModalExtendBillet}
                handleClose={() => setOpenModalExtendBillet(false)}
              >
                <SecondBillet
                  billet={billet}
                  clientName={clientName}
                  installment={installment}
                  successMessage="Boleto prorrogado com sucesso!"
                  handleClose={() => {
                    update()
                    setOpenModalExtendBillet(false)
                  }}
                />
              </MessageBox>
            </>
          )}

          <Box mt={2} mb={1} className={classes.containerInstallment}>
            <Grid container justify="space-between" alignItems="baseline">
              <Grid item lg={1} sm={12} xs={12} className={classes.grid}>
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item>
                    <Typography variant="body2" className={classes.title}>Parcela</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                      {`${installment.number}/${installment.installmentsCount}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={3} sm={12} xs={12} className={classes.grid}>
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item>
                    <Typography variant="body2" className={classes.title}>Apólice</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{installment.policyNumber}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item>
                    <Typography variant="body2" className={classes.title}>Proposta</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{installment.proposalNumber}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item>
                    <Typography variant="body2" className={classes.title}>Segurado</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={classes.textCenter}>{installment.insurerName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} sm={12} xs={12} className={classes.grid}>
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item>
                    <Typography variant="body2" className={classes.title}>Data da Apólice</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{formatDate(installment.policyDate)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={1} sm={12} xs={12} className={classes.grid}>
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item>
                    <Typography variant="body2" className={classes.title}>Valor</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      <Currency quantity={installment?.installmentAmount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={1} sm={12} xs={12}>
                <Grid container direction="column" alignItems="center" justify="center">
                  <Grid item>
                    <Typography variant="body2" className={classes.title}>&nbsp;</Typography>
                  </Grid>
                  <Grid item>
                    <BilletActions installment={installment} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Box mt={4} mb={2} className={classes.invoiceBox}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="body2" className={classes.invoiceLabel}>Valor do Boleto:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={classes.invoiceLabel} style={{ color: '#5D20AE' }}>
                    <Currency quantity={billet?.amount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {billetStatus === 'AVencer' && (
              <Box mt={3} mb={1}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Box ml={1} mr={1} title="Código de Barras" onClick={() => setOpenModalBarcode(true)}>
                      <img src={IcoBarcode} alt="Código de Barras" style={{ width: '22px' }} className={classes.icones} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box ml={1} mr={1} title="Boleto em PDF" onClick={handleModalBilletSave}>
                      <img src={IcoPDF} alt="Boleto em PDF" className={classes.icones} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box ml={1} title="Imprimir Boleto" mr={1} onClick={printBillet}>
                      <img src={IcoPrint} alt="Imprimir Boleto" className={classes.icones} style={{ width: '18px' }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box ml={1} mr={1} title="Enviar Boleto" onClick={() => setOpenModalEmail(true)}>
                      <img src={IcoSendEmail} alt="Enviar Boleto" className={classes.icones} style={{ width: '18px' }} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>

          <Box mt={2} mb={2}>
            <Grid container justify="flex-end">
              <Grid item>
                {billetStatus === 'Pago' && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleModalReceiptSave}
                    className={classes.button}
                    title="Comprovante de quitação"
                  >
                    Comprovante de quitação
                  </Button>
                )}

                {reprocessEnabled && billetStatus === 'Vencido' && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setOpenModalSecondBillet(true)}
                    className={classes.button}
                    title="Segunda via"
                  >
                    Segunda via
                  </Button>
                )}

                {reprocessEnabled && billetStatus === 'AVencer' && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setOpenModalExtendBillet(true)}
                    className={classes.button}
                    title="Prorrogar"
                  >
                    Prorrogar
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Resolve>
  )
}

BilletInstallment.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  billetId: PropTypes.string.isRequired,
  billetStatus: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  reprocessEnabled: PropTypes.bool.isRequired,
}

export default BilletInstallment
