import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEmpty, find } from 'lodash'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Autocomplete } from '@material-ui/lab'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Paper } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import useYup from '../../hooks/useYup'
import { useAlert } from '../../components'
import YUP_MESSAGE from '../../helpers/yup'
import TermModal from '../../layouts/TermModal'
import useSecurity from '../../security/useSecurity'
import useBankClient from '../../clients/BankClient'
import useBrokerClient from '../../clients/BrokerClient'
import { useBrokerComplement } from './ComplementContext'
import icoAlert from '../../assets/img/ico-alert-warning.svg'

const useStyles = makeStyles((theme) => ({
  imgAlert: {
    textAlign: 'center',
    '& img': {
      maxWidth: 100,
      textAlign: 'center',
    },
  },
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(6),
    '& img': {
      maxWidth: 100,
      marginRight: theme.spacing(1),
    },
  },
  checkboxTerm: {
    paddingRight: '2px',
    paddingTop: '5px',
  },
  buttonTerm: {
    paddingRight: '0px',
  },
}))

const DEFAULT_BANK = {
  branch: '',
  accountNumber: '',
  accountDigit: '',
  financialInstitutionId: '',
  trustedDataConfirmation: false,
}

const Bank = forwardRef((props, ref) => {
  const termRef = useRef()
  const classes = useStyles()

  const bankClient = useBankClient()
  const { addMsgDanger } = useAlert()
  const { getContext } = useSecurity()
  const brokerClient = useBrokerClient()

  const [banks, setBanks] = useState([])
  const [show, setShow] = useState(false)

  const {
    broker,
    actionNext,
    actionPrevious,
    setBroker,
    isBrokerPF,
    onNext,
    onPrevious,
  } = useBrokerComplement()

  const { onlyNumber } = useYup()

  const complete = (data) => {
    const context = getContext()

    brokerClient().complete(context.personId).then((response) => {
      onNext({ ...data, ...response.data })
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const formik = useFormik({
    initialValues: { ...DEFAULT_BANK },
    validationSchema: Yup.object({
      branch: onlyNumber.required().test({
        test: (value) => value?.length <= 4,
      }),
      accountNumber: onlyNumber.required().test({
        test: (value) => value?.length <= 13,
      }),
      accountDigit: onlyNumber.required().test({
        test: (value) => value?.length <= 2,
      }),
      financialInstitutionId: Yup.string().required(),
      trustedDataConfirmation: Yup.boolean().test({
        message: YUP_MESSAGE.required,
        test: (value) => (isBrokerPF(broker) && actionNext ? value : true),
      }),
    }),

    onSubmit: (data) => {
      const context = getContext()
      const { brokerBankReference: currentBank } = broker
      const { id } = currentBank || {}

      const values = {
        ...data,
        id,
        brokerId: context.personId,
      }

      delete values.trustedDataConfirmation

      bankClient().editBank(values).then((response) => {
        const { trustedDataConfirmation } = data
        setBroker({ ...response.data, trustedDataConfirmation })

        if (actionNext) {
          if (isBrokerPF(broker)) {
            complete({ ...response.data, trustedDataConfirmation })
          } else {
            onNext({ ...response.data, trustedDataConfirmation })
          }
        }

        if (actionPrevious) {
          onPrevious()
        }
      }, (error) => {
        addMsgDanger(error.data)
      })
    },
  })

  const { setValues } = formik

  useEffect(() => {
    const {
      trustedDataConfirmation,
      brokerBankReference,
    } = broker

    const {
      branch,
      accountNumber,
      accountDigit,
      financialInstitutionId,
    } = brokerBankReference || {}

    const data = {
      branch: branch || '',
      accountNumber: accountNumber || '',
      accountDigit: accountDigit || '',
      financialInstitutionId: financialInstitutionId || '',
      trustedDataConfirmation: trustedDataConfirmation || false,
    }

    setValues(data)
  }, [broker, setValues])

  useImperativeHandle(ref, () => ({
    onOpen: () => new Promise((resolve) => {
      bankClient().getBanks().then((response) => {
        setBanks(response.data)
        setShow(true)
        resolve()
      }, (error) => {
        addMsgDanger(error.data)
      })
    }),
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: 'Verifique os campos em destaque antes de prosseguir.' }
        }
        resolve(data)
        formik.submitForm()
      })
    }),
    onClose: () => setShow(false),
  }))

  const { financialInstitutionId } = formik.values

  const getBankById = useCallback(() => find(banks, ['id', financialInstitutionId]) || null, [banks, financialInstitutionId])

  const handleChangeFinancialInstitution = (event, value) => {
    formik.setValues({ ...formik.values, financialInstitutionId: value?.id })
  }

  return (
    <>
      {show && (
        <>
          <Paper className="paper">
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography component="span" color="primary" className={classes.title}>
                  DADOS BANCÁRIOS
                </Typography>
              </Grid>

              <Grid item lg={6} sm={6} xs={12}>
                <Autocomplete
                  id="financialInstitutionId"
                  options={banks}
                  getOptionLabel={(option) => `${option.code} - ${option.name}`}
                  clearOnBlur
                  handleHomeEndKeys
                  value={getBankById()}
                  onChange={handleChangeFinancialInstitution}
                  noOptionsText="Nenhuma opção correspondente"
                  ListboxProps={{ style: { fontSize: 12 } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="financialInstitutionId"
                      title="Selecione o banco que deseja receber sua comissão"
                      label="Banco que deseja receber a comissão*:"
                      color="secondary"
                      error={formik.touched.financialInstitutionId
                        && !!formik.errors.financialInstitutionId}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <FormHelperText
                  hidden={!formik.touched.financialInstitutionId
                    || !formik.errors.financialInstitutionId}
                  error={formik.touched.financialInstitutionId
                    && !!formik.errors.financialInstitutionId}
                >
                  {formik.errors.financialInstitutionId}
                </FormHelperText>
              </Grid>

              <Grid item lg={6} sm={6} xs={12}>
                <TextField
                  id="branch"
                  color="secondary"
                  title="Número da agência"
                  {...formik.getFieldProps('branch')}
                  label="Agência*:"
                  error={formik.touched.branch && !!formik.errors.branch}
                  helperText={formik.touched.branch && formik.errors.branch}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} sm={6} xs={12}>
                <TextField
                  id="accountNumber"
                  color="secondary"
                  title="Número da conta"
                  {...formik.getFieldProps('accountNumber')}
                  label="Conta*:"
                  error={formik.touched.accountNumber && !!formik.errors.accountNumber}
                  helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                  fullWidth
                />
              </Grid>

              <Grid item lg={6} sm={6} xs={12}>
                <TextField
                  id="accountDigit"
                  color="secondary"
                  title="Dígito verificador"
                  {...formik.getFieldProps('accountDigit')}
                  label="Dígito*:"
                  error={formik.touched.accountDigit && !!formik.errors.accountDigit}
                  helperText={formik.touched.accountDigit && formik.errors.accountDigit}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>

          <Box mt={2}>
            <Paper>
              <Box p={4} pt={2} pb={2}>
                <Grid container>
                  <Grid item xs={12} md={2} className={classes.imgAlert}>
                    <img src={icoAlert} alt="" />
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Typography component="span" variant="body2">
                      As comissões serão depositadas nessa conta fornecida.
                      Dessa forma, certifique-se de que os dados bancários estejam corretos
                      para que não haja problemas no pagamento.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>

          {isBrokerPF(broker) && (
            <>
              <TermModal
                ref={termRef}
                onAccept={() => {
                  formik.setFieldValue('trustedDataConfirmation', true)
                }}
              />

              <Box ml={1} mt={2}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      id="trustedDataConfirmation"
                      name="trustedDataConfirmation"
                      className={classes.checkboxTerm}
                      checked={formik.values.trustedDataConfirmation}
                      onChange={(event) => {
                        formik.setFieldValue('trustedDataConfirmation', event.target.checked)
                      }}
                    />
                  )}
                  label={(
                    <>
                      <Box component="span">Ao concluir seu cadastro, você aceita nosso</Box>
                      <Box component="span">
                        <Button
                          color="primary"
                          className={classes.buttonTerm}
                          onClick={() => termRef.current.onOpen()}
                          title="Termos e condições"
                        >
                          Termos e condições
                        </Button>
                      </Box>
                      <Box component="span">.</Box>
                    </>
                  )}
                />
                <Box ml={-1}>
                  <FormHelperText
                    hidden={!formik.touched.trustedDataConfirmation
                      || !formik.errors.trustedDataConfirmation}
                    error={formik.touched.trustedDataConfirmation
                      && !!formik.errors.trustedDataConfirmation}
                  >
                    {formik.errors.trustedDataConfirmation}
                  </FormHelperText>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
      {!show && <></>}
    </>
  )
})

export default Bank
