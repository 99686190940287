import { useCallback } from 'react'
import produce from 'immer'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { DEFAULT_THEME } from '../../constants'

const INITIAL_STATE = {
  user: {
    color: DEFAULT_THEME,
  },
  demand: {},
  sinister: {},
  credential: null,
}

export const Types = {
  USER: 'security/USER',
  DEMAND: 'security/DEMAND',
  SINISTER: 'security/SINISTER',
  CREDENTIAL: 'security/CREDENTIAL',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREDENTIAL: {
      const { credential } = action.payload

      return produce(state, (draft) => {
        draft.credential = credential
      })
    }

    case Types.USER: {
      const { user } = action.payload

      return produce(state, (draft) => {
        draft.user = user
      })
    }

    case Types.SINISTER: {
      const { sinister } = action.payload

      return produce(state, (draft) => {
        draft.sinister = sinister
      })
    }

    case Types.DEMAND: {
      const { demand } = action.payload

      return produce(state, (draft) => {
        draft.demand = demand
      })
    }

    default: {
      return state
    }
  }
}

export const useSecurityAction = () => {
  const dispatch = useDispatch()

  const setUser = useCallback((user) => dispatch({
    type: Types.USER,
    payload: { user },
  }), [dispatch])

  const setSinister = useCallback((sinister) => dispatch({
    type: Types.SINISTER,
    payload: { sinister },
  }), [dispatch])

  const setCredential = useCallback((credential) => dispatch({
    type: Types.CREDENTIAL,
    payload: { credential },
  }), [dispatch])

  const setDemand = useCallback((demand) => dispatch({
    type: Types.DEMAND,
    payload: { demand },
  }), [dispatch])

  const cleanUser = useCallback(() => dispatch({
    type: Types.USER,
    payload: { user: {} },
  }), [dispatch])

  const cleanDemand = useCallback(() => dispatch({
    type: Types.DEMAND,
    payload: { demand: {} },
  }), [dispatch])

  const cleanSinister = useCallback(() => dispatch({
    type: Types.SINISTER,
    payload: { sinister: {} },
  }), [dispatch])

  const cleanCredential = useCallback(() => dispatch({
    type: Types.CREDENTIAL,
    payload: { credential: null },
  }), [dispatch])

  return {
    setUser,
    setDemand,
    setSinister,
    setCredential,
    cleanUser,
    cleanDemand,
    cleanSinister,
    cleanCredential,
  }
}

export default persistReducer({
  key: 'security',
  storage,
}, reducer)
