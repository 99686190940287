import React, { useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import PanelFallback from './PanelFallback'
import useSecurity from '../../security/useSecurity'
import PanelErrorFallback from './PanelErrorFallback'
import alert from '../../assets/img/ico-sinistro-orange.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    cursor: 'pointer',
    minHeight: '215px',
  },
  iconBig: {
    maxWidth: 50,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  textDemanda: {
    fontSize: 12,
    color: '#343434',
    marginTop: 20,
    marginBottom: 10,
    fontWeight: 'normal',
  },
  containerSinister: {
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  subtitle2: {
    fontWeight: 'bold',
  },
  paddingIcon: {
    paddingTop: '0px !important',
  },
  paddingBox: {
    marginTop: 10,
  },
  fallback: {
    minHeight: '180px',
  },
}))

const Sinister = () => {
  const classes = useStyles()
  const { getSinister } = useSecurity()
  const sinister = useMemo(() => getSinister(), [getSinister])

  return (
    <Card
      className={classes.root}
      onClick={() => window.open(sinister.url)}
    >
      <CardContent className={classes.container}>
        {sinister.processing && (<PanelFallback className={classes.fallback} />)}

        {!sinister.processing && !sinister.success && (
          <PanelErrorFallback title="Aviso de Sinistro" className={classes.fallback} />
        )}

        {!sinister.processing && sinister.success && (
          <>
            <Box className={classes.paddingBox}>
              <Typography variant="subtitle2" className={classes.subtitle2}>Aviso de Sinistro</Typography>
            </Box>
            <Box className={classes.containerSinister}>
              <Grid container alignItems="center" justify="center" spacing={6}>
                <Grid item>
                  <Box textAlign="center">
                    <Typography variant="h6" component="p" className={classes.textDemanda}>
                      Comunique um sinistro e
                      <br />
                      acompanhe sua solicitação!
                    </Typography>
                  </Box>
                </Grid>
                <Grid item className={classes.paddingIcon}>
                  <img src={alert} alt="Painel" className={classes.iconBig} />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default Sinister
