import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { merge, isEmpty } from 'lodash'
import TextField from '@material-ui/core/TextField'

import {
  CPFInput,
  CPF_SIZE_WITHOUT_MASK,
} from '../../components'
import useYup from '../../hooks/useYup'
import { useRegister } from './RegisterContext'
import useBrokerClient from '../../clients/BrokerClient'

const Responsavel = forwardRef((props, ref) => {
  const brokerClient = useBrokerClient()
  const { cpf } = useYup()

  const {
    broker,
    actionNext,
    actionPrevious,
    setBroker,
    onRegister,
    onPrevious,
    addMsgError,
  } = useRegister()

  const [disabled, setDisabled] = useState(false)

  const formik = useFormik({
    initialValues: { ...broker },
    validationSchema: Yup.object({
      sponsorDocument: cpf.required(),
      sponsorName: Yup.string().max(200).required(),
      sponsorEmail: Yup.string().max(200).email().required(),
    }),
    onSubmit: (data) => {
      const value = merge(broker, { ...data, returnUrl: window.location.origin })
      setBroker(value)

      if (actionNext) {
        brokerClient().register(value).then((response) => {
          onRegister(response.data)
        }, (response) => {
          addMsgError(response.data)
        })
      }

      if (actionPrevious) {
        onPrevious()
      }
    },
  })

  const { setValues } = formik

  useEffect(() => {
    const { brokerName, brokerDocument, sponsorEmail } = broker

    if (broker.brokerDocument.length === CPF_SIZE_WITHOUT_MASK) {
      setDisabled(true)

      setValues({
        sponsorName: brokerName,
        sponsorDocument: brokerDocument,
        sponsorEmail: sponsorEmail || '',
      })
    }
  }, [broker, setValues, setDisabled])

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: 'Verifique os campos em destaque antes de prosseguir.' }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  return (
    <>
      <CPFInput
        id="sponsorDocument"
        color="secondary"
        title="CPF do responsável"
        label="CPF do responsável:*"
        {...formik.getFieldProps('sponsorDocument')}
        error={formik.touched.sponsorDocument && !!formik.errors.sponsorDocument}
        helperText={formik.touched.sponsorDocument && formik.errors.sponsorDocument}
        InputProps={{ disabled }}
        fullWidth
      />

      <TextField
        id="sponsorName"
        color="secondary"
        title="Nome do responsável"
        label="Nome do responsável:*"
        {...formik.getFieldProps('sponsorName')}
        error={formik.touched.sponsorName && !!formik.errors.sponsorName}
        helperText={formik.touched.sponsorName && formik.errors.sponsorName}
        InputProps={{ disabled }}
        fullWidth
      />

      <TextField
        id="sponsorEmail"
        color="secondary"
        title="E-mail do responsável"
        label="E-mail do responsável:*"
        {...formik.getFieldProps('sponsorEmail')}
        error={formik.touched.sponsorEmail && !!formik.errors.sponsorEmail}
        helperText={formik.touched.sponsorEmail && formik.errors.sponsorEmail}
        fullWidth
      />
    </>
  )
})

export default Responsavel
