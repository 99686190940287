import React, { useState, useEffect } from 'react'

import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { RiEqualizerLine } from 'react-icons/ri'
import {
  makeStyles, Box, TextField, Grid, InputLabel, Slider, Button,
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'
import { FilterContainer } from '../../../../components'

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1 / 2),
    fontSize: '1.5rem',
    transform: 'rotate(90deg)',
    borderRadius: 4,
  },
}))

const DEFAULT_FILTERS = {
  clientName: '',
  policyNumber: '',
  startIS: '',
  endIS: '',
  startPremium: '',
  endPremium: '',
  period: '',
}

const marks = [
  {
    value: 1,
    label: '1 dia',
  },
  {
    value: 60,
    label: 'Até 60',
  },
]

const PolicyFilter = ({ addFilter, filters }) => {
  const classes = useStyles()

  const [filterBox, setFilterBox] = useState(false)

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTERS },
    validationSchema: Yup.object({
      clientName: Yup.string().max(200),
      policyNumber: Yup.number(),
      startIS: Yup.number(),
      endIS: Yup.number(),
      startPremium: Yup.number(),
      endPremium: Yup.number(),
      period: Yup.number(),
    }),
    onSubmit: (data) => {
      const values = {
        ...data,
        startIS: data.startIS || '',
        endIS: data.endIS || '',
        startPremium: data.startPremium || '',
        endPremium: data.endPremium || '',
      }

      addFilter(values)
      setFilterBox(false)
    },
  })

  const { setValues } = formik

  const handleClear = () => {
    addFilter({ ...DEFAULT_FILTERS })
    formik.resetForm({ ...DEFAULT_FILTERS })
  }

  useEffect(() => {
    setValues({ ...filters, period: filters.period || 0 })
  }, [filters, setValues])

  return (
    <>
      <Button className={classes.filterIcon}>
        <RiEqualizerLine title="Filtrar" onClick={() => setFilterBox(true)} />
      </Button>

      <FilterContainer
        open={filterBox}
        onClean={handleClear}
        onSearch={() => formik.submitForm()}
        onClose={() => setFilterBox(false)}
      >
        <Box mb={2}>
          <TextField
            id="clientName"
            color="secondary"
            label="Cliente:"
            title="Cliente"
            {...formik.getFieldProps('clientName')}
            error={formik.touched.clientName && !!formik.errors.clientName}
            helperText={formik.touched.clientName && formik.errors.clientName}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <TextField
            id="policyNumber"
            label="Número da apólice:"
            title="Número da apólice"
            color="secondary"
            {...formik.getFieldProps('policyNumber')}
            error={formik.touched.policyNumber && !!formik.errors.policyNumber}
            helperText={formik.touched.policyNumber && formik.errors.policyNumber}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <InputLabel>IS Atualizada</InputLabel>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <CurrencyTextField
                fullWidth
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="startIS"
                title="Valor final"
                color="secondary"
                {...formik.getFieldProps('startIS')}
                error={formik.touched.startIS && !!formik.errors.startIS}
                helperText={formik.touched.startIS && formik.errors.startIS}
                onChange={(event, value) => formik.setFieldValue('startIS', value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="center">Até</Box>
            </Grid>
            <Grid item xs={5}>
              <CurrencyTextField
                fullWidth
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="endIS"
                title="Valor final"
                color="secondary"
                {...formik.getFieldProps('endIS')}
                error={formik.touched.endIS && !!formik.errors.endIS}
                helperText={formik.touched.endIS && formik.errors.endIS}
                onChange={(event, value) => formik.setFieldValue('endIS', value)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <InputLabel>Novo Prêmio</InputLabel>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <CurrencyTextField
                fullWidth
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="startPremium"
                title="Valor inicial"
                color="secondary"
                {...formik.getFieldProps('startPremium')}
                error={formik.touched.startPremium && !!formik.errors.startPremium}
                helperText={formik.touched.startPremium && formik.errors.startPremium}
                onChange={(event, value) => formik.setFieldValue('startPremium', value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="center">Até</Box>
            </Grid>
            <Grid item xs={5}>
              <CurrencyTextField
                fullWidth
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="endPremium"
                title="Valor final"
                color="secondary"
                {...formik.getFieldProps('endPremium')}
                error={formik.touched.endPremium && !!formik.errors.endPremium}
                helperText={formik.touched.endPremium && formik.errors.endPremium}
                onChange={(event, value) => formik.setFieldValue('endPremium', value)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <InputLabel>Dias restantes para renovação</InputLabel>
          <Slider
            id="period"
            onChange={(event, value) => formik.setFieldValue('period', value)}
            defaultValue={1}
            color="primary"
            aria-labelledby="discrete-slider-custom"
            step={5}
            max={60}
            valueLabelDisplay="auto"
            marks={marks}
          />
        </Box>
      </FilterContainer>
    </>
  )
}

PolicyFilter.propTypes = {
  filters: PropTypes.object,
  addFilter: PropTypes.func,
}

PolicyFilter.defaultProps = {
  filters: {},
  addFilter: () => { },
}

export default PolicyFilter
