import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box, Grid, Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '200px',
  },
}))

const BackButton = (props) => {
  const { path } = props
  const classes = useStyles()

  return (
    <>
      <Box mt={3} mb={3}>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              className={classes.button}
              color="primary"
              component={Link}
              variant="contained"
              to={path}
              title="Voltar"
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

BackButton.propTypes = {
  path: PropTypes.string.isRequired,
}

export default BackButton
