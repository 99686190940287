import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isCnpj } from 'validator-brazil'
import { isEmpty, debounce } from 'lodash'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

import {
  useAlert,
  CNPJInput,
  useLoader,
  CNPJ_SIZE_WITHOUT_MASK,
} from '../../../components'
import useYup from '../../../hooks/useYup'
import useUtils from '../../../hooks/useUtils'
import { DEFAULT_QUOTATION_INITIAL } from './Constantes'
import { useQuotationContext } from './QuotationContext'
import useQuotationClient from '../../../clients/QuotationClient'

const useStyles = makeStyles(() => ({
  lineTitle: {
    height: '2px',
    background: '#f36523',
    border: 'none',
    width: '60px',
    float: 'left',
  },
}))

const QuotationInitial = forwardRef((props, ref) => {
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const { cnpj: cnpjRule } = useYup()
  const quotationClient = useQuotationClient()
  const { enableLoader, disableLoader } = useLoader()
  const { isBlank, formatCNPJ, getOnlyNumber } = useUtils()

  const {
    onNext,
    quotation,
    setQuotation,
    brokerDocument,
  } = useQuotationContext()

  const [show, setShow] = useState(false)
  const [holder, setHolder] = useState({})
  const [loading, setLoading] = useState(false)
  const [policyHolders, setPolicyHolders] = useState([])
  const [policyHolderRun, setPolicyHolderRun] = useState()

  const formik = useFormik({
    initialValues: { ...DEFAULT_QUOTATION_INITIAL },
    validationSchema: Yup.object({
      policyHolderId: Yup.string().required(),
      policyHolderName: Yup.string(),
      policyHolderDocument: cnpjRule,
      insuredId: Yup.string(),
      insuredName: Yup.string().max(200),
      insuredDocument: cnpjRule.required(),
      isPublicCoverage: Yup.boolean(),
    }),
    onSubmit: (data) => {
      const {
        policyHolderId,
        policyHolderName,
        policyHolderDocument,
        insuredId,
        insuredName,
        insuredDocument,
        isPublicCoverage,
      } = data

      setQuotation({
        ...quotation,
        policyHolder: {
          id: `${policyHolderId}`,
          name: policyHolderName,
          document: policyHolderDocument,
        },
        insured: {
          id: insuredId,
          isPublicCoverage,
          name: insuredName,
          document: insuredDocument,
        },
      })

      onNext()
    },
  })

  const handleChangePolicyHolder = (event, value) => {
    const { id = '', name = '', documentNumber = '' } = value || {}
    setHolder({ id, name, documentNumber })

    formik.setFieldValue('policyHolderId', id)
    formik.setFieldValue('policyHolderName', name)
    formik.setFieldValue('policyHolderDocument', documentNumber)
  }

  const handleInputChangePolicyHolder = (event, value) => {
    setLoading(true)
    setHolder({ name: value })

    if (policyHolderRun) {
      policyHolderRun.current.cancel()
    }

    if (isEmpty(value) || value.length < 3) {
      setLoading(false)
      setPolicyHolders([])
      return
    }

    const selected = value.split(' / ')

    if (selected.length === 2) {
      setLoading(false)
      return
    }

    const debounced = debounce(() => {
      disableLoader()

      quotationClient().getPolicyHolders(value, brokerDocument).then((response) => {
        enableLoader()
        setLoading(false)
        setPolicyHolders([...response.data])
      }, (error) => {
        enableLoader()
        setLoading(false)
        setPolicyHolders([])
        addMsgDanger(error.data)
      })
    }, 1000)

    debounced()
    setPolicyHolderRun({ current: debounced })
  }

  const handleFindInsured = (event) => {
    const { value } = event.target
    formik.setFieldValue('insuredDocument', value)
    formik.setFieldValue('insuredId', '')
    formik.setFieldValue('insuredName', '')
    formik.setFieldValue('isPublicCoverage', '')

    if (isBlank(value)) {
      return
    }

    const cnpj = getOnlyNumber(value)

    if (cnpj.length !== CNPJ_SIZE_WITHOUT_MASK || !isCnpj(cnpj)) {
      return
    }

    quotationClient().getInsured(cnpj, brokerDocument).then((response) => {
      const { id, name, isPublicCoverage } = response.data
      formik.setFieldValue('insuredId', id)
      formik.setFieldValue('insuredName', name)
      formik.setFieldValue('isPublicCoverage', isPublicCoverage)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  useImperativeHandle(ref, () => ({
    onOpen: () => new Promise((resolve) => {
      const { setValues } = formik
      const { insured = {}, policyHolder = {} } = quotation

      const {
        id: policyHolderId = '',
        name: policyHolderName = '',
        document: policyHolderDocument = '',
      } = policyHolder

      const {
        id: insuredId = '',
        name: insuredName = '',
        document: insuredDocument = '',
        isPublicCoverage = '',
      } = insured

      setValues({
        policyHolderId,
        policyHolderName,
        policyHolderDocument,
        insuredId,
        insuredName,
        insuredDocument,
        isPublicCoverage,
      })

      setHolder({
        id: policyHolderId,
        name: policyHolderName,
        documentNumber: policyHolderDocument,
      })

      setShow(true)
      resolve()
    }),
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: 'Verifique os campos em destaque antes de prosseguir.' }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
    onPrevious: () => { },
    onClose: () => setShow(false),
  }))

  return (
    <>
      {show && (
        <>
          <Box display="flex" component={Paper} p={4} my={2}>
            <Grid container justify="space-between">
              <Grid item>
                <Box mb={3}>
                  <Typography variant="subtitle1" component="h2">
                    <Box fontWeight="bold">Dados do Tomador</Box>
                    <hr className={classes.lineTitle} />
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  clearOnBlur
                  value={holder}
                  handleHomeEndKeys
                  loading={loading}
                  autoSelect={false}
                  options={policyHolders}
                  getOptionLabel={(item) => {
                    const { id, name, documentNumber } = item
                    return isEmpty(id) ? `${name || ''}` : `${formatCNPJ(documentNumber)} / ${name}`
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onInputChange={handleInputChangePolicyHolder}
                  onChange={handleChangePolicyHolder}
                  loadingText="Carregando opções..."
                  noOptionsText="Nenhuma opção correspondente"
                  ListboxProps={{ style: { fontSize: 12 } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="policyHolderId"
                      name="policyHolderId"
                      title="Informe o CNPJ ou o nome"
                      label="Informe o CNPJ ou o nome:"
                      color="secondary"
                      fullWidth
                      error={formik.touched.policyHolderId && !!formik.errors.policyHolderId}
                      helperText={formik.touched.policyHolderId && formik.errors.policyHolderId}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>

          <Box component={Paper} p={4} my={2}>
            <Grid container justify="space-between">
              <Grid item>
                <Box mb={3}>
                  <Typography variant="subtitle1" component="h2">
                    <Box fontWeight="bold">Dados do segurado</Box>
                    <hr className={classes.lineTitle} />
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box mb={3}>
                  <CNPJInput
                    id="insuredDocument"
                    color="secondary"
                    label="CNPJ:"
                    title="CNPJ"
                    {...formik.getFieldProps('insuredDocument')}
                    onChange={handleFindInsured}
                    error={formik.touched.insuredDocument && !!formik.errors.insuredDocument}
                    helperText={formik.touched.insuredDocument && formik.errors.insuredDocument}
                    fullWidth
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="insuredName"
                  color="secondary"
                  title="Nome:"
                  label="Nome:"
                  InputProps={{
                    disabled: true,
                  }}
                  {...formik.getFieldProps('insuredName')}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  )
})

export default QuotationInitial
