import React, {
  useEffect,
  useState,
  useMemo,
} from 'react'
import {
  Step,
  Button,
  Hidden,
  Stepper,
  StepLabel,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Link, useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

import {
  useAlert,
  Breadcrumb,
  MessageBox,
} from '../../../components'
import {
  STEP_TYPE,
  PRODUCT_TYPE,
  STEP_DESCRIPTION,
  DEFAULT_QUOTATION,
} from './Constantes'
import QuotationRisk from './QuotationRisk'
import useUtils from '../../../hooks/useUtils'
import QuotationInitial from './QuotationInitial'
import QuotationContext from './QuotationContext'
import useSecurity from '../../../security/useSecurity'
import QuotationConfirmation from './QuotationConfirmation'

const useStyles = makeStyles((theme) => ({
  step: {
    flexDirection: 'column',
    width: 135,
    textAlign: 'center',
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
    },
    '& .MuiStepLabel-labelContainer': {
      textTransform: 'uppercase',
      marginTop: 4,
      '& .MuiStepLabel-label': {
        fontWeight: 600,
      },
    },
    '& .MuiStepLabel-completed, & .MuiStepLabel-active': {
      color: theme.palette.primary.main,
    },
  },
  containerStepper: {
    maxWidth: 550,
    margin: 'auto',
    marginBottom: theme.spacing(3),
    width: '100%',
    '& .MuiStepConnector-root': {
      height: 20,
      margin: -40,
      maxWidth: 120,
    },
    '& .MuiPaper-root': {
      justifyContent: 'center',
    },
  },
  subtitle: {
    fontSize: theme.typography.body2.fontSize,
  },
  steps: {
    width: '85%',
  },
}))

const Path = [
  {
    text: 'Meu Painel',
    href: '/',
  },
  {
    text: 'Cotação',
    href: '/cotacoes',
  },
  {
    text: 'Nova Cotação',
  },
]

const QuotationNew = ({ type: productType }) => {
  const classes = useStyles()
  const history = useHistory()
  const { formatCNPJ } = useUtils()
  const { addMsgDanger } = useAlert()
  const { getContext } = useSecurity()
  const { Provider } = QuotationContext

  const [steps] = useState([...STEP_DESCRIPTION])
  const [stepsRef, setStepsRef] = useState({})
  const [display, setDisplay] = useState('none')
  const [openCancel, setOpenCancel] = useState(false)
  const [activeStep, setActiveStep] = useState(STEP_TYPE.INITIAL)

  const brokerDocument = useMemo(() => getContext().mainDocument, [getContext])
  const isBid = useMemo(() => productType === PRODUCT_TYPE.BID, [productType])
  const isPerfomer = useMemo(() => productType === PRODUCT_TYPE.Performance, [productType])
  const description = useMemo(() => (isBid ? 'GARANTIA DO LICITANTE (BID)' : 'GARANTIA DO EXECUTANTE (PERFORMANCE)'), [isBid])

  const [quotation, setQuotation] = useState({
    ...DEFAULT_QUOTATION,
    productType,
    broker: { documentNumber: brokerDocument },
  })

  useEffect(() => {
    const stepsControl = {}
    stepsControl[STEP_TYPE.RISK] = React.createRef()
    stepsControl[STEP_TYPE.INITIAL] = React.createRef()
    stepsControl[STEP_TYPE.CONFIRMATION] = React.createRef()
    setStepsRef(stepsControl)

    setTimeout(() => {
      const currentRef = stepsControl[STEP_TYPE.INITIAL]

      if (currentRef.current) {
        currentRef.current.onOpen().then(() => {
          setDisplay('block')
        })
      }
    })
  }, [])

  const handleNext = () => {
    setTimeout(() => {
      const stepRef = stepsRef[activeStep]
      stepRef.current.onSubmit().then((message) => addMsgDanger(message))
    })
  }

  const handlePrevious = () => {
    setTimeout(() => {
      const stepRef = stepsRef[activeStep]
      stepRef.current.onPrevious()
    })
  }

  const onNext = () => {
    const nextStep = activeStep + 1
    const stepRef = stepsRef[nextStep]
    const currentRef = stepsRef[activeStep]

    stepRef.current.onOpen().then(() => {
      setActiveStep(nextStep)
      currentRef.current.onClose()
    })
  }

  const onPrevious = () => {
    const previousStep = activeStep - 1
    const stepRef = stepsRef[previousStep]
    const currentRef = stepsRef[activeStep]

    stepRef.current.onOpen().then(() => {
      setActiveStep(previousStep)
      currentRef.current.onClose()
    })
  }

  const onCorrect = () => {
    const currentRef = stepsRef[activeStep]
    const stepRef = stepsRef[STEP_TYPE.RISK]

    stepRef.current.onOpen().then(() => {
      setActiveStep(STEP_TYPE.RISK)
      currentRef.current.onClose()
    })
  }

  const onCancel = () => setOpenCancel(true)

  return (
    <Provider
      value={{
        quotation,
        setQuotation,
        onNext,
        onCancel,
        onCorrect,
        onPrevious,
        isBid,
        isPerfomer,
        description,
        brokerDocument,
      }}
    >
      <Box position="relative" component="main" display={display}>
        <Breadcrumb paths={Path} />

        <Box mb={5}>
          <Typography variant="h4" component="h1">
            Nova Cotação
          </Typography>

          <Box mb={1}>
            <Typography
              component="span"
              variant="subtitle2"
              className={classes.subtitle}
            >
              {description}
            </Typography>
          </Box>

          {activeStep === STEP_TYPE.RISK && (
            <>
              <Box>
                <Box component="span" mr={1}>
                  <Typography
                    component="label"
                    variant="subtitle2"
                    className={classes.subtitle}
                  >
                    Tomador:
                  </Typography>
                </Box>
                <Box component="span">
                  <Typography
                    variant="body2"
                    component="span"
                  >
                    {`${formatCNPJ(quotation?.policyHolder.document)} / ${quotation?.policyHolder.name}`}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box component="span" mr={1}>
                  <Typography
                    component="label"
                    variant="subtitle2"
                    className={classes.subtitle}
                  >
                    Segurado:
                  </Typography>
                </Box>
                <Box component="span">
                  <Typography variant="body2" component="span">
                    {`${formatCNPJ(quotation?.insured?.document)} / ${quotation?.insured?.name}`}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>

        <Grid container spacing={2} justify="center">
          <Hidden xsDown>
            <Box className={classes.containerStepper}>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  const labelProps = {
                    classes: {
                      horizontal: classes.step,
                    },
                  }
                  return (
                    <Step key={label}>
                      <StepLabel {...labelProps}>
                        {label}
                      </StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
            </Box>
          </Hidden>

          <Grid item xs={12} lg={10}>
            <QuotationInitial ref={stepsRef[STEP_TYPE.INITIAL]} />
            <QuotationRisk ref={stepsRef[STEP_TYPE.RISK]} />
            <QuotationConfirmation ref={stepsRef[STEP_TYPE.CONFIRMATION]} />
          </Grid>

          <Grid item xs={12} lg={10}>
            {activeStep === STEP_TYPE.INITIAL && (
              <Grid container spacing={1} justify="flex-end">
                <Grid item lg={2}>
                  <Button
                    color="primary"
                    title="Cancelar"
                    variant="outlined"
                    component={Link}
                    to="/cotacoes"
                    fullWidth
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item lg={2}>
                  <Button
                    color="primary"
                    title="Próximo"
                    variant="contained"
                    onClick={handleNext}
                    fullWidth
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            )}

            {activeStep === STEP_TYPE.RISK && (
              <Grid container spacing={1} justify="flex-end">
                <Grid item lg={2}>
                  <Button
                    color="primary"
                    title="Anterior"
                    variant="outlined"
                    onClick={handlePrevious}
                    fullWidth
                  >
                    Anterior
                  </Button>
                </Grid>
                <Grid item lg={2}>
                  <Button
                    color="primary"
                    title="Próximo"
                    variant="contained"
                    onClick={handleNext}
                    fullWidth
                  >
                    Próximo
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>

      <MessageBox
        opened={openCancel}
        handleClose={() => setOpenCancel(false)}
        title="Cancelar Cotação"
        text="Deseja cancelar? Ao clicar em Confirmar todas as informações desta cotação serão perdidas."
        handleSecondary={() => setOpenCancel(false)}
        handlePrimary={() => history.push('/cotacoes')}
      />
    </Provider>
  )
}

QuotationNew.propTypes = {
  type: PropTypes.oneOf([
    PRODUCT_TYPE.BID,
    PRODUCT_TYPE.Performance,
  ]).isRequired,
}

export default QuotationNew
