import React, {
  useMemo,
  useState,
  useCallback,
} from 'react'
import {
  Box,
  Card,
  Grid,
  Button,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core'

import {
  Resolve,
  useAlert,
  Breadcrumb,
  MessageBox,
} from '../../components'
import UserTable from './UserTable'
import UserHeader from './UserHeader'
import useSecurity from '../../security/useSecurity'
import useUserClient from '../../clients/UserClient'

import icoPadlock from '../../assets/img/ico-lock-permission.svg'
import icoAlertSuccess from '../../assets/img/ico-alert-success.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 700,
  },
  card: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(1.5),
      paddingTop: 0,
      paddingBottom: 0,
    },
    backgroundColor: '#f2652224',
  },
  btn: {
    fontSize: 12,
    minWidth: 60,
    width: 130,
    height: 32,
    fontWeight: '800',
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkIcon: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    marginTop: 4,
  },
  textBold: {
    fontWeight: 'bold',
  },
  textLink: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  icoPermission: {
    width: 70,
  },
  buttonGrid: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
}))

const PathInfo = [
  {
    text: 'Espaço Corretor',
    href: '/',
  },
  {
    text: 'Usuários',
    href: '',
  },
]

const DEFAULT_FILTER = {
  name: '',
  email: '',
  office: '',
  active: '',
  administrator: '',
}

const UserList = () => {
  const [users, setUsers] = useState([])
  const [results, setResults] = useState(0)

  const [query, setQuery] = useState({
    pageNumber: 1,
    orderBy: 'name',
    filter: DEFAULT_FILTER,
  })

  const [disabledConsent] = useState(false)
  const [openConsent, setOpenConsent] = useState(false)
  const [permissionConfigured, setPermissionConfigured] = useState(false)

  const classes = useStyles()
  const userClient = useUserClient()
  const { addMsgDanger } = useAlert()
  const { getContext, reload } = useSecurity()
  const { pottencialAccessPermissionConsent } = getContext()

  const handleResolve = useMemo(() => ({
    user: () => new Promise((resolve, reject) => {
      userClient().getUsers(query).then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [userClient, addMsgDanger, query])

  const handleLoaded = useCallback((data, resolve) => {
    setUsers(data.user.data)
    setResults(data.user.total)
    resolve()
  }, [])

  const onSearch = useCallback((data) => {
    userClient().getUsers(data).then((response) => {
      setUsers(response.data.data)
      setResults(response.data.total)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }, [userClient, addMsgDanger])

  const updateFilter = (value) => {
    const data = { ...query, pageNumber: 1, filter: { ...value } }
    setQuery(data)
    onSearch(data)
  }

  const updatePage = (value) => {
    const data = { ...query, pageNumber: value }
    setQuery(data)
    onSearch(data)
  }

  const updateOrder = (value) => {
    const data = { ...query, orderBy: value }
    setQuery(data)
    onSearch(data)
  }

  const updateAction = () => onSearch(query)

  const accessPermissionConsentYes = () => {
    userClient().accessPermissionConsentYes().then(() => {
      setPermissionConfigured(true)
      setOpenConsent(true)
      reload()
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const closeConsentModal = () => setOpenConsent(false)

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <Box position="relative" component="main" className={classes.root}>
        <Breadcrumb paths={PathInfo} />

        <Box mb={5}>
          <Typography variant="h5" component="h1">Gestão de Usuários</Typography>
        </Box>

        {!pottencialAccessPermissionConsent && !disabledConsent && !permissionConfigured && (
          <Box mb={6}>
            <Card className={classes.card} elevation={2}>
              <CardContent>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={12} md={1} lg="auto">
                    <Box textAlign="center">
                      <img className={classes.icoPermission} src={icoPadlock} alt="" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9}>
                    <Typography component="span" variant="body2">
                      <Box fontWeight="fontWeightBold">
                        Autorizo a Pottencial Seguradora S.A. e seus respectivos colaboradores,
                        a terem acesso ao Portal do Corretor,
                        em total observância à Lei nº 13.709/2018.
                        Ao autorizar, a Pottencial poderá te auxiliar a
                        qualquer momento com mais agilidade!
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} lg={2} className={classes.buttonGrid}>
                    <Grid container direction="row" justify="flex-end" alignItems="center">
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          className={classes.btn}
                          onClick={accessPermissionConsentYes}
                          title="Sim"
                        >
                          Permitir Acesso
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}

        <UserHeader
          defaultFilter={DEFAULT_FILTER}
          updateFilter={updateFilter}
          updateOrder={updateOrder}
          results={results}
        />

        <UserTable
          users={users}
          results={results}
          updatePage={updatePage}
          userClient={userClient}
          updateAction={updateAction}
          defaultFilter={DEFAULT_FILTER}
        />

        <MessageBox
          labelPrimary="OK"
          opened={openConsent}
          thumb={icoAlertSuccess}
          handleClose={closeConsentModal}
          handlePrimary={closeConsentModal}
          title="Configuração de permissão realizada com sucesso!"
          text="Agora poderemos te auxiliar a qualquer momento com mais agilidade. Conte sempre conosco!"
        />
      </Box>
    </Resolve>
  )
}

export default UserList
