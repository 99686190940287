import React,
{
  useMemo,
  useState,
  useCallback,
} from 'react'

import { Link } from 'react-router-dom'
import { Box, Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {
  Resolve,
  useAlert,
} from '../../../components'
import IPList from './IPList'
import IPNew from './IPNew'

import useIpsClient from '../../../clients/IpsClient'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  subTitle: {
    color: 'black',
  },
}))

const IP = () => {
  const { addMsgDanger } = useAlert()

  const classes = useStyles()
  const ipsClient = useIpsClient()

  const [ips, setIps] = useState([])

  const getIps = useCallback(() => {
    ipsClient().getIps().then((response) => {
      setIps(response.data)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }, [ipsClient, addMsgDanger])

  const handleResolve = useMemo(() => ({
    ips: () => new Promise((resolve, reject) => {
      ipsClient().getIps().then((response) => {
        resolve(response.data)
      }, (response) => {
        reject()
        addMsgDanger(response.data)
      })
    }),
  }), [ipsClient, addMsgDanger])

  const handleLoaded = useCallback((data, resolve) => {
    setIps(data.ips)
    resolve()
  }, [])

  return (
    <Resolve
      onLoaded={handleLoaded}
      resolve={handleResolve}
    >
      <>
        <Box mb={5}>
          <Typography component="h1" variant="h5">Segurança</Typography>
        </Box>

        <Box mb={3} fontWeight="bold">
          <Typography className={classes.subTitle} component="h1" variant="h6">
            Restringir acesso ao Portal por endereço IP
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={8}>
            <Box>
              Restrinja o acesso ao portal do corretor apenas de dentro da sua rede empresarial.
              Para isso você pode adicionar os endereços IP à lista abaixo.
              Isso permite que o portal seja acessado somente desses endereços IP adicionados.
              Se você não adicionar qualquer endereço IP,
              o portal estará acessível a todos que possuírem acesso a Internet.
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box textAlign="right" pr={2} pt={3}>
              <IPNew onInsertIp={getIps} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mt={4}>
              <IPList ips={ips} onDeleteIp={getIps} />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mt={5}>
              Importante: Depois de adicionar um endereço de IP à lista de restrições,
              o portal estará acessível apenas para os endereços na lista.
              Dessa forma, se você tentar acessar o portal de um endereço
              de IP que não esteja na lista, o acesso será negado.
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box textAlign="right" pr={2} pt={3}>
              <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                title="Voltar ao menu inicial"
                to="/"
                component={Link}
              >
                Voltar ao menu inicial
              </Button>
            </Box>
          </Grid>
        </Grid>
      </>
    </Resolve>
  )
}

export default IP
