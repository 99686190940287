import React from 'react'
import { useParams } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Breadcrumb } from '../../components'

import UserProfile from './UserProfile'

const UserNew = () => {
  const params = useParams()

  const PathInfo = [
    {
      text: 'Espaço Corretor',
      href: '/',
    },
    {
      text: 'Usuários',
      href: '/usuario/criar',
    },
    {
      text: params.id ? 'Editar usuário' : 'Novo usuário',
      href: '',
    },
  ]

  return (
    <Box position="relative" component="main">
      <Breadcrumb paths={PathInfo} />
      <Box mb={10}>
        <Typography variant="h4" component="h1">
          {params.id ? 'Editar usuário' : 'Novo usuário'}
        </Typography>
      </Box>

      <Grid container spacing={2} justify="center">
        <UserProfile user={params.id} />
      </Grid>
    </Box>
  )
}

export default UserNew
