import {
  Box,
  Card,
  Grid,
  Button,
  makeStyles,
  Typography,
  CardContent,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { Rating } from '@material-ui/lab'
import React, { useState, useCallback } from 'react'

import HistoryDetail from './HistoryDetail'
import useUtils from '../../hooks/useUtils'
import { Paginator, useAlert } from '../../components'
import useHistoryClient from '../../clients/HistoryClient'

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 5,
    marginBottom: 5,
  },
  gridContainer: {
    alignItems: 'center',
  },
  statusAtendido: {
    backgroundColor: '#DEF1E5',
    '& span': {
      color: '#46835B',
    },
  },
  statusEmAtendimento: {
    backgroundColor: '#F7EEEA',
    '& span': {
      color: '#BE431D',
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  align: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  rating: {
    color: theme.palette.primary.main,
  },
  solicitacao: {
    color: theme.palette.primary.main,
  },
  statusMessage: {
    borderRadius: 16,
    textAlign: 'center',
    padding: theme.spacing(1),
    width: 120,
  },
  statusContainer: {
    alignSelf: 'center',
  },
}))

const HistoryCard = ({ updatePage, historyList, results }) => {
  const classes = useStyles()
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()
  const historyClient = useHistoryClient()
  const [messages, setMessages] = useState([])
  const [openDate, setOpenDate] = useState('')
  const [closeDate, setCloseDate] = useState('')

  // PAGINAÇÃO
  const changePaginator = (value) => {
    updatePage(value)
  }

  // PERMISSÕES
  const [openModalPermissao, setOpenModalPermissao] = useState(false)
  const handleModalPermissao = () => {
    setOpenModalPermissao(!openModalPermissao)
  }

  const onFindById = useCallback((data, open, close) => {
    historyClient().getHistoryFilterId(data).then((response) => {
      const { items } = response.data
      setMessages(items)
      setOpenDate(open)
      setCloseDate(close)
      setOpenModalPermissao(!openModalPermissao)
    }, (response) => {
      addMsgDanger(response.data)
    })
  }, [historyClient, addMsgDanger, openModalPermissao])

  return (
    <>
      <HistoryDetail
        messages={messages}
        openDate={openDate}
        closeDate={closeDate}
        opened={openModalPermissao}
        handleClose={handleModalPermissao}
      />
      {historyList.map((item) => (
        <Card className={classes.card} key={item.id}>
          <CardContent>
            <Grid
              container
              spacing={3}
              justify="space-between"
              className={classes.gridContainer}
            >
              <Grid item md={2} sm={6} xs={12}>
                <Typography
                  gutterBottom
                  variant="body2"
                  style={{ fontWeight: 'bold' }}
                >
                  Data:
                </Typography>
                <Box
                  data-html
                  data-tip={formatDate(item.statusDate)}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Typography
                  gutterBottom
                  variant="body2"
                  style={{ fontWeight: 'bold' }}
                >
                  Tipo de Atendimento:
                </Typography>

                <Box
                  data-html
                  data-tip={item.typeService}
                />
              </Grid>
              <Grid className={classes.statusContainer} item md={3} xs={6}>
                <Box className={`${classes.statusMessage} ${item.closeDate ? classes.statusAtendido : classes.statusEmAtendimento}`}>
                  <Typography variant="body2" component="span">
                    {item.closeDate ? 'Atendido' : 'Em atendimento'}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={2} xs={6}>
                <Typography
                  gutterBottom
                  variant="body2"
                  style={{ fontWeight: 'bold' }}
                >
                  {item.closeDate ? 'Avaliação:' : ''}
                </Typography>
                <Rating className={classes.rating} name="read-only" value={item.rating || 0} size="small" max={5} />
              </Grid>
              <Grid item>
                <Button title="Ver Solicitações" onClick={() => onFindById(item.id, item.openDate, item.closeDate)}>
                  <Typography variant="body2" className={classes.solicitacao} style={{ fontWeight: 'bold' }}>VER SOLICITAÇÕES</Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}

      <Paginator totalResults={results} changePaginator={changePaginator} />
      <ReactTooltip type="dark" />
    </>
  )
}

HistoryCard.propTypes = {
  updatePage: PropTypes.func.isRequired,
  historyList: PropTypes.array.isRequired,
  results: PropTypes.number.isRequired,
}

HistoryCard.defaultProps = {}

export default HistoryCard
