import React from 'react'
import {
  Box,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'
import { makeStyles } from '@material-ui/core/styles'

import useUtils from '../../../hooks/useUtils'
import { useQuotationContext } from './QuotationContext'

const useStyles = makeStyles(() => ({
  lineTitle: {
    height: '2px',
    background: '#f36523',
    border: 'none',
    width: '60px',
    float: 'left',
  },
  subtitle: {
    fontSize: '11px',
  },
  values: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  icon: {
    width: '70px',
  },
}))

const QuotationInfo = () => {
  const classes = useStyles()
  const { quotation } = useQuotationContext()
  const { formatDate, formatCNPJ } = useUtils()

  return (
    <>
      <Box component={Paper} pt={3} px={4} pb={3} mb={3}>
        <Grid container justify="space-between">
          <Grid item xs={12}>
            <Box mb={3}>
              <Typography variant="subtitle1" component="h2">
                <Box fontWeight="bold">Confirmação dos Dados</Box>
                <hr className={classes.lineTitle} />
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={6} sm={6} xs={12}>
                <Box display="inline" mr={1}>
                  <Typography variant="subtitle2" component="span">Segurado:</Typography>
                </Box>
                <Typography variant="body2" component="span">{quotation?.insured.name}</Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Box display="inline" mr={1}>
                  <Typography variant="subtitle2" component="span">Inicio da vigência:</Typography>
                </Box>
                <Typography variant="body2" component="span">
                  {`${formatDate(quotation?.policyPeriod.initial)} até ${formatDate(quotation?.policyPeriod.final)}`}
                </Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Box display="inline" mr={1}>
                  <Typography variant="subtitle2" component="span">CNPJ do segurado:</Typography>
                </Box>
                <Typography variant="body2" component="span">{formatCNPJ(quotation?.insured.document)}</Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Box display="inline" mr={1}>
                  <Typography variant="subtitle2" component="span">Prazo em dias:</Typography>
                </Box>
                <Typography variant="body2" component="span">{quotation?.insuredDays}</Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Box display="inline" mr={1}>
                  <Typography variant="subtitle2" component="span">Tomador:</Typography>
                </Box>
                <Typography variant="body2" component="span">{quotation?.policyHolder.name}</Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Box display="inline" mr={1}>
                  <Typography variant="subtitle2" component="span">Valor do contrato:</Typography>
                </Box>
                <Typography variant="body2" component="span">
                  {quotation?.policyValues.contractValue && (
                    <Currency quantity={quotation?.policyValues.contractValue || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                  )}
                </Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Box display="inline" mr={1}>
                  <Typography variant="subtitle2" component="span">CNPJ do Tomador:</Typography>
                </Box>
                <Typography variant="body2" component="span">{formatCNPJ(quotation?.policyHolder.document)}</Typography>
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Box display="inline" mr={1}>
                  <Typography variant="subtitle2" component="span">Importância Segurada:</Typography>
                </Box>
                <Typography variant="body2" component="span">
                  {quotation?.policyValues.insuredAmount && (
                    <Currency quantity={quotation?.policyValues.insuredAmount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default QuotationInfo
