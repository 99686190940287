import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, CardContent, Grid, makeStyles, Typography,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'
import { isNumber } from 'lodash'

import icoInstallmentOverdue from '../../assets/img/ico-installment-overdue.svg'

const useStyles = makeStyles({
  root: {
    width: 250,
    height: 92,
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: '#e0e0e0',
    },
    '& .MuiCardContent-root': {
      padding: '16px 12px',
    },
  },
  title: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  installmentIcon: {
    height: 32,
    width: 32,
    marginTop: 18,
  },
  valuesGrid: {
    width: 194,
    height: 60,
  },
  totalValueTitle: {
    fontSize: 11,
    color: '#8b8b8b',
  },
  totalValue: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  quantity: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingLeft: 10,
    width: 52,
  },
})

const InstallmentOverdueCard = (props) => {
  const {
    installment, onClick, title,
  } = props

  const classes = useStyles()

  return (
    <Card className={classes.root} onClick={onClick}>
      <CardContent>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Grid container direction="column" justify="space-between" className={classes.valuesGrid}>
              <Grid item>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="flex-end">
                  <Grid item className={classes.quantity}>
                    {installment?.quantity}
                  </Grid>
                  <Grid item xs>
                    <Grid container direction="column" alignItems="center">
                      <Grid item className={classes.totalValueTitle}>
                        Valor total:
                      </Grid>
                      <Grid item className={classes.totalValue}>
                        { isNumber(installment?.amount) ? (
                          <Currency quantity={installment?.amount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                        ) : '--'}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div>
              <img src={icoInstallmentOverdue} alt="" className={classes.installmentIcon} />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

InstallmentOverdueCard.propTypes = {
  installment: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
}

InstallmentOverdueCard.defaultProps = {
  onClick: () => { },
}

export default InstallmentOverdueCard
