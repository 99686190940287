import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Card,
  Select,
  MenuItem,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core'
import { isNumber } from 'lodash'
import Currency from 'react-currency-formatter'

import icoInstallmentDue from '../../assets/img/ico-installment-due.svg'

const useStyles = makeStyles({
  root: {
    width: 250,
    height: 92,
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: '#e0e0e0',
    },
    '& .MuiCardContent-root': {
      padding: '16px 12px',
    },
  },
  title: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  installmentIcon: {
    height: 32,
    width: 32,
    marginTop: 18,
  },
  valuesGrid: {
    width: 194,
    height: 60,
  },
  select: {
    backgroundColor: '#fff',
    borderRadius: 50,
    paddingLeft: 10,
    fontSize: 11,
    width: 78,
  },
  totalValueTitle: {
    fontSize: 11,
    color: '#8b8b8b',
  },
  totalValue: {
    fontSize: 14,
    fontWeight: 'bold',
  },
})

const InstallmentDueCard = (props) => {
  const {
    id, installment, onChange, onClick, title,
  } = props

  const classes = useStyles()

  const handleOnChangeDays = (event) => {
    const { value } = event.target

    onChange({
      target: { id, value },
    })
  }

  return (
    <Card className={classes.root} onClick={onClick}>
      <CardContent>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Grid container direction="column" justify="space-between" className={classes.valuesGrid}>
              <Grid item>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="space-between" alignItems="flex-end">
                  <Grid item>
                    <Select
                      id="dias"
                      title="dias"
                      value={installment?.days || ''}
                      onChange={handleOnChangeDays}
                      disableUnderline
                      className={classes.select}
                      onClick={(event) => event.stopPropagation()}
                      xs="auto"
                    >
                      <MenuItem value={30}>30 dias</MenuItem>
                      <MenuItem value={60}>60 dias</MenuItem>
                      <MenuItem value={90}>90 dias</MenuItem>
                      <MenuItem value={120}>120 dias</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs>
                    <Grid container direction="column" alignItems="center">
                      <Grid item className={classes.totalValueTitle}>
                        Valor total:
                      </Grid>
                      <Grid item className={classes.totalValue}>
                        { isNumber(installment?.amount) && (
                          <Currency quantity={installment.amount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                        )}

                        { !isNumber(installment?.amount) && '--' }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div>
              <img src={icoInstallmentDue} alt="" className={classes.installmentIcon} />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

InstallmentDueCard.propTypes = {
  id: PropTypes.string.isRequired,
  installment: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
}

InstallmentDueCard.defaultProps = {
  onChange: () => { },
  onClick: () => { },
}

export default InstallmentDueCard
