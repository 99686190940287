import {
  Box,
  Select,
  MenuItem,
  FormLabel,
  InputLabel,
  makeStyles,
} from '@material-ui/core'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { RiEqualizerLine } from 'react-icons/ri'
import CloseIcon from '@material-ui/icons/Close'
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'

import useUtils from '../../../hooks/useUtils'
import { FilterContainer } from '../../../components'
import { PRODUCTS_POTTENCIAL } from '../../../constants'
import DateInput from '../../../components/DateInput/DateInput'

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1 / 2),
    fontSize: '1.5rem',
    transform: 'rotate(90deg)',
    borderRadius: 4,
  },
  clearIcon: {
    right: 25,
    width: '0.75em',
    height: '0.75em',
    cursor: 'pointer',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.54)',
  },
}))

const DEFAULT_FILTERS = {
  policyNumber: '',
  brokeDocument: '',
  customerName: '',
  initialAward: '',
  finalAward: '',
  initialCommission: '',
  finalCommission: '',
  dateEmissionInitial: '',
  dateEmissionFinal: '',
  product: '',
  descriptionModality: '',
}

const PolicyFilter = ({ addFilter, filters }) => {
  const classes = useStyles()
  const { isBlank } = useUtils()
  const [filterBox, setFilterBox] = useState(false)

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTERS },
    validationSchema: Yup.object({
      product: Yup.string(),
      descriptionModality: Yup.string(),
      policyNumber: Yup.string().max(30),
      customerName: Yup.string().max(100),

      // Prêmio
      initialAward: Yup.number(),
      finalAward: Yup.number().when('initialAward', (initialAward) => {
        let schema = Yup.number()

        if (initialAward && initialAward !== 0) {
          schema = Yup.number().min(initialAward, 'O valor deve ser maior que o valor anterior').required()
        }
        return schema
      }),

      // Comissão
      initialCommission: Yup.number(),
      finalCommission: Yup.number().when('initialCommission', (initialCommission) => {
        let schema = Yup.number()

        if (initialCommission && initialCommission !== 0) {
          schema = Yup.number().min(initialCommission, 'O valor deve ser maior que o valor anterior').required()
        }
        return schema
      }),

      // Data de emissão
      dateEmissionInitial: Yup.date(),
      dateEmissionFinal: Yup.date().when('dateEmissionInitial', (dateEmissionFinal) => dateEmissionFinal && Yup.date().min(dateEmissionFinal, 'A data final deve ser maior que a data inicial').required()),
    }),
    onSubmit: (data) => {
      addFilter(data)
      setFilterBox(false)
    },
  })

  const { setValues } = formik

  useEffect(() => {
    setValues({ ...filters })
  }, [filters, setValues])

  const handleClear = () => {
    addFilter({ ...DEFAULT_FILTERS })
    formik.resetForm({ ...DEFAULT_FILTERS })
  }

  return (
    <>
      <Button title="Filtrar Apólices" className={classes.filterIcon}>
        <RiEqualizerLine onClick={() => setFilterBox(true)} />
      </Button>
      <FilterContainer
        open={filterBox}
        onClean={handleClear}
        onClose={() => setFilterBox(false)}
        onSearch={() => formik.submitForm()}
      >
        <Box mb={3}>
          <TextField
            id="policyNumber"
            label="Número da apólice"
            title="Número da apólice"
            color="secondary"
            {...formik.getFieldProps('policyNumber')}
            error={formik.touched.policyNumber && !!formik.errors.policyNumber}
            helperText={formik.touched.policyNumber && formik.errors.policyNumber}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <TextField
            id="customerName"
            label="Cliente"
            title="Cliente"
            color="secondary"
            {...formik.getFieldProps('customerName')}
            error={formik.touched.customerName && !!formik.errors.customerName}
            helperText={formik.touched.customerName && formik.errors.customerName}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <FormControl fullWidth>
            <InputLabel
              color="secondary"
              error={formik.touched.product && !!formik.errors.product}
            >
              Produto
            </InputLabel>
            <Select
              id="product"
              title="Produto"
              color="secondary"
              endAdornment={(
                <InputAdornment position="end">
                  <CloseIcon
                    className={classes.clearIcon}
                    onClick={() => formik.setFieldValue('product', '')}
                  />
                </InputAdornment>
              )}
              {...formik.getFieldProps('product')}
              error={formik.touched.product && !!formik.errors.product}
            >
              {PRODUCTS_POTTENCIAL.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.enum}
                >
                  {item.description}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText
              hidden={!formik.touched.product || !formik.errors.product}
              error={formik.touched.product && !!formik.errors.product}
            >
              {formik.errors.product}
            </FormHelperText>
          </FormControl>
        </Box>

        <Box mb={3}>
          <TextField
            id="modality"
            label="Modalidade"
            title="Modalidade"
            color="secondary"
            {...formik.getFieldProps('descriptionModality')}
            error={formik.touched.descriptionModality && !!formik.errors.descriptionModality}
            helperText={formik.touched.descriptionModality && formik.errors.descriptionModality}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <FormControl fullWidth>
            <Box my={1}>
              <FormLabel>Prêmio:</FormLabel>
            </Box>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item lg={5} xs={12}>
                <CurrencyTextField
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  id="initialAward"
                  title="Menor valor do Prêmio"
                  color="secondary"
                  {...formik.getFieldProps('initialAward')}
                  error={formik.touched.initialAward && !!formik.errors.initialAward}
                  helperText={formik.touched.initialAward && formik.errors.initialAward}
                  fullWidth
                  onChange={(event, value) => {
                    if (value === 0) {
                      formik.setFieldValue('finalAward', '')
                    }

                    formik.setFieldValue('initialAward', value)
                  }}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <Box textAlign="center">
                  <Typography component="label" htmlFor="finalAward">Até</Typography>
                </Box>
              </Grid>
              <Grid item lg={5} xs={12}>
                <CurrencyTextField
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  id="finalAward"
                  title="Maior valor do Prêmio"
                  color="secondary"
                  {...formik.getFieldProps('finalAward')}
                  error={formik.touched.finalAward && !!formik.errors.finalAward}
                  helperText={formik.touched.finalAward && formik.errors.finalAward}
                  fullWidth
                  disabled={!formik.values.initialAward}
                  onChange={(event, value) => formik.setFieldValue('finalAward', value)}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Box>

        <Box mb={3}>
          <FormControl fullWidth>
            <Box my={1}>
              <FormLabel>Comissão:</FormLabel>
            </Box>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item lg={5} xs={12}>
                <CurrencyTextField
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  id="initialCommission"
                  title="Menor valor da Comissão"
                  color="secondary"
                  {...formik.getFieldProps('initialCommission')}
                  error={formik.touched.initialCommission && !!formik.errors.initialCommission}
                  helperText={formik.touched.initialCommission && formik.errors.initialCommission}
                  fullWidth
                  onChange={(event, value) => {
                    if (value === 0) {
                      formik.setFieldValue('finalCommission', '')
                    }

                    formik.setFieldValue('initialCommission', value)
                  }}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <Box textAlign="center">
                  <Typography component="label" htmlFor="finalCommission">Até</Typography>
                </Box>
              </Grid>
              <Grid item lg={5} xs={12}>
                <CurrencyTextField
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  id="finalCommission"
                  title="Maior valor da Comissão"
                  color="secondary"
                  {...formik.getFieldProps('finalCommission')}
                  disabled={!formik.values.initialCommission}
                  error={formik.touched.finalCommission && !!formik.errors.finalCommission}
                  helperText={formik.touched.finalCommission && formik.errors.finalCommission}
                  fullWidth
                  onChange={(event, value) => formik.setFieldValue('finalCommission', value)}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Box>

        <Box mb={4}>
          <FormControl fullWidth>
            <Box mb={2}>
              <FormLabel>Data de emissão:</FormLabel>
            </Box>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item lg={5} xs={12}>
                <DateInput
                  fullWidth
                  id="dateEmissionInitial"
                  label="Data Inicial"
                  title="Data Inicial"
                  {...formik.getFieldProps('dateEmissionInitial')}
                  error={formik.touched.dateEmissionInitial
                    && !!formik.errors.dateEmissionInitial}
                  helperText={formik.touched.dateEmissionInitial
                    && formik.errors.dateEmissionInitial}
                  onChange={(event) => {
                    const { value } = event.target

                    if (isBlank(value)) {
                      formik.setFieldValue('dateEmissionFinal', '')
                    }

                    formik.setFieldValue('dateEmissionInitial', value)
                  }}
                />
              </Grid>
              <Grid item lg={2} xs={12}>
                <Box textAlign="center">
                  <Typography component="label" htmlFor="dateEmissionFinal">Até</Typography>
                </Box>
              </Grid>
              <Grid item lg={5} xs={12}>
                <DateInput
                  fullWidth
                  id="dateEmissionFinal"
                  label="Data Final"
                  title="Data Final"
                  disabled={!formik.values.dateEmissionInitial}
                  {...formik.getFieldProps('dateEmissionFinal')}
                  error={formik.touched.dateEmissionFinal && !!formik.errors.dateEmissionFinal}
                  helperText={formik.touched.dateEmissionFinal && formik.errors.dateEmissionFinal}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </FilterContainer>
    </>
  )
}

PolicyFilter.propTypes = {
  addFilter: PropTypes.func,
  filters: PropTypes.object,
}

PolicyFilter.defaultProps = {
  addFilter: () => { },
  filters: {},
}

export default PolicyFilter
