import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'

import 'react-circular-progressbar/dist/styles.css'

function ProgressBar({
  percentage, width, height, backgroundColor, children,
}) {
  const theme = useTheme()

  return (
    <div style={{ width, height }}>
      <CircularProgressbarWithChildren
        value={percentage}
        styles={
          buildStyles({
            rotation: 0.25,
            strokeLinecap: 'round', // butt
            pathTransitionDuration: 0.5,
            pathColor: backgroundColor || theme.palette.primary.main,
            textColor: theme.palette.text.primary,
            trailColor: '#d6d6d6',
            backgroundColor: backgroundColor || theme.palette.primary.main,
          })
        }
      >
        {children}
      </CircularProgressbarWithChildren>
    </div>
  )
}

ProgressBar.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  children: PropTypes.element.isRequired,
  percentage: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}

ProgressBar.defaultProps = {
  height: 200,
  width: 200,
}

export default ProgressBar
