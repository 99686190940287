import React, { useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Grid,
  Button,
  makeStyles,
  IconButton,
  Typography,
  TextField,
} from '@material-ui/core'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useBroker } from './BrokerViewContext'
import {
  ModalInfo, PhoneInput, useAlert,
} from '../../components'
import useYup from '../../hooks/useYup'
import useBrokerClient from '../../clients/BrokerClient'
import icoEdit from '../../assets/img/ico-edit-default.svg'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 800,
    },
  },
  modalForm: {
    padding: 20,
  },
  backgroundIcon: {
    backgroundColor: '#f25a0f5c',
  },
}))

const DEFAULT_INFO = {
  phone: '',
  emailAddress: '',
}

const CommunicationsModal = () => {
  const { addMsgDanger, addMsgWarning, addMsgSuccess } = useAlert()
  const brokerClient = useBrokerClient()
  const { broker, setBroker } = useBroker()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const {
    phoneOrCellphone: phoneRule,
  } = useYup()

  const formik = useFormik({
    initialValues: { ...DEFAULT_INFO },
    validationSchema: Yup.object({
      phone: phoneRule.required(),
      emailAddress: Yup.string().email().required(),
    }),
    onSubmit: (data) => {
      if (data.phone === broker.phone && data.comunicationEmail === broker.comunicationEmail) {
        addMsgWarning('Dados não alterados. Nenhuma ação será realizada.')
        setOpen(false)
        return
      }

      const values = {
        id: broker.id,
        name: broker.name,
        idPhone: broker.idPhone,
        phone: data.phone,
        idEmail: broker.idEmail,
        emailAddress: data.emailAddress,
      }

      brokerClient().editCommunicationChannels(values).then((response) => {
        const { emailAddress } = response.data

        setBroker({
          ...broker,
          ...response.data,
          comunicationEmail: emailAddress,
        })
        setOpen(false)
        addMsgSuccess('Dados alterados com sucesso!')
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  const handleOpenModalEdit = () => {
    formik.setValues({
      phone: broker.phone,
      emailAddress: broker.comunicationEmail || '',
    })

    setOpen(true)
  }

  return (
    <>
      <ModalInfo
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
      >
        <>
          <Box mb={5}>
            <Typography component="span" color="primary" className={classes.title}>ALTERAR DADOS DA CORRETORA</Typography>
          </Box>
          <form>
            <Grid container spacing={4} className={classes.modalForm}>
              <Grid item xs={6}>
                <TextField
                  id="emailAddress"
                  color="secondary"
                  label="E-mail*:"
                  title="E-mail"
                  fullWidth
                  {...formik.getFieldProps('emailAddress')}
                  error={formik.touched.emailAddress && !!formik.errors.emailAddress}
                  helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneInput
                  id="phone"
                  color="secondary"
                  label="Telefone*:"
                  title="Telefone"
                  {...formik.getFieldProps('phone')}
                  error={formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                  fullWidth
                />
              </Grid>

              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    color="primary"
                    title="Cancelar"
                    variant="outlined"
                    onClick={() => setOpen(false)}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    title="Salvar"
                    color="primary"
                    variant="contained"
                    onClick={() => formik.submitForm()}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </>
      </ModalInfo>
      <IconButton
        size="small"
        color="primary"
        component="span"
        aria-label="Editar"
        title="Editar dados"
        onClick={handleOpenModalEdit}
        className={clsx({ [classes.backgroundIcon]: open })}
      >
        <img src={icoEdit} alt="Editar dados" />
      </IconButton>
    </>
  )
}

export default CommunicationsModal
