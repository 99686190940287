import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import UserPermission from './UserPermission'

const SystemAccess = ({ userInfo, onPermission, functionality }) => {
  const [permission, setPermission] = useState([])

  const applyChecked = useCallback(() => {
    const { functionalities = [] } = userInfo
    const data = functionality.map((item) => ({ ...item }))

    functionalities.forEach((userFunctionality) => {
      const index = data.findIndex((item) => item.id === userFunctionality.id)

      if (index !== -1) {
        data[index].checked = true
      }
    })

    setPermission(data)
  }, [functionality, userInfo])

  useEffect(() => {
    const functionalityChecked = permission.filter((item) => item.checked)
    const functionalityId = {
      functionalities: functionalityChecked.map((item) => item.id),
    }
    onPermission(functionalityId)
  }, [permission, onPermission])

  useEffect(() => {
    if (functionality.length === 0) {
      setPermission([])
    } else {
      applyChecked()
    }
  }, [functionality, applyChecked])

  return (
    <>
      <Typography
        variant="body1"
        component="h2"
        color="primary"
      >
        ACESSO AO SISTEMA
      </Typography>
      <Box px={4} py={3}>
        <UserPermission permission={permission} setPermission={setPermission} />
      </Box>
    </>
  )
}

SystemAccess.propTypes = {
  userInfo: PropTypes.object,
  functionality: PropTypes.array,
  onPermission: PropTypes.func.isRequired,
}

SystemAccess.defaultProps = {
  userInfo: {},
  functionality: [],
}

export default SystemAccess
