import React from 'react'
import {
  Box, Typography,
} from '@material-ui/core'
import {
  Breadcrumb,
} from '../../components'
import IFrameDemand from './IFrameDemand'
import Back from './BackButton'

const PathInfo = [
  {
    text: 'Painel',
    href: '/painel',
  },
  {
    text: 'Cotações',
    href: '/cotacoes',
  },
  {
    text: 'Cotações em Andamento',
    href: '',
  },
]

const InProgressDemand = () => {
  const { DEMAND_URL } = window.ENV

  return (
    <>
      <Breadcrumb paths={PathInfo} />
      <Box mb={10}>
        <Typography component="h1" variant="h4">Cotações em Andamento</Typography>
      </Box>

      <IFrameDemand url={`${DEMAND_URL}/DemandasAndamento`} title="Cotações em Andamento" height="1200" />
      <Back path="/demands" />
    </>
  )
}

export default InProgressDemand
