import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Grid,
  Paper,
  Button,
  Accordion,
  makeStyles,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Currency from 'react-currency-formatter'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useUtils from '../../hooks/useUtils'
import BilletInstallment from './BilletInstallment'
import { BILLETS_STATUS_ENUM } from '../../constants'
import { Paginator, useAlert } from '../../components'
import useBilletClient from '../../clients/BilletClient/useBilletClient'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 'max(min(0.73vw, 14px), 0.675rem)',
    fontWeight: 600,
    marginRight: 4,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  values: {
    fontSize: 'max(min(0.73vw, 14px), 0.675rem)',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  accordion: {
    borderRight: '6px solid',
  },
  statusPaidOut: {
    borderColor: '#6E937F',
  },
  statusOverdue: {
    borderColor: '#C6516D',
  },
  statusToWin: {
    borderColor: '#F7A57D',
  },
  grid: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  expand: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailButton: {
    width: 82,
    height: 25,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 9,
    marginTop: 10,
    marginLeft: -16,
    [theme.breakpoints.down('md')]: {
      width: 128,
      height: 30,
      fontSize: 12,
    },
  },
  expandIcon: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  },
  summary: {
    userSelect: 'inherit',
    cursor: 'text !important',
  },
}))

const BilletTable = ({ filters }) => {
  const classes = useStyles()
  const { formatDate } = useUtils()
  const { addMsgDanger } = useAlert()
  const billetClient = useBilletClient()

  const [total, setTotal] = useState(0)
  const [billets, setBillets] = useState()
  const [expanded, setExpanded] = useState(false)

  const onSearch = useCallback((values) => {
    billetClient().getBillets(values).then((response) => {
      setBillets(response.data.data)
      setTotal(response.data.total)
    }, (error) => {
      addMsgDanger(error.data)
    })
  }, [billetClient, addMsgDanger])

  useEffect(() => onSearch(filters), [onSearch, filters])

  const getStatusClassName = (status) => {
    if (status === 'Pago') return classes.statusPaidOut
    if (status === 'AVencer') return classes.statusToWin
    if (status === 'Vencido') return classes.statusOverdue
    return classes.statusToWin
  }

  const handleAccordion = (panel) => {
    let value = ''

    if (panel !== expanded) {
      value = panel
    }
    setExpanded(value)
  }

  useEffect(() => {
    setExpanded(false)
  }, [billets])

  const nextPage = useCallback((value) => {
    setExpanded('')

    const values = {
      ...filters,
      page: { pageNumber: value, pageSize: 10 },
    }

    onSearch(values)
  }, [onSearch, filters])

  return (
    <>
      { billets && billets.length === 0 && (
        <Paper>
          <Box px={2} py={3} mt={2}>Não foi possível encontrar registros para esta pesquisa.</Box>
        </Paper>
      )}

      {billets && billets.map((billet, index) => (
        <Box mt={2} key={index}>
          <Accordion
            expanded={expanded === `${index}`}
            className={`${classes.accordion} ${getStatusClassName(billet.status)}`}
          >
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className={classes.summary}>
              <Grid
                container
                spacing={3}
                justify="space-between"
                data-tip={BILLETS_STATUS_ENUM[billet.status]}
              >
                <Grid item lg={4} xs={12}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item className={classes.grid}>
                      <Typography variant="body2" className={classes.title}>Cliente:</Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                      <Typography variant="body2" className={classes.values}>{billet.clientName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} xs={12}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item className={classes.grid}>
                      <Typography variant="body2" className={classes.title}>Produto:</Typography>
                      <Typography variant="body2" className={classes.values}>{billet.product}</Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                      <Typography variant="body2" className={classes.title}>Proposta:</Typography>
                      <Typography variant="body2" className={classes.values}>{billet.proposalNumber}</Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                      <Typography variant="body2" className={classes.title}>Apólice:</Typography>
                      <Typography variant="body2" className={classes.values}>{billet.policyNumber}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={3} xs={12}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item className={classes.grid}>
                      <Typography variant="body2" className={classes.title}>Vencimento:</Typography>
                      <Typography variant="body2" className={classes.values}>{formatDate(billet.dueDate)}</Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                      <Typography variant="body2" className={classes.title}>Valor do boleto:</Typography>
                      <Typography variant="body2" className={classes.values}>
                        <Currency quantity={billet?.amount || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={1} xs={12} className={classes.expand}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.detailButton}
                    onClick={() => handleAccordion(`${index}`)}
                    title="Detalhar"
                  >
                    Detalhar
                    {expanded !== `${index}` && <ExpandMoreIcon className={classes.expandIcon} />}
                    {expanded === `${index}` && <ExpandLessIcon className={classes.expandIcon} />}
                  </Button>
                </Grid>
              </Grid>

              <ReactTooltip
                type="dark"
                place="right"
              />
            </AccordionSummary>
            <AccordionDetails>
              {expanded === `${index}` && (
                <Grid container>
                  <Grid item xs={12}>
                    <BilletInstallment
                      billetId={billet.billetId}
                      billetStatus={billet.status}
                      clientName={billet.clientName}
                      onUpdate={() => onSearch(filters)}
                      reprocessEnabled={billet.reprocessEnabled}
                    />
                  </Grid>
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}

      <Paginator totalResults={total} changePaginator={nextPage} />
    </>
  )
}

BilletTable.propTypes = {
  filters: PropTypes.object.isRequired,
}

export default BilletTable
