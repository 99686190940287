import React, { useContext } from 'react'

const RegisterContext = React.createContext()

export const useRegister = () => {
  const {
    broker,
    actionNext,
    actionPrevious,
    onNext,
    onPrevious,
    onRegister,
    setBroker,
    setStepForm,
    addMsgError,
  } = useContext(RegisterContext)

  return {
    broker,
    actionNext,
    actionPrevious,
    onNext,
    onPrevious,
    onRegister,
    setBroker,
    setStepForm,
    addMsgError,
  }
}

export default RegisterContext
