import React from 'react'
import {
  Box,
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import { ModalInfo } from '../../../components'
import { useQuotationContext } from './QuotationContext'

import bgDenied from '../../../assets/img/bg-denied-quote.png'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 850,
      padding: 0,
    },
  },
  btn: {
    width: 150,
  },
  background: {
    backgroundImage: `url(${bgDenied})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '290px 485px',
    height: 485,
  },
  subtitle: {
    fontSize: 11,
  },
  midText: {
    maxHeight: 216,
    overflow: 'auto',
    marginBottom: 24,
  },
  values: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
}))

const RejectedModal = ({
  open, ticketNumber, solicitationNumber,
}) => {
  const classes = useStyles()
  const { description } = useQuotationContext()

  return (
    <ModalInfo
      open={open}
      close={false}
      className={classes.modal}
    >
      <Box textAlign="center">
        <Grid container justify="space-between">
          <Grid item xs={8}>
            <Box px={5}>
              <Box mt={3} textAlign="left" mb={1}>
                <Typography
                  className={classes.subtitle}
                  variant="subtitle2"
                  component="span"
                >
                  {description}
                </Typography>
              </Box>
              <Box textAlign="left">
                <Typography
                  component="span"
                  variant="subtitle2"
                  className={classes.values}
                >
                  {`Solicitação enviada com sucesso sob nº ${solicitationNumber}.`}
                </Typography>
              </Box>

              <Box my={1}>
                <Typography variant="body1" align="left">
                  <b>
                    {`Acompanhe através do chamado ${ticketNumber}.`}
                  </b>
                </Typography>
              </Box>

              <Box className={classes.midText}>
                <Typography variant="body2" align="justify">
                  <Box my={1}>
                    A presente Solicitação de Proposta de Garantia não representa, por si só, uma
                    Proposta de Seguro Garantia, tratando-se meramente de uma solicitação de análise
                    de viabilidade de operação reservada, exclusivamente, à Pottencial Seguradora.
                  </Box>
                  <Box my={1}>
                    Avaliada a possibilidade de prosseguir com a operação descrita neste formulário,
                    levando-se em conta aspectos cadastrais, técnicos e econômicos-financeiros,
                    a Pottencial Seguradora encaminhará formulário de Proposta de Seguro a ser
                    devidamente assinado pelo Proponente / Corretor, sendo, a referida Proposta,
                    o documento formal pelo qual a Seguradora procederá a emissão de Apólice de
                    Seguro, no prazo e demais itens previstos na Circular SUSEP 251/04.
                  </Box>
                  <Box my={1}>
                    Ressalta-se ainda que a Pottencial Seguradora reserva-se o direito de,
                    independentemente de prazo, não responder formalmente às Solicitações de Estudo
                    que forem por ela julgados inviáveis ou improcedentes.
                  </Box>
                </Typography>
              </Box>
              <Box mb={3} textAlign="right">
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.btn}
                  to="/cotacoes"
                  component={Link}
                  title="Voltar"
                >
                  Voltar
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4} className={classes.background} />
        </Grid>
      </Box>
    </ModalInfo>
  )
}

RejectedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  ticketNumber: PropTypes.string.isRequired,
  solicitationNumber: PropTypes.string.isRequired,
}

export default RejectedModal
