import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'

import UserAction from './UserAction'
import useUtils from '../../hooks/useUtils'
import { Paginator } from '../../components'

const useStyles = makeStyles((theme) => ({
  containerAction: {
    alignSelf: 'center',
  },
  statusContainer: {
    alignSelf: 'center',
  },
  statusIcon: {
    width: 28,
    height: 28,
  },
  small: {
    fontSize: '.8rem',
    position: 'absolute',
    bottom: 0,
  },
  statusMessage: {
    borderRadius: 10,
    textAlign: 'center',
    padding: 4,
    width: 80,
  },
  title: {
    fontWeight: 600,
  },
  card: {
    '& .MuiCardContent-root': {
      padding: theme.spacing(1.5),
    },
    '& .MuiGrid-item': {
      [theme.breakpoints.down('md')]: {
        order: 1,
        '&.xsOrder': {
          order: 0,
        },
      },
    },
  },
  statusActive: {
    backgroundColor: '#DEF1E5',
    '& span': {
      color: '#46835B',
    },
  },
  statusInactive: {
    backgroundColor: '#F7EEEA',
    '& span': {
      color: '#BE431D',
    },
  },
}))

const UserTable = ({
  users,
  results,
  updatePage,
  updateAction,
}) => {
  const classes = useStyles()
  const { formatPhoneOrCellphone } = useUtils()

  return (
    <>
      <Box mt={2}>
        {users.length === 0 && (
          <Paper>
            <Box px={2} py={3}>Não foi possível encontrar registros para esta pesquisa.</Box>
          </Paper>
        )}

        {users.map((item, index) => (
          <Box key={index} pt={1}>
            <Card className={classes.card} key={item.id} elevation={2}>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                >
                  <Grid item className="xsOrder" lg={3} sm={11} xs>
                    <Box position="relative" pb={2.5}>
                      <Typography variant="body2" className={classes.title}>Nome:</Typography>
                      <Typography variant="body2">{item.name}</Typography>
                      { item.administrator && (
                        <Typography component="span" className={classes.small}>Administrador</Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item lg={3} sm={6} xs={12}>
                    <Typography variant="body2" className={classes.title}>E-mail:</Typography>
                    <Typography variant="body2">{item.email}</Typography>
                  </Grid>

                  <Grid item lg={2} sm={6} xs={12}>
                    <Typography variant="body2" className={classes.title}>Cargo:</Typography>
                    <Typography variant="body2">{item.office}</Typography>
                  </Grid>

                  <Grid item lg={2} sm={6} xs={12}>
                    <Typography variant="body2" className={classes.title}>Telefone:</Typography>
                    <Typography variant="body2">{formatPhoneOrCellphone(item.phoneNumber)}</Typography>
                  </Grid>

                  <Grid className={classes.statusContainer} item lg={1} sm={6} xs={12}>
                    <Box className={`${classes.statusMessage} ${item.active ? classes.statusActive : classes.statusInactive}`}>
                      <Typography variant="body2" component="span">
                        {item.active ? 'Ativa' : 'Inativa'}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item className={`${classes.containerAction} xsOrder`} lg="auto" sm={1} xs="auto">
                    <UserAction
                      user={item}
                      updateAction={updateAction}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>

      <Paginator totalResults={results} changePaginator={updatePage} />
    </>
  )
}

UserTable.propTypes = {
  users: PropTypes.array.isRequired,
  results: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
}

UserTable.defaultProps = {}

export default UserTable
