import React, { useMemo } from 'react'
import {
  Typography,
  Box,
  makeStyles,
  Grid,
  Paper,
} from '@material-ui/core'
import { useBroker } from './BrokerViewContext'
import { CPF_SIZE_WITHOUT_MASK } from '../../components'
import useUtils from '../../hooks/useUtils'
import BankModal from './BankModal'
import PopOverHistory from './PopOverHistory'
import icoAlert from '../../assets/img/ico-alert-warning.svg'

const useStyles = makeStyles(() => ({
  root: {
    width: '90%',
    marginBottom: 10,
  },
  imgAlert: {
    textAlign: 'center',
    '& img': {
      maxWidth: 100,
      textAlign: 'center',
    },
  },
  icoHelp: {
    maxWidth: 15,
    marginBottom: 5,
  },
}))

const BankInfo = () => {
  const classes = useStyles()
  const { broker } = useBroker()
  const { formatCNPJ, formatCPF } = useUtils()

  const isBrokerPF = useMemo(() => {
    const { documentNumber = '' } = broker
    return documentNumber.length === CPF_SIZE_WITHOUT_MASK
  }, [broker])

  const brokerBank = useMemo(() => broker?.brokerBankReference || {}, [broker])

  const cpfOrCnpjBroker = useMemo(() => {
    const { documentNumber } = broker
    return isBrokerPF ? formatCPF(documentNumber) : formatCNPJ(documentNumber)
  }, [broker, isBrokerPF, formatCPF, formatCNPJ])

  return (
    <>
      <Grid container justify="center">
        <Paper className={classes.root}>
          <Box p={4} pt={2} pb={5}>
            <Box mb={5} spacing={2} className={classes.hoverButtonIcon}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography component="span" color="primary" className={classes.title}>
                    DADOS BANCÁRIOS
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="span">
                    <PopOverHistory />
                    <BankModal />
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={3}>
              <Grid item lg={7} sm={6} xs={12}>
                <Box display="inline" mr={1} fontWeight="fontWeightBold">Titular da conta:</Box>
                <Typography variant="body2" component="span">{broker?.name || ''}</Typography>
              </Grid>
              <Grid item lg={5} sm={6} xs={12}>
                <Box display="inline" mr={1} fontWeight="fontWeightBold">CPF/CNPJ do titular:</Box>
                <Typography variant="body2" component="span">{cpfOrCnpjBroker}</Typography>
              </Grid>
              <Grid item lg={7} sm={6} xs={12}>
                <Box display="inline" mr={1} fontWeight="fontWeightBold">Banco:</Box>
                <Typography variant="body2" component="span">{brokerBank?.bank || ''}</Typography>
              </Grid>
              <Grid item lg={5} sm={6} xs={12}>
                <Box display="inline" mr={1} fontWeight="fontWeightBold">Agência:</Box>
                <Typography variant="body2" component="span">{brokerBank?.branch || ''}</Typography>
              </Grid>
              <Grid item lg={7} sm={6} xs={12}>
                <Box display="inline" mr={1} fontWeight="fontWeightBold">Conta:</Box>
                <Typography variant="body2" component="span">
                  {`${brokerBank.accountNumber}-${brokerBank.accountDigit}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid container justify="center">
        <Paper className={classes.root}>
          <Box p={4} pt={2} pb={2}>
            <Grid container>
              <Grid item xs={12} md={2} className={classes.imgAlert}>
                <img src={icoAlert} alt="" />
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography component="span" variant="body2">
                  As comissões serão depositadas nessa conta fornecida.
                  Dessa forma, certifique-se de que os dados bancários estejam corretos
                  para que não haja problemas no pagamento.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </>
  )
}

export default BankInfo
