import React, { useState, useEffect } from 'react'
import {
  makeStyles, Typography, Grid, Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import useUtils from '../../hooks/useUtils'
import IconPottencial from '../../assets/img/ico_pottencial.svg'

const cardStyle = {
  boxShadow: '0px 3px 6px #00000029',
  padding: '20px 15px',
  maxWidth: '34%',
  width: '100%',
  borderRadius: '60px 60px 60px 0',
  textAlign: 'left',
  backgroundColor: '#FFF',
}
const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: '1px solid #E0E0E0',
  },
  message: {
    fontWeight: 400,
  },
  paperCardBot: {
    ...cardStyle,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
  },
  paperCardUser: {
    ...cardStyle,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
    borderRadius: '60px 60px 0 60px',
    textAlign: 'right',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginLeft: 'auto',
  },

  button: {
    color: '#fff',
  },
  logo: {
    fill: '#fff',
    marginRight: 10,
    marginTop: 'auto',
    marginBottom: 1,
  },

  dataUser: {
    textAlign: 'right',
  },

  textDate: {
    fontSize: 9,
    color: '#808080',
    paddingLeft: 37,
    paddingTop: 2,
  },
  dateHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const MessageList = ({ messages }) => {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const { formatDate, formatHours } = useUtils()

  /**
   * Ignore the type 'application/vnd.iris.ticket+json'
   */
  useEffect(() => {
    const data = messages.filter((item) => item.type !== 'application/vnd.iris.ticket+json').reverse()
    setItems(data)
  }, [messages])

  const messageSelectJson = (content, parent) => {
    const { text, options } = JSON.parse(content)
    return (
      <>
        <Typography variant="body2">{text}</Typography>
        {options.map((item, index) => (<div key={`${parent}_${index}`}>{item.text}</div>))}
      </>
    )
  }

  const messageMediaLink = (content) => {
    const { uri } = JSON.parse(content)
    return (
      <div className={classes.button}>
        <Button title="Arquivo" onClick={() => window.open(uri)}>Arquivo</Button>
      </div>
    )
  }

  const isSent = (message) => message.direction === 'sent'

  const isText = (message) => message.type === 'text/plain'

  const isSelectJson = (message) => message.type === 'application/vnd.lime.select+json'

  const isMediaLink = (message) => message.type === 'application/vnd.lime.media-link+json'

  return (
    <>
      {items.map((message, index) => (
        <div key={index}>
          <Typography variant="body2" className={classes.dateHeader}>{formatDate(message.date)}</Typography>

          <Grid container className={classes.grid}>
            {isSent(message) && (<Grid item className={classes.logo}><img src={IconPottencial} alt="logo" /></Grid>)}
            <Grid item className={isSent(message) ? classes.paperCardBot : classes.paperCardUser}>
              {/* text/plain */}
              {isText(message) && (<Typography variant="body2">{message.content}</Typography>)}

              {/* application/vnd.lime.select+json */}
              {isSelectJson(message) && (<>{messageSelectJson(message.content, index)}</>)}

              {/* application/vnd.lime.media-link+json */}
              {isMediaLink(message) && (<>{messageMediaLink(message.content)}</>)}
            </Grid>
            <Grid item xs={12} className={isSent(message) ? classes.dataBot : classes.dataUser}>
              <Typography variant="subtitle1" className={classes.textDate}>{formatHours(message.date)}</Typography>
            </Grid>
          </Grid>
        </div>
      ))}
    </>
  )
}

MessageList.propTypes = {
  messages: PropTypes.array.isRequired,
}

export default MessageList
