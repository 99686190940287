import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography, Box, makeStyles,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      paddingLeft: theme.spacing(4),
    },
    '& >svg': {
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(1),
      color: '#B4B4B4',
    },
  },
}))
const CustomAutocomplete = ({
  label, id, formik, ...props
}) => {
  const classes = useStyles()

  const updateFormik = (value) => {
    formik.setValues({ ...formik.values, [id]: value })
  }

  return (
    <div>
      <Box mb={1}>
        <Typography component="label" htmlFor={id}>
          {label}
        </Typography>
      </Box>
      <Box className={classes.root}>
        <SearchIcon />
        <Autocomplete
          {...props}
          id={id}
          onChange={(_, value) => updateFormik(value)}
        />
      </Box>
    </div>
  )
}

CustomAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  label: PropTypes.string,
}

CustomAutocomplete.defaultProps = {
  label: '',
}
export default CustomAutocomplete
