import React, { useState, useEffect } from 'react'

import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { RiEqualizerLine } from 'react-icons/ri'
import {
  makeStyles,
  Box,
  TextField,
  Grid,
  InputLabel,
  Button,
  FormControl,
  FormLabel,
  Typography,
} from '@material-ui/core'
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField'
import { DateInput, FilterContainer } from '../../../../components'
import useUtils from '../../../../hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1 / 2),
    fontSize: '1.5rem',
    transform: 'rotate(90deg)',
    borderRadius: 4,
  },
  formcontrol: {
    paddingBottom: theme.spacing(3),
  },
  dateRangeText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
}))

const DEFAULT_FILTERS = {
  clientName: '',
  policyNumber: '',
  insuredName: '',
  startDate: '',
  endDate: '',
  startIS: '',
  endIS: '',
  startPremium: '',
  endPremium: '',
  startCommission: '',
  endCommission: '',
}

const PolicyFilter = ({ addFilter, filters }) => {
  const classes = useStyles()
  const { isBlank } = useUtils()

  const [filterBox, setFilterBox] = useState(false)

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTERS },
    validationSchema: Yup.object({
      clientName: Yup.string().max(200),
      policyNumber: Yup.number(),
      insuredName: Yup.string().max(200),
      startDate: Yup.date(),
      endDate: Yup.date().when('startDate', (startDate) => startDate && Yup.date().min(startDate, 'A data final deve ser maior que a data inicial').required()),
      startIS: Yup.number(),
      endIS: Yup.number(),
      startPremium: Yup.number(),
      endPremium: Yup.number(),
      startCommission: Yup.number(),
      endCommission: Yup.number(),
    }),
    onSubmit: (data) => {
      const values = {
        ...data,
        startIS: data.startIS || '',
        endIS: data.endIS || '',
        startPremium: data.startPremium || '',
        endPremium: data.endPremium || '',
        startCommission: data.startCommission || '',
        endCommission: data.endCommission || '',
      }

      addFilter(values)
      setFilterBox(false)
    },
  })

  const { setValues } = formik

  const handleClear = () => {
    addFilter({ ...DEFAULT_FILTERS })
    formik.resetForm({ ...DEFAULT_FILTERS })
  }

  useEffect(() => {
    setValues({ ...filters })
  }, [filters, setValues])

  return (
    <>
      <Button className={classes.filterIcon}>
        <RiEqualizerLine title="Filtrar" onClick={() => setFilterBox(true)} />
      </Button>

      <FilterContainer
        open={filterBox}
        onClean={handleClear}
        onSearch={() => formik.submitForm()}
        onClose={() => setFilterBox(false)}
      >
        <Box mb={2}>
          <TextField
            id="clientName"
            color="secondary"
            label="Cliente:"
            title="Cliente"
            {...formik.getFieldProps('clientName')}
            error={formik.touched.clientName && !!formik.errors.clientName}
            helperText={formik.touched.clientName && formik.errors.clientName}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <TextField
            id="policyNumber"
            label="Número da apólice:"
            title="Número da apólice"
            color="secondary"
            {...formik.getFieldProps('policyNumber')}
            error={formik.touched.policyNumber && !!formik.errors.policyNumber}
            helperText={formik.touched.policyNumber && formik.errors.policyNumber}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <TextField
            id="insuredName"
            label="Segurado:"
            title="Segurado"
            color="secondary"
            {...formik.getFieldProps('insuredName')}
            error={formik.touched.insuredName && !!formik.errors.insuredName}
            helperText={formik.touched.insuredName && formik.errors.insuredName}
            fullWidth
          />
        </Box>

        <FormControl className={classes.formcontrol} fullWidth>
          <Box mt={2}>
            <FormLabel>Data da emissão:</FormLabel>
          </Box>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item lg={5} xs={12}>
              <DateInput
                id="startDate"
                label="Data Inicial"
                title="Data Inicial"
                {...formik.getFieldProps('startDate')}
                error={formik.touched.startDate && !!formik.errors.startDate}
                helperText={formik.touched.startDate && formik.errors.startDate}
                onChange={(event) => {
                  const { value } = event.target
                  if (isBlank(value)) {
                    formik.setFieldValue('endDate', '')
                  }
                  formik.setFieldValue('startDate', value)
                }}
              />
            </Grid>
            <Grid item lg={2} xs={12} className={classes.dateRangeText}>
              <Typography component="label" htmlFor="endDate">Até</Typography>
            </Grid>
            <Grid item lg={5} xs={12}>
              <DateInput
                id="endDate"
                label="Data Final"
                title="Data Final"
                disabled={!formik.values.startDate}
                {...formik.getFieldProps('endDate')}
                error={formik.touched.endDate && !!formik.errors.endDate}
                helperText={formik.touched.endDate && formik.errors.endDate}
                onChange={(event) => {
                  const { value } = event.target
                  formik.setFieldValue('endDate', value)
                }}
              />
            </Grid>
          </Grid>
        </FormControl>

        <Box mb={3}>
          <InputLabel>IS</InputLabel>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <CurrencyTextField
                fullWidth
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="startIS"
                title="Valor final"
                color="secondary"
                {...formik.getFieldProps('startIS')}
                error={formik.touched.startIS && !!formik.errors.startIS}
                helperText={formik.touched.startIS && formik.errors.startIS}
                onChange={(event, value) => formik.setFieldValue('startIS', value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="center">Até</Box>
            </Grid>
            <Grid item xs={5}>
              <CurrencyTextField
                fullWidth
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="endIS"
                title="Valor final"
                color="secondary"
                {...formik.getFieldProps('endIS')}
                error={formik.touched.endIS && !!formik.errors.endIS}
                helperText={formik.touched.endIS && formik.errors.endIS}
                onChange={(event, value) => formik.setFieldValue('endIS', value)}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <InputLabel>Prêmio Total</InputLabel>
          <Grid container alignItems="center">
            <Grid item xs={5}>
              <CurrencyTextField
                fullWidth
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="startPremium"
                title="Valor inicial"
                color="secondary"
                {...formik.getFieldProps('startPremium')}
                error={formik.touched.startPremium && !!formik.errors.startPremium}
                helperText={formik.touched.startPremium && formik.errors.startPremium}
                onChange={(event, value) => formik.setFieldValue('startPremium', value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="center">Até</Box>
            </Grid>
            <Grid item xs={5}>
              <CurrencyTextField
                fullWidth
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                id="endPremium"
                title="Valor final"
                color="secondary"
                {...formik.getFieldProps('endPremium')}
                error={formik.touched.endPremium && !!formik.errors.endPremium}
                helperText={formik.touched.endPremium && formik.errors.endPremium}
                onChange={(event, value) => formik.setFieldValue('endPremium', value)}
              />
            </Grid>
          </Grid>
        </Box>
      </FilterContainer>
    </>
  )
}

PolicyFilter.propTypes = {
  filters: PropTypes.object,
  addFilter: PropTypes.func,
}

PolicyFilter.defaultProps = {
  filters: {},
  addFilter: () => { },
}

export default PolicyFilter
