export const DEFAULT_THEME = {
  id: 'default',
  primary: '#F26522',
}

export const CONTEXT_EMPTY = '00000000-0000-0000-0000-000000000000'

export const MONTHS = {
  1: 'Jan',
  2: 'Fev',
  3: 'Mar',
  4: 'Abr',
  5: 'Mai',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez',
}

export const PRODUCTS_POTTENCIAL_ENUM = {
  DepositoRecursal: 'Depósito Recursal',
  Garantia: 'Garantia',
  GarantiaJudicial: 'Garantia Judicial',
  MaquinasEquipamentos: 'Máquinas e Equipamentos',
  Aluguel: 'Aluguel',
  Incendio: 'Incêndio',
  RiscosDeEngenharia: 'Riscos de Engenharia',
}

export const INVOICE_STATUS_ENUM = {
  Paid: 'Pago',
  ToExpire: 'A Vencer',
  Expired: 'Vencido',
  InProgress: 'Em Aberto',
}

export const BILLETS_STATUS_ENUM = {
  Pago: 'Pago',
  AVencer: 'A Vencer',
  Vencido: 'Vencido',
  EmAndamento: 'Em Aberto',
}

export const PRODUCTS_POTTENCIAL = [
  {
    id: 2,
    enum: 'DepositoRecursal',
    description: 'Depósito Recursal',
  },
  {
    id: 3,
    enum: 'Garantia',
    description: 'Garantia',
  },
  {
    id: 4,
    enum: 'GarantiaJudicial',
    description: 'Garantia Judicial',
  },
  {
    id: 5,
    enum: 'MaquinasEquipamentos',
    description: 'Máquinas e Equipamentos',
  },
  {
    id: 7,
    enum: 'Aluguel',
    description: 'Aluguel',
  },
  {
    id: 8,
    enum: 'Incendio',
    description: 'Incêndio',
  },
  {
    id: 9,
    enum: 'RiscosDeEngenharia',
    description: 'Riscos de Engenharia',
  },
]

export default {
  MONTHS,
  CONTEXT_EMPTY,
  DEFAULT_THEME,
  PRODUCTS_POTTENCIAL,
  INVOICE_STATUS_ENUM,
  BILLETS_STATUS_ENUM,
  PRODUCTS_POTTENCIAL_ENUM,
}
