import React from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Button,
  Grid,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import MessageList from './MessageList'
import useUtils from '../../hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 800,
    width: 800,
    borderRadius: 14,
    backgroundColor: '#f7f7f7',
    '& .MuiDialogContent-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: 0,
    },
  },

  footer: {
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '1.1rem',
  },
  message: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1, 3),
  },

  date: {
    marginLeft: 5,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
  close: {
    position: 'absolute',
    cursor: 'pointer',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}))

const HistoryDetail = ({
  opened, handleClose, messages, openDate, closeDate,
}) => {
  const classes = useStyles()
  const { formatDate } = useUtils()

  return (
    <Dialog
      open={opened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>Histórico de solicitações</DialogTitle>
      <Box px={3}>
        <Box>
          <Typography component="span" variant="body2" style={{ fontWeight: 'bold' }}>Início do atendimento: </Typography>
          <Typography variant="caption" className={classes.date}>{formatDate(openDate)}</Typography>
        </Box>
        <Box>
          <Typography component="span" variant="body2" style={{ fontWeight: 'bold' }}>Fim do atendimento: </Typography>
          <Typography variant="caption" className={classes.date}>{formatDate(closeDate)}</Typography>
        </Box>
      </Box>
      <CloseIcon
        title="Fechar"
        onClick={handleClose}
        className={classes.close}
      />
      <DialogContent>
        <Box className={classes.message}>
          <MessageList messages={messages} />
        </Box>
      </DialogContent>

      <DialogActions className={classes.footer}>
        <Grid p={2} container alignItems="center" justify="center">
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleClose}
              style={{ marginRight: 8 }}
              title="Voltar"
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

HistoryDetail.propTypes = {
  opened: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  openDate: PropTypes.string.isRequired,
  closeDate: PropTypes.string.isRequired,
}

export default HistoryDetail
