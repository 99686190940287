import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import useSecurity from '../../security/useSecurity'
import {
  PapperCard,
  Breadcrumb,
  MessageBox,
  CPF_SIZE_WITHOUT_MASK,
} from '../../components'

import icoBid from '../../assets/img/ico-product-bid.svg'
import icoAluguel from '../../assets/img/ico-product-aluguel.svg'
import icoMaquina from '../../assets/img/ico-product-maquina.svg'
import icoJudicial from '../../assets/img/ico-product-judicial.svg'
import icoGarantia from '../../assets/img/ico-product-garantia.svg'
import icoRecursal from '../../assets/img/ico-product-recursal.svg'
import icoEngenharia from '../../assets/img/ico-product-engenharia.svg'
import icoPerformance from '../../assets/img/ico-product-performance.svg'

const useStyles = makeStyles((theme) => ({
  title: {
    borderBottom: '1px solid #E0E0E0',
    '& img': {
      maxWidth: 70,
    },
  },
  message: {
    fontWeight: 400,
  },
  cardContainer: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '90%',
      margin: 'auto',
    },
  },

  description: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '.73rem',
    },
  },
}))

const DEFAULT_LABEL = 'Saiba mais'

const URL_GARANTIA = 'seguro/seguro-garantia'

const PRODUCTS = [
  {
    title: 'Garantia',
    icon: icoGarantia,
    description: 'Solução para empresas dos setores público e privado que precisam garantir o cumprimento das obrigações contratuais de uma parte.',
    relativeURL: URL_GARANTIA,
    label: DEFAULT_LABEL,
  },
  {
    title: 'Garantia Judicial',
    icon: icoJudicial,
    description: 'Dê continuidade às obrigações judiciais sem a necessidade de descapitalizar o patrimônio da empresas, otimizando custos e melhorando o fluxo de caixa, em substituição dos depósitos judiciais em dinheiro, penhora de bens e fianças bancárias.',
    relativeURL: 'seguro/seguro-garantia-judicial',
    label: DEFAULT_LABEL,
  },
  {
    title: 'Garantia Judicial Depósito Recursal',
    icon: icoRecursal,
    description: 'Permite a substituição dos depósitos judiciais, nos recursos da justiça do trabalho, por uma apólice de seguros. Está previsto na CLT e tem grande facilidade de contratação.',
    relativeURL: 'seguro/seguro-garantia-judicial-deposito-recursal',
    label: DEFAULT_LABEL,
  },
  {
    title: 'Garantia Performance',
    icon: icoPerformance,
    description: 'Garante o cumprimento de todas as obrigações assumidas no contrato para construção, fornecimento ou prestação de serviços, protegendo o segurado contra o risco de inadimplência do tomador.',
    relativeURL: URL_GARANTIA,
    label: DEFAULT_LABEL,
  },
  {
    title: 'Garantia do Licitante (BID)',
    icon: icoBid,
    description: 'Garante que, nas licitações públicas ou privadas, o tomador vencedor do certame mantenha sua proposta e assine o contrato nas condições apresentadas e dentro do prazo estabelecido no edital.',
    relativeURL: URL_GARANTIA,
    label: DEFAULT_LABEL,
  },
  {
    title: 'Pottencial Aluguel',
    icon: icoAluguel,
    description: 'É a modalidade de seguro que substitui outras garantias para locação de imóvel e que protege o proprietário em caso de não pagamento de aluguel e encargos por parte do inquilino.',
    relativeURL: 'seguro/pottencial-aluguel',
    label: DEFAULT_LABEL,
  },
  {
    title: 'Pottencial Máquinas e Equipamentos',
    icon: icoMaquina,
    description: 'Desenvolvido para proteger os mais diversos tipos de equipamentos, seja qual for o negócio ou atividade da empresa – agronegócio, indústria ou serviços.',
    relativeURL: 'seguro/pottencial-maquinas-e-equipamentos',
    label: DEFAULT_LABEL,
  },
  {
    title: 'Riscos de Engenharia',
    icon: icoEngenharia,
    description: 'Seguro desenvolvido para proporcionar a tranquilidade necessária na condução dos projetos de engenharia. Pode ser contratado para construções, reformas e ampliações.',
    relativeURL: 'seguro/riscos-de-engenharia',
    label: DEFAULT_LABEL,
  },
]

const PathInfo = [
  {
    text: 'Meus seguros',
    href: '/',
  },
  {
    text: 'Produtos',
    href: '',
  },
]

const Product = () => {
  const classes = useStyles()
  const history = useHistory()
  const { onlyFunctionality, getContext } = useSecurity()

  const [open, setOpen] = useState(false)
  const { name = '', mainDocument = '' } = getContext()

  useEffect(() => {
    const incomplete = onlyFunctionality('CADASTRO')
    setOpen(incomplete)
  }, [onlyFunctionality])

  return (
    <main>
      <MessageBox
        opened={open}
        maxWidth="xs"
        buttonPosition="below"
        title={`Olá, ${name}!`}
        handleClose={() => setOpen(false)}
        labelPrimary="Complementar Cadastro"
        labelSecondary="Cancelar"
        handlePrimary={() => history.push('/corretor/complemento')}
        handleSecondary={() => setOpen(false)}
      >
        <Box pt={2} pb={4}>
          <Typography
            variant="h6"
            align="center"
            className={classes.message}
          >
            {mainDocument.length !== CPF_SIZE_WITHOUT_MASK && 'Para ativarmos o cadastro da corretora é necessário completar algumas informações.'}
            {mainDocument.length === CPF_SIZE_WITHOUT_MASK && 'Para ativarmos o seu cadastro é necessário completar algumas informações.'}
          </Typography>
        </Box>
      </MessageBox>

      <Box mb={8}>
        <Breadcrumb paths={PathInfo} />
        <Typography variant="h5" component="h1">Produtos Pottencial</Typography>
      </Box>
      <Grid container className={classes.cardContainer}>
        <Grid item>
          <Grid container spacing={4}>
            {
              PRODUCTS.map((product, index) => (
                <Grid item lg={4} sm={6} xs={12} key={index}>
                  <PapperCard>
                    <>
                      <Box className={classes.title} pb={1} mb={3}>
                        <img src={product.icon} alt={product.title} />
                        <Box mt={1}>
                          <Typography component="h2" variant="h6" style={{ fontSize: '1rem' }}>{product.title}</Typography>
                        </Box>
                      </Box>

                      <Box mb={2}>
                        <Typography className={classes.description} variant="body2">{product.description}</Typography>
                      </Box>
                      <Box mt="auto">
                        <Button
                          component="a"
                          color="primary"
                          target="_blank"
                          variant="contained"
                          title={product.label}
                          href={`${window.ENV.SITE_URL}/${product.relativeURL}`}
                        >
                          {product.label}
                        </Button>
                      </Box>
                    </>
                  </PapperCard>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </main>
  )
}

export default Product
