import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '2px solid #EBEBEB',
    boxShadow: '0px 3px 6px #00000029',
    padding: theme.spacing(3, 4),
    borderRadius: '60px 60px 0 60px',
    width: '100%',
    height: '100%',
    transition: `all .3s ${theme.transitions.easing.easeInOut}`,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
}))

const PapperCard = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

PapperCard.propTypes = {
  children: PropTypes.element.isRequired,
}

export default PapperCard
