import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  Typography,
  Box,
  makeStyles,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'
import ReactTooltip from 'react-tooltip'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Error'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

import PolicyDetails from './PolicyDetails'
import PolicyStopModal from './PolicyStopModal'
import { useAlert, Paginator, MessageBox } from '../../../../components'
import useUtils from '../../../../hooks/useUtils'
import usePolicyClient from '../../../../clients/PolicyClient/usePolicyClient'
import SuccessModal from './SuccessModal'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    paddingRight: 5,
  },
  details: {
    padding: 8,
  },
  expand: {
    padding: '0 !important',
  },
  gridButton: {
    backgroundColor: '#efefef94',
    marginRight: 2,
  },
  gridDays: {
    display: 'flex',
  },
  expandIcon: {
    padding: 5,
  },
  daysRenew: {
    height: '100%',
  },
  stopIcon: {
    color: '#ff0000a6',
    fontSize: '2rem',
  },
  warningIcon: {
    color: '#808080',
    fontSize: '2rem',
  },
  checkIcon: {
    color: 'green',
  },
  actions: {
    [theme.breakpoints.up('md')]: {
      marginTop: 24,
    },
  },
  summary: {
    userSelect: 'inherit',
    cursor: 'text !important',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
}))

const Policies = ({
  policies, total, updatePage, updateAction,
}) => {
  const classes = useStyles()
  const { formatDate, date, isBlank } = useUtils()
  const { addMsgDanger } = useAlert()

  const policyClient = usePolicyClient()

  const [openConfirm, setOpenConfirm] = useState(false)
  const [policyRenew, setPolicyRenew] = useState({})
  const [expanded, setExpanded] = useState(false)
  const [stopOpen, setStopOpen] = useState(false)
  const [policy, setPolicy] = useState({})
  const [success, setSuccess] = useState(false)

  const handleStop = (currentPolicy) => {
    setPolicy(currentPolicy)
    setStopOpen(true)
  }

  const handleConfirmRenew = (item, open) => {
    setPolicyRenew(item)
    setOpenConfirm(open)
  }

  const getDiffDate = (dateInitial, dateFinal) => {
    if (isBlank(dateInitial) || isBlank(dateFinal)) {
      return ''
    }

    const inicial = date(dateInitial)
    const final = date(dateFinal)
    return final.diff(inicial, 'days')
  }

  const proposalStatus = (item) => {
    if (item?.bpmError !== 0) {
      return '-'
    }

    return ''
  }

  useEffect(() => {
    setExpanded(false)
  }, [policies])

  const handleDetails = useCallback((data, callBack) => {
    policyClient().getDetailsPolicy(data.proposalId).then(
      (response) => {
        setPolicy({ ...response.data, proposalNumber: data.proposalNumber })
        callBack()
      },
      (response) => {
        addMsgDanger(response.data)
      },
    )
  },
  [policyClient, addMsgDanger])

  const handleChange = (panel, isExpanded, data) => {
    if (!isExpanded) {
      handleDetails(data, () => setExpanded(panel))
    } else {
      setExpanded(false)
    }
  }

  const handleRenew = useCallback((current) => {
    const { proposalId } = current

    const values = {
      proposalId,
    }

    policyClient().renewPolicy(values).then(
      () => {
        handleConfirmRenew(current, false)
        setSuccess(true)
        updateAction()
      },
      (response) => {
        handleConfirmRenew({}, false)
        addMsgDanger(response.data)
      },
    )
  },
  [policyClient, updateAction, addMsgDanger])

  return (
    <>
      {stopOpen && (
        <PolicyStopModal
          open={stopOpen}
          setOpen={setStopOpen}
          onStopPolicy={updateAction}
          policy={policy}
        />
      )}

      {policies
        && policies.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Box mt={1} mb={1}>
              <Accordion expanded={expanded === `panel${index}`}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className={classes.summary}>
                  <Grid container justify="space-between">
                    <Grid item lg={5} sm={12}>
                      <Box display="flex" m={1}>
                        <Typography variant="body2" className={classes.title}>
                          Cliente:
                        </Typography>
                        <Typography variant="body2">{item?.name}</Typography>
                      </Box>

                      <Box display="flex" m={1}>
                        <Typography variant="body2" className={classes.title}>
                          Modalidade:
                        </Typography>
                        <Typography variant="body2">{item?.modality}</Typography>
                      </Box>

                      <Box display="flex" m={1}>
                        <Typography variant="body2" className={classes.title}>
                          Apólice:
                        </Typography>
                        <Typography variant="body2">
                          {item.policyNumber}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item lg={4} sm={12}>
                      <Box display="flex" m={1}>
                        <Typography variant="body2" className={classes.title}>
                          IS Atualizada:
                        </Typography>
                        <Typography variant="body2">
                          {item?.updatedIs && item?.bpmError === 0 && (
                            <Currency quantity={item?.updatedIs || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          )}
                          {proposalStatus(item)}
                        </Typography>
                      </Box>

                      <Box display="flex" m={1}>
                        <Typography variant="body2" className={classes.title}>
                          Novo prêmio a pagar:
                        </Typography>
                        <Typography variant="body2">
                          {item?.newPremium && item?.bpmError === 0 && (
                            <Currency quantity={item?.newPremium || 0} currency="R$ " locale="pt_BR" decimal="," group="." />
                          )}
                          {proposalStatus(item)}
                        </Typography>
                      </Box>

                      <Box display="flex" m={1}>
                        <Typography variant="body2" className={classes.title}>
                          Nova vigência:
                        </Typography>
                        <Typography variant="body2">
                          {item?.bpmError === 0 && (
                            `${formatDate(item?.startProposalPeriod)} até ${formatDate(item?.endProposalPeriod)}`
                          )}
                          {proposalStatus(item)}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item lg={1} xs={12} className={classes.gridDays}>
                      <Box display="flex" m={1} fontWeight="600" alignItems="center">
                        {item?.proposalStatus === 0 && item?.bpmError === 0 && (
                          <>
                            {getDiffDate(date(), item?.startProposalPeriod) - 60}
                            {' '}
                            dias para renovação
                          </>
                        )}

                        {(item?.proposalStatus === 4 || item?.bpmError !== 0) && (
                          <>
                            {getDiffDate(date(), item?.endPolicyPeriod)}
                            {' '}
                            dias para findar
                          </>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={2}>
                      <Box className={classes.actions} textAlign="center" mx={4}>
                        {item?.proposalStatus === 0 && item?.bpmError !== 0 && (
                          <>
                            <ErrorIcon
                              className={clsx(
                                classes.stopIcon,
                              )}
                              data-tip="Ocorreu um erro na sua renovação. Em caso de dúvida entre em contato com nossa Central de Atendimento."
                            />
                            <ReactTooltip place="top" type="dark" />
                          </>
                        )}

                        {item?.proposalStatus === 4 && (
                          <>
                            <AccessTimeIcon
                              className={clsx(
                                classes.warningIcon,
                              )}
                              color="primary"
                              data-tip="A renovação está sendo tratada internamente. Em caso de dúvida entre em contato com nossa Central de Atendimento."
                            />
                            <ReactTooltip place="top" type="dark" />
                          </>
                        )}

                        {item?.proposalStatus === 0 && item?.bpmError === 0 && (
                          <>
                            <IconButton
                              title="Interromper renovação"
                              className={clsx(
                                classes.gridButton,
                                classes.stopIcon,
                              )}
                              aria-label="Interromper renovação"
                              onClick={() => handleStop(item)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              title="Confirmar renovação"
                              className={clsx(
                                classes.gridButton,
                                classes.checkIcon,
                              )}
                              aria-label="Confirmar renovação"
                              onClick={() => handleConfirmRenew(item, true)}
                            >
                              <CheckIcon fontSize="small" />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </Grid>

                    {expanded === `panel${index}` && (
                      <Grid item xs={12}>
                        <AccordionDetails className={classes.details} style={{ flexDirection: 'column' }}>
                          <PolicyDetails
                            details={policy}
                            stopPolicy={() => handleStop(item)}
                            renewPolicy={() => handleConfirmRenew(item, true)}
                          />
                        </AccordionDetails>
                      </Grid>
                    )}

                    {item?.proposalStatus === 0 && (
                      <Grid item xs={12} className={classes.expand}>
                        <Box textAlign="center">
                          <IconButton
                            title="Ver detalhes"
                            className={clsx(
                              classes.buttonExpand,
                              classes.expandIcon,
                            )}
                            color="primary"
                            aria-label="Ver detalhes"
                            component="span"
                            onClick={() => handleChange(
                              `panel${index}`,
                              expanded === `panel${index}`,
                              item,
                            )}
                          >
                            <ExpandMoreIcon className={clsx({
                              [classes.rotate]: expanded === `panel${index}`,
                            })}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
              </Accordion>
            </Box>
          </Grid>
        ))}

      <MessageBox
        opened={openConfirm}
        handleClose={() => handleConfirmRenew({}, false)}
        title="Confirmar renovação"
        text="Deseja realmente renovar a apólice?"
        handleSecondary={() => handleConfirmRenew({}, false)}
        handlePrimary={() => handleRenew(policyRenew)}
      />

      <SuccessModal currentPolicy={policyRenew} open={success} setOpen={setSuccess} />

      <Paginator totalResults={total} changePaginator={updatePage} />
    </>
  )
}

Policies.propTypes = {
  policies: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
}

export default Policies
