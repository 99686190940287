import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'
import Box from '@material-ui/core/Box'
import Step from '@material-ui/core/Step'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'

import {
  Resolve,
  useAlert,
  MessageBox,
  Breadcrumb,
  StepControl,
  CPF_SIZE_WITHOUT_MASK,
} from '../../components'
import Bank from './Bank'
import Sponsor from './Sponsor'
import PersonBroker from './PersonBroker'
import CompanyBroker from './CompanyBroker'
import ComplementContext from './ComplementContext'
import useSecurity from '../../security/useSecurity'
import useBrokerClient from '../../clients/BrokerClient'
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics'
import thumbCadastro from '../../assets/img/ico-alert-success.svg'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    height: '100%',
  },
  dataContainer: {
    '& .paper': {
      padding: theme.spacing(4, 3),
    },
  },
  userContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 300,
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 400,
    },
  },
  btn: {
    width: '100%',
  },
  step: {
    flexDirection: 'column',
    width: 135,
    textAlign: 'center',
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
    },
    '& .MuiStepLabel-labelContainer': {
      textTransform: 'uppercase',
      marginTop: 4,
      '& .MuiStepLabel-label': {
        fontWeight: 600,
      },
    },
    '& .MuiStepLabel-completed, & .MuiStepLabel-active': {
      color: theme.palette.primary.main,
    },
  },
  containerStepper: {
    maxWidth: 550,
    margin: 'auto',
    marginBottom: theme.spacing(3),
    width: '100%',
    '& .MuiStepConnector-root': {
      height: 20,
      margin: -40,
      maxWidth: 120,
    },
    '& .MuiPaper-root': {
      justifyContent: 'center',
    },
  },
}))

const StepType = {
  BROKER: 0,
  BANK_DATA: 1,
  SPONSOR: 2,
}

const PathInfo = [
  {
    text: 'Espaço Corretor',
    href: '/',
  },
  {
    text: 'Cadastro',
    href: '',
  },
]

const BrokerComplement = () => {
  const { Provider } = ComplementContext

  const { addMsgDanger } = useAlert()
  const brokerClient = useBrokerClient()
  const { event } = useGoogleAnalytics()
  const { getContext, onlyFunctionality } = useSecurity()

  const classes = useStyles()
  const history = useHistory()

  const [steps, setSteps] = useState([])
  const [open, setOpen] = useState(false)
  const [broker, setBroker] = useState({})
  const [stepsRef, setStepsRef] = useState({})
  const [display, setDisplay] = useState('none')
  const [actionNext, setActionNext] = useState(false)
  const [actionPrevious, setActionPrevious] = useState(false)
  const [activeStep, setActiveStep] = useState(StepType.BROKER)

  useEffect(() => {
    // Ensures that access occurs only when the broker has an incomplete registration.
    if (!onlyFunctionality('CADASTRO')) {
      history.replace('/')
    }
  }, [onlyFunctionality, history])

  const isBrokerPF = useCallback((data = {}) => {
    const { documentNumber = '' } = data
    return documentNumber.length === CPF_SIZE_WITHOUT_MASK
  }, [])

  const onSubmitStep = () => {
    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((message) => addMsgDanger(message))
  }

  const handleResolve = useMemo(() => ({
    broker: () => new Promise((resolve, reject) => {
      const context = getContext()

      brokerClient().getBrokerById(context.personId).then((response) => {
        resolve(response.data)
      }, (error) => {
        reject()
        addMsgDanger(error.data)
      })
    }),
  }), [brokerClient, addMsgDanger, getContext])

  const handleLoaded = useCallback((data, resolve) => {
    setBroker(data.broker)

    const stepsControl = {}
    stepsControl[StepType.BROKER] = React.createRef()
    stepsControl[StepType.BANK_DATA] = React.createRef()

    if (isBrokerPF(data.broker)) {
      setSteps(['Corretor', 'Dados bancários'])
    } else {
      stepsControl[StepType.SPONSOR] = React.createRef()
      setSteps(['Corretora', 'Dados bancários', 'Responsável'])
    }
    setStepsRef(stepsControl)
    resolve()

    setTimeout(() => {
      const currentRef = stepsControl[StepType.BROKER]

      if (currentRef.current) {
        currentRef.current.onOpen().then(() => {
          setDisplay('block')
        })
      }
    })
  }, [isBrokerPF])

  const handleRedirectProduct = () => {
    history.replace('/corretor/concluido', {
      path: '/produto',
      broker: broker?.name || '',
      documentNumber: broker?.documentNumber || '',
    })
  }

  const handleRedirectRegister = () => {
    history.replace('/corretor/concluido', {
      path: '/corretor/visualizar',
      broker: broker?.name || '',
      documentNumber: broker?.documentNumber || '',
    })
  }

  const handleNext = () => {
    setActionNext(true)
    setActionPrevious(false)
    setTimeout(() => onSubmitStep())
  }

  const handlePrevious = () => {
    if (activeStep === StepType.BROKER) {
      history.replace('/produto')
      return
    }

    setActionNext(false)
    setActionPrevious(true)
    setTimeout(() => onSubmitStep())
  }

  const onNext = (data) => {
    let end = activeStep === StepType.SPONSOR

    if (isBrokerPF(broker)) {
      end = activeStep === StepType.BANK_DATA
    }

    if (end) {
      const { registrationComplementDuration = 0 } = data

      setOpen(true)
      event('Cadastro', 'Complemento de Cadastro - Tempo', registrationComplementDuration)
    } else {
      const nextStep = activeStep + 1
      const stepRef = stepsRef[nextStep]
      const currentRef = stepsRef[activeStep]

      stepRef.current.onOpen().then(() => {
        setActiveStep(nextStep)
        currentRef.current.onClose()
      })
    }
  }

  const onPrevious = () => {
    const previousStep = activeStep - 1
    const stepRef = stepsRef[previousStep]
    const currentRef = stepsRef[activeStep]

    stepRef.current.onOpen().then(() => {
      setActiveStep(previousStep)
      currentRef.current.onClose()
    })
  }

  return (
    <Provider
      value={{
        broker,
        actionNext,
        actionPrevious,
        setBroker,
        isBrokerPF,
        onNext,
        onPrevious,
      }}
    >
      <Resolve
        onLoaded={handleLoaded}
        resolve={handleResolve}
      >
        {/* CONTAINER PRINCIPAL */}
        <main>
          <Box display={display}>
            <Breadcrumb paths={PathInfo} />

            <Box mb={4}>
              <Typography variant="h4">Cadastro da corretora</Typography>
            </Box>

            <Grid container spacing={2} justify="center">

              {/* STEPS */}
              <Hidden xsDown>
                <Box className={classes.containerStepper}>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                      const labelProps = {
                        classes: {
                          horizontal: classes.step,
                        },
                      }
                      return (
                        <Step key={label}>
                          <StepLabel {...labelProps}>
                            {label}
                          </StepLabel>
                        </Step>
                      )
                    })}
                  </Stepper>
                </Box>
              </Hidden>

              {/* CONTEUDO */}

              {/* PJ */}
              {!isBrokerPF(broker) && (
                <Grid item xs={12} lg={10} className={classes.dataContainer}>
                  <CompanyBroker ref={stepsRef[StepType.BROKER]} />
                  <Bank ref={stepsRef[StepType.BANK_DATA]} />
                  <Sponsor ref={stepsRef[StepType.SPONSOR]} />
                </Grid>
              )}

              {/* PF */}
              {isBrokerPF(broker) && (
                <Grid item xs={12} lg={10} className={classes.dataContainer}>
                  <PersonBroker ref={stepsRef[StepType.BROKER]} />
                  <Bank ref={stepsRef[StepType.BANK_DATA]} />
                </Grid>
              )}

              {/* CONTROLES */}
              <Grid item xs={12} lg={10}>
                <Box>
                  <StepControl
                    steps={steps}
                    activeStep={activeStep}
                    handleNext={handleNext}
                    handleBack={handlePrevious}
                    finishText="Concluir"
                  />
                </Box>
              </Grid>
            </Grid>

            {/* MODAL DE CONCLUSÃO */}
            <MessageBox
              opened={open}
              thumb={thumbCadastro}
              handleClose={handleRedirectProduct}
              labelPrimary="Produtos Pottencial"
              labelSecondary="Ver cadastro"
              handlePrimary={handleRedirectProduct}
              handleSecondary={handleRedirectRegister}
              title="Obrigado por completar o cadastro!"
            />
          </Box>
        </main>
      </Resolve>
    </Provider>
  )
}

export default BrokerComplement
