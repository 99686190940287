import React, { useMemo } from 'react'
import {
  Box,
  Grid,
  Button,
  TextField,
  makeStyles,
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { DropzoneArea } from 'material-ui-dropzone'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import Typography from '@material-ui/core/Typography'
import BackupIcon from '@material-ui/icons/Backup'
import { ModalInfo, useAlert } from '../../../../components'

import usePolicyClient from '../../../../clients/PolicyClient/usePolicyClient'

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 850,
      padding: 0,
    },
  },
  dropzone: {
    minHeight: 'unset',
    borderColor: '#f265229c',
    background: '#FAFAFA',
    '& .MuiDropzoneArea-textContainer p': {
      fontSize: 15,
      color: 'gray',
    },
  },
  iconUpload: {
    fontSize: 45,
  },
  selectedMember: {
    ...theme.overrides.MuiButton.outlined,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
    padding: theme.spacing(1, 5),
    display: 'inline-block',
    borderRadius: '5px',
  },
}))

const DEFAULT_JUSTIFICATION = {
  justification: '',
  files: [],
}

const PolicyStopModal = ({
  open, setOpen, onStopPolicy, policy,
}) => {
  const classes = useStyles()
  const { addMsgDanger, addMsgSuccess } = useAlert()

  const policyClient = usePolicyClient()

  const formik = useFormik({
    initialValues: { ...DEFAULT_JUSTIFICATION },
    validationSchema: Yup.object({
      justification: Yup.string().max(500).required(),
      files: Yup.array(),
    }),
    onSubmit: (data) => {
      if (data.files.length === 0) {
        addMsgDanger('Pelo menos um documento deve ser inserido.')
        return
      }

      const values = {
        ...data,
        proposalId: policy.proposalId,
      }

      policyClient().stopPolicy(values).then(() => {
        addMsgSuccess('Interrupção enviada para análise.')
        onStopPolicy()
        setOpen(false)
      }, (response) => {
        addMsgDanger(response.data)
      })
    },
  })

  const files = useMemo(() => formik.values.files, [formik])

  function handleChange(file) {
    formik.setFieldValue('files', file)
  }

  return (
    <ModalInfo
      open={open}
      onClose={() => setOpen(false)}
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box p={7}>
        <Box textAlign="center" mb={3}>
          <Typography component="h1" variant="h5">Interromper a renovação</Typography>
        </Box>

        <Box mb={3}>
          <Typography variant="body1" component="span">
            Informe a justificativa para o desejo de interromper a renovação:
          </Typography>
        </Box>

        <Box mb={2}>
          <TextField
            id="justification"
            {...formik.getFieldProps('justification')}
            error={formik.touched.justification && !!formik.errors.justification}
            helperText={formik.touched.justification && formik.errors.justification}
            label="Digite aqui..."
            variant="outlined"
            multiline
            fullWidth
            rows={6}
          />
        </Box>

        <Box mb={4}>
          <DropzoneArea
            id="files"
            {...formik.getFieldProps('files')}
            dropzoneClass={classes.dropzone}
            Icon={() => (
              <>
                <Box mb={2}>
                  <BackupIcon color="primary" className={classes.iconUpload} />
                </Box>

                <Box mb={2}>
                  <Typography variant="body2" component="span">
                    Arraste o arquivo ou
                  </Typography>
                </Box>

                <Box mb={2}>
                  <Button
                    color="primary"
                    title="Selecione do seu computador"
                    variant="outlined"
                  >
                    Selecione do seu computador
                  </Button>
                </Box>

                <Box mb={2}>
                  <Typography variant="body2" component="span">
                    Extensões permitidas:
                    .pdf, .xls, .xlsx, .txt, .doc, .docx, .jpeg, .jpg, .png, .ods, .odt.
                  </Typography>
                </Box>
              </>
            )}
            onChange={handleChange}
            dropzoneText=""
            acceptedFiles={['application/pdf', '.xls', '.xlsx', '.txt', '.doc', '.docx', '.jpeg', '.jpg', '.png', '.ods', '.odt']}
            showPreviewsInDropzone={false}
            showAlerts={false}
          />

          {files.length > 0 && (
            <>
              <Box pt={2} pb={2}>
                {files.map((item, index) => (
                  <span key={index}>
                    <Box component="span" mr={1} my={1 / 2} className={classes.selectedMember}>
                      {item.name}
                    </Box>
                  </span>
                ))}
              </Box>
            </>
          )}
        </Box>

        <Grid container justify="center" spacing={2} className={classes.buttonsPad}>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              color="primary"
              title="Cancelar"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              title="Salvar"
              color="primary"
              variant="contained"
              onClick={() => formik.submitForm()}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ModalInfo>
  )
}

PolicyStopModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  onStopPolicy: PropTypes.func.isRequired,
}

export default PolicyStopModal
