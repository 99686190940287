import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  makeStyles,
  Box,
  Popover,
  Typography,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt'
import LockIcon from '@material-ui/icons/Lock'
import FeedBackModal from './FeedBackModal'

import useUserClient from '../../clients/UserClient'
import useSecurity from '../../security/useSecurity'
import { useAlert } from '../../components'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPopover-paper': {
      borderRadius: '14px',
    },
  },
  btn: {
    color: theme.palette.primary.contrastText,
  },
  name: {
    fontWeight: 600,
    fontSize: '1.05rem',
  },
  buttonsMenu: {
    border: '1px solid #8080805e',
    borderLeft: 'none',
    borderRight: 'none',
    width: '100%',
    borderRadius: '0px',
    fontWeight: 600,
  },
  buttonFeed: {
    borderBottom: 'none',
    display: 'initial',
  },
  buttonPwd: {
    borderBottom: 'none',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  iconForm: {
    color: 'gray',
    fontSize: '20px',
  },
  bold: {
    fontWeight: 600,
  },
  labelInfo: {
    color: '#9c9898',
    fontSize: '12px',
  },
}))

const UserMenu = ({ logout }) => {
  const userClient = useUserClient()
  const [anchorEl, setAnchorEl] = useState(null)
  const { addMsgDanger, addMsgSuccess } = useAlert()

  const {
    getName,
    getCargo,
    getProfile,
    getUsername,
    hasFunctionality,
  } = useSecurity()

  // POPOVER
  const handleClick = (event) => {
    setAnchorEl(event.target)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangePassword = () => {
    const mail = getUsername()

    userClient().requestPassword(mail).then(() => {
      addMsgSuccess('Mensagem foi enviada para <b>seu e-mail</b> com as instruções para definir uma nova senha.')
    }, (response) => {
      addMsgDanger(response.data)
    })
  }

  const classes = useStyles()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [openFeedBack, setOpenFeedBack] = useState(false)

  const handleOpenFeedBack = () => {
    setOpenFeedBack(true)
  }

  return (
    <>
      <Button
        className={classes.btn}
        onClick={handleClick}
        aria-controls="simple-menu"
        title={getName()}
      >
        {getName()}
        <KeyboardArrowDownIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box textAlign="center">
          <Box m={2}>
            <Typography variant="body2">{getCargo()}</Typography>
            <Typography variant="body2">{getUsername()}</Typography>
          </Box>

          {hasFunctionality('CADASTRO') && (
            <Box my={2} ml={2} mr={2}>
              <Typography variant="body2" className={classes.bold}>
                <Box component="span">Perfil:</Box>
                <Box component="span" m={1}>{getProfile()}</Box>
              </Typography>
            </Box>
          )}

          <Box>
            <Button
              className={`${classes.buttonsMenu} ${classes.buttonFeed}`}
              title="Alterar Senha"
              variant="outlined"
              onClick={handleOpenFeedBack}
            >
              <Box display="inline-flex" alignItems="center">
                <Box mr={1}>
                  <SentimentSatisfiedAltIcon className={classes.iconForm} />
                </Box>
                Dar Feedback
              </Box>

              <Typography className={classes.labelInfo} variant="body2">
                Ajude-nos a melhorar a experiência do Portal!
              </Typography>
            </Button>
          </Box>

          <Box>
            <Button
              className={`${classes.buttonsMenu} ${classes.buttonPwd}`}
              title="Alterar Senha"
              variant="outlined"
              onClick={handleChangePassword}
            >
              <Box mr={1}>
                <LockIcon className={classes.iconForm} />
              </Box>
              Alterar Senha
            </Button>
          </Box>

          <Box>
            <Button
              title="Sair"
              onClick={logout}
              className={`${classes.buttonsMenu} ${classes.buttonFeed}`}
            >
              <Box p={1}>
                SAIR
              </Box>
            </Button>
          </Box>
        </Box>
      </Popover>

      {openFeedBack && (
        <FeedBackModal open={openFeedBack} setOpen={setOpenFeedBack} />
      )}

    </>
  )
}

UserMenu.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default UserMenu
