import React, {
  useState,
  useEffect,
} from 'react'
import {
  Box,
  AppBar,
  Select,
  MenuItem,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { useAlert } from '../../components'
import useUserClient from '../../clients/UserClient'

import logo from '../../assets/img/logo.jpg'
import icoErro from '../../assets/img/ico-error.svg'

const useStyles = makeStyles(() => ({
  headerTitle: {
    display: 'flex',
    width: 160,
    '& img': {
      maxWidth: '100%',
    },
  },
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    maxWidth: '100%',
  },
  title: {
    color: '#595959',
    fontWeight: 600,
  },
  list: {
    paddingLeft: 16,
  },
  context: {
    width: 300,
  },
}))

const Restricted = () => {
  const classes = useStyles()
  const history = useHistory()
  const userClient = useUserClient()
  const { addMsgDanger } = useAlert()
  const [currentContext, setCurrentContext] = useState()

  const { contexts = [] } = useSelector(({ context }) => ({
    contexts: context.contexts,
  }))

  useEffect(() => {
    const userContexts = contexts
    const data = userContexts.find((item) => item.main)
    setCurrentContext(data)
  }, [contexts])

  const handleChangeContext = (contextId) => {
    userClient().updateUserContext(contextId).then(() => {
      history.replace('/reload')
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  return (
    <>
      <AppBar position="relative">
        <Container maxWidth="xl">
          <Box className={classes.headerTitle}>
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </Box>
        </Container>
      </AppBar>

      <Box className={classes.root}>
        <img
          src={icoErro}
          alt="Under development"
          className={classes.image}
        />

        <Typography variant="h1" className={classes.title}>Oops!</Typography>

        <Box my={5}>
          <Typography variant="h6">
            Parece que você não está usando uma rede corporativa autorizada.
          </Typography>

          <Box mt={1}>
            <Typography variant="subtitle1">
              <ul className={classes.list}>
                <li>
                  Verifique se está usando uma rede corporativa da sua corretora.
                </li>
                <li>
                  Cheque o status da conexão dessa rede.
                </li>

                {contexts.length > 1 && (
                  <li>
                    Ou tente acessar o portal de uma corretora diferente.
                    <Box mt={2} textAlign="center">
                      <Select
                        id="context"
                        color="primary"
                        variant="standard"
                        className={classes.context}
                        value={currentContext?.id || ''}
                        title={currentContext?.name || ''}
                        onChange={(e) => {
                          const { target } = e
                          handleChangeContext(target.value)
                        }}
                      >
                        {contexts.map((item) => (
                          <MenuItem key={item.id} value={item.id}>{item.shortName}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </li>
                )}
              </ul>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Restricted
