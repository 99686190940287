import React from 'react'
import {
  Box,
  AppBar,
  Button,
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'

import logo from '../../assets/img/logo.jpg'
import icoError from '../../assets/img/ico-error-status.svg'

const useStyles = makeStyles(() => ({
  headerTitle: {
    display: 'flex',
    width: 160,
    '& img': {
      maxWidth: '100%',
    },
  },
  root: {
    height: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    maxWidth: '100%',
  },
  title: {
    color: '#595959',
    fontWeight: 600,
  },
  status: {
    top: '47px',
    left: '18px',
    position: 'relative',
    fontSize: '2.4rem',
    fontWeight: '900',
    color: '#c0ccd8',
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
}))

const Error = () => {
  const classes = useStyles()
  const { status } = useParams()

  return (
    <>
      <AppBar position="relative">
        <Container maxWidth="xl">
          <Box className={classes.headerTitle}>
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </Box>
        </Container>
      </AppBar>

      <Box className={classes.root}>
        <Box component="span" className={classes.status}>{status}</Box>
        <img
          src={icoError}
          alt="Under development"
          className={classes.image}
        />

        <Typography variant="h1" className={classes.title}>Oops!</Typography>

        <Box my={5}>
          <Typography variant="h5">
            {status !== '403' && (
              <Box>Desculpe, parece que algo deu errado…</Box>
            )}

            {status === '403' && (
              <>
                <Box>Você não tem permissão para acessar essa funcionalidade.</Box>
                <Box>
                  Entre em contato com o Administrador da Corretora ou retorne
                  para o Portal clicando no botão a baixo.
                </Box>
              </>
            )}
          </Typography>
        </Box>

        <Button
          color="primary"
          variant="contained"
          to="/"
          component={Link}
          title="Voltar a página inicial"
        >
          Voltar a página inicial
        </Button>
      </Box>
    </>
  )
}

export default Error
