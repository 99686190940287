import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  btn: {
    width: '75%',
  },
}))

const ConcludedRegister = () => {
  const classes = useStyles()

  return (
    <>
      <Box mb={6} mt={8} align="center">
        <Typography variant="h5">Corretora cadastrada com sucesso!</Typography>
      </Box>

      <Box mb={8} align="center">
        <Typography variant="body2" align="center">Olá! Você já possui cadastro com a Pottencial, dessa forma realize</Typography>
        <Typography variant="body2" align="center">seu login para ativar o cadastro da corretora.</Typography>
      </Box>

      <Box align="center">
        <Box mt={3}>
          <Button
            fullWidth
            component="a"
            color="primary"
            variant="contained"
            className={classes.btn}
            href={window.location.origin}
            title="Login Portal do Cliente"
          >
            Login Portal do Cliente
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default ConcludedRegister
