import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {
  useAlert,
  PhoneInput,
} from '../../components'
import useYup from '../../hooks/useYup'
import YUP_MESSAGE from '../../helpers/yup'
import TermModal from '../../layouts/TermModal'
import useSecurity from '../../security/useSecurity'
import useBrokerClient from '../../clients/BrokerClient'
import { useBrokerComplement } from './ComplementContext'

const useStyles = makeStyles(() => ({
  checkboxTerm: {
    paddingRight: '2px',
    paddingTop: '5px',
  },
  buttonTerm: {
    paddingRight: '0px',
  },
}))

const DEFAULT_SPONSER = {
  name: '',
  phone: '',
  email: '',
  document: '',
  trustedDataConfirmation: false,
}

const Sponsor = forwardRef((props, ref) => {
  const termRef = useRef()
  const classes = useStyles()
  const { addMsgDanger } = useAlert()
  const { getContext } = useSecurity()
  const brokerClient = useBrokerClient()
  const [show, setShow] = useState(false)

  const {
    broker,
    actionNext,
    actionPrevious,
    setBroker,
    onNext,
    onPrevious,
  } = useBrokerComplement()

  const {
    phoneOrCellphone: phoneRule,
  } = useYup()

  const complete = (data) => {
    const context = getContext()

    brokerClient().complete(context.personId).then((response) => {
      onNext({ ...data, ...response.data })
    }, (error) => {
      addMsgDanger(error.data)
    })
  }

  const formik = useFormik({
    initialValues: { ...DEFAULT_SPONSER },
    validationSchema: Yup.object({
      phone: phoneRule.required(),
      document: Yup.string().required(),
      name: Yup.string().max(200).required(),
      email: Yup.string().email().max(200).required(),
      trustedDataConfirmation: Yup.boolean().test({
        message: YUP_MESSAGE.required,
        test: (value) => (actionNext ? value : true),
      }),
    }),
    onSubmit: (data) => {
      const context = getContext()
      const { brokerSponsor = {} } = broker

      const values = {
        ...data,
        idSponsor: brokerSponsor.id,
        idPhone: brokerSponsor.idPhone,
      }

      delete values.trustedDataConfirmation

      brokerClient().editSponsor(values, context.personId).then((response) => {
        const { trustedDataConfirmation } = data
        setBroker({ ...response.data, trustedDataConfirmation })

        if (actionNext) {
          complete({ ...response.data, trustedDataConfirmation })
        }

        if (actionPrevious) {
          onPrevious()
        }
      }, (error) => {
        addMsgDanger(error.data)
      })
    },
  })

  const { setValues } = formik

  useEffect(() => {
    const {
      brokerSponsor,
      trustedDataConfirmation,
    } = broker

    const {
      name,
      phone,
      email,
      document,
    } = brokerSponsor

    setValues({
      name: name || '',
      email: email || '',
      phone: phone || '',
      document: document || '',
      trustedDataConfirmation: trustedDataConfirmation || false,
    })
  }, [broker, setValues])

  useImperativeHandle(ref, () => ({
    onOpen: () => new Promise((resolve) => {
      setShow(true)
      resolve()
    }),
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: 'Verifique os campos em destaque antes de prosseguir.' }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
    onClose: () => setShow(false),
  }))

  return (
    <>
      {show && (
        <>
          <Paper className="paper">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography component="span" color="primary" className={classes.title}>
                  RESPONSÁVEL
                </Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  id="document"
                  color="secondary"
                  label="CPF*:"
                  title="CPF"
                  {...formik.getFieldProps('document')}
                  error={formik.touched.document && !!formik.errors.document}
                  helperText={formik.touched.document && formik.errors.document}
                  fullWidth
                  InputProps={{
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="name"
                  title="Nome"
                  color="secondary"
                  label="Nome*:"
                  {...formik.getFieldProps('name')}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                  fullWidth
                  InputProps={{
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="email"
                  title="E-mail"
                  color="secondary"
                  label="E-mail*:"
                  {...formik.getFieldProps('email')}
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                  InputProps={{
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <PhoneInput
                  id="phone"
                  title="Telefone"
                  color="secondary"
                  label="Telefone:"
                  {...formik.getFieldProps('phone')}
                  error={formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Paper>

          <TermModal
            ref={termRef}
            onAccept={() => {
              formik.setFieldValue('trustedDataConfirmation', true)
            }}
          />

          <Box ml={1} mt={2}>
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  id="trustedDataConfirmation"
                  name="trustedDataConfirmation"
                  className={classes.checkboxTerm}
                  checked={formik.values.trustedDataConfirmation}
                  onChange={(event) => {
                    formik.setFieldValue('trustedDataConfirmation', event.target.checked)
                  }}
                />
              )}
              label={(
                <>
                  <Box component="span">Ao concluir seu cadastro, você aceita nosso</Box>
                  <Box component="span">
                    <Button
                      color="primary"
                      className={classes.buttonTerm}
                      onClick={() => termRef.current.onOpen()}
                      title="Termos e condições"
                    >
                      Termos e condições
                    </Button>
                  </Box>
                  <Box component="span">.</Box>
                </>
              )}
            />
            <Box ml={-1}>
              <FormHelperText
                hidden={!formik.touched.trustedDataConfirmation
                  || !formik.errors.trustedDataConfirmation}
                error={formik.touched.trustedDataConfirmation
                  && !!formik.errors.trustedDataConfirmation}
              >
                {formik.errors.trustedDataConfirmation}
              </FormHelperText>
            </Box>
          </Box>
        </>
      )}
      {!show && <></>}
    </>
  )
})

export default Sponsor
