import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SlashScreen } from '../../components'
import useSecurity from '../../security/useSecurity'

const Home = () => {
  const history = useHistory()
  const { onlyFunctionality, hasFunctionality } = useSecurity()

  useEffect(() => {
    // Case Incomplete registration
    if (onlyFunctionality('CADASTRO')) {
      history.push('/produto')

      // Case you have permission to the panel
    } else if (hasFunctionality('PAINEL')) {
      history.push('/painel')

      // Case no panel permission
    } else {
      history.push('/produto')
    }
  }, [history, onlyFunctionality, hasFunctionality])

  return (<SlashScreen />)
}

export default Home
