import { useCallback } from 'react'
import axios from 'axios'

import useUtils from '../../hooks/useUtils'

const useInvoicesClient = () => {
  const { isBlank } = useUtils()

  const getInvoice = useCallback((filter) => {
    const params = {}
    const invoice = {}
    const others = {}

    if (!isBlank(filter.clientName)) {
      others.clientName = filter.clientName
    }

    if (!isBlank(filter.proposalNumber)) {
      others.proposalNumber = Number(filter.proposalNumber)
    }

    if (!isBlank(filter.policyNumber)) {
      others.policyNumber = filter.policyNumber
    }

    if (!isBlank(`${filter.period || ''}`) && filter.status === 'ToExpire') {
      others.period = filter.period
    }

    if (!isBlank(`${filter.number || ''}`)) {
      invoice.number = Number(filter.number)
    }

    if (!isBlank(`${filter.startAmount || ''}`)) {
      invoice.startAmount = filter.startAmount
    }

    if (!isBlank(`${filter.endAmount || ''}`)) {
      invoice.endAmount = filter.endAmount
    }

    if (!isBlank(filter.startDueDate)) {
      invoice.startDueDate = filter.startDueDate
    }

    if (!isBlank(filter.endDueDate)) {
      invoice.endDueDate = filter.endDueDate
    }

    if (!isBlank(filter.status)) {
      invoice.status = filter.status
    }

    if (filter.page) {
      params.page = { pageNumber: filter.page.pageNumber, pageSize: filter.page.pageSize }
    }

    if (Object.keys(others).length !== 0) {
      params.filter = { ...others }
    }

    if (Object.keys(invoice).length !== 0) {
      params.filter = { ...params.filter, invoice: { ...invoice } }
    }

    return axios.post('/api/invoice', params)
  }, [isBlank])

  const getSummary = useCallback(() => axios.get('/api/invoice/summary'), [])

  const getSumarryToExpire = useCallback((days) => axios.get(`/api/invoice/summary/to-expire?Days=${days}`), [])

  const getBilletBarcode = useCallback((id) => axios.get(`/api/billet/billetbarcode?BilletId=${id}`), [])

  const getDownloadBillet = useCallback((id) => axios.get(`/api/billet/download/pdf?ourBilletNumber=${id}`), [])

  const getSendBillet = useCallback((to, billet) => axios.post(`/api/billet/sendBillet?SendTo=${to}&OurNumberBillet=${billet}`), [])

  const getPaidOffReceipt = useCallback((invoice) => axios.get(`/api/invoice/paidoffreceipt?InvoiceId=${invoice}`), [])

  const getInstallment = useCallback((invoice) => axios.post('/api/installment', { invoiceNumber: +invoice }), [])

  const getDownloadInvoicePDF = useCallback((invoice) => axios.get(`/api/invoice/download/pdf?NumberInvoice=${invoice}`), [])

  const getDownloadInvoiceExcel = useCallback((invoice) => axios.get(`/api/invoice/download/excel?NumberInvoice=${invoice}`), [])

  return useCallback(() => ({
    getInvoice,
    getSummary,
    getSumarryToExpire,
    getBilletBarcode,
    getDownloadBillet,
    getSendBillet,
    getPaidOffReceipt,
    getInstallment,
    getDownloadInvoicePDF,
    getDownloadInvoiceExcel,
  }), [
    getInvoice,
    getSummary,
    getSumarryToExpire,
    getBilletBarcode,
    getDownloadBillet,
    getSendBillet,
    getPaidOffReceipt,
    getInstallment,
    getDownloadInvoicePDF,
    getDownloadInvoiceExcel,
  ])
}

export default useInvoicesClient
