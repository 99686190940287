import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  Grid,
  Card,
  Button,
  useTheme,
  Typography,
  CardContent,
  Box, makeStyles,
} from '@material-ui/core'
import { DescriptionOutlined } from '@material-ui/icons'

import PanelFallback from './PanelFallback'
import useSecurity from '../../security/useSecurity'
import PanelErrorFallback from './PanelErrorFallback'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    minHeight: '215px',
  },
  image: {
    height: '100%',
  },
  grid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  textDemanda: {
    fontSize: 12,
    color: '#343434',
    marginTop: 10,
    marginBottom: 25,
    fontWeight: 'normal',
  },
  subtitle2: {
    fontWeight: 'bold',
  },
  marginButton: {
    marginBottom: 10,
  },
  fallback: {
    minHeight: '128px',
  },
}))
const Demand = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { getDemand } = useSecurity()
  const demand = useMemo(() => getDemand(), [getDemand])

  return (
    <Card className={classes.root}>
      <CardContent>
        {demand.processing && (<PanelFallback className={classes.fallback} />)}

        {!demand.processing && !demand.success && (
          <PanelErrorFallback title="Demandas" className={classes.fallback} />
        )}

        {!demand.processing && demand.success && (
          <>
            <Box mb={2}>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="subtitle2" className={classes.subtitle2}>Cotações</Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid container alignItems="center" className={classes.grid}>
              <DescriptionOutlined
                style={{
                  color: theme.palette.primary.main,
                  fontSize: 35,
                }}
              />
              <Box my={0} color="#595959">
                <Typography variant="h6" component="p" className={classes.textDemanda}>Inclua uma nova cotação!</Typography>
              </Box>
              <Button
                className={classes.marginButton}
                color="primary"
                variant="contained"
                to="/cotacoes"
                component={Link}
                title="Incluir"
              >
                Incluir
              </Button>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default Demand
