import React, { useMemo } from 'react'
import {
  Box,
  Grid,
  Paper,
  Button,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import CoBroker from './CoBroker'

const useStyles = makeStyles(() => ({
  lineTitle: {
    height: '2px',
    background: '#f36523',
    border: 'none',
    width: '60px',
    float: 'left',
  },
  removeButton: {
    padding: 0,
  },
}))

const QuotationBrokers = ({ parentFormik }) => {
  const classes = useStyles()
  const { values, touched, errors } = parentFormik

  const coBrokers = useMemo(() => values.coBrokers || [], [values.coBrokers])
  const coBrokersErrors = useMemo(() => errors.coBrokers || [], [errors.coBrokers])
  const coBrokersTouched = useMemo(() => touched.coBrokers || [], [touched.coBrokers])

  const handleAdd = () => {
    parentFormik.setFieldValue('coBrokers', [...coBrokers, {
      id: '',
      brokerName: '',
      documentNumber: '',
      percentageOfParticipation: '',
    }])
  }

  const handleRemove = (index) => {
    const current = [...coBrokers]
    current.splice(index, 1)
    parentFormik.setFieldValue('coBrokers', current)
  }

  return (
    <Box component={Paper} p={4} my={2}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="subtitle1" component="h2">
            <Box fontWeight="bold">Co-Corretagem</Box>
            <hr className={classes.lineTitle} />
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={handleAdd}
          >
            <Typography
              variant="body2"
              component="span"
            >
              Adicionar Co-Corretor
            </Typography>
            <Box ml={2}>
              <AddCircleIcon color="primary" fontSize="small" />
            </Box>
          </Button>
        </Grid>
      </Grid>

      {coBrokers.map((coBroker, index) => (
        <CoBroker
          key={index}
          index={index}
          coBroker={coBroker}
          coBrokers={coBrokers}
          onRemove={handleRemove}
          errors={coBrokersErrors[index]}
          touched={coBrokersTouched[index]}
          onChange={(key, value) => parentFormik.setFieldValue(`coBrokers.${index}.${key}`, value)}
        />
      ))}
    </Box>
  )
}

QuotationBrokers.propTypes = {
  parentFormik: PropTypes.object.isRequired,
}

export default QuotationBrokers
