import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import { RiEqualizerLine } from 'react-icons/ri'
import TextField from '@material-ui/core/TextField'
import { makeStyles, Box } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { FilterContainer } from '../../components'

const useStyles = makeStyles((theme) => ({
  filterIcon: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1 / 2),
    fontSize: '1.5rem',
    transform: 'rotate(90deg)',
    borderRadius: 4,
  },
  formcontrol: {
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #E3E3E3',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  buttonOption: {
    padding: '6px 16px',
  },
}))

const DEFAULT_FILTERS = {
  name: '',
  email: '',
  office: '',
  active: '',
  administrator: '',
}

const UserFilter = ({ addFilter, filters }) => {
  const classes = useStyles()
  const [filterBox, setFilterBox] = useState(false)

  const formik = useFormik({
    initialValues: { ...DEFAULT_FILTERS },
    validationSchema: Yup.object({
      name: Yup.string().max(200),
      email: Yup.string().max(200),
      office: Yup.string().max(60),
      active: Yup.bool(),
      administrator: Yup.bool(),
    }),
    onSubmit: (data) => {
      addFilter({ ...data })
      setFilterBox(false)
    },
  })

  const { setValues } = formik

  useEffect(() => {
    setValues({ ...filters })
  }, [filters, setValues])

  const handleClear = () => {
    addFilter({ ...DEFAULT_FILTERS })
    formik.resetForm({ ...DEFAULT_FILTERS })
  }

  return (
    <>
      <Button title="Filtrar Usuários" className={classes.filterIcon}>
        <RiEqualizerLine onClick={() => setFilterBox(true)} />
      </Button>

      <FilterContainer
        open={filterBox}
        onClean={handleClear}
        onClose={() => setFilterBox(false)}
        onSearch={() => formik.submitForm()}
      >
        <Box mb={2}>
          <TextField
            id="name"
            label="Nome"
            title="Nome"
            color="secondary"
            {...formik.getFieldProps('name')}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
        </Box>

        <Box mb={2}>
          <TextField
            id="email"
            label="E-mail"
            title="E-mail"
            color="secondary"
            {...formik.getFieldProps('email')}
            error={formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <TextField
            id="office"
            label="Cargo"
            title="Cargo"
            color="secondary"
            {...formik.getFieldProps('office')}
            error={formik.touched.office && !!formik.errors.office}
            helperText={formik.touched.office && formik.errors.office}
            fullWidth
          />
        </Box>

        <FormControl className={`${classes.formcontrol} ${classes.marginBottom}`} fullWidth>
          <FormLabel>Situação:</FormLabel>
          <RadioGroup
            row
            id="active"
            {...formik.getFieldProps('active')}
            onChange={(event) => {
              const { value } = event.target
              formik.setFieldValue('active', JSON.parse(value))
            }}
          >
            <FormControlLabel
              value
              label="Ativa"
              title="Ativa"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value={false}
              label="Inativa"
              title="Inativa"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>

        <FormControl className={classes.formcontrol} fullWidth>
          <FormLabel>Perfil administrador:</FormLabel>
          <RadioGroup
            row
            id="administrator"
            {...formik.getFieldProps('administrator')}
            onChange={(event) => {
              const { value } = event.target
              formik.setFieldValue('administrator', JSON.parse(value))
            }}
          >
            <FormControlLabel
              value
              label="Sim"
              title="Sim"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value={false}
              label="Não"
              title="Não"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
      </FilterContainer>
    </>
  )
}

UserFilter.propTypes = {
  addFilter: PropTypes.func,
  filters: PropTypes.object,
}

UserFilter.defaultProps = {
  filters: {},
  addFilter: () => { },
}

export default UserFilter
