import React, {
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  List,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Collapse,
} from '@material-ui/core'

import {
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons'
import UserThumb from './UserThumb'
import HeaderMenu from './header/HeaderMenu'
import useSecurity from '../security/useSecurity'

import ccg from '../assets/img/ico-ccg.svg'
import users from '../assets/img/ico-users.svg'
import demanda from '../assets/img/ico-demanda.svg'
import clientes from '../assets/img/ico-clients.svg'
import limiteTaxa from '../assets/img/ico-limit.svg'
import produtos from '../assets/img/ico-produtos.svg'
import sinistro from '../assets/img/ico-sinistro.svg'
import dashboard from '../assets/img/ico-dashboard.svg'
import financeiro from '../assets/img/ico-financeiro.svg'
import atendimento from '../assets/img/ico-atendimento.svg'
import novademanda from '../assets/img/ico-new-demand.svg'
import areaCliente from '../assets/img/ico-area-client.svg'
import registerCliente from '../assets/img/ico-register-client.svg'
import apolicesMenu from '../assets/img/ico-apolicies-menu.svg'
import demandaNegadas from '../assets/img/ico-demand-denied.svg'
import prodBroker from '../assets/img/ico-production-broker.svg'
import demandaAndamento from '../assets/img/ico-demand-inprogress.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: theme.sizes.nav,
      textAlign: 'center',
    },
  },
  thumbContainer: {
    color: theme.palette.primary.contrastText,
    height: 290,
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: theme.sizes.nav,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  userContainer: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  listContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  listIcon: {
    minWidth: 35,
  },
  iconSmall: {
    maxWidth: 16,
  },
  icon: {
    maxWidth: 20,
  },
  iconBig: {
    maxWidth: 25,
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  textItem: {
    color: '#808080',
    '& span': {
      fontSize: '.8rem',
    },
  },
  textItemArrow: {
    color: '#808080',
    flex: 'none',
    '& span': {
      fontSize: '.8rem',
    },
  },
  subMenu: {
    '& .MuiListItem-root': {
      paddingLeft: 40,
    },
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
  subMenuArrow: {
    color: '#606060',
    width: '.8em',
  },
}))

const Nav = ({
  mobileOpen, handleDrawerToggle, logout,
}) => {
  const classes = useStyles()
  const [openDemands, setOpenDemands] = useState('')
  const { hasFunctionality, getSinister, getDemand } = useSecurity()

  const demand = useMemo(() => getDemand(), [getDemand])
  const sinister = useMemo(() => getSinister(), [getSinister])
  const container = window !== undefined ? () => window.document.body : undefined

  const handleToggleDemands = (menu, opened) => {
    if (opened) {
      setOpenDemands('')
    } else {
      setOpenDemands(menu)
    }
  }

  const items = (
    <List className={classes.listContainer}>

      {hasFunctionality('PAINEL') && (
        <ListItem className={classes.listItem} button component={Link} to="/painel">
          <ListItemIcon className={classes.listIcon}>
            <img src={dashboard} alt="Painel" className={classes.icon} />
          </ListItemIcon>
          <ListItemText className={classes.textItem} primary="Painel" />
        </ListItem>
      )}

      {hasFunctionality('DEMANDAS') && (
        <>
          <ListItem
            button
            disabled={!demand?.success}
            onClick={() => handleToggleDemands('Demandas', openDemands === 'Demandas')}
          >
            <ListItemIcon className={classes.listIcon}>
              <img src={demanda} alt="Cotações" className={classes.icon} />
            </ListItemIcon>
            <ListItemText className={classes.textItemArrow} primary="Cotações" />
            {openDemands === 'Demandas' ? <ExpandLess className={classes.subMenuArrow} /> : <ExpandMore className={classes.subMenuArrow} />}
          </ListItem>

          <Collapse className={classes.subMenu} in={openDemands === 'Demandas'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.textItem} component={Link} to="/cotacoes">
                <ListItemIcon>
                  <img src={novademanda} alt="Nova Cotação" className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary="Nova Cotação" />
              </ListItem>

              <ListItem button className={classes.textItem} component={Link} to="/em-progresso">
                <ListItemIcon>
                  <img src={demandaAndamento} alt="Em Andamento" className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary="Em Andamento" />
              </ListItem>

              <ListItem button className={classes.textItem} component={Link} to="/operacao-negada">
                <ListItemIcon>
                  <img src={demandaNegadas} alt="Cotações Negadas" className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary="Cotações Negadas" />
              </ListItem>
            </List>
          </Collapse>
        </>
      )}

      {hasFunctionality('APOLICES') && (
      <>
        <ListItem button onClick={() => handleToggleDemands('Apolices', openDemands === 'Apolices')}>
          <ListItemIcon className={classes.listIcon}>
            <img src={apolicesMenu} alt="Apolices" className={classes.iconBig} />
          </ListItemIcon>
          <ListItemText className={classes.textItemArrow} primary="Apolices" />
          {openDemands === 'Apolices' ? <ExpandLess className={classes.subMenuArrow} /> : <ExpandMore className={classes.subMenuArrow} />}
        </ListItem>

        <Collapse className={classes.subMenu} in={openDemands === 'Apolices'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.textItem} component={Link} to="/apolices/emitidas">
              <ListItemIcon>
                <img src={novademanda} alt="Apólices Emitidas" className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Apólices Emitidas" />
            </ListItem>

            <ListItem button className={classes.textItem} component={Link} to="/apolices/acompanhar">
              <ListItemIcon>
                <img src={novademanda} alt="Acompanhar Apólices" className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Acompanhar Apólices" />
            </ListItem>
          </List>
        </Collapse>
      </>
      )}

      {hasFunctionality('FINANCEIRO') && (
        <ListItem className={classes.listItem} button component={Link} to="/financeiro">
          <ListItemIcon className={classes.listIcon}>
            <img src={financeiro} alt="Financeiro" className={classes.icon} />
          </ListItemIcon>
          <ListItemText className={classes.textItem} primary="Financeiro" />
        </ListItem>
      )}

      {hasFunctionality('USUARIOS') && (
        <ListItem className={classes.listItem} button component={Link} to="/usuario/listar">
          <ListItemIcon className={classes.listIcon}>
            <img src={users} alt="Usuários" className={classes.iconBig} />
          </ListItemIcon>
          <ListItemText className={classes.textItem} primary="Usuários" />
        </ListItem>
      )}

      {hasFunctionality(['LIMITE_TAXA', 'CCG', 'AREA_CLIENTE']) && (
        <>
          <ListItem button onClick={() => handleToggleDemands('Clientes', openDemands === 'Clientes')}>
            <ListItemIcon className={classes.listIcon}>
              <img src={clientes} alt="Clientes" className={classes.iconBig} />
            </ListItemIcon>
            <ListItemText className={classes.textItemArrow} primary="Clientes" />
            {openDemands === 'Clientes' ? <ExpandLess className={classes.subMenuArrow} /> : <ExpandMore className={classes.subMenuArrow} />}
          </ListItem>

          <Collapse className={classes.subMenu} in={openDemands === 'Clientes'} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {hasFunctionality('AREA_CLIENTE') && (
                <ListItem button className={classes.textItem} component={Link} to="/cadastro-clientes">
                  <ListItemIcon>
                    <img src={registerCliente} alt="Cadastro" className={classes.iconBig} />
                  </ListItemIcon>
                  <ListItemText primary="Cadastro" />
                </ListItem>
              )}

              {hasFunctionality('LIMITE_TAXA') && (
                <ListItem button className={classes.textItem} component={Link} to="/limite">
                  <ListItemIcon>
                    <img src={limiteTaxa} alt="Limite de Taxa" className={classes.iconSmall} />
                  </ListItemIcon>
                  <ListItemText primary="Limite de Taxa" />
                </ListItem>
              )}

              {hasFunctionality('CCG') && (
                <ListItem button className={classes.textItem} component={Link} to="/ccg">
                  <ListItemIcon>
                    <img src={ccg} alt="CCG" className={classes.iconSmall} />
                  </ListItemIcon>
                  <ListItemText primary="CCG" />
                </ListItem>
              )}

              {hasFunctionality('AREA_CLIENTE') && (
                <ListItem button className={classes.textItem} component={Link} to="/demanda-cliente">
                  <ListItemIcon>
                    <img src={areaCliente} alt="Área do Cliente" className={classes.iconBig} />
                  </ListItemIcon>
                  <ListItemText primary="Área do Cliente" />
                </ListItem>
              )}
            </List>
          </Collapse>
        </>
      )}

      {hasFunctionality('PRODUCAO_CORRETORA') && (
        <ListItem className={classes.listItem} button component={Link} to="/demanda-produto">
          <ListItemIcon className={classes.listIcon}>
            <img src={prodBroker} alt="Produção Corretora" className={classes.icon} />
          </ListItemIcon>
          <ListItemText className={classes.textItem} primary="Produção Corretora" />
        </ListItem>
      )}

      <ListItem className={classes.listItem} button component={Link} to="/produto">
        <ListItemIcon className={classes.listIcon}>
          <img src={produtos} alt="Produtos Pottencial" className={classes.iconBig} />
        </ListItemIcon>
        <ListItemText className={classes.textItem} primary="Produtos Pottencial" />
      </ListItem>

      {hasFunctionality('SINISTRO') && (
        <ListItem
          button
          component="a"
          target="_blank"
          href={sinister.url}
          className={classes.listItem}
          disabled={!sinister?.success}
        >
          <ListItemIcon className={classes.listIcon}>
            <img src={sinistro} alt="Sinistro" className={classes.iconBig} />
          </ListItemIcon>
          <ListItemText className={classes.textItem} primary="Sinistro" />
        </ListItem>
      )}

      <ListItem className={classes.listItem} button component={Link} to="/atendimento-ao-cliente">
        <ListItemIcon className={classes.listIcon}>
          <img src={atendimento} alt="Atendimento" className={classes.iconBig} />
        </ListItemIcon>
        <ListItemText className={classes.textItem} primary="Atendimento" />
      </ListItem>
    </List>
  )

  return (
    <nav className={classes.root}>
      <Box className={classes.thumbContainer}>
        <UserThumb constrast />
      </Box>
      <Box>
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onOpen={() => { }}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Box p={1} className={classes.userContainer}>
              <UserThumb constrast />
              <HeaderMenu logout={logout} />
            </Box>

            {items}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown>{items}</Hidden>
      </Box>
    </nav>
  )
}

Nav.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}

export default Nav
