import { useCallback } from 'react'
import axios from 'axios'

const useLocationClient = () => {
  const getStates = useCallback(() => axios.get('/api/resources/states'), [])

  const getAddressByZipCode = useCallback((zipCode) => axios.get(`/api/resources/correios/${zipCode}`), [])

  return useCallback(() => ({
    getStates,
    getAddressByZipCode,
  }), [
    getStates,
    getAddressByZipCode,
  ])
}

export default useLocationClient
